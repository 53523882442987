<template>
  <div>
    <div class="fixed top-0 w-full sm:w-[480px]">
      <div class="flex top-0 flex h-max justify-between py-4 align-middle p-4" style="z-index: 50; ">
        <router-link 
         to="/profile"
          class="flex h-max align-middle relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
          aria-label="Quay lại"
        >
          <svg
            class="h-7 w-7"
            height="1em"
            width="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
              fill="currentColor"
            />
          </svg>
        </router-link>
        <h3 class=" rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none  h-8 text-primary-400  hover:text-primary-300  uppercase font-semibold text-gray-1100">
        {{$t('upgrade_account.upgrade_account')}}
        </h3>
        <button @click="makePhoneCall"
          class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none border h-8 text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 text-md uppercase"
          type="button"
          style="margin-right: 10px;"
        >
          <span>{{$t('support.support')}}</span>
          <span class="text-md ml-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.3333 5.4165C13.9167 3.83317 12 2.9165 10 2.9165C8 2.9165 6.08333 3.83317 4.66667 5.4165C3.25 6.99984 2.5 9.08317 2.5 11.2498V14.5832C2.5 15.9998 3.58333 17.0832 5 17.0832C6.41667 17.0832 7.5 15.9998 7.5 14.5832V12.0832C7.5 10.6665 6.41667 9.58317 5 9.58317C4.75 9.58317 4.58333 9.58317 4.33333 9.6665C4.58333 8.49984 5.16667 7.33317 5.91667 6.49984C7 5.24984 8.5 4.58317 10 4.58317C11.5 4.58317 13 5.24984 14.0833 6.49984C14.8333 7.33317 15.4167 8.49984 15.6667 9.6665C15.4167 9.58317 15.25 9.58317 15 9.58317C13.5833 9.58317 12.5 10.6665 12.5 12.0832V14.5832C12.5 15.9998 13.5833 17.0832 15 17.0832C16.4167 17.0832 17.5 15.9998 17.5 14.5832V11.2498C17.5 9.08317 16.75 6.99984 15.3333 5.4165ZM5 11.2498C5.5 11.2498 5.83333 11.5832 5.83333 12.0832V14.5832C5.83333 15.0832 5.5 15.4165 5 15.4165C4.5 15.4165 4.16667 15.0832 4.16667 14.5832V12.0832C4.16667 11.5832 4.5 11.2498 5 11.2498ZM15.8333 14.5832C15.8333 15.0832 15.5 15.4165 15 15.4165C14.5 15.4165 14.1667 15.0832 14.1667 14.5832V12.0832C14.1667 11.5832 14.5 11.2498 15 11.2498C15.5 11.2498 15.8333 11.5832 15.8333 12.0832V14.5832Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </button>
      </div>
      
    </div>  
  <div v-if="ismenu===false" class="relative form-group" style="z-index: 10;" >
    <img
      alt="Introduce platform fee"
      class="absolute rounded-lg top-10 mt-10 w-full sm:w-[480px]"
      :src="datashop.avatar"
      :style="{ backgroundColor: datashop.background_color_1 ,}"
      style="margin-top: 60px;"
    />
    <div class="absolute z-10 w-full bg-white px-4  rounded-t-3xl sm:top-[450px]" style="top: 200px;">
      <div class="space-y-1">
        <p class="text-center text-3xl font-bold text-primary-600">
          {{$t('title_form_platform_fee.title_form_platform_fee')}}
        </p>
        <p class="text-center text-gray-600 text-nm">
          {{$t('title_form_platform_fee2.title_form_platform_fee2')}}
          {{datashop.name  }}
          {{$t('title_form_platform_fee3.title_form_platform_fee3')}}
        </p>
      </div>
      <div class="mt-8 space-y-2">
        <button id="select4" class="w-full rounded-lg border px-4 py-5 text-left  bg-primary-25 " @click="change(4)">
          <p class="font-bold text-lg">{{member_data.location_name}}</p>
          <div class="space-x-1">
            <p v-if="member_data.price && member_data.month_number" class="inline whitespace-nowrap font-bold text-nm">
              {{formatPrice(manager_data.price)}} / {{ member_data.month_number }}{{$t('month.month')}}
            </p>
            <p v-else class="inline whitespace-nowrap font-bold text-nm">
              {{$t('free.free')}}
            </p>
            <!-- <p class="inline text-nm">•</p> -->
           
              <div v-html="member_data.content"></div>
            
          </div>
        </button>
        <button id="select3" class="w-full rounded-lg border px-4 py-5 text-left  bg-primary-25 " @click="change(3)">
          <p class="font-bold text-lg">{{manager_data.location_name}}</p>
          <div class="space-x-1">
            <p v-if="manager_data.price && manager_data.month_number" class="inline whitespace-nowrap font-bold text-nm">
              {{formatPrice(manager_data.price)}} / {{ manager_data.month_number }} {{$t('month.month')}}
            </p>
            <p v-else class="inline whitespace-nowrap font-bold text-nm">
              {{$t('free.free')}}
            </p>
            <!-- <p class="inline text-nm">•</p> -->
           
              <div v-html="manager_data.content"></div>
            
          </div>
        </button>
        <button id="select2" class="w-full rounded-lg border px-4 py-5 text-left  bg-primary-25 " @click="change(2)">
          <p class="font-bold text-lg">{{dealer_data.location_name}}</p>
          <div class="space-x-1">
            <p v-if="dealer_data.price && dealer_data.month_number" class="inline whitespace-nowrap font-bold text-nm">
              {{formatPrice(dealer_data.price)}} / {{ dealer_data.month_number }} {{$t('month.month')}}
            </p>
            <p v-else class="inline whitespace-nowrap font-bold text-nm">
              {{$t('free.free')}}
            </p>
            <!-- <p class="inline text-nm">•</p> -->
           
              <div v-html="dealer_data.content"></div>
            
          </div>
        </button>
        <button id="select1" class="w-full rounded-lg border px-4 py-5 text-left  bg-primary-25 " @click="change(1)">
          <p class="font-bold text-lg">{{director_data.location_name}}</p>
          <div class="space-x-1">
            <p v-if="director_data.price && director_data.month_number" class="inline whitespace-nowrap font-bold text-nm">
              {{formatPrice(director_data.price)}} / {{ director_data.month_number }}  {{$t('month.month')}}
            </p>
            <p v-else class="inline whitespace-nowrap font-bold text-nm">
              {{$t('free.free')}}
            </p>
            <!-- <p class="inline text-nm">•</p> -->
           
              <div v-html="director_data.content"></div>
            
          </div>
        </button>
      
        
       
        <!-- <button id="select1" class="w-full rounded-lg border px-4 py-5 text-left  bg-primary-25 " @click="change(1)">
          <p class="font-bold text-lg">Cộng tác viên</p>
          <div class="space-x-1">
            <p class="inline whitespace-nowrap font-bold text-nm">
              ₫599,000 / 6 Tháng
            </p>
            <p class="inline text-nm">•</p>
            <p class="inline text-nm">
              Tặng ngay khoá học kinh doanh online nền tảng từ A tới Z.
            </p>
          </div>
        </button>
        <button id="select2" class="w-full rounded-lg border px-4 py-5 text-left bg-primary-25 "  @click="change(2)">
          <div class="flex items-center gap-2">
            <p class="font-bold text-lg">Đại lý chính thức</p>
            <p class="rounded-2xl bg-gradient-to-r from-red-600 to-red-200 px-2 py-px text-white text-sl">
    - 80% ký quỹ
</p>
          </div>
          <div class="space-x-1">
            <p class="inline whitespace-nowrap font-bold text-nm">
              ₫1,599,000
            </p>
            <p class="inline text-nm">•</p>
            <p class="inline text-nm">
              Đã bao gồm 6 tháng sử dụng và được tặng khoá học kinh doanh
              online nền tảng
            </p>
          </div>
        </button> -->
      </div>
      <!-- <p class="mt-1 py-4 text-center font-normal text-primary-400 text-sm" @click="iopenmenu">Xem chi tiết gói</p> -->
      <footer class="sticky w-full absolute bottom-0 left-0 right-0">
          <footer class="inset-x-0 bottom-0">
            <div  class="flex h-[72px] items-center border-t border-gray-200 pb-0 bg-white px-4 sm:container flex w-full justify-center">
              <router-link
                class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300 max-w-md" to="/login"   >
              {{$t('title_login_other.title_login_other')}}
              </router-link>
            </div>
            <div
              class="flex h-[72px] items-center border-t border-gray-200 bg-white px-4 sm:container flex w-full justify-center">
             
              <button
                class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300 max-w-md"    @click="Save">
               
                <div v-if="isloading===true" data-v-3fcda164="" class="icon-inside-textarea">
                <span data-v-3fcda164="" style="display: none;">Loading...</span>
                <i data-v-3fcda164="" class="fa fa-spinner fa-spin"></i>
              </div>
              
              <p v-if="isloading===false"> {{$t('buy_gift.buy_gift')}}</p> 
              </button>
            </div>
          </footer>
        </footer>
     
    </div>
  </div>
 
</div>
</template>

<script>
import {getPositions,signupsuccess} from "@/services/authentication/authService";

// import { getLogin } from "../../../services/authentication/authService";
import { useToast } from "vue-toastification";
export default {
  name: "PlatformFeeBanner",
  data() {
   return {
    ismenu:false,
    isCTv: true,
    Positions:'',
    dealer_data:'',
    director_data:'',
    manager_data:'',
    member_data:'',
    type:'',
    isloading:false,
    datashop:'',
   };
 },
 methods: {
  getdatashop(){
    this.datashop = JSON.parse(localStorage.getItem("getshop")) ;
  },
  formatPrice(price) {
        // Chuyển số thành chuỗi
        let priceString = price.toString();
        // Tạo mảng chứa các phần tử
        let parts = [];
        // Thêm từng ba chữ số vào mảng
        while (priceString.length > 3) {
            parts.unshift(priceString.slice(-3));
            priceString = priceString.slice(0, -3);
        }
        // Thêm phần còn lại (ít hơn ba chữ số) vào mảng
        if (priceString) {
            parts.unshift(priceString);
        }
        // Kết hợp các phần và thêm dấu chấm vào giữa chúng
        return parts.join('.') + ' ₫';
  },
  Save(){
    this.isloading= true;
    this.signupsuccess();
  },
  async signupsuccess(){
    const package_type = this.type;
    let data ='';
      if (this.dealer_data && this.dealer_data.package_type == package_type) {
          data = this.dealer_data;
          console.log("1");
      }else if (this.director_data && this.director_data.package_type == package_type) { 
        data = this.director_data; console.log("2");
      }else if (this.manager_data && this.manager_data.package_type == package_type) {
        data = this.manager_data;console.log("3");
      }else if (this.member_data && this.member_data.package_type == package_type) {
        data = this.member_data;console.log("4");
      }

    const body={
    "customer_id":localStorage.getItem("customer_id"),
    "package_type":package_type,
    "name":data.location_name,
    "price":data.price,
    // "price":1000,
    "plan":data.plan,
    "month_number":data.month_number
}
const toast = useToast();
  // console.log(data,body);
    const response = await signupsuccess(body);
    // console.log(response);
    if(response.data.status == 1){
      if(response.data.package_type_check === true){
        this.isloading= false;
        const mes= 'package_purchase_successful';
        toast.success(this.$t(`${mes}.${mes}`));
        // toast.error(this.$t(`${mes}.${mes}`)); 
        setTimeout(() => {
          toast.clear();
        }, 1500);
        this.$router.push("/");
          localStorage.setItem("islogin",true);
          // const datasingup =JSON.parse(localStorage.getItem("singup")) ;
          // console.log(datasingup);
          // const user = await getLogin({ email: datasingup.username, password: datasingup.password});
          //console.log(this.$route.path);
          // console.log("data", user);
          // if(user.error === true){
          //   localStorage.setItem("islogin",false);
          //   const toast = useToast();
          //   toast.error(user.msg);
          //   setTimeout(() => {
          //     toast.clear();
          //   }, 1000);
          // }else{
          //   const toast = useToast();
          //   toast.success("dang nhap thanh cong");
          //   setTimeout(() => {
          //     toast.clear();
          //   }, 1000);
           
          //   this.$router.push("/home");
          // }
      }
    }else{
      this.isloading= false;
      toast.success(this.$t(`${response.data.msg_lang_code}.${response.data.msg_lang_code}`));
        setTimeout(() => {
          toast.clear();
        }, 1500);
    }
    
  },
  async getosition(){
    const Username  = localStorage.getItem('Username');
    const response = await getPositions(Username);
    // console.log(response);
    this.Positions = response.data.data;
    this.dealer_data = JSON.parse(this.Positions.dealer_data);
    this.director_data=  JSON.parse(this.Positions.director_data);
    this.manager_data = JSON.parse(this.Positions.manager_data);
    this.member_data = JSON.parse(this.Positions.member_data);
    // console.log(this.dealer_data);
  },
  openCTV(){
    this.isCTv = true;
  },
  openDLy(){
    this.isCTv =false;
  },
  iopenmenu(){
    // this.ismenu=true;
    this.ismenu =! this.ismenu;
  },
  change(id){
      if(id ==1){
        var button = document.getElementById("select1");
            button.classList.add("text-primary-400");
            button.classList.add("border-primary-400");
        var button1 = document.getElementById("select2");
            button1.classList.remove("text-primary-400");
            button1.classList.remove("border-primary-400");
            var button2 = document.getElementById("select3");
            button2.classList.remove("text-primary-400");
            button2.classList.remove("border-primary-400");
            var button3 = document.getElementById("select4");
            button3.classList.remove("text-primary-400");
            button3.classList.remove("border-primary-400");
            this.type = 9;
      }
      if(id ==2){
         button = document.getElementById("select2");
          button.classList.add("text-primary-400");
          button.classList.add("border-primary-400");
         button1 = document.getElementById("select1");
          button1.classList.remove("text-primary-400");
          button1.classList.remove("border-primary-400");
           button2 = document.getElementById("select3");
            button2.classList.remove("text-primary-400");
            button2.classList.remove("border-primary-400");
             button3 = document.getElementById("select4");
            button3.classList.remove("text-primary-400");
            button3.classList.remove("border-primary-400");
            this.type = 10;
      }
      if(id ==3){
         button = document.getElementById("select3");
          button.classList.add("text-primary-400");
          button.classList.add("border-primary-400");
         button1 = document.getElementById("select4");
          button1.classList.remove("text-primary-400");
          button1.classList.remove("border-primary-400");
           button2 = document.getElementById("select1");
            button2.classList.remove("text-primary-400");
            button2.classList.remove("border-primary-400");
             button3 = document.getElementById("select2");
            button3.classList.remove("text-primary-400");
            button3.classList.remove("border-primary-400");
            this.type = 8;
      }
      if(id ==4){
         button = document.getElementById("select4");
          button.classList.add("text-primary-400");
          button.classList.add("border-primary-400");
         button1 = document.getElementById("select3");
          button1.classList.remove("text-primary-400");
          button1.classList.remove("border-primary-400");
           button2 = document.getElementById("select1");
            button2.classList.remove("text-primary-400");
            button2.classList.remove("border-primary-400");
             button3 = document.getElementById("select2");
            button3.classList.remove("text-primary-400");
            button3.classList.remove("border-primary-400");
            this.type = 6;

      }
         
  },
  
 },
 created() {
  document.title =this.$t(`upgrade_account.upgrade_account`);
  this.getosition();
  this.getdatashop();
  
 },
 mounted() {
  this.change(1);
 },
 computed: {
 }
};
</script>

<style scoped>
  .grow {
    flex-grow: 1;
  }
</style>
