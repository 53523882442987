<template>
    <div>
      <header class="fixed inset-x-0 top-0 z-20 sm:w-[480px] mx-auto">
        <div class="border-b border-gray-25 bg-white sm:container bg-white">
          <div class="relative flex h-16 items-center gap-4 pr-14 h-[64px]">
            <router-link to="/courses">
              <button
                class="left-2 center-y relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                type="button"
                aria-label="Trở về"
              >
                <svg
                  class="h-6 w-6"
                  height="1em"
                  width="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </router-link>
            <h5 class="!font-bold leading-snug font-semibold text-gray-900 text-lg truncate">
              Khóa học 
            </h5>
          </div>
        </div>
      </header>
      <main  class="bg-gray-10 sm:min-h-screen sm:container pt-[65px]">
       
        <div class="mt-2 flex flex-col gap-4 bg-white p-4">
          <p class="font-bold text-gray-900 text-md">{{this.listtraning.name}}</p>
          <div v-if="listtraning.video_url" class="video-container">
              <!-- <iframe
                :src="videoUrl"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="aspect-video w-full rounded-xl"
              >
            </iframe> -->
            <iframe 
              class="aspect-video w-full rounded-xl"
              :src=videoUrl()
              title="YouTube video player" frameborder="0" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
              allowfullscreen>
          </iframe>

          </div>
        </div>
        
    
      <div class="mt-2 w-full flex flex-col gap-4 p-4 w-full">
        <h2 v-for="(item, index) in listchapter" :key="index" class=" w-full rounded-lg  ">
          <button @click="toggleAccordion(index,item.id)" :class="item.buttonClasses" class=" w-full  rounded-lg bg-white p-3">
            <div class="flex w-full items-center space-x-0">
                  <span class="flex h-10 w-10 items-center justify-center text-xl">
                      <p>{{ index+1 }} </p>
                  </span>
                  <div class="grow text-left">
                      <p class="font-medium text-gray-900">
                          {{ item.name }}
                      </p>
                  </div>
                  <div class="flex items-center space-x-3">
                      <img width="25px" :src="flagImage">
                      <svg class="text-xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                              fill="currentColor" />
                      </svg>
                  </div>
            </div>
          </button>
          <div v-if="selectedAccordionIndex === index" class="pl-4 pt-2 pr-4 space-y-2">
            <!-- <p class="mb-2 text-gray-500 dark:text-gray-400">{{ item.answer }}</p> -->
            <div v-for="(lesson, index1) in listlesson1" :key="lesson.id" class="pt-2 rounded-lg item  item:hover w-full ">
              <div class="flex items-center w-full" @click.prevent="getvideolesson(lesson.id)">
                  <p>{{index+1}}.{{index1 +1}}</p>
                  <p class="pl-2">{{lesson.name}}</p>
                  <!-- <div class="flex items-center space-x-3 ">
                      <img width="25px" :src="flagImage">
                      <svg class="text-xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                              fill="currentColor" />
                      </svg>
                  </div> -->
              </div>
          </div>
          </div>
        </h2>
      </div>

        <!-- <div class="mt-2 flex flex-col gap-4 bg-white p-4">
          <button v-for="(chapter, index) in listchapter" :key="chapter.id" class="flex relative w-full flex-col items-center justify-center space-x-4 h-[65px]"
                  @click.prevent="toggleOptionLang(chapter.id)">
              <div class="flex w-full items-center space-x-0">
                  <span class="flex h-10 w-10 items-center justify-center text-xl">
                      <p>{{ index+1 }} </p>
                  </span>
                  <div class="grow text-left">
                      <p class="font-medium text-gray-900">
                          {{ chapter.name }}
                      </p>
                  </div>
                  <div class="flex items-center space-x-3">
                      <img width="25px" :src="flagImage">
                      <svg class="text-xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path fillRule="evenodd" clipRule="evenodd"
                              d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                              fill="currentColor" />
                      </svg>
                  </div>
              </div>
              
              <div v-if="selectedChapterId === chapter.id" 
                  class="d-flex w-full cursor-pointer play_video show_video no-border"
                  style="top: 90%; box-shadow: gray 2px 2px 6px; margin-left: 0;">
                  <div v-for="(lesson, index1) in listlesson1" :key="lesson.id">
                      <div class=" flex items-center"  @click.prevent="getvideolesson(lesson.id)">
                          <p>{{index+1}}.{{index1 +1}}</p>
                          <p>{{lesson.name}}</p>
                      </div>
                  </div>
              </div>
          </button>
      </div> -->

       
      </main>
    </div>
  </template>
  <style scoped>
.item {
    padding: 10px;
    background-color: white;
    transition: background-color 0.3s;
    border: 1px solid transparent;
}

.item:hover {
    background-color: rgb(169, 169, 235);

}
</style>
  <script>
  import {getCoursesDetail} from "@/services/product/productService";
  import {
  training
} from '@/services/affiliate/affiliate';
  export default {
  name: 'TrainingYoutube',
  data() {
    return {
       listtraning:'',
       videoId:'',
       infovideo:'',
       isShowOptionLang:false,
       listchapter:'',
       selectedChapterId:'',
       listlesson:'',
      selectedAccordionIndex: -1
    };
  },
  methods: {
    toggleAccordion(index,id) {
      if (this.selectedAccordionIndex === index) {
        this.selectedAccordionIndex = -1;
      } else {
        this.selectedAccordionIndex = index;
      }
      this.toggleOptionLang(id);
    },
    async getstraing1(){
          var currentUrl = window.location.href;
            var idIndex = currentUrl.indexOf('?id=');
            if (idIndex !== -1) { 
                var idString = currentUrl.slice(idIndex + 4);
                var idValue = parseInt(idString);
            } else {
                console.log("Không tìm thấy chuỗi '?id=' trong URL.");
            }
            const response = await getCoursesDetail(idValue);
            this.listtraning = response.data.data[0];
            this.listchapter = response.data.data[1].chapter_course;
            this.lislesson = response.data.data[1].lesson;
            // const url = "https://www.youtube.com/watch?v=f2G5sX5_5K0";
            const url = this.listtraning.video_url;
            this.videoId = url.substring(url.lastIndexOf("=") + 1);
            // console.log(this.videoId); 
            await this.getvideo();
            // console.log(this.infovideo);
            // this.listtraning.video_url += "?si=" + this.infovideo.etag;
            // console.log("this list stores", this.listtraning.video_url);
            // Kết quả: f2G5sX5_5K0

    },
    toggleOptionLang(id){
      // console.log(id);
      this.isShowOptionLang = !this.isShowOptionLang;
      this.selectedChapterId = id;
      this.listlesson1 =  this.lislesson.filter(item => item.chapter_id === id)
      return this.listlesson1;
    },
    getvideolesson(id){
      console.log(id);
      const test =  this.lislesson.filter(item => item.id === id)
      console.log(test[0].video_url);
      const url = test[0].video_url;
      this.videoId = url.substring(url.lastIndexOf("=") + 1);
    },
    async getstraing(){
            const response = await training();
            console.log(response.data.source);
            this.listtraning = response.data.source;
            // this.listStores = response.data.list_store;
            // this.videoId = "zoEtcR5EW08";
            console.log("this list stores", this.listtraning.video_url);
            const url = "https://www.youtube.com/watch?v=f2G5sX5_5K0";
            this.videoId = url.substring(url.lastIndexOf("=") + 1);
            console.log(this.videoId); 
            // Kết quả: f2G5sX5_5K0

    },
    videoUrl() {
      return `https://www.youtube.com/embed/${this.videoId}`;
    },
    async getvideo(){
      const apiKey = 'AIzaSyC_hYZ30k3xMthgKpd62XzbFDE_iKuYx8o';
      const videoId =this.videoId;

      await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=${apiKey}&part=snippet`)
        .then(response => response.json())
        .then(data => {
          // console.log(data);
          this.infovideo = data;
          // const videoInfo = data.items[0].snippet;
          // const title = videoInfo.title;
          // const description = videoInfo.description;
          // Xử lý các thông tin khác cần thiết về video ở đây
          // console.log(videoInfo,title,description);
        })
        .catch(error => {
          console.error('Error fetching video information:', error);
        });

    }
  },
  created() {
    this.getstraing1();
  },
  mounted() {
    // this.getstraing();
   
  },
  computed: {
   
  }
}
  </script>
  
  <style scoped>
  /* Your component styles here */
  </style>
  