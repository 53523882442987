<template>
  <div>
    <div class="relative flex items-center gap-3 h-[64px]">
      <router-link to="/home">
        <button
          class="left-2 center-y relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
          type="button"
          aria-label="Trở về"
        >
          <svg
            class="h-6 w-6"
            height="1em"
            width="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
              fill="currentColor"
            />
          </svg>
        </button>
      </router-link>
      <h5 class="!font-bold leading-snug font-semibold text-gray-900 text-lg truncate">
        Thông báo
      </h5>
    </div>
    <nav class="flex items-center justify-between space-x-2 overflow-y-auto bg-white px-4 pb-4">
      <button
        v-for="category in categories"
        :key="category.id"
        class="flex flex-col items-center rounded-xl p-3"
        :class="{ 'bg-primary-400': category.id === activeCategoryId, 'bg-gray-200': category.id !== activeCategoryId }"
        :id="'category-' + category.id"
        @click="setActiveCategory(category.id)"
      >
        <div class="relative mb-[5px]" v-if="category.iconType === 'svg'">
          <svg
            :class="category.iconClasses"
            class="text-[20px] text-white"
            width="1em"
            height="1em"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path :d="category.iconPath" fill="currentColor" />
          </svg>
        </div>
        <div class="relative mb-[5px]" v-else-if="category.iconType === 'img'">
          <img
            :src="category.iconSrc"
            :alt="category.name"
            class="!h-5 !w-5"
          />
        </div>
        <p class="whitespace-nowrap font-medium text-nm" :class="{ 'text-white': category.id === activeCategoryId, 'text-gray-900': category.id !== activeCategoryId }">{{ category.name }}</p>
      </button>
    </nav>
    <!-- Noti list -->
    <div class="p-4 bg-primary-400/10 border-y" v-for="notification in notifications" :key="notification.id">
      <button class="flex w-full gap-4">
        <img
          :src="notification.iconSrc"
          :alt="notification.title"
          class="h-10 w-10 flex-none rounded-full"
        />
        <div class="space-y-2 overflow-hidden text-left">
          <p class="w-fit rounded bg-orange-400 px-2 text-[12px] text-white" v-if="notification.isHot">Hot</p>
          <p class="font-bold text-gray-900 truncate">{{ notification.title }}</p>
          <p class="text-gray-900 text-sm">{{ notification.description }}</p>
          <p class="text-gray-400 text-xs">{{ notification.date }}</p>
          <div class="mt-1 space-x-3" />
        </div>
      </button>
      <button
        class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-primary-400 hover:text-primary-300"
        type="button"
        @click="markAsRead(notification.id)"
      >
        Đánh dấu đã đọc
      </button>
      <button
        class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-primary-400 hover:text-primary-300 !text-red-400"
        type="button"
        @click="deleteNotification(notification.id)"
      >
        Xóa
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationPage',
  data() {
    return {
      activeCategoryId: 1, // ID của danh mục hiện tại
      categories: [ // Danh sách các danh mục
        {
          id: 1,
          name: 'Tất cả',
          iconType: 'svg',
          iconClasses: 'text-white',
          iconPath: 'M3.83331 5.83333C3.83331 5.3731 4.20641 5 4.66665 5H16.3333C16.7935 5 17.1666 5.3731 17.1666 5.83333C17.1666 6.29357 16.7935 6.66667 16.3333 6.66667H4.66665C4.20641 6.66667 3.83331 6.29357 3.83331 5.83333Z'
        },
        {
          id: 2,
          name: 'Đơn hàng',
          iconType: 'img',
          iconSrc: 'https://cdn.droppii.vn/droppii-production-public/notifier/category/1-order.png'
        },
        // Thêm các danh mục khác tương tự ở đây
      ],
      notifications: [ // Danh sách thông báo
        {
          id: 1,
          title: 'Lịch xử lý đơn Tết Nguyên Đán 2024',
          description: 'Hãy kiểm tra để không bị bỏ lỡ bất kì thông tin quan trọng nào bạn nhé.',
          date: '22/01/2024 - 14:48',
          iconSrc: 'https://cdn.droppii.vn/droppii-production-public/icon/News.png',
          isHot: true // Có phải là thông báo hot hay không
        },
        {
          id: 2,
          title: 'PDP HAESONG VINA - Điều chỉnh thông tin khuyến mãi.',
          description: 'Cùng cập nhật các chương trình khuyến mãi từ Droppii bạn nhé !',
          date: '21/01/2024 - 16:23',
          iconSrc: 'https://cdn.droppii.vn/droppii-production-public/icon/Promotion.png',
          isHot: false
        },
        // Thêm các thông báo khác tương tự ở đây
      ]
    };
  },
  methods: {
    setActiveCategory(categoryId) {
      this.activeCategoryId = categoryId;
      // Thực hiện xử lý khi chọn một danh mục
    },
    markAsRead(notificationId) {
      console.log(notificationId);
      // Thực hiện đánh dấu thông báo đã đọc
    },
    deleteNotification(notificationId) {
      // Thực hiện xóa thông báo
      console.log(notificationId);
    }
  }
};
</script>

<style scoped>
/* Thêm CSS tùy chỉnh tại đây nếu cần */
</style>
