module.exports = {

    BASE_URL: process.env.NODE_ENV === 'production' ? 'https://salekit.net/api/v1/affiliate' : 'https://salekit.net/api/v1/affiliate',
    API_URL: process.env.NODE_ENV === 'production' ? 'https://salekit.net/api/v1/affiliate/dropship' : 'https://salekit.net/api/v1/affiliate/dropship',
    BASE_URL_test: process.env.NODE_ENV === 'production' ? 'http://localhost:3000' : 'http://localhost:3000',
    // BASE_URL_Node: process.env.NODE_ENV === 'production' ? 'https://api.salekit.net:3038/api/v1' : 'http://localhost:3038/api/v1',
    BASE_URL_Node: process.env.NODE_ENV === 'production' ? 'https://api.salekit.net:3038/api/v1' : 'https://api.salekit.net:3038/api/v1',
    //  define('PAY_STATUS_PENDING', 0); // chưa thanh toán
    // define('PAY_STATUS_PAID', 1); // đã thanh toán
    // //define('PAY_STATUS_SPLITED', 2); // đã chia
    // define('PAY_STATUS_FREE', 3); // miễn phí
    // define('PAY_STATUS_CANCEL', 4); // Hoàn tiền
    // define('PAY_STATUS_CASH', 2); // Tạm ứng

}