<template>
    <div class="fixed inset-0 z-50 min-h-screen md:w-[480px] mx-auto" id="headlessui-dialog-:r1p:" role="dialog"
        aria-modal="true" data-headlessui-state="open" aria-labelledby="headlessui-dialog-title-:r1q:">
        <div class="sm:relative sm:h-full sm:translate-x-0 sm:transform sm:container">
            <div class="absolute bg-white inset-y-0 right-0 w-full bg-gray-10 translate-x-0">
                <header class="absolute inset-x-0 top-0 z-20 h-[128px] bg-white"><button
                        @click.prevent="closeModalAddress" class="!absolute left-2 top-3 relative rounded-md text-2xl h-10 w-10 transition 
                        duration-500 border border-transparent bg-transparent text-primary-400 
                        hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap 
                        text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                        type="button" aria-label="Thoát" tabindex="0"><svg class="h-6 w-6" height="1em" width="1em"
                            viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
                                fill="currentColor"></path>
                        </svg></button>
                    <div class="flex h-[64px] items-center pl-12 pr-2">
                        <h3 class="w-full text-lg font-semibold leading-snug text-gray-1100"
                            id="headlessui-dialog-title-:r1q:" data-headlessui-state="open">Chọn khách hàng</h3>
                        <router-link to="/customer/new"
                            class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap leading-none focus:outline-none bg-transparent h-10 text-primary-400 hover:text-primary-300"><span
                                class="text-lg mr-2"><svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
                                        fill="currentColor"></path>
                                </svg></span>
                            <span class=""><b class="text-nm">Thêm mới</b></span>
                        </router-link>
                    </div>
                    <div class="-mt-1 flex h-[56px] space-x-2 px-4 pb-2">
                        <div class="relative inline-flex h-10 w-full rounded-md bg-gray-25">
                            <div class="absolute flex h-10 w-10 flex-none items-center justify-center text-xl text-gray-300"
                                aria-hidden="true"><svg class="h-[18px] w-[18px] text-gray-600" width="1em" height="1em"
                                    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.3117 17.3433C17.7806 16.8777 18.5311 16.8743 19.0039 17.3333L21.5513 19.3567C22.1496 19.9614 22.1496 20.9407 21.5513 21.5454C20.9516 22.1515 19.9782 22.1515 19.3785 21.5454L17.3047 19.1693C17.0672 18.927 16.9343 18.6001 16.9343 18.2599C16.9343 17.9187 17.0713 17.5854 17.3117 17.3433Z"
                                        fill="currentColor"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.6123 2C5.85342 2 2 5.89865 2 10.7024C2 15.5061 5.85342 19.4047 10.6123 19.4047C15.3713 19.4047 19.2247 15.5061 19.2247 10.7024C19.2247 8.3952 18.3179 6.182 16.7031 4.54979C15.0882 2.9175 12.8973 2 10.6123 2ZM3.95122 10.7024C3.95122 6.9567 6.95053 3.95122 10.6123 3.95122C12.373 3.95122 14.0654 4.65802 15.316 5.92211C16.5673 7.18683 17.2735 8.906 17.2735 10.7024C17.2735 14.448 14.2741 17.4535 10.6123 17.4535C6.95053 17.4535 3.95122 14.448 3.95122 10.7024Z"
                                        fill="currentColor"></path>
                                </svg></div>
                            <input autocomplete="off" class="h-10 w-full rounded-md pl-10 pr-8 appearance-none
                                 !ring-0 transition duration-200 focus:outline-none border-gray-100
                                  bg-white text-gray-1100 text-gray-900 placeholder:text-gray-200 
                                  hover:border-gray-100 focus:border-primary-400 bg-gray-25 border-gray-25"
                                placeholder="Tìm theo tên hoặc số điện thoại" value="">
                            <div
                                class="absolute right-0 flex h-10 w-10 flex-none items-center justify-center text-gray-800">
                            </div>
                        </div>
                    </div>
                </header>
                <div class="absolute inset-x-0 bottom-0 top-[98px] space-y-3 overflow-auto bg-gray-75 py-3">
                    <div class="space-y-2 bg-gray-75">
                        <div class="mt-5 space-y-3">
                            <div class="bg-white shadow-100 p-4" v-for="(address, index) in customersAddress"
                                :key="index">
                                <div class="mt-1 divide-y divide-gray-25" role="radiogroup">
                                    <div class="flex items-start space-x-3 pr-1 pt-4 first:pt-0">
                                        <div class="flex-1">
                                            <div data-radiogroup="true" class="flex space-x-2 items-start gap-3">
                                                <div class="relative inline-flex items-center">

                                                    <input :checked="selectedAddress == address.id"
                                                        class="rounded-full border-2 checked:bg-primary-400 checked:text-primary-400 !bg-[length:75%_75%] form-radio checked:border-primary-400 h-5 w-5 text-white form-radio checked:hover:border-primary-400 border-gray-400"
                                                        type="radio" :value="address.id"
                                                        @click="setCustomerAddress(address)">

                                                </div>
                                                <label class="block text-nm text-gray-600 font-normal text-gray-900"
                                                    for="radio-:r1s:">
                                                    <p class="text-nm font-bold text-gray-1100">{{ address.name }} </p>
                                                    <p class="mt-2 font-medium text-gray-900">{{ address.fulladdress
                                                        }}<span class="text-nm font-normal text-gray-600"> -
                                                            {{ address.phone }}</span></p>
                                                    <div
                                                        class="inline-flex rounded-full px-2 py-0.5 text-nm bg-green-500 text-white mt-2 text-xs">
                                                        Mặc định</div>
                                                </label>
                                            </div>
                                        </div><button
                                            class="!-mr-3 -mt-3 text-[24px] relative rounded-md text-md h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                                            type="button" aria-label="edit"><svg height="21" width="20"
                                                viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M9.99998 7.16671C10.9205 7.16671 11.6666 6.42052 11.6666 5.50004C11.6666 4.57957 10.9205 3.83337 9.99998 3.83337C9.07951 3.83337 8.33331 4.57957 8.33331 5.50004C8.33331 6.42052 9.07951 7.16671 9.99998 7.16671Z"
                                                    fill="#393E40"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M9.99998 12.1667C10.9205 12.1667 11.6666 11.4205 11.6666 10.5C11.6666 9.57957 10.9205 8.83337 9.99998 8.83337C9.07951 8.83337 8.33331 9.57957 8.33331 10.5C8.33331 11.4205 9.07951 12.1667 9.99998 12.1667Z"
                                                    fill="#393E40"></path>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M11.6666 15.5C11.6666 16.4205 10.9205 17.1667 9.99998 17.1667C9.07951 17.1667 8.33331 16.4205 8.33331 15.5C8.33331 14.5796 9.07951 13.8334 9.99998 13.8334C10.9205 13.8334 11.6666 14.5796 11.6666 15.5Z"
                                                    fill="#393E40"></path>
                                            </svg></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="relative">
                            <div class="absolute" style="top: 0px;"></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCustomers } from "@/services/customer/customerService";

export default {
    name: 'ModalAddress',
    props: {
        closeModalAddress: {
            type: Function,
            required: true
        },
        onChangeAddress: {
            type: Function,
            required: true,
        }
    },
    data() {
        return {
            customersAddress: [],
            isShowModalAddress: false,
            selectedAddress: null
        };
    },
    mounted() {

        this.fetchCustomers();
    },
    methods: {
        setCustomerAddress(address) {
            // localStorage.setItem('customerItems', JSON.stringify({ address: address }));
            this.onChangeAddress(address);
            this.selectedAddress = address.id;
            this.$router.push('?address=' + encodeURIComponent(JSON.stringify(address)));
            this.closeModalAddress()
        },

        async fetchCustomers() {
            try {
                const getIdHash = JSON.parse(localStorage.getItem('datauserInfo')).id_hash;
                const res = await getCustomers(getIdHash);
                if (this.$route.query.address) {
                    const addressID = JSON.parse(this.$route.query.address).id;
                    this.selectedAddress = addressID;
                }
                this.customersAddress = res.data;
            } catch (error) {
                console.error("Error fetching customers:", error);
                throw error;
            }
        },


    }
};
</script>
