<template>
    <div class="fixed inset-0 z-50 min-h-screen md:w-[480px] mx-auto " id="headlessui-dialog-:r8f:" role="dialog"
        aria-modal="true" data-headlessui-state="open" aria-labelledby="headlessui-dialog-title-:r8g:">
        <div class="sm:relative sm:h-full sm:translate-x-0 sm:transform sm:container">
            <div class="absolute bg-white inset-y-0 right-0 w-full translate-x-0">
                <form>

                    <header
                        class="absolute inset-x-0 top-0 z-20 flex h-[56px] items-center bg-white px-12 shadow-300 shadow-none">

                        <h3 class="w-full text-lg font-semibold leading-snug text-gray-1100"
                            id="headlessui-dialog-title-:r8g:" data-headlessui-state="open">Thêm sản phẩm</h3><button
                            @click.prevent="closeModalProduct"
                            class="!absolute left-2 center-y relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                            type="button" aria-label="Thoát" tabindex="0"><svg height="1em" width="1em"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.63615 18.3641C6.02668 18.7546 6.65984 18.7546 7.05037 18.3641L12.0001 13.4143L16.9499 18.3641C17.3404 18.7546 17.9736 18.7546 18.3641 18.3641C18.7546 17.9736 18.7546 17.3404 18.3641 16.9499L13.4143 12.0001L18.3641 7.05037C18.7546 6.65984 18.7546 6.02668 18.3641 5.63615C17.9736 5.24563 17.3404 5.24563 16.9499 5.63616L12.0001 10.5859L7.0504 5.63617C6.65987 5.24565 6.02671 5.24565 5.63618 5.63617C5.24566 6.0267 5.24566 6.65986 5.63618 7.05039L10.5859 12.0001L5.63615 16.9499C5.24563 17.3404 5.24563 17.9736 5.63615 18.3641Z"
                                    fill="currentColor"></path>
                            </svg></button>
                    </header>
                    <div class="h-[56px]"></div>
                    <div class="relative inline-flex h-10 w-full rounded-full px-4"><button aria-label=""
                            class="relative inline-flex h-10 w-full rounded-full border border-transparent bg-gray-25"
                            type="button">
                            <div class="absolute flex h-10 w-10 flex-none items-center justify-center pb-0.5 text-xl text-gray-400"
                                aria-hidden="true"><svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M17.3117 17.3433C17.7806 16.8777 18.5311 16.8743 19.0039 17.3333L21.5513 19.3567C22.1496 19.9614 22.1496 20.9407 21.5513 21.5454C20.9516 22.1515 19.9782 22.1515 19.3785 21.5454L17.3047 19.1693C17.0672 18.927 16.9343 18.6001 16.9343 18.2599C16.9343 17.9187 17.0713 17.5854 17.3117 17.3433Z"
                                        fill="currentColor"></path>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M10.6123 2C5.85342 2 2 5.89865 2 10.7024C2 15.5061 5.85342 19.4047 10.6123 19.4047C15.3713 19.4047 19.2247 15.5061 19.2247 10.7024C19.2247 8.3952 18.3179 6.182 16.7031 4.54979C15.0882 2.9175 12.8973 2 10.6123 2ZM3.95122 10.7024C3.95122 6.9567 6.95053 3.95122 10.6123 3.95122C12.373 3.95122 14.0654 4.65802 15.316 5.92211C16.5673 7.18683 17.2735 8.906 17.2735 10.7024C17.2735 14.448 14.2741 17.4535 10.6123 17.4535C6.95053 17.4535 3.95122 14.448 3.95122 10.7024Z"
                                        fill="currentColor"></path>
                                </svg></div>
                            <div class="flex h-10 w-full items-center rounded-full pb-0.5 pl-10 text-left"><span
                                    class="text-gray-400 text-nm truncate">Tìm kiếm</span></div>
                        </button></div>
                    <div class="absolute inset-x-0 bottom-[72px] top-[122px] overflow-y-auto">
                        <!-- <div class="scrollbar flex overflow-x-auto whitespace-nowrap bg-white px-4 shadow-300 sticky top-0 z-10"
                            role="tablist" aria-orientation="horizontal"><button
                                class="relative inline-flex flex-1 items-center justify-center whitespace-nowrap border-b-2 border-transparent px-2 py-3 font-semibold focus-visible:outline-none text-gray-600 text-primary-400 !border-primary-400"
                                id="headlessui-tabs-tab-:r8h:" role="tab" aria-selected="true" tabindex="0"
                                data-headlessui-state="selected" type="button"
                                aria-controls="headlessui-tabs-panel-:r8k:">Tất cả sản phẩm</button>
                            <button
                                class="relative inline-flex flex-1 items-center justify-center whitespace-nowrap border-b-2 border-transparent px-2 py-3 font-semibold focus-visible:outline-none text-gray-600"
                                id="headlessui-tabs-tab-:r8i:" role="tab" aria-selected="false" tabindex="-1"
                                data-headlessui-state="" type="button"
                                aria-controls="headlessui-tabs-panel-:r8l:">Khuyến mãi</button><button
                                class="relative inline-flex flex-1 items-center justify-center whitespace-nowrap border-b-2 border-transparent px-2 py-3 font-semibold focus-visible:outline-none text-gray-600"
                                id="headlessui-tabs-tab-:r8j:" role="tab" aria-selected="false" tabindex="-1"
                                data-headlessui-state="" type="button" aria-controls="headlessui-tabs-panel-:r8m:">Yêu
                                thích</button>

                        </div> -->
                        <div>
                            <div id="headlessui-tabs-panel-:r8k:" role="tabpanel" tabindex="0"
                                data-headlessui-state="selected" aria-labelledby="headlessui-tabs-tab-:r8h:">
                                <div class="">
                                    <div v-if="isLoading" class="text-center">Đang tải...</div>

                                    <div v-for="(store, index) in this.storeInfo" :key="index"
                                        class="mx-4 rounded-sm bg-[#E6F7FF] px-4 pt-2.5" v-else>
                                        <p v-if="index === 0 || this.storeInfo[index - 1].id !== store.store.id"
                                            class="text-nm font-semibold text-gray-1100 pb-3">{{ store.store.name }}</p>
                                    </div>

                                    <div class="bg-white flex space-x-4 py-4 ml-4" v-for="(item, index) in productList"
                                        :key="index">
                                        <div class="w-[94px] flex-none space-y-2">
                                            <div class="relative">
                                                <div class="relative h-0" style="padding-bottom: 100%;">
                                                    <div
                                                        class="absolute inset-0 h-full w-full rounded-lg animate-pulse bg-gray-800 bg-opacity-10">
                                                    </div>
                                                    <img v-if="item.img"
                                                        class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg"
                                                        :src="item.img">
                                                    <img v-else
                                                        class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg"
                                                        :src="item.photo">
                                                </div>
                                            </div>
                                            <div
                                                class="flex items-center rounded-md bg-warning-300 px-1 py-0.5 text-xs text-white">
                                                <svg class="text-lg" width="1em" height="1em" viewBox="0 0 16 16"
                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11.7724 7.46667C11.6191 7.26667 11.4324 7.09333 11.2591 6.92C10.8124 6.52 10.3057 6.23333 9.87906 5.81333C8.88572 4.84 8.66572 3.23333 9.29906 2C8.66572 2.15333 8.11239 2.5 7.63906 2.88C5.91239 4.26667 5.23239 6.71333 6.04572 8.81333C6.07239 8.88 6.09906 8.94667 6.09906 9.03333C6.09906 9.18 5.99906 9.31333 5.86572 9.36667C5.71239 9.43333 5.55239 9.39333 5.42572 9.28667C5.38766 9.2552 5.35598 9.21672 5.33239 9.17333C4.57906 8.22 4.45906 6.85333 4.96572 5.76C3.85239 6.66667 3.24572 8.2 3.33239 9.64667C3.37239 9.98 3.41239 10.3133 3.52572 10.6467C3.61906 11.0467 3.79906 11.4467 3.99906 11.8C4.71906 12.9533 5.96572 13.78 7.30572 13.9467C8.73239 14.1267 10.2591 13.8667 11.3524 12.88C12.5724 11.7733 12.9991 10 12.3724 8.48L12.2857 8.30667C12.1457 8 11.7724 7.46667 11.7724 7.46667ZM9.66572 11.6667C9.47906 11.8267 9.17239 12 8.93239 12.0667C8.18572 12.3333 7.43906 11.96 6.99906 11.52C7.79239 11.3333 8.26572 10.7467 8.40572 10.1533C8.51906 9.62 8.30572 9.18 8.21906 8.66667C8.13906 8.17333 8.15239 7.75333 8.33239 7.29333C8.45906 7.54667 8.59239 7.8 8.75239 8C9.26572 8.66667 10.0724 8.96 10.2457 9.86667C10.2724 9.96 10.2857 10.0533 10.2857 10.1533C10.3057 10.7 10.0657 11.3 9.66572 11.6667Z"
                                                        fill="currentColor"></path>
                                                </svg> Khuyến Mãi
                                            </div>
                                        </div>
                                        <div class="space-y-1">
                                            <h6 class="text-nm font-semibold text-gray-1100 line-clamp-2">{{
                                                item.name }}</h6>
                                            <div class="flex flex-wrap items-center text-nm"><b v-if="item.price_sale"
                                                    class="font-semibold text-primary-400">
                                                    {{ formatNumber(item.price_sale) }}₫</b>
                                                <b v-else class="font-semibold text-primary-400"> {{
                                                    formatNumber(item.price)
                                                    }}₫</b><span class="mx-1 text-gray-50">|</span>
                                                <p class="text-gray-1100">SL: {{ item.quantity ?? 1 }}</p>
                                            </div>

                                            <div class="flex flex-wrap items-center pb-3 text-nm"><span
                                                    class="text-gray-1100">CK: {{ item.rate }}%</span><span
                                                    class="mx-1 text-gray-50">|</span>
                                                <!-- <p class="text-gray-1100">Points: 160,173</p> -->
                                            </div>

                                            <div class="flex items-center space-x-1"
                                                v-if="selectedProductIds.includes(item.id)">
                                                <div>
                                                    <div class="inline-flex">
                                                        <button @click.prevent="decreaseQuantity(item.id, storeId)"
                                                            class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                                                            type="button" aria-label="Giảm"><svg height="1em"
                                                                width="1em" viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
                                                                    fill="currentColor"></path>
                                                            </svg></button>
                                                        <input :value="item.quantity ?? 1"
                                                            class="h-10 w-10 border-none bg-transparent p-0 text-center font-bold text-gray-900 focus:outline-none h-10 w-10"
                                                            max="100" min="0" disabled>
                                                        <button @click.prevent="increaseQuantity(item.id, storeId)"
                                                            class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                                                            type="button" aria-label="Thêm"><svg height="1em"
                                                                width="1em" viewBox="0 0 24 24" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                                    d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
                                                                    fill="currentColor"></path>
                                                            </svg></button>
                                                    </div>
                                                </div><button @click.prevent="toggleProductSelection(item.id)"
                                                    class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent h-8 text-sm text-gray-900 hover:text-gray-600"
                                                    type="button">Xoá</button>
                                            </div>
                                            <button @click.prevent="toggleProductSelection(item.id)"
                                                v-if="!selectedProductIds.includes(item.id)"
                                                class="rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none border h-8 text-sm text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 px-6 uppercase"
                                                type="button">Chọn</button>
                                        </div>
                                    </div>
                                    <!-- <div class="relative">
                                        <div class="absolute" style="top: 0px;"></div>
                                        <div>
                                            <div class="flex w-full flex-col items-center justify-center p-4"><svg
                                                    class="mb-2" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink" width="20px"
                                                    height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid"
                                                    style="margin: auto; background: transparent; display: block;">
                                                    <circle cx="85" cy="50" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0.875s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0.875s"></animate>
                                                    </circle>
                                                    <circle cx="74.7" cy="74.7" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0.75s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0.75s"></animate>
                                                    </circle>
                                                    <circle cx="50" cy="85" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0.625s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0.625s"></animate>
                                                    </circle>
                                                    <circle cx="25.3" cy="74.7" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0.5s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0.5s"></animate>
                                                    </circle>
                                                    <circle cx="15" cy="50" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0.375s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0.375s"></animate>
                                                    </circle>
                                                    <circle cx="25.3" cy="25.3" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0.25s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0.25s"></animate>
                                                    </circle>
                                                    <circle cx="49.99999999999999" cy="15" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0.125s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0.125s"></animate>
                                                    </circle>
                                                    <circle cx="74.7" cy="25.3" fill="#1e3bbe" r="5">
                                                        <animate attributeName="r" values="5;5;10;5;5"
                                                            times="0;0.1;0.2;0.3;1" dur="1s" repeatCount="indefinite"
                                                            begin="0s"></animate>
                                                        <animate attributeName="fill"
                                                            values="#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe;#1e3bbe"
                                                            repeatCount="indefinite" times="0;0.1;0.2;0.3;1" dur="1s"
                                                            begin="0s"></animate>
                                                    </circle>
                                                </svg>
                                                <p class="text-xs text-primary-400">Đang tải</p>
                                            </div>
                                        </div>
                                    </div> -->
                                    <form></form>
                                </div>
                            </div><span id="headlessui-tabs-panel-:r8l:" role="tabpanel" tabindex="-1"
                                aria-labelledby="headlessui-tabs-tab-:r8i:"
                                style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px;"></span><span
                                id="headlessui-tabs-panel-:r8m:" role="tabpanel" tabindex="-1"
                                aria-labelledby="headlessui-tabs-tab-:r8j:"
                                style="position: fixed; top: 1px; left: 1px; width: 1px; height: 0px; padding: 0px; margin: -1px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); white-space: nowrap; border-width: 0px;"></span>
                        </div>
                    </div>
                    <div class="h-[72px]"></div>
                    <footer
                        class="absolute inset-x-0 bottom-0 z-20 h-[72px] bg-white border-t border-gray-25 px-4 py-3">
                        <button class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none 
                            focus:outline-none h-10 w-full flex  bg-primary-400 text-white
                             hover:bg-primary-300" type="submit" @click.prevent="addProduct">Thêm sản phẩm</button>

                    </footer>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from "axios";
import {
    getAllProduct,
} from '@/services/product/productService';

export default {
    name: 'ModalProductAdd',
    props: {
        closeModalProduct: {
            type: Function,
            required: true
        },
        getOrder: {
            type: Function,
            required: true
        },
        
        summoney: {
            type: Function,
            required: true
        },
    },
    data() {

        return {
            productList: [],
            isLoading: false,
            isShowModalAddress: false,
            choose: false,
            orderItems: [],
            selectedProductIds: [],
            storeId: '',
            storeName: '',
            quantity: 1, // Số lượng mặc định
            minQuantity: 1, // Số lượng tối thiểu
            maxQuantity: Infinity,
            storeInfo:JSON.parse(localStorage.getItem('order'))

        }

    },
    mounted() {
        this.fetchProducts();
        this.getStoreId()
    },
    created() {
    }
    ,

    methods: {
        getStoreId() {
            for (let store of JSON.parse(localStorage.getItem('order'))) {
                this.storeId = store.store.id
                this.storeName = store.store.name
            }
        },
        //Cập nhật lợi nhuận, total
        calculatePriceAndProfit(item) {
            // console.log(item.id)
            if (item.price_sale) {
                item.total = item.price_sale * item.quantity;
                item.profit = item.total * (item.rate / 100);
                // console.log(item.total, item.profit);
            } else {
                item.total = item.price * item.quantity;
                item.profit = item.total * (item.rate / 100);
                // console.log(item.total, item.profit);
            }
            // localStorage.setItem('order', JSON.stringify(this.orderItems));
        },
        //Tăng số lượng
        increaseQuantity(productId) {
            const index = this.productList.findIndex(item => item.id === productId);
            // console.log(index);
            if (index !== -1) {
                if (this.productList[index].quantity === undefined) {
                    this.productList[index].quantity = 1; // Gán giá trị mặc định nếu quantity không tồn tại
                }
                this.productList[index].quantity++;
                this.quantity = this.productList[index].quantity
                this.calculatePriceAndProfit(this.productList[index]);
                // console.log(storeId, "quantity: " + this.productList[index].quantity);
            }
        },
        //Giảm số lượng
        decreaseQuantity(productId) {
            const index = this.productList.findIndex(item => item.id === productId);
            if (index !== -1 && this.productList[index].quantity > 1) { // Giảm đến số lượng tối thiểu là 1
                this.productList[index].quantity--;
                this.quantity = this.productList[index].quantity
                this.calculatePriceAndProfit(this.productList[index]);
            }
        },
        toggleProductSelection(productId) {
            const index = this.selectedProductIds.indexOf(productId);
            if (index === -1) {
                // Nếu sản phẩm chưa được chọn, thêm vào mảng selectedProductIds
                this.selectedProductIds.push(productId);
            } else {
                // Nếu sản phẩm đã được chọn, loại bỏ nó khỏi mảng selectedProductIds
                this.selectedProductIds.splice(index, 1);
            }
        },
        addProduct() {
            this.productList.forEach(product => {
                if (this.selectedProductIds.includes(product.id)) {
                    let orderData = JSON.parse(localStorage.getItem('order')) || [];

                    let existingItemIndex = orderData.findIndex(item => item.product.id === product.id);

                    if (product.price_sale) {
                        this.total = product.price_sale * this.quantity
                        this.profit = this.total * (product.rate / 100);

                    } else {
                        this.total = product.price * this.quantity
                        this.profit = this.total * (product.rate / 100);

                    }
                    if (existingItemIndex !== -1) {
                        orderData[existingItemIndex].quantity += this.quantity;
                        orderData[existingItemIndex].store.id = this.storeId;
                        orderData[existingItemIndex].store.name = this.storeName;
                        orderData[existingItemIndex].total = this.total;
                        orderData[existingItemIndex].profit = this.profit;
                    } else {
                        orderData.push({
                            product: product,
                            profit: this.profit,
                            quantity: this.quantity,
                            store: {
                                id: this.storeId,
                                name: this.storeName
                            },
                            total: this.total
                        })
                    }
                    localStorage.setItem('order', JSON.stringify(
                        orderData
                    ));
                    this.getOrder()
                    this.closeModalProduct()
                    this.summoney()
                }
            });
        },
        async fetchProducts() {
            try {
                // localStorage.removeItem('cart');
                this.isLoading = true;
                const response = await getAllProduct();
                this.productList = response.data.data;

                // console.log(this.productList);
                // this.store=
            } catch (error) {
                console.error('Error fetching products:', error.message);
            } finally {
                this.isLoading = false;
            }
        },

        formatNumber(value) {
            const result = parseFloat(value);
            if (isNaN(result)) {
                return "Invalid value";
            }
            return result.toLocaleString('en-US', {
                maximumFractionDigits: 0
            });
        },
    }
};
</script>
