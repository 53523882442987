import { reactive, provide, inject } from 'vue';
import { getLogin } from '../services/authentication/authService';

// Khởi tạo một biến constant để đại diện cho context key
const AuthContextSymbol = Symbol();

// Tạo ra một hàm để tạo ra context và cung cấp các method và state cần thiết
function createAuthContext() {
  const state = reactive({
    isLoading: false,
    error: false,
    user: null,
    isAuthenticated: false,
    login: async (username, password) => {
      state.isLoading = true;
      try {
        const res = await getLogin({ username, password });
        state.user = res.user;
        state.isAuthenticated = true;
      } catch (error) {
        state.error = true;
      } finally {
        state.isLoading = false;
      }
    }
  });
  return state;
}

// Tạo một hàm provide để cung cấp context cho các component con
export function provideAuth() {
  provide(AuthContextSymbol, createAuthContext());
}

// Tạo một hàm inject để lấy context trong các component con
export function useAuth() {
  const context = inject(AuthContextSymbol);
  if (!context) {
    throw new Error('useAuth phải được sử dụng bên trong AuthProvider');
  }
  return context;
}
