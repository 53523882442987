import axios from "axios";
import Config from "@/components/config";
//const token = `eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDIzLTEyLTE4VDE2OjMyOjM3KzA3MDAiLCJzaG9wX2lkIjoiODZlNzg0OTllZWIzM2ZiOWNhYzE2Yjc1NTViNTA3NjcifQ.bUI5cTy8t5mtiRQ6cXcLZWDfp-uHEpAz90mg8UuOJQE`;
let datashop = JSON.parse(localStorage.getItem("getshop"));
// let token;
let config;
// let token = '';
let datauserInfo =  localStorage.getItem('datauserInfo');
if(datauserInfo){
  datauserInfo = JSON.parse(datauserInfo)
}
if (datashop) {
  // token = datashop.token;
  config = {
    headers: {
      "Content-Type": "application/json",
      Token: datashop.token
    },
  };
}else{
  setTimeout(() => {
    datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
    config = {
      headers: {
        "Content-Type": "application/json",
        Token: datashop.token
      }
    };
  }, 2000);
}

const getService_GHN = async(token1, shop_id,from,to) =>{
  // console.log(token1, shop_id,from,to)
  return await axios.post('https://online-gateway.ghn.vn/shiip/public-api/v2/shipping-order/available-services',{
  
    "shop_id":shop_id,
    "from_district": from,
    "to_district": to
  },{
    headers: {
      "token": token1
    }
  })
};
const getStoreGHN = async(token1) =>{
  return await axios.post('https://online-gateway.ghn.vn/shiip/public-api/v2/shop/all',{
  body:{
      "offset": 0,
      "limit": 50,
      "client_phone": ""
  }},{
    headers: {
      "token": token1
    }
  })
};
const getProvinceGHN = async(token1) =>{
  return await axios.get('https://online-gateway.ghn.vn/shiip/public-api/master-data/province',{
    headers: {
      "token": token1
    }
  })
  
};
const getDistrictGHN = async(token1,id) =>{
  return await axios.post('https://online-gateway.ghn.vn/shiip/public-api/master-data/district',{
  body:{
      "province_id" : id
  }},{
    headers: {
      "token": token1
    }
  })
};
const getWardGHN = async (token1, id) => {
  // console.log(token1, id);
  return await axios.post(
    "https://online-gateway.ghn.vn/shiip/public-api/master-data/ward",
    {
      "district_id": id
    },
    {
      headers: {
        "token": token1
      }
    }
  );
};
const getShippingUnits = async () => {
  try {
    if (datashop) {
      const response = await axios.get(
        `${Config.BASE_URL_Node}/shipping/infoship`,
        config
      );
      return response.data;
    }
  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const shipGHTK = async (params) => {
  try {
    const response = await axios.get("/one/services/shipment/fee", {
      params: {
        ...params,
      },
      headers: {
        Token: "4dc0b0fe7ee95de3c200c9e1131e713db771f789",
      },
    });
    return response;
    // console.log("Dữ liệu từ API:", response.data);
  } catch (error) {
    console.error("Lỗi khi gọi API:", error);
  }
};
const shipGHN = async (data) => {
  try {
    const response = await axios.post(
      `${Config.BASE_URL_Node}/shipping/shippingGHN`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Token: "74667173-7886-11ee-a59f-a260851ba65c",
          // ShopId: "4668685",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Lỗi khi gọi API:", error);
  }
};
const getshipGHTK = async(data) =>{
  const config={
    headers: {
      "Content-Type":"application/json",
      "Token": "eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDI0LTAzLTA3VDE5OjI0OjU4KzA3MDAiLCJzaG9wX2lkIjoiMmEzOGE0YTkzMTZjNDllNWE4MzM1MTdjNDVkMzEwNzAifQ.X2Er2TeVr-Us7NtU54kvU53t_6v7msbCdXCnI68QkKA",
    },
  };
    return await axios.post(Config.BASE_URL_Node+'/shipping/shippingGHTK',data,config)
     
};
const getPriceAllViettelPost = async (data) => {
  try {
    const response = await axios.post(
      `${Config.BASE_URL_Node}/shipping/getshipVTP`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Token:
            "eyJhbGciOiJFUzI1NiJ9.eyJzdWIiOiIwMzM1NDI2NTc1IiwiVXNlcklkIjo2ODgyOTUwLCJGcm9tU291cmNlIjo1LCJUb2tlbiI6IkNFTkQzR1JDNlRWIiwiZXhwIjoxNzExNDM5NDgxLCJQYXJ0bmVyIjo2ODgyOTUwfQ.2vedMDpqf9UXZjBCozXKZOVAe6sVeIqCM3479Y7QP5Ui-DJTcR7R7at-GbI1lRlIHSLjileXx97DcfS6fJKHmw",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Lỗi khi gọi API:", error);
  }
};
const getPriceViettelPost = async (data) => {
  try {
    const response = await axios.post(
      `${Config.BASE_URL_Node}/shipping/getshipVTP2`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "Token":
            "eyJhbGciOiJFUzI1NiJ9.eyJzdWIiOiIwMzM1NDI2NTc1IiwiVXNlcklkIjo2ODgyOTUwLCJGcm9tU291cmNlIjo1LCJUb2tlbiI6IkNFTkQzR1JDNlRWIiwiZXhwIjoxNzExNDM5NDgxLCJQYXJ0bmVyIjo2ODgyOTUwfQ.2vedMDpqf9UXZjBCozXKZOVAe6sVeIqCM3479Y7QP5Ui-DJTcR7R7at-GbI1lRlIHSLjileXx97DcfS6fJKHmw",
        },
      }
    );
    return response;
  } catch (error) {
    console.error("Lỗi khi gọi API:", error);
  }
};
export {
  getShippingUnits, getProvinceGHN, getDistrictGHN, getWardGHN, getService_GHN,
  shipGHTK,  shipGHN,  getPriceAllViettelPost,  getPriceViettelPost,  getshipGHTK,  getStoreGHN
};
