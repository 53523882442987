import axios from "axios";
import Config from "@/components/config";
// import { getItemLocalStorage } from "@/utils";
// const BASE_URL = "https://salekit.net/api/v1/affiliate";
const BASE_URL = Config.BASE_URL;
let datashop =  JSON.parse(localStorage.getItem('getshop'));
let config = ''; 
if(datashop && datashop.length >0){
   config = {
    headers: {
      'Content-Type': 'application/json',
      'Token': datashop.token
    }
  };
}
else{
  setTimeout(() => {
    datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')):'' ;
    config = {
      headers: {
        'Content-Type': 'application/json',
        Token: datashop.token
      }
    };
  }, 1000);
}
const apiUploadImage = (body) => {
  return axios.post('https://photo.salekit.com/upload_image', body, {
    headers: {
      "content-type": "multipart/form-data",
    },
  })

}
const getCustomer = (id_hash) => {
  return axios.get(`${BASE_URL}/dropship/getCustomer`, {
    headers: {
      "Token": datashop.token,
      "Affiliate-Id-Hash":id_hash,
      "Content-Type": "application/json",
    },
  })
}
const  getCustomerNode = async(id_hash) => {
  //  console.log(datashop.token);
  const config1 = {
    headers: {
      "Content-Type": "application/json",
      "Token": datashop.token,
      "Affiliate-Id-Hash":id_hash,
    },
    // withCredentials: true 
  };
  return await axios.get(Config.BASE_URL_Node+'/customer/customerdetail',config1 )
}
const apiUploadImage1= (data,id_hash) => {

    // Tùy chọn cấu hình cho yêu cầu
    const requestOptions = {
      method: 'POST', // Phương thức yêu cầu POST
      body:{
        "photo":data
      } , // Dữ liệu của yêu cầu là formData
      headers: {
        'Token': datashop.token,
        'Affiliate-Id-Hash': id_hash,
        'Content-Type': 'application/json',
      },
      credentials: 'include'
    };
    console.log(requestOptions.body)
    // Gửi yêu cầu POST bằng fetch()
    return fetch('https://salekit.net/api/v1/affiliate/dropship/updateAvatar', requestOptions);

}
const uploaduploadAvatar =  (body,id_hash) => {
 
       return   axios.post(`https://salekit.net/api/v1/affiliate/dropship/uploadAvatar`, body, {
        headers: {
          'Token': datashop.token,
          'Affiliate-Id-Hash': id_hash,
          'Content-Type': 'application/json',
        }
      });
};
const uploadphoto =  (body,id_hash) => {
  console.log(id_hash);
  return   axios.post(`https://salekit.net/api/v1/affiliate/dropship/uploadPhoto`, body, {
   headers: {
     'Token': datashop.token,
     'Affiliate-Id-Hash': id_hash,
     'Content-Type': 'application/json',
   }
 });
};
const uploadphotoNode =  (body,id_hash) => {
  console.log(id_hash);
  return   axios.post(`${Config.BASE_URL_Node}/member/upload-photo`, body, {
   headers: {
     'Token': datashop.token,
     'Affiliate-Id-Hash': id_hash,
     'Content-Type': 'application/json',
   }
 });
};
const uploaduploadAvatarNode =  (body,id_hash) => {
 
  return   axios.post(`${Config.BASE_URL_Node}/member/upload-avatar`, body, {
   headers: {
     'Token': datashop.token,
     'Affiliate-Id-Hash': id_hash,
     'Content-Type': 'application/json',
   }
 });
};
const Saveinfobanking = async (body, id_hash) => {
  try {
    if (datashop) {
      console.log(body);
      const response = await axios.post(`https://salekit.io/api/v1/affiliate/setting`, body, {
        headers: {
          'Token': datashop.token,
          'Affiliate-Id-Hash': id_hash,
          'Content-Type': 'application/json',

        }
      });
      return response;
    }

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const SaveinfobankingNode = async (body, id_hash) => {
  try {
    if (datashop) {
      console.log(body);
      const response = await axios.post(`${Config.BASE_URL_Node}/payment/update`, body, {
        headers: {
          'Token': datashop.token,
          'Affiliate-Id-Hash': id_hash,
          'Content-Type': 'application/json',

        }
      });
      return response;
    }

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const getShopCurren = async (username) => {
  try {
    const res = await axios.get(`${Config.BASE_URL_Node}/shop/detail?username=`+username);
    return res;
  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const getCampaign = async () => {
  try {
    const config1 = {
      headers: {
        'Content-Type': 'application/json',
        'Token': datashop.token
      }
    };
    return await axios.get(`${Config.BASE_URL_Node}/shop/Campaign`,config1);
   } catch (error) {
     console.error("Error fetching getShippingUnits:", error.message);
   }
};


const getProvinces = () => {
  try {
      const response = axios.get(`https://api.salekit.com:3039/file/province.json`);
      // const response = axios.get(`${Config.BASE_URL_test}/dropship/getBanners`, config);
      return response;
    
  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const getDistricts = (id) => {
  try {
      const response = axios.get(`${Config.BASE_URL_Node}/address/district?province_id=` + id, config);
      // const response = axios.get(`${Config.BASE_URL_test}/dropship/getBanners`, config);
      return response;
    

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const getWards = (id) => {
  try {
      const response = axios.get(`${Config.BASE_URL_Node}/address/ward?district_id=` + id, config);
      // const response = axios.get(`${Config.BASE_URL_test}/dropship/getBanners`, config);
      return response;
    

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};



const getpayoutNode = async(id_hash) => {
  try {
    const config1 = {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token,
        "Affiliate-Id-Hash":id_hash,
      }
    };
    return await axios.get(`${Config.BASE_URL_Node}/payment/detail`, config1);
  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};

const getCoupons = () => {
  try {
    if (datashop) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Token': datashop.token
        }
      };
      return axios.get(`${Config.BASE_URL_Node}/coupon/list`, config);
    }

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const getStories = () => {
  try {
    if (datashop) {
      const response = axios.get(`${BASE_URL}/dropship/getBanners`, config);
      // const response = axios.get(`${Config.BASE_URL_test}/dropship/getBanners`, config);
      return response;
    }

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const viewbaner = () => {
  try {
    if (datashop) {
      const response = axios.get(`${BASE_URL}/dropship/getBanners`, { ...config, "email": 'levanquyetcd@gmail.com' });
      // const response = axios.get(`${Config.BASE_URL_test}/dropship/getBanners`, config);
      return response;
    }

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const viewodertop11 = async () => {
  try {
    if (datashop && datashop.length > 0) {
      config = {
        headers: {
          "Content-Type": "application/json",
          "token": datashop.token
        }
      };
      return await axios.get(`${Config.BASE_URL_Node}/order/top10`, config);
    }else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      config = {
        headers: {
          "Content-Type": "application/json",
          "token": datashop.token
        }
      };
      return await axios.get(`${Config.BASE_URL_Node}/order/top10`, config);
    }

  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};

const training = async () => {
  try {
    if (datashop) {
      config = {
        headers: {
          'Content-Type': 'application/json',
          'Token': datashop.token
        }
      };
      const response = await axios.get(`${BASE_URL}/training`, { ...config, "Affiliate-Id-Hash": "ebdb82eb" });
      return response;
    }
    else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      config = {
        headers: {
          "Content-Type": "application/json",
          Token: datashop.token
      
        }
      };
      const response = await axios.get(`${BASE_URL}/training`, { ...config, "Affiliate-Id-Hash": "ebdb82eb" });
      return response;
    }
  } catch (error) {
    console.error("Error fetching getShippingUnits:", error.message);
  }
};
const commission1 = (data) => {
  try {
    if (datashop) {
      const datauserInfo =  JSON.parse(localStorage.getItem('datauserInfo'));
      config = {
        headers: {
          "Content-Type": "application/json",
          Token: datashop.token,
          "Affiliate-Id-Hash":datauserInfo.id_hash
      
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/income/month?sum_month=${data[1]}&sum_year=${data[0]}`, config);
      return response;
    }
    else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      const datauserInfo =  JSON.parse(localStorage.getItem('datauserInfo'));
      config = {
        headers: {
          "Content-Type": "application/json",
          Token: datashop.token,
          "Affiliate-Id-Hash":datauserInfo.id_hash
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/income/month?sum_month=${data[1]}&sum_year=${data[0]}`, config);
      return response;

    }


  } catch (error) {
    console.error("Error fetching getPayments:", error.message);
  }
};
const income = async() => {
  try {
    if (datashop) {
      const datauserInfo =  JSON.parse(localStorage.getItem('datauserInfo'));
      config = {
        headers: {
          "Content-Type": "application/json",
          Token: datashop.token,
          "Affiliate-Id-Hash":datauserInfo.id_hash
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/income/income`, config);
      return response;
    }
    else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      const datauserInfo =  JSON.parse(localStorage.getItem('datauserInfo'));
      config = {
        headers: {
          "Content-Type": "application/json",
          Token: datashop.token,
          "Affiliate-Id-Hash":datauserInfo.id_hash
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/income/income`, config);
      return response;
    }
  } catch (error) {
    console.error("Error fetching getPayments:", error.message);
  }
};
const getsale = async()=> {
  try {
    if (datashop) {
      config = {
        headers: {
          "Content-Type": "application/json",
          Token: datashop.token
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/sale/list`, config);
      return response;
    }
    else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      config = {
        headers: {
          "Content-Type": "application/json",
          Token: datashop.token
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/sale/list`, config);
      return response;
    }
  } catch (error) {
    console.error("Error fetching getPayments:", error.message);
  }
};
const getPayments = () => {
  try {
    if (datashop) {
      const config1 = {
        headers: {
          'Content-Type': 'application/json',
          'Token': datashop.token
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/payment/method`, config1);
      return response;
    }

  } catch (error) {
    console.error("Error fetching getPayments:", error.message);
  }
};
const getRank = async (type = 'day') => {
  try {
    if (datashop) {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'Token': datashop.token
        }
      };
      const response = axios.get(`${Config.BASE_URL_Node}/member/top?type=${type}`, config)
      return response;
    }
  } catch (error) {
    console.log("Error fetching getRank:", error.message)
  }
}
const printorder1 = async () => {
  try {
    if (datashop) {
      const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': datashop.token
        }
    };
    const data = {
      order_id: [
          525359
          // 518526,
          // 518460,
          // 514675,
          // 512541
      ]
  };
  const res = await axios.post(`${Config.BASE_URL_Node}/print/printorder`,data,config)
      return res;
    }
  } catch (error) {
    console.log("Error fetching getRank:", error.message)
  }
};
const printorder = async () => {
  try {
    if (datashop) {
      const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': 'eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDIzLTEwLTE3VDE2OjUzOjQyKzA3MDAiLCJzaG9wX2lkIjoiYzllMTA3NGY1YjNmOWZjOGVhMTVkMTUyYWRkMDcyOTQifQ.-IDZ_rWpPnnSgcqDAtV4HhgTu-Y4Sjrq5e8GS3i0hYg'
        }
    };
    const data = {
      order_id: [
        534119,535609
          // 518526,
          // 518460,
          // 514675,
          // 512541
      ]
  };
  const res = await axios.post(`http://localhost:3039/api/v1/order/print`,data,config)
      return res;
    }
  } catch (error) {
    console.log("Error fetching getRank:", error.message)
  }
};
const dowloadorder1 = async () =>{
  try {
    if (datashop) {
      const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': datashop.token
        }
    };
    const data = {
      order_id: [
          525359
          // 518526,
          // 518460,
          // 514675,
          // 512541
      ],
  };
  return await axios.post(`${Config.BASE_URL_Node}/print/dowload`,data,config,
  {
    responseType: 'blob'  
  })
    }
  } catch (error) {
    console.log("Error fetching getRank:", error.message)
  }
}
const dowloadorder = async () =>{
  try {
    if (datashop) {
      const config = {
        headers: {
            'Content-Type': 'application/json',
            'Token': datashop.token
        }
    };
    const data = {
      order_id: [
          525359
          // 518526,
          // 518460,
          // 514675,
          // 512541
      ],
  };
  return await axios.post(`${Config.BASE_URL_Node}/print/dowload`,data,config,
  {
    responseType: 'blob'  
  })
    }
  } catch (error) {
    console.log("Error fetching getRank:", error.message)
  }
}
export {
   getPayments, getRank,uploadphotoNode,uploaduploadAvatarNode, training, viewodertop11, getStories, viewbaner, getCoupons, 
getCustomer,getCustomerNode,getProvinces, getDistricts, getWards, Saveinfobanking,SaveinfobankingNode, getShopCurren, uploaduploadAvatar,uploadphoto, 
  apiUploadImage,apiUploadImage1,getpayoutNode,getCampaign,commission1,income,getsale,printorder,printorder1,dowloadorder,dowloadorder1
};
