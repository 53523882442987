<template>
  <div>
    <!-- <h4 class="my-6 leading-snug font-semibold text-gray-1100 text-3xl">
     {{$t('account_registration.account_registration')}}
    </h4> -->
    <div id="register-form" class="bg-white">
      <div class="mb-10 space-y-5">
        
        <div>
          <label class="block text-nm text-gray-600 mb-1 mb-2 font-bold" for="full-name">
            {{$t('fullname.fullname')}} 
          </label>
          <div class="relative inline-flex w-full">
            <input
              class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-400 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-400 focus:border-primary-400"
              name="fullName"
              :placeholder="$t('fullname.fullname') "
              aria-invalid="false"
              id="full-name"
              v-model="fullName"
            />
          </div>
          <p v-if="errors && errors.fullName" style="color: red;font-size: 14px;">{{ errors.fullName }} </p>
        </div>
        <div>
          <label class="block text-nm text-gray-600 mb-1 mb-2 font-bold" for="full-name">
           {{$t('username.username')}}
          </label>
          <div class="relative inline-flex w-full">
            <input
              class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-400 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-400 focus:border-primary-400"
              name="fullName"
              :placeholder="$t('placeholder_username_shop.placeholder_username_shop')"
              id="full-name"
              style="font-size: 16px;"
              v-model="Username"
              aria-invalid="false"
              pattern="^[^ \t\n\r]+$"
            />
          </div>
          <p v-if="errors && errors.Username" style="color: red;">{{ errors.Username }} </p>
        </div>
        <div>
          <label class="block text-nm text-gray-600 mb-1 mb-2 font-bold" for="full-name">
            {{$t('phone_number.phone_number')}}
          </label>
          <div class="w-full">
            <div class="relative inline-flex w-full">
              <input
                class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-400 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-400 focus:border-primary-400"
                type="tel"
                name="phone"
                :placeholder="$t('phone_number.phone_number')"
                aria-invalid="false"
                id="phone-number"
                v-model="phone"
              />
            </div>
          </div>
          <p v-if="errors && errors.phone" style="color: red;">{{ errors.phone }} </p>
        </div>
        
        <div>
          <label class="block text-nm text-gray-600 mb-1 mb-2 font-bold" for="full-name">
            Email
          </label>
          <div class="relative inline-flex w-full">
            <input
              class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-400 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-400 focus:border-primary-400"
              name="email"
              placeholder="Email"
              aria-invalid="false"
              id="full-name"
              v-model="email"
              @change="changeemail(email)"
            />
          </div>
          <p v-if="errors && errors.email" style="color: red;">{{ errors.email }} </p>
        </div>
        <div>
          <label class="block text-nm text-gray-600 mb-1 mb-2 font-bold" for="password">
           {{$t('password.password')}}
          </label>
          <div class="relative inline-flex w-full">
            <input
                class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 pr-23 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-1000 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400"
                :type="showPassword ? 'text' : 'password'"
                v-model="password" name="password"
                :placeholder="$t('password.password')"
                aria-invalid="false"
              /> 
             
              <button
                class="!absolute right-1 top-1 relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                type="button" aria-label="Hiện"
                @click.prevent="toggleShowPassword">
                <svg v-if="showPassword === false" height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M11.9995 8C9.79023 8 8 9.79064 8 12.0005C8 14.2096 9.79043 16 11.9995 16C14.2082 16 16 14.21 16 12.0005C16 9.79023 14.2084 8 11.9995 8ZM10 12.0005C10 10.8948 10.8952 10 11.9995 10C13.1043 10 14 10.8952 14 12.0005C14 13.1046 13.1045 14 11.9995 14C10.895 14 10 13.105 10 12.0005Z"
                    fill="currentColor" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M12.0019 4C7.77868 4 4.09588 6.98537 2.08337 11.6003C1.97221 11.8552 1.97221 12.1448 2.08337 12.3997C4.10222 17.0292 8.0226 20 12.0019 20C15.9526 20 19.5766 17.7019 21.9149 12.4038C22.0278 12.148 22.0284 11.8566 21.9166 11.6003C20.2236 7.71791 16.8868 4 12.0019 4ZM12.0019 18C9.13107 18 5.93087 15.8681 4.09804 11.9992C5.92305 8.1182 8.91948 6 12.0019 6C15.5885 6 18.3193 8.63312 19.9018 11.9989C17.8228 16.425 14.9122 18 12.0019 18Z"
                    fill="currentColor" />
                </svg>
                <svg  v-else height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.3298 6.75071L21.326 4.72927C21.7166 4.33366 21.7166 3.69231 21.326 3.2967C20.9354 2.9011 20.3031 2.9011 19.9124 3.2967L4.13843 19.2715C3.74787 19.667 3.7478 20.3072 4.13822 20.7028C4.33334 20.9016 4.59002 21 4.84521 21C5.10037 21 5.35747 20.9011 5.55259 20.7024L7.88266 18.3428C9.19401 19.037 10.5943 19.4135 12.0019 19.4135C15.9526 19.4135 19.5766 17.0861 21.9149 11.7205C22.0278 11.4615 22.0284 11.1664 21.9166 10.9068C21.283 9.43521 20.419 7.98696 19.3298 6.75071ZM17.9152 8.1832L15.864 10.2605C15.9527 10.5958 16 10.9483 16 11.3121C16 13.5497 14.2082 15.3625 11.9995 15.3625C11.6405 15.3625 11.2926 15.3147 10.9616 15.2249L9.38592 16.8205C10.2572 17.1953 11.1442 17.388 12.0019 17.388C14.9122 17.388 17.8228 15.7929 19.9018 11.3105C19.367 10.1586 18.701 9.09137 17.9152 8.1832Z" fill="currentColor"></path><path d="M12.0019 3.20979C13.5474 3.20979 14.9379 3.58668 16.1705 4.2265L14.6586 5.75758C13.8363 5.42117 12.949 5.23525 12.0019 5.23525C8.91948 5.23525 5.92305 7.38041 4.09804 11.3108C4.65967 12.5115 5.34968 13.5469 6.11926 14.4057L4.70553 15.8374C3.66219 14.689 2.76454 13.2983 2.08337 11.7164C1.97221 11.4583 1.97221 11.1649 2.08337 10.9068C4.09588 6.23316 7.77868 3.20979 12.0019 3.20979Z" fill="currentColor"></path><path d="M11.9995 7.26071C12.3588 7.26071 12.707 7.30866 13.0382 7.3986C11.4038 9.05379 9.77017 10.7083 8.13597 12.3633C8.04729 12.0281 8 11.6757 8 11.3121C8 9.07414 9.79023 7.26071 11.9995 7.26071Z" fill="currentColor"></path></svg>
              </button>
          </div>
          <p v-if="errors && errors.password" style="color: red;">{{ errors.password }} </p>
          
        </div>
        
        <div>
          <label class="block text-nm text-gray-600 mb-1 mb-2 font-bold" for="password">
          {{$t('a_password_confirm.a_password_confirm')}}
          </label>
          <div class="relative inline-flex w-full">
            <input
            
              class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-400 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-400 focus:border-primary-400"
              :type="showPassword1 ? 'text' : 'password'" 
              name="confirmPassword"
              :placeholder="$t('a_password_confirm.a_password_confirm')"
              aria-invalid="false"
              v-model="confirmPassword"
            />
            <button
                  class="!absolute right-1 top-1 relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                  type="button" aria-label="Hiện" @click.prevent="toggleShowPassword1">
                  <svg v-if="showPassword1 === false" height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.9995 8C9.79023 8 8 9.79064 8 12.0005C8 14.2096 9.79043 16 11.9995 16C14.2082 16 16 14.21 16 12.0005C16 9.79023 14.2084 8 11.9995 8ZM10 12.0005C10 10.8948 10.8952 10 11.9995 10C13.1043 10 14 10.8952 14 12.0005C14 13.1046 13.1045 14 11.9995 14C10.895 14 10 13.105 10 12.0005Z"
                      fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M12.0019 4C7.77868 4 4.09588 6.98537 2.08337 11.6003C1.97221 11.8552 1.97221 12.1448 2.08337 12.3997C4.10222 17.0292 8.0226 20 12.0019 20C15.9526 20 19.5766 17.7019 21.9149 12.4038C22.0278 12.148 22.0284 11.8566 21.9166 11.6003C20.2236 7.71791 16.8868 4 12.0019 4ZM12.0019 18C9.13107 18 5.93087 15.8681 4.09804 11.9992C5.92305 8.1182 8.91948 6 12.0019 6C15.5885 6 18.3193 8.63312 19.9018 11.9989C17.8228 16.425 14.9122 18 12.0019 18Z"
                      fill="currentColor" />
                  </svg>
                  <svg v-else height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M19.3298 6.75071L21.326 4.72927C21.7166 4.33366 21.7166 3.69231 21.326 3.2967C20.9354 2.9011 20.3031 2.9011 19.9124 3.2967L4.13843 19.2715C3.74787 19.667 3.7478 20.3072 4.13822 20.7028C4.33334 20.9016 4.59002 21 4.84521 21C5.10037 21 5.35747 20.9011 5.55259 20.7024L7.88266 18.3428C9.19401 19.037 10.5943 19.4135 12.0019 19.4135C15.9526 19.4135 19.5766 17.0861 21.9149 11.7205C22.0278 11.4615 22.0284 11.1664 21.9166 10.9068C21.283 9.43521 20.419 7.98696 19.3298 6.75071ZM17.9152 8.1832L15.864 10.2605C15.9527 10.5958 16 10.9483 16 11.3121C16 13.5497 14.2082 15.3625 11.9995 15.3625C11.6405 15.3625 11.2926 15.3147 10.9616 15.2249L9.38592 16.8205C10.2572 17.1953 11.1442 17.388 12.0019 17.388C14.9122 17.388 17.8228 15.7929 19.9018 11.3105C19.367 10.1586 18.701 9.09137 17.9152 8.1832Z" fill="currentColor"></path><path d="M12.0019 3.20979C13.5474 3.20979 14.9379 3.58668 16.1705 4.2265L14.6586 5.75758C13.8363 5.42117 12.949 5.23525 12.0019 5.23525C8.91948 5.23525 5.92305 7.38041 4.09804 11.3108C4.65967 12.5115 5.34968 13.5469 6.11926 14.4057L4.70553 15.8374C3.66219 14.689 2.76454 13.2983 2.08337 11.7164C1.97221 11.4583 1.97221 11.1649 2.08337 10.9068C4.09588 6.23316 7.77868 3.20979 12.0019 3.20979Z" fill="currentColor"></path><path d="M11.9995 7.26071C12.3588 7.26071 12.707 7.30866 13.0382 7.3986C11.4038 9.05379 9.77017 10.7083 8.13597 12.3633C8.04729 12.0281 8 11.6757 8 11.3121C8 9.07414 9.79023 7.26071 11.9995 7.26071Z" fill="currentColor"></path></svg>
            </button>
          </div>
         <p v-if="errors && errors.confirmPassword" style="color: red;">{{ errors.confirmPassword }} </p>
        </div>
        <!-- Các trường khác tương tự -->
        <div> 
        <div class="flex space-x-2">
          <div class="relative inline-flex items-center">
            <input
              class="rounded border-2 border-gray-400 !bg-[length:75%_75%] h-5 w-5 text-primary-400 form-checkbox border-gray-400 focus:ring-primary-400"
              id="checkbox-terms"
              type="checkbox"
              v-model="acceptTerms"
              @click="checkbox"
            />
          </div>
          <label
            class="block text-nm text-gray-600 text-gray-900 font-s pl-[0] "
            style="font-size: 12px;"
            for="checkbox-terms"
          >
            <p class="text-gray-1100">
             {{$t('I_accept.I_accept')}}
              <a href="https://salekit.net/terms" target="_blank" class="text-left font-medium text-primary-400">
                    {{$t('the_terms_and_policies.the_terms_and_policies')}}
              </a>
              {{$t('of_the_company.of_the_company')}}
            </p>
          </label>
        </div>
        <p v-if="errors && errors.acceptTerms" style="color: red;">{{ errors.acceptTerms }} </p>
        </div>
        <button
          class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300"
          type="submit"
          @click.prevent="handleSubmit"
        >
         {{$t('register_now_upcase.register_now_upcase')}}
        </button>
        <div class=" flex items-center justify-center text-center ">
          <p style="font-size: 14px; padding-right: 5px;">{{$t('do_you_already_have_account.do_you_already_have_account')}} </p> <router-link to="/" style="font-weight: 500;"> {{$t('login.login')}}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/components/config';
import { useToast } from "vue-toastification";
// const BASE_URL = 'https://salekit.net/api/v1/affiliate';

export default {
  name: "Registry_step3",
  data() {
    return {
      formData: {
        phone: '',
        fullName: '',
        email: '',
       
        password: '',
        confirmPassword: '',
        acceptTerms: false,
        Username:'',
      },
      errors:{
              Username:'',
              phone:'',
              fullName:'',
              email:'',
              password:'',
              confirmPassword:'',
              acceptTerms:'',
        },
      showPassword:false,
      showPassword1:false,
    };
  },
  created(){
   
  },
  methods: {
    dieukhoan(){

    },
    updatePassword() {
      
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    toggleShowPassword1() {
      console.log("thay doi");
      this.showPassword1 = !this.showPassword1;
      console.log( this.showPassword1);
    },
    checkbox(){
      this.acceptTerms !=  this.acceptTerms;
    },
    changeemail(email){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    checkusername(){
      const toast = useToast();
      const vietnameseWithoutDiacriticsAndSpaceRegex = /^[a-zA-Z0-9\s]*$/;
      if (vietnameseWithoutDiacriticsAndSpaceRegex.test(this.Username)) {
        return true ;
      } else {
        this.errors.Username = this.$t('username_must_not_spaces.username_must_not_spaces');
          toast.error(this.$t('username_must_not_spaces.username_must_not_spaces'));
        return false ;
      }
    },
  async  handleSubmit() {
      const toast = useToast();
      const check = await this.checkusername();
      if(check === false){
        return;
      }
      if(!this.fullName || this.fullName && this.fullName.length < 5){
        this.errors.fullName = this.$t('re_enter_your_full_name.re_enter_your_full_name');
        toast.error( this.$t('re_enter_your_full_name.re_enter_your_full_name'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }
      else{
        this.errors.fullName = '';
      }
      if(!this.Username || this.Username.length < 5){
        this.errors.Username = this.$t('re_enter_your_user_name.re_enter_your_user_name');
        toast.error(this.$t('re_enter_your_user_name.re_enter_your_user_name'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }
      else{
        this.errors.Username = "";
      }
      if(!this.phone || (this.phone && this.phone.length < 10)){
        this.errors.phone = this.$t('re_enter_your_phone.re_enter_your_phone');
        toast.error(this.$t('re_enter_your_phone.re_enter_your_phone'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }
      else{
        this.errors.phone = "";
      }
      
      if(!this.email || this.email && this.email.length < 5 ){
        this.errors.email = this.$t('re_enter_your_email.re_enter_your_email');
        toast.error(this.$t('re_enter_your_email.re_enter_your_email'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }else if(!this.changeemail(this.email)){
        this.errors.email = this.$t('re_enter_your_email.re_enter_your_email');
        toast.error(this.$t('re_enter_your_email.re_enter_your_email'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }
      else{
        this.errors.email = "";
      }
      
      if(!this.password || (this.password && this.password.length < 8)){
        this.errors.password = this.$t('password_must_be_more_than_8_characters_in_length.password_must_be_more_than_8_characters_in_length');
        toast.error(this.$t('password_must_be_more_than_8_characters_in_length.password_must_be_more_than_8_characters_in_length'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }
      else{
        this.errors.password = "";
      }
      if(!this.confirmPassword || this.confirmPassword !== this.password){
        this.errors.confirmPassword = this.$t('a_password_confirm.a_password_confirm');
        toast.error(this.$t('a_password_confirm.a_password_confirm'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }
      else{
        this.errors.confirmPassword = "";
      }
      if(this.acceptTerms !== true){
        this.errors.acceptTerms =  this.$t('accept_terms.accept_terms');
        toast.error(this.$t('accept_terms.accept_terms'));
        setTimeout(() => {
          toast.clear();
        }, 2000);
        return  ;
      }
      else{
        this.errors.acceptTerms = "";
      }
      const cid = localStorage.getItem('cid');
      let getshop = JSON.parse(localStorage.getItem("getshop"));
     
      const body = {
          "fullname":this.fullName,
          "phone":this.phone,
          "email": this.email,
          "username":this.Username,
          "password":this.password,
          "re_password":this.confirmPassword,
          "cid": cid
      }
      const config1 = {
        headers: {
          'Content-Type': 'application/json',
          'Token': getshop.token,
          }
      };
      // console.log("body",body);
      const response = await axios.post(`${config.BASE_URL_Node}/member/register`,body, config1);
       console.log("ket qua",response);
      if(response.data.status == 1){
        localStorage.removeItem('cid');
        const toast = useToast();
        localStorage.setItem("customer_id",response.data.data.id);
        toast.success(this.$t('register_success_up.register_success_up'));
        localStorage.setItem("singup",JSON.stringify(body));
        if(response.data.data.redirect === "register_now"){
          this.$router.push("/register/step5");
        }
        else if(response.data.data.redirect === "verify")
        {
          this.$emit('next-step');
        }
       
      }else{
        const toast = useToast();
        toast.error(response.data.msg);
      }

    
      // this.$router.replace("/");
    },
    
  }
};
</script>

<!-- You may add scoped styles here -->
