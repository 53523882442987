<template>
  <div>
    <div class="mb-16 space-y-2">
      <b class="text-[26px] text-gray-1100">{{$t('invitation_code.invitation_code')}}</b>
      <p class="text-gray-600">{{$t('request_to_enter_the_invite_code_of_the_referrer.request_to_enter_the_invite_code_of_the_referrer')}}</p>
    </div>
    <form   >
      <div class="mb-16 space-y-20">
        <div class="space-y-3">
          <div>
            <div class="relative inline-flex w-full">
              <input
                class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-1000 bg-white text-gray-900 placeholder:text-gray-400 hover:border-gray-1000 focus:border-primary-400"
                name="phoneOrUsername"
                :placeholder="$t('request_to_enter_the_invite_code_of_the_referrer.request_to_enter_the_invite_code_of_the_referrer')"
                aria-invalid="false"
                v-model="phone"
               
              />
            </div>
            <!-- @keypress="onlyNumbers" -->
            <div>
              <p v-if="this.error" style="color: red; " class="pt-[20px]">{{ this.error }} </p>
            </div>
          </div>
        </div>
        <button
          class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300"
          @click.prevent="handleSubmit"
        >
        <div v-if="isloading===true" data-v-3fcda164="" class="icon-inside-textarea">
                <span data-v-3fcda164="" style="display: none;">{{$t('salekit_value_70.salekit_value_70')}}</span>
                <i data-v-3fcda164="" class="fa fa-spinner fa-spin"></i>
              </div>
          <p v-else> {{$t('cko_ma.cko_ma')}}</p>
        </button>
      </div>
      <div class="flex justify-center gap-2">
        <p class="text-gray-1100">{{$t('do_you_already_have_account.do_you_already_have_account')}} </p>
        <router-link to="/register-partner">
          <button
            class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap leading-none focus:outline-none bg-transparent w-fit h-fit text-primary-400 hover:text-primary-300"
            type="button"
          >
            {{$t('sign_in_now.sign_in_now')}}
          </button>
        </router-link>
      </div>
      
    </form>
  </div>
</template>

<script>

import { useToast } from "vue-toastification";
import {getMembers} from "@/services/customer/customerService"

export default {
  name: "Registry_step1",
  components: {
  },
  props: ['step1Data'],
  data(){
    return {
      phone:'',
      error:'',
      isloading:false,
     
    }
  },
  // setup() {
  //   const navigate = useRouter();
  //   const route = useRoute();
  //   //const ref = ref('');

  //   const handleSubmit = () => {
  //     console.log(route);
  //     navigate.push(`/registry?ref=${ref.value}`);
  //   };

  //   return {
  //     ref,
  //     handleSubmit
  //   };
  // },
  methods:{
  
    async handleSubmit (event)  {
      const toast = useToast();
           
      event.preventDefault();
      this.isloading = true;
      if(!this.phone){
        this.isloading = false;
        toast.error(this.$t('input_invite_Code.input_invite_Code'));
            setTimeout(() => {
              toast.clear();
            }, 2000);
        return;

      }
      {
      const response = await getMembers(this.phone);
      // console.log(response);
        if(response.msg === "Success"){
            console.log(response);
           localStorage.setItem('cid',this.phone)
          localStorage.setItem("userintroduce",JSON.stringify(response.data));
          this.$router.push(`/register?ref=` + this.phone);
          // console.log(response.customer[0]);
          this.$emit('next-step',response.data);
        }else{
          this.isloading = false;
          console.log("error",response.msg)
          toast.error(response.msg);
            setTimeout(() => {
              toast.clear();
            }, 2000);
         
        }       
      }}
     
      //navigate.push(`/registry?ref=${this.phone}`);
      // }
    // },
  }
};
</script>

<!-- You may add scoped styles here -->
