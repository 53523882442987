<template>
  <div class="px-4 flex flex-col gap-4 bg-gay-10 pt-4 ">
    <!-- <div class="px-4 flex flex-col gap-4">
    <div v-for="(order, index) in orders" :key="index" class="bg-white shadow-lg p-4 rounded-2xl pb-3 pt-5">
      <div class="flex space-x-3">
        <div class="flex justify-center ring-4 ring-gray-50 aspect-square overflow-hidden rounded-full bg-white w-10 self-start">
          <div class="relative aspect-square self-center w-[66%]">
            <img :src="order.avatar" alt="" class="object-cover w-full h-full" />
          </div>
        </div>
        <div class="w-full space-y-1.5">
          <div class="flex items-center space-x-3">
            <span class="text-nm font-semibold text-gray-1100">{{ order.name }}</span>
            <p class="text-xs text-gray-300">{{ order.time }}</p>
          </div>
          <p class="text-nm text-gray-1100">
            <span />
          </p>
          <p>Vừa có đơn hàng đầu tiên trong ngày, trị giá <strong><span style="color: #FF6314">{{ order.price }}</span></strong>.</p>
          <p />
        </div>
      </div>
      <div class="mt-2 flex w-full items-center">
        <div class="w-10 flex-none" />
        <div class="flex items-center justify-between gap-3 ml-1 w-full">
          <div class="flex w-2/6 items-center">
            <button class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-primary-400 hover:text-primary-300 ml-2 !font-normal !text-gray-600" type="button">
              <span class="text-md mr-2">
                <svg class="!text-lg !text-gray-300" width="1em" height="1em" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.668 5.66675H10.9913L11.9271 2.86091C12.0955 2.35425 12.0105 1.79258 11.698 1.35925C11.3855 0.925915 10.8788 0.666748 10.3446 0.666748H9.0013C8.7538 0.666748 8.51964 0.776748 8.36047 0.966748L4.4438 5.66675H2.33464C1.41547 5.66675 0.667969 6.41425 0.667969 7.33341V14.8334C0.667969 15.7526 1.41547 16.5001 2.33464 16.5001H4.83464H13.4238C14.1146 16.5001 14.7413 16.0659 14.9846 15.4184L17.2821 9.29258C17.3171 9.19925 17.3346 9.10008 17.3346 9.00008V7.33341C17.3346 6.41425 16.5871 5.66675 15.668 5.66675ZM2.33464 7.33341H4.0013V14.8334H2.33464V7.33341ZM15.668 8.84925L13.4238 14.8334H5.66797V6.80175L9.3913 2.33341H10.3463L9.04464 6.23591C8.9588 6.49008 9.00214 6.76925 9.1588 6.98675C9.31547 7.20508 9.56714 7.33341 9.83464 7.33341H15.668V8.84925Z" fill="currentColor" />
                </svg>
              </span>
              <span>Thích</span>
            </button>
          </div>
          <div class="flex w-full items-center gap-2">
            <input type="text" class="border border-gray-300 focus:outline-none p-2 rounded-lg text-nm text-gray-1100 w-full" placeholder="Bình luận..." />
            <button class="px-3 py-2 bg-primary-400 text-white font-semibold rounded-lg transition duration-200 hover:bg-primary-500 focus:outline-none" type="button">Gửi</button>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <div v-if="isLoading"  class=" p-4 rounded-2xl pb-3 pt-5 " style="margin:0px 5px 0px 5px">
    <div class="bg-white shadow-lg p-4 rounded-2xl pb-3 pt-5" >
      <div class="flex space-x-3 ">
        <div class=" aspect-square overflow-hidden rounded-full bg-gray-25 w-10 self-start">
          <div class="relative aspect-square self-center w-full">
            <span
              style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: absolute; inset: 0px;">
              <div class="relative h-0" style="padding-bottom: 100%;">
                        <div class="absolute inset-0 h-full w-full rounded-lg animate-pulse bg-gray-800 bg-opacity-10" />
                        <img  class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg" src="https://salekit.net/assets/images/no-image.png" />
                    </div>
            </span>
          </div>
        </div>
        <div class="w-full space-y-2">
          <div class="flex items-center space-x-3 bg-gray-25 " style="height: 20px;width: 120px;" >
            <span class="text-nm font-semibold text-gray-1100"> </span>
            <p class="text-xs text-gray-300 bg-gray-25">
              <time datetime="2024-01-22T08:05:08.875Z"
                title="2024-01-22T08:05:08.8754357Z"> </time>
            </p>
          </div>
          <div class="grid grid-cols-2 ">
            <div>
              <p class="space-y-2 bg-gray-25" style="height: 20px; width: 90%;"> </p>
            </div>
            <p class="space-y-2 bg-gray-25" style="height: 20px; width: 30%;"> </p>
             <div>
              
            </div>
          </div>
          
         
         
        </div>
      </div>
    </div> 
    <br> 
    <div class="bg-white shadow-lg p-4 rounded-2xl pb-3 pt-5" >
      <div class="flex space-x-3 ">
        <div class=" aspect-square overflow-hidden rounded-full bg-gray-25 w-10 self-start">
          <div class="relative aspect-square self-center w-full">
            <span
              style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: absolute; inset: 0px;">
              <div class="relative h-0" style="padding-bottom: 100%;">
                        <div class="absolute inset-0 h-full w-full rounded-lg animate-pulse bg-gray-800 bg-opacity-10" />
                        <img  class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg" src="https://salekit.net/assets/images/no-image.png" />
                    </div>
            </span>
          </div>
        </div>
        <div class="w-full space-y-2">
          <div class="flex items-center space-x-3 bg-gray-25 " style="height: 20px;width: 120px;" >
            <span class="text-nm font-semibold text-gray-1100"> </span>
            <p class="text-xs text-gray-300 bg-gray-25">
              <time datetime="2024-01-22T08:05:08.875Z"
                title="2024-01-22T08:05:08.8754357Z"> </time>
            </p>
          </div>
          <div class="grid grid-cols-2 ">
            <div>
              <p class="space-y-2 bg-gray-25" style="height: 20px; width: 90%;"> </p>
            </div>
            <p class="space-y-2 bg-gray-25" style="height: 20px; width: 30%;"> </p>
             <div>
              
            </div>
          </div>
          
         
         
        </div>
      </div>
    </div> 
    <br> 
    <div class="bg-white shadow-lg p-4 rounded-2xl pb-3 pt-5" >
      <div class="flex space-x-3 ">
        <div class=" aspect-square overflow-hidden rounded-full bg-gray-25 w-10 self-start">
          <div class="relative aspect-square self-center w-full">
            <span
              style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: absolute; inset: 0px;">
              <div class="relative h-0" style="padding-bottom: 100%;">
                        <div class="absolute inset-0 h-full w-full rounded-lg animate-pulse bg-gray-800 bg-opacity-10" />
                        <img  class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg" src="https://salekit.net/assets/images/no-image.png" />
                    </div>
            </span>
          </div>
        </div>
        <div class="w-full space-y-2">
          <div class="flex items-center space-x-3 bg-gray-25 " style="height: 20px;width: 120px;" >
            <span class="text-nm font-semibold text-gray-1100"> </span>
            <p class="text-xs text-gray-300 bg-gray-25">
              <time datetime="2024-01-22T08:05:08.875Z"
                title="2024-01-22T08:05:08.8754357Z"> </time>
            </p>
          </div>
          <div class="grid grid-cols-2 ">
            <div>
              <p class="space-y-2 bg-gray-25" style="height: 20px; width: 90%;"> </p>
            </div>
            <p class="space-y-2 bg-gray-25" style="height: 20px; width: 30%;"> </p>
             <div>
              
            </div>
          </div>
          
         
         
        </div>
      </div>
    </div> 
    <br> 
    <div class="bg-white shadow-lg p-4 rounded-2xl pb-3 pt-5" >
      <div class="flex space-x-3 ">
        <div class=" aspect-square overflow-hidden rounded-full bg-gray-25 w-10 self-start">
          <div class="relative aspect-square self-center w-full">
            <span
              style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: absolute; inset: 0px;">
              <div class="relative h-0" style="padding-bottom: 100%;">
                        <div class="absolute inset-0 h-full w-full rounded-lg animate-pulse bg-gray-800 bg-opacity-10" />
                        <img  class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg" src="https://salekit.net/assets/images/no-image.png" />
                    </div>
            </span>
          </div>
        </div>
        <div class="w-full space-y-2">
          <div class="flex items-center space-x-3 bg-gray-25 " style="height: 20px;width: 120px;" >
            <span class="text-nm font-semibold text-gray-1100"> </span>
            <p class="text-xs text-gray-300 bg-gray-25">
              <time datetime="2024-01-22T08:05:08.875Z"
                title="2024-01-22T08:05:08.8754357Z"> </time>
            </p>
          </div>
          <div class="grid grid-cols-2 ">
            <div>
              <p class="space-y-2 bg-gray-25" style="height: 20px; width: 90%;"> </p>
            </div>
            <p class="space-y-2 bg-gray-25" style="height: 20px; width: 30%;"> </p>
             <div>
              
            </div>
          </div>
          
         
         
        </div>
      </div>
    </div>
  </div>  
  <div v-else class="bg-white shadow-lg p-4 rounded-2xl pb-3 pt-5" v-for="oder in this.listoder" :key="oder.id">
      <div class="flex space-x-3">
        <div class=" aspect-square overflow-hidden rounded-full bg-white w-10 self-start">
          <div class="relative aspect-square self-center w-full">
            <span
              style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: absolute; inset: 0px;">
              <img alt="" :src="oder.aff_avatar" decoding="async" data-nimg="fill"
                style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;">
            </span>
          </div>
        </div>
        <div class="w-full space-y-1.5">
          <div class="flex items-center space-x-3">
            <span class="text-nm font-semibold text-gray-1100">{{ oder.aff_name }} </span>
            <p class="text-xs text-gray-300">
              <time datetime="2024-01-22T08:05:08.875Z"
                title="2024-01-22T08:05:08.8754357Z">{{ formatdate(oder.created_at) }}</time>
            </p>
          </div>
          <p class="text-nm text-gray-1100">
            <span>
            </span>
          </p>
          <p> {{ $t('just_received_a_valuable_order.just_received_a_valuable_order') }} <strong>
              <span style="color: rgb(255, 99, 20);">{{ formatNumber(oder.revenue) }}đ</span>
            </strong></p>
          <p></p>
        </div>
      </div>
      <div class="mt-2 flex w-full items-center">
        <div class="w-10 flex-none">
        </div>
        <div class="flex items-center justify-between gap-3 ml-1 w-full">
          <div class="flex w-2/6 items-center">
            <button
              class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-primary-400 hover:text-primary-300 ml-2 !font-normal !text-gray-600"
              type="button" fdprocessedid="rb7dmp">
            </button>
          </div>
          <div class="flex w-full items-center gap-2">
            <div class="w-7 flex-none">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="bg-white shadow-lg p-4 rounded-2xl pb-3 pt-5">
    <div class="flex space-x-3">
      <div class=" aspect-square overflow-hidden rounded-full bg-white w-10 self-start">
        <div class="relative aspect-square self-center w-full"><span style="box-sizing: border-box; display: block; overflow: hidden; width: initial; height: initial; background: none; opacity: 1; border: 0px; margin: 0px; padding: 0px; position: absolute; inset: 0px;"><img alt="" src="https://api.droppii.com/uploader-service/v1/uploader/user%252Favatar%252F968e789e-9708-45f6-b744-9b64095dd68d.jpeg" decoding="async" data-nimg="fill" style="position: absolute; inset: 0px; box-sizing: border-box; padding: 0px; border: none; margin: auto; display: block; width: 0px; height: 0px; min-width: 100%; max-width: 100%; min-height: 100%; max-height: 100%; object-fit: cover;"></span></div></div><div class="w-full space-y-1.5"><div class="flex items-center space-x-3"><span class="text-nm font-semibold text-gray-1100">Nguyễn Thị Hoài Phương </span>
        <p class="text-xs text-gray-300">
          <time datetime="2024-01-22T10:08:48.535Z" title="2024-01-22T10:08:48.5359217Z">5 phút trước</time>
        </p></div><p class="text-nm text-gray-1100">
            <span>
          </span></p><p> Vừa có đơn hàng trị giá <strong>
            <span style="color: rgb(255, 99, 20);">đ2,238,000</span>
          </strong></p><p></p></div>
        </div>
          <div class="mt-2 flex w-full items-center">
            <div class="w-10 flex-none">

          </div>
          <div class="flex items-center justify-between gap-3 ml-1 w-full">
            <div class="flex w-2/6 items-center">
           </div>
           <div class="flex w-full items-center gap-2"><div class="w-7 flex-none">
          
          </div>
        </div>
        </div>
        </div>
        </div> -->
  </div>
</template>

<script>
import {

  viewodertop11
} from '@/services/affiliate/affiliate';
import { formatDistanceToNow, parseISO } from 'date-fns';
export default {
  name: "RankPage",
  data() {
    return {
      listoder: '',
      isLoading: false,
      // Khai báo các biến dữ liệu cần thiết
    };
  },
  methods: {
    // formatdate(createdAt){
    //   console.log(createdAt)
    //   const dateObject = new Date(createdAt); // Tạo đối tượng ngày tháng từ chuỗi
    //   const hour = dateObject.getHours().toString().padStart(2, '0'); // Lấy giờ từ đối tượng ngày tháng và định dạng thành chuỗi có 2 chữ số
    //   const minute = dateObject.getMinutes().toString().padStart(2, '0'); // Lấy phút từ đối tượng ngày tháng và định dạng thành chuỗi có 2 chữ số

    //   return `${hour}:${minute}`;
     
    // },
    
    formatdate(createdAt) {
    const createdDate = parseISO(createdAt); // Chuyển đổi chuỗi thời gian thành đối tượng Date
    const distance = formatDistanceToNow(createdDate, { addSuffix: true }); // Tính khoảng cách thời gian từ createdAt đến thời điểm hiện tại và thêm từ "trước" hoặc "sau"
    // console.log(distance,"==")
    if(distance === "less than a minute ago"){
      const x  = this.$t('just_finished.just_finished')
      return x;
    }
    const match = distance.match(/(\d+) (.+)/); // Số và đơn vị được lưu vào match[1] và match[2] tương ứng

    if (match) {
        let value = match[1];
        if(value && value === "less than"){
          value = this.$t('just_finished.just_finished')
        }
        let unit = match[2];
        if(unit && unit === "hours ago"){
          unit = this.$t('hours_ago.hours_ago')
        }
        if(unit && unit === "day ago"){
          unit = this.$t('day_ago1.day_ago1')
        }
        if(unit && unit === "days ago"){
          unit = this.$t('days_ago.days_ago')
        }
        if(unit && unit === "minute ago"){
          unit = this.$t('minute_ago.minute_ago')
        }
        if(unit && unit === "minutes ago"){
          unit = this.$t('minutes_ago.minutes_ago')
        }
        return `${value} ${unit} `;
    }
    return distance;
},
    formatPrice(price) {
      // Chuyển số thành chuỗi
      let priceString = price.toString();
      // Tạo mảng chứa các phần tử
      let parts = [];
      // Thêm từng ba chữ số vào mảng
      while (priceString.length > 3) {
        parts.unshift(priceString.slice(-3));
        priceString = priceString.slice(0, -3);
      }
      // Thêm phần còn lại (ít hơn ba chữ số) vào mảng
      if (priceString) {
        parts.unshift(priceString);
      }
      // Kết hợp các phần và thêm dấu chấm vào giữa chúng
      return parts.join('.') + '₫';
    },
    formatNumber(value) {
            const result = parseFloat(value);

            if (isNaN(result)) {
                return "Invalid value";
            }
            return result.toLocaleString('vi-VN', {
                maximumFractionDigits: 0
            });
    },
    async getviewtop10() {
      this.isLoading = true;
      const response = await viewodertop11();
      if (response && response.data) {
        //console.log("this list stores", response.data);
        this.listoder = response.data.data;
        //console.log(this.listoder);
        this.isLoading = false;
      }

      //console.log("this list stores", this.listStores);

    }
  },
  created() {


  },
  mounted() {
    this.getviewtop10();

    // In ra giá trị của dữ liệu được truyền qua URL
    // Thực hiện các tác vụ sau khi component đã được render
  },
  computed: {
  }

};
</script>

<style scoped>
/* Add your styles here */
</style>
