<template>
  <!-- <Header /> -->
  <div>
    <div>
      <div class="relative z-10 pb-[64px] pt-4">
        <div
          class="scrollbar flex overflow-x-auto whitespace-nowrap bg-white px-4 shadow-300 mx-6 rounded-2xl p-1 px-1 drop-shadow-none"
          role="tablist" aria-orientation="horizontal">
          <button
            class="relative inline-flex flex-1 items-center justify-center whitespace-nowrap px-2 py-3 font-semibold focus-visible:outline-none text-primary-400 py-1 rounded-xl text-white bg-primary-300 py-1 rounded-xl"
            id="headlessui-tabs-tab-:r185:" role="tab" aria-selected="true" tabIndex={0}
            data-headlessui-state="selected" type="button"
            :style="{ backgroundColor: colorrankday ? 'rgb(60 92 241 / var(--tw-bg-opacity)' : '#FFFFFF', color: colorrankday ? '#FFFFFF !important' : '#000000' }"
            @click="rankday">
            {{$t('day_up.day_up')}}
          </button>
          <button
            class="relative inline-flex flex-1 items-center justify-center whitespace-nowrap px-2 py-3 font-semibold focus-visible:outline-none text-primary-400 py-1 rounded-xl"
            id="headlessui-tabs-tab-:r186:" role="tab" aria-selected="false" tabIndex={-1} data-headlessui-state
            type="button"
            :style="{ backgroundColor: colorrankweek ? 'rgb(60 92 241 / var(--tw-bg-opacity)' : '#FFFFFF', color: colorrankweek ? '#FFFFFF  !important' : '#000000' }"
            @click="rankweek">
            {{$t('week.week')}}
          </button>
          <button
            class="relative inline-flex flex-1 items-center justify-center whitespace-nowrap px-2 py-3 font-semibold focus-visible:outline-none text-primary-400 py-1 rounded-xl"
            id="headlessui-tabs-tab-:r187:" role="tab" aria-selected="false" tabIndex={-1} data-headlessui-state
            type="button"
            :style="{ backgroundColor: colorrankmoth ? 'rgb(60 92 241 / var(--tw-bg-opacity)' : '#FFFFFF', color: colorrankmoth ? '#FFFFFF  !important' : '#000000' }"
            @click="rankmoth">
            {{$t('month.month')}}
          </button>
        </div>
        <img alt="" src="img/bg-rank.png" class="absolute top-0 -z-10 object-cover" />
        <div class="mt-4 flex items-end justify-center">
          <div class="w-fit">
            <div class="relative flex flex-col items-center pb-3">
              <div class="inline-block w-16 h-16 bg-gray-200 box-border rounded-full p-1 z-10">
                <div v-if="avatarSrc2" class="relative h-0" style=" padding-bottom: 100%">
                  <div
                    class="absolute inset-0 h-full w-full flex items-center justify-center rounded-full bg-gray-200 text-3xl">
                    <!-- <img class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                      :src="avatarSrc2" alt="Customer Avatar" /> -->
                    <img class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                      :src="avatarSrc2" alt="Customer Avatar" />
                  </div>
                </div>
              </div>
              <div class="absolute bottom-0 z-10 h-7 w-7">
                <div class="bg-gray-200 h-7 w-7 rounded-full border-4 border-blue-900"></div>
                <div
                  class="rank-text top-1.5 absolute w-7 text-center text-nm font-bold leading-none text-blue-900 center-y">
                  2
                </div>
              </div>
            </div>
            <div class="relative -mt-16">
              <img alt="rank rectangle icon" class="opacity-20" width="120px" src="img/rank-rectangle-2nd.svg" />
              <div v-if="ranktop2" class="absolute w-full -translate-y-[67px] text-center">
                <p class="overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-white text-sm">
                  {{ 
                    (ranktop2.fullname && ranktop2.fullname.trim() !== '') ? 
                    ranktop2.fullname :
                    "V.T.Hà"
                  }}
                </p>
                  <p v-if="ranktop2.total_day || ranktop2.total_week|| ranktop2.total_month" class="text-white text-sm">
                    {{
                      (ranktop2.total_day) ? 
                      ranktop2.total_day.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                      (ranktop2.total_week) ? 
                      ranktop2.total_week.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                      (ranktop2.total_month) ? 
                      ranktop2.total_month.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                      ''
                    }}₫

                </p>

              </div>
            </div>
          </div>
          <div class="w-fit w-[102px]">
            <div class="relative flex flex-col items-center pb-3">
              <div class="relative z-20 -mb-4 w-[72px] flex-none">
                <picture>
                  <source srcSet="img/crown.png" type="image/webp" />
                  <img alt="" src="img/crown.png" height={72} width={72} />
                </picture>
              </div>
              <div class="inline-block w-[72px] h-[72px] bg-yellow-400 box-border rounded-full p-1 z-10">
                <div v-if="avatarSrc1" class="relative h-0" style="padding-bottom: 100%">
                  <div class="absolute inset-0 h-full w-full rounded-full animate-pulse bg-gray-800 bg-opacity-10" />
                  <!-- <img class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                    :src="avatarSrc1" alt="Customer Avatar" /> -->
                  <img class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                    :src="avatarSrc1" alt="Customer Avatar" />
                </div>
              </div>
              <div class="absolute bottom-0 z-10 h-7 w-7">
                <div class="bg-yellow-400 h-7 w-7 rounded-full border-4 border-blue-900" />
                <div
                  class="rank-text top-1.5 absolute w-7 text-center text-nm font-bold leading-none text-blue-900 center-y">
                  1
                </div>
              </div>
            </div>
            <div class="relative -mt-20">
              <img alt="rank rectangle icon" class=" opacity-30" width="160px" src="img/rank-rectangle.svg" />
              <div v-if="ranktop1" class="absolute w-full -translate-y-[81px] text-center">
                <p class="overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-white text-sm">
                  {{ 
                    (ranktop1.fullname && ranktop1.fullname.trim() !== '') ? 
                    ranktop1.fullname :
                    "V.T.Hà"
                  }}
                </p>
                <p v-if="ranktop1.total_day || ranktop1.total_week|| ranktop1.total_month" class="text-white text-sm">
                  {{
                    (ranktop1.total_day) ? 
                    ranktop1.total_day.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    (ranktop1.total_week) ? 
                    ranktop1.total_week.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    (ranktop1.total_month) ? 
                    ranktop1.total_month.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    ''
                  }}₫
                </p>
              </div>
            </div>
          </div>
          <div class="w-fit w-[102px]">
            <div class="relative flex flex-col items-center pb-3">
              <div class="inline-block w-14 h-14 bg-red-500 box-border rounded-full p-1 z-10">
                <div class="relative h-0" style=" padding-bottom: 100%">
                  <div
                    class="absolute inset-0 h-full w-full flex items-center justify-center rounded-full bg-gray-200 text-xl">
                    <!-- Lỗi ảnh server -->
                    <!-- <img v-if="avatarSrc3"
                      class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                      :src="avatarSrc3" alt="Customer Avatar" /> -->
                    <!-- Remove khi ảnh hết lỗi -->
                    <img v-if="avatarSrc3" class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                      :src="avatarSrc3" alt="Customer Avatar" />
                    <img v-else class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                      src="img/customers.png" alt="Customer Avatar" />
                  </div>
                </div>
              </div>
              <div class="absolute bottom-0 z-10 h-7 w-7">
                <div class="bg-red-500 h-7 w-7 rounded-full border-4 border-blue-900" />
                <div
                  class="rank-text top-1.5 absolute w-7 text-center text-nm font-bold leading-none text-blue-900 center-y">
                  3
                </div>
              </div>
            </div>
            <div class="relative -mt-14">
              <img alt="rank rectangle icon" class="opacity-10" width="120px" src="img/rank-rectangle-3rd.svg" />
              <div v-if="ranktop3" class="absolute w-full -translate-y-[60px] text-center">
                <p class="overflow-hidden text-ellipsis whitespace-nowrap font-semibold text-white text-sm">
                  {{ ranktop3.fullname || 'L.Đ.Tuấn' }}
                </p>
                <p v-if="ranktop3.total_day || ranktop3.total_week|| ranktop3.total_month" class="text-white text-sm">
                  {{
                    (ranktop3.total_day) ? 
                    ranktop3.total_day.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    (ranktop3.total_week) ? 
                    ranktop3.total_week.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    (ranktop3.total_month) ? 
                    ranktop3.total_month.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    ''
                  }}₫

                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="drop-shadow-3xl z-10 m-3 rounded-2xl" style=" box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 30px">
          <div class="bg-white shadow-100 p-4 rounded-2xl">
            <div class="bg-white p-4 rounded-2xl mb-3 bg-gray-100 p-3 text-nm">
              <p class="text-nm text-gray-1100">
                {{$t('text_rank_dropship.text_rank_dropship')}}
              </p>
            </div>
            <div v-for="(rank, index) in this.ranklist" :key="index"
              class="bg-white p-4 rounded-2xl flex items-center gap-4">
          
              <div  v-if="rank.total_day || rank.total_week ||rank.total_month" class="relative mr-1 h-8 w-8">
                <picture>
                  <source srcSet="/star.png" type="image/webp" />
                  <img alt="" src="star.png" class="absolute top-0" />
                </picture>
                <p class="absolute flex h-full w-full items-center justify-center text-blue-700 font-semibold p-2">
                  {{ index + 4 }} <!-- Đổi thành rank.star hoặc thuộc tính tương ứng trong ranklist -->
                </p>
              </div>
              <div  v-if="rank.total_day || rank.total_week ||rank.total_month" class="inline-block w-12 h-12 mr-2 flex-shrink-0">
                <div class="relative h-0" style="padding-bottom: 100%">
                  <div class="absolute inset-0 h-full w-full rounded-full animate-pulse bg-gray-800 bg-opacity-10" />
                  <!-- Lỗi ảnh server -->
                  <!-- <img class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                    :src="rank.customer_avatar" alt="Customer Avatar" /> -->
                  <!-- Remove khi ảnh server hết lỗi -->
                  <img class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-full"
                    :src='rank.customer_avatar' alt="Customer Avatar" />
                </div>
              </div>
              <div  v-if="rank.total_day || rank.total_week ||rank.total_month" class="flex flex-col space-y-1">
                <div class="flex items-start">
                  <p class="text-normal font-medium text-gray-1100">{{ rank.fullname || `Customer name ${index + 4}` }}</p>
                  <!-- Lấy customer_name từ ranklist -->
                </div>
                <p v-if="rank.total_day || rank.total_week ||rank.total_month" class="text-normal font-medium text-gray-600">
                  {{
                    (rank.total_day) ? 
                    rank.total_day.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    (rank.total_week) ? 
                    rank.total_week.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    (rank.total_month) ? 
                    rank.total_month.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") :
                    ''
                  }}₫
              </p>
                <!-- Lấy total_day từ ranklist -->
              </div>
              </div>
            
           
          </div>
        </div>
      </div> <!-- Nội dung của trang RankPage -->
    </div>
  </div>
</template>

<script>
// import Header from "../../shared/Header.vue";
import { getRank } from '@/services/affiliate/affiliate';
// import { forEach } from 'core-js/core/array';
// https://salekit.net/api/v1/affiliate/product
export default {
  name: "RankPage",
  components: {
    // Header
  },
  data() {
    return {
      isLoading: false,
      colorrankday: true,
      colorrankweek: false,
      colorrankmoth: false,
      ranklist: [],
      rankTop: [],
      ranktop1: '',
      ranktop2: '',
      ranktop3: '',
      avatarSrc1: '',
      avatarSrc2: '',
      avatarSrc3: '',

      // Khai báo các biến dữ liệu cần thiết
    };
  },
  methods: {
    rankday() {
      this.colorrankday = true;
      this.colorrankweek = false;
      this.colorrankmoth = false;
      this.getrank('day');
      this.ranktop1= '';
      this.ranktop2= '';
      this.ranktop3= '';
      this.avatarSrc1= '';
      this.avatarSrc2= '';
      this.avatarSrc3= '';
      this.ranklist= '';
      this.rankTop= '';
     
     
      //Xử lý logic rank list của tuần, sắp xếp rank list giảm dần theo total day
    },
    rankweek() {
      this.colorrankweek = true;
      this.colorrankday = false;
      this.colorrankmoth = false;
      //Xử lý logic rank list của tuần, sắp xếp rank list giảm dần theo total week
      this.getrank('week');
      this.ranktop1= '';
      this.ranktop2= '';
      this.ranktop3= '';
      this.avatarSrc1= '';
      this.avatarSrc2= '';
      this.avatarSrc3= '';
      this.ranklist= '';
      this.rankTop= '';
     
    },
    rankmoth() {
      this.colorrankday = false;
      this.colorrankweek = false;
      this.colorrankmoth = true;
      //Xử lý logic rank list của tháng, sắp xếp rank list giảm dần theo total month
      this.getrank('month')
      this.ranktop1= '';
      this.ranktop2= '';
      this.ranktop3= '';
      this.avatarSrc1= '';
      this.avatarSrc2= '';
      this.avatarSrc3= '';
      this.ranklist= '';
      this.rankTop= '';
     
    },
    async getrank(type = 'day') {
      try {
        //Xử lý logic loading
        this.isLoading = true;
        const response = await getRank(type)
        let res = response.data.data
        res.forEach(item => {
          if(item.photo && item.photo.length >0 ){
            item.customer_avatar = item.photo;
          }else{
            item.customer_avatar = 'https://salekit.net/avatar/'+ item.customer_id +'.png';
          }
         
        });
        console.log(res);
        const [v1, v2, v3, ...restList] = res;
        
        //this.ranklist = list
        // list.filter(rank => !!rank);

        // this.ranklist = list.filter(item => {
        //   return item.total_day !== null && item.customer_avatar !== null && item.customer_name !== null;
        // });
        // Loại bỏ các phần tử có thuộc tính null
        //Khi mouted cần sắp xếp rank list giảm dần theo total day mặc định
        this.avatarSrc1 = v1.customer_avatar;
        this.avatarSrc2 = v2.customer_avatar;
        this.avatarSrc3 = v3.customer_avatar;
        this.ranktop1 = v1;
        this.ranktop2 = v2;
        this.ranktop3 = v3;
        this.ranklist = restList;
      } catch (error) {
        console.error('Error fetching products:', error.message);
      }
    },

  },
  created() {
    document.title =this.$t(`rank.rank`);
    this.getrank();
    // Gọi các API hoặc xử lý logic khởi tạo khi trang được tạo
  },
  mounted() {
    // Thực hiện các tác vụ sau khi component đã được render
    // console.log('Component mounted');
    // Ví dụ: Gửi request HTTP hoặc tương tác với DOM ở đây
  },
  computed: {
  }
};
</script>

<style scoped>
/* CSS cho trang RankPage */
</style>
