<template>
    <div class="relative h-screen overflow-auto overflow-x-hidden px-8">
        <div class="relative w-auto bg-white">
            <div class="flex h-max justify-between py-4 align-middle">
                <button @click="backHome"
                    class="flex h-max align-middle relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                    type="button" aria-label="Quay lại"><svg class="h-6 w-6" height="1em" width="1em"
                        viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
                            fill="currentColor"></path>
                    </svg>
                </button>
                <button
                    class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none border h-8 text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 text-md uppercase"
                    type="button"><span class="">Hỗ Trợ</span><span class="text-md ml-2"><svg width="20" height="20"
                            viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M15.3333 5.4165C13.9167 3.83317 12 2.9165 10 2.9165C8 2.9165 6.08333 3.83317 4.66667 5.4165C3.25 6.99984 2.5 9.08317 2.5 11.2498V14.5832C2.5 15.9998 3.58333 17.0832 5 17.0832C6.41667 17.0832 7.5 15.9998 7.5 14.5832V12.0832C7.5 10.6665 6.41667 9.58317 5 9.58317C4.75 9.58317 4.58333 9.58317 4.33333 9.6665C4.58333 8.49984 5.16667 7.33317 5.91667 6.49984C7 5.24984 8.5 4.58317 10 4.58317C11.5 4.58317 13 5.24984 14.0833 6.49984C14.8333 7.33317 15.4167 8.49984 15.6667 9.6665C15.4167 9.58317 15.25 9.58317 15 9.58317C13.5833 9.58317 12.5 10.6665 12.5 12.0832V14.5832C12.5 15.9998 13.5833 17.0832 15 17.0832C16.4167 17.0832 17.5 15.9998 17.5 14.5832V11.2498C17.5 9.08317 16.75 6.99984 15.3333 5.4165ZM5 11.2498C5.5 11.2498 5.83333 11.5832 5.83333 12.0832V14.5832C5.83333 15.0832 5.5 15.4165 5 15.4165C4.5 15.4165 4.16667 15.0832 4.16667 14.5832V12.0832C4.16667 11.5832 4.5 11.2498 5 11.2498ZM15.8333 14.5832C15.8333 15.0832 15.5 15.4165 15 15.4165C14.5 15.4165 14.1667 15.0832 14.1667 14.5832V12.0832C14.1667 11.5832 14.5 11.2498 15 11.2498C15.5 11.2498 15.8333 11.5832 15.8333 12.0832V14.5832Z"
                                fill="currentColor"></path>
                        </svg>
                    </span>
                </button>
            </div>
            <div class="relative flex items-center justify-center">
                <div class="absolute inset-0 flex items-center">
                    <div class="w-full border-t border-gray-50"></div>
                </div>
            </div>
        </div>
        <div class="pointer-events-none absolute -right-4 -top-8 h-36 w-32 bg-cover"
            style="background-image: url(&quot;/img/auth-backdrop.png&quot;);"></div>
        <div class="space-y-2 py-6">
            <p class="font-semibold uppercase text-gray-400 text-nm">Đăng nhập</p>
            <p class="font-semibold text-gray-900 text-xl">Bằng số điện thoại</p>
            <p class="text-gray-600 text-nm">Sử dụng số điện thoại đã được xác minh lúc bạn đăng ký tài khoản</p>
        </div>
        <form @submit.prevent="handleSubmit" class="w-full">
            <div class="m-auto mb-4 max-w-lg space-y-4">
                <div class="w-full">
                    <div class="relative inline-flex w-full mb-2">
                        <div
                            class="absolute bottom-3 left-0 top-3 flex w-20 items-center justify-center border-r border-gray-300">
                            <span class="text-gray-900 text-medium font-normal">+84</span>
                        </div>
                        <input
                            class="block w-full rounded-lg py-2 text-nm pl-20 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-300 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium focus:border-blue-700 flex-1"
                            type="tel" name="phone" placeholder="Nhập số điện thoại"
                            v-model="phone">

                    </div>
                    <span class="p-2 text-red-400 " v-if="errors">{{ errors }}</span>
                </div>
                <button
                    class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300"
                    type="submit">
                    Gửi xác thực OTP
                </button>
            </div>
        </form>
        <div class="flex flex-col items-center justify-center gap-3 pb-16">
            <p class="lowercase text-gray-600 text-sm">Hoặc</p>
            <button @click="backHome" class="w-fit text-nm font-medium text-primary-400">
                Đăng nhập bằng mật khẩu
            </button>
        </div>
        <div className="absolute bottom-0 left-0 right-0 bg-gray-100 px-8 pb-8 pt-4 text-center z-10">
            <p className="mb-4 text-gray-600 text-sm">
                Trở thành đối tác của chúng tôi?
            </p>
            <button @click="this.$router.push('/registry')"
                className="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none border h-10 w-full flex text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 max-w-lg !text-nm capitalize"
                type="button"
                style="    --tw-border-opacity: 1;border-color: rgb(27 63 228 / var(--tw-border-opacity));">
                Đăng ký ngay
            </button>
        </div>
    </div>
</template>

<script>
import { getResendCode } from '@/services/authentication/authService';
export default {
    name: "LoginPhonePage",
    data() {
        return {
            phone: '',
            errors: ''
        }
    },
    methods: {
        backHome() {
            this.$router.replace('/')
        },
        handleSubmit() {
            const regexPhoneNumber = /(84|0[3|5|7|8|9])+([0-9]{8})\b/g;
            this.phone?.match(regexPhoneNumber) ?
            getResendCode(this.phone) :
            this.errors = 'Số điện thoại không đúng định dạng'
          
        }
    },
}
</script>