<template>
  <div>
    <div class="mb-6 space-y-2">
      <div class="flex flex-col space-y-1">
        <b class="uppercase text-gray-400 text-sm">Đăng ký</b>
        <b class="text-gray-900 text-lg">Nhập mã xác thực</b>
      </div>
      <p class="mb-4 text-gray-600 text-sm">
        Mã xác thực đã được gửi đến Số điện thoại của bạn.
        <!-- <span class="font-semibold text-primary-400"></span> -->
        Vui lòng kiểm tra tin nhắn và nhập mã xác thực
      </p>
    </div>
    <div class="mb-6 space-y-20">
      <div class="relative space-y-2">
        <input
          autocomplete="one-time-code"
          class="absolute opacity-0"
          inputmode="numeric"
          type="text"
          defaultValue=""
        />
        <div class="inline-flex w-full justify-center space-x-2">
          <div class="inline-flex w-full justify-center space-x-2">
    <input ref="inputs[0]" class="relative h-14 w-[46px] outline-none block text-3xl font-semibold text-center rounded-xl border border-gray-400 bg-white" type="text" v-model="inputs[0]" @input="handleInputChange(0)" maxlength="1" />
    <input ref="inputs[1]" class="relative h-14 w-[46px] outline-none block text-3xl font-semibold text-center rounded-xl border border-gray-400 bg-white" type="text" v-model="inputs[1]" @input="handleInputChange(1)" maxlength="1" />
    <input ref="inputs[2]" class="relative h-14 w-[46px] outline-none block text-3xl font-semibold text-center rounded-xl border border-gray-400 bg-white" type="text" v-model="inputs[2]" @input="handleInputChange(2)" maxlength="1" />
    <input ref="inputs[3]" class="relative h-14 w-[46px] outline-none block text-3xl font-semibold text-center rounded-xl border border-gray-400 bg-white" type="text" v-model="inputs[3]" @input="handleInputChange(3)" maxlength="1" />
    <input ref="inputs[4]" class="relative h-14 w-[46px] outline-none block text-3xl font-semibold text-center rounded-xl border border-gray-400 bg-white" type="text" v-model="inputs[4]" @input="handleInputChange(4)" maxlength="1" />
    <input ref="inputs[5]" class="relative h-14 w-[46px] outline-none block text-3xl font-semibold text-center rounded-xl border border-gray-400 bg-white" type="text" v-model="inputs[5]" @input="handleInputChange(5)" maxlength="1" />
  </div>
          <!-- <button class="relative h-14 w-[46px] outline-none" type="button" fdprocessedid="qfx97l">
            <span class="absolute left-0 top-0 flex min-h-full w-full items-center justify-center rounded-xl border border-gray-400 bg-white text-3xl font-semibold"> 
            </span>
          </button>
          <button class="relative h-14 w-[46px] outline-none" type="button" fdprocessedid="3cxs2e">
              <span class="absolute left-0 top-0 flex min-h-full w-full items-center justify-center rounded-xl border border-gray-400 bg-white text-3xl font-semibold"> 
              </span>
            </button>
            <button class="relative h-14 w-[46px] outline-none" type="button" fdprocessedid="woxfa">
              <span class="absolute left-0 top-0 flex min-h-full w-full items-center justify-center rounded-xl border border-gray-400 bg-white text-3xl font-semibold"> 
              </span>
            </button>
            <button class="relative h-14 w-[46px] outline-none" type="button" fdprocessedid="cxow3e">
              <span class="absolute left-0 top-0 flex min-h-full w-full items-center justify-center rounded-xl border border-gray-400 bg-white text-3xl font-semibold"> 
              </span>
            </button>
            <button class="relative h-14 w-[46px] outline-none" type="button" fdprocessedid="1145cq">
              <span class="absolute left-0 top-0 flex min-h-full w-full items-center justify-center rounded-xl border border-gray-400 bg-white text-3xl font-semibold">
                 </span>
                </button>
                <button class="relative h-14 w-[46px] outline-none" type="button" fdprocessedid="3rxa4i">
                  <span class="absolute left-0 top-0 flex min-h-full w-full items-center justify-center rounded-xl border border-gray-400 bg-white text-3xl font-semibold"> 
                  </span>
                </button> -->
        </div>
      </div>
      <button
        class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap focus:outline-none h-12 text-md w-full flex bg-primary-400 text-white hover:bg-primary-300 !mt-4 !text-md"
        type="button"
        @click="handleSubmit"
      >
        Xác nhận
      </button>
    </div>
    <p class="mb-2 text-center text-gray-900 text-nm">Không nhận được mã?</p>
    <div class="flex flex-col justify-center gap-2 text-center align-middle">
      <button
        class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap leading-none focus:outline-none bg-transparent w-fit h-fit opacity-30 text-primary-400 hover:text-primary-300"
        type="button"
        disabled
      >
        <a class="w-fit text-nm font-semibold !text-primary-400">Gửi lại mã (chờ 59 giây)</a>
      </button>
      <svg
        class="h-auto text-gray-200"
        fill="none"
        height="4"
        viewBox="0 0 3 4"
        width="3"
      >
        <circle cx="1.5" cy="2" fill="currentColor" r="1.5" />
      </svg>
      <a class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap leading-none focus:outline-none bg-transparent w-fit h-fit text-primary-400 hover:text-primary-300"></a>
      <a class="w-fit text-nm font-semibold !text-primary-400">Đổi số điện thoại</a>
    </div>
  </div>
</template>

<script>
// import { useNavigate } from "vue-router";
import axios from 'axios';

const BASE_URL = 'https://salekit.net/api/v1/affiliate';
export default {
  name: "Registry_step4",
  data() {
    return{
      formData: '',
      inputs: ['', '', '', '', '', ''],
    }
  },
  methods: {
    handleInputChange(inputIndex) {
      const currentInput = this.inputs[inputIndex];
      if (currentInput.length === 1) {
        // Di chuyển focus đến ô nhập tiếp theo nếu độ dài của ô hiện tại là 1
        const nextInputIndex = inputIndex + 1;
        if (nextInputIndex < this.inputs.length) {
          this.$refs['inputs[' + nextInputIndex + ']'].focus();
        }
      }
    },
    async handleSubmit() {
      const code = this.inputs[0]+this.inputs[1]+this.inputs[2]+this.inputs[3]+this.inputs[4]+this.inputs[5];
      const body = {
          "code":code
      }
      const username = localStorage.getItem("Username");
      const Cookie = 'ci_session=92c7mu67klpriile7pt4sfpolj4kvl74; ci_session=iq82he3a738nae4c6952p894fr2ed257; ci_session=iq82he3a738nae4c6952p894fr2ed257; ci_session=vsj0h9qjas8eajlt3l5rg8h9gccbvrpi';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          
          'Username': username,
          'Cookie': Cookie
          // 'withCredentials': true,
          
          }
      };
      //console.log("body",body,config);
      const response = await axios.post(`${BASE_URL}/dropship/verify-code`,body, config);
     if(response.data.error != true){
        this.$router.replace("/platformfee");
     }else{
      console.log(response.data.error);
      //this.$router.replace("/platform-fee");
     }
      // this.$router.replace("/platform-fee");
      //navigate(`/platform-fee`);
    },
  },
  created() {
   // console.log('Data from Step3:', this.formData);
  }
};
</script>

<!-- Add scoped styles here -->
