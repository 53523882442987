<template>
  <div>
    <div class="relative flex flex-col justify-center pr-14 h-[64px] pl-4"
      :style="{ backgroundColor: datashop.background_color_1 }">

      <div class="">
        <h5
          class="!font-bold leading-snug font-semibold text-primary-400 text-lg truncate items-center  justify-center">
          <img class="!font-bold  justify-center justify-content-center items-center  " :src="shop" alt=""
            style="width:200px;  padding:5px">
        </h5>
      </div>

      <div class="absolute right-3 center-y">
        <div class="flex items-center space-x-1">
          <router-link to="/cart">
            <button
              class="relative rounded-md text-2xl h-10 w-10 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
              type="button" aria-label="2 sản phẩm trong giỏ hàng">
              <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M13 8C12.4477 8 12 8.44772 12 9C12 9.55228 12.4477 10 13 10H17C17.5523 10 18 9.55228 18 9C18 8.44772 17.5523 8 17 8H13Z"
                  fill="white"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M3 2C2.44772 2 2 2.44772 2 3C2 3.55228 2.44772 4 3 4H4.60222C5.03304 4 5.33 4.35693 5.30033 4.7127L5.3002 4.71426L4.53628 13.1832L4.53608 13.1855C4.35337 15.2646 6.0324 17 8.10131 17H17.908C19.7417 17 21.3491 15.5507 21.4924 13.7295L22.9896 7.4712L22.99 7.46576C23.1454 5.36495 21.4969 3.68299 19.4052 3.68299H7.11421C6.71693 2.69769 5.73627 2 4.60222 2H3ZM6.5284 13.3605L7.22093 5.68299H19.4052C20.3883 5.68299 21.0599 6.42978 20.9956 7.31598L20.9954 7.31824L19.4986 13.5726C19.4393 14.3254 18.7262 15 17.908 15H8.10131C7.1503 15 6.45388 14.215 6.5284 13.3605Z"
                  fill="white"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18ZM15.3333 20C15.3333 19.6318 15.6318 19.3333 16 19.3333C16.3682 19.3333 16.6667 19.6318 16.6667 20C16.6667 20.3682 16.3682 20.6667 16 20.6667C15.6318 20.6667 15.3333 20.3682 15.3333 20Z"
                  fill="white"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8 20C8 18.8954 8.89543 18 10 18C11.1046 18 12 18.8954 12 20C12 21.1046 11.1046 22 10 22C8.89543 22 8 21.1046 8 20ZM10 19.3333C9.63181 19.3333 9.33333 19.6318 9.33333 20C9.33333 20.3682 9.63181 20.6667 10 20.6667C10.3682 20.6667 10.6667 20.3682 10.6667 20C10.6667 19.6318 10.3682 19.3333 10 19.3333Z"
                  fill="white"></path>
              </svg>

              <span :class="{
                'absolute -right-1 -top-px flex h-4 min-w-[16px] items-center justify-center rounded-full px-1 text-[10px] text-white': cartItemCount <= 0,
                'bg-danger-300 absolute -right-1 -top-px flex h-4 min-w-[16px] items-center justify-center rounded-full px-1 text-[10px] text-white': cartItemCount > 0
              }" aria-hidden="true">{{ cartItemCount }}</span>

            </button>

          </router-link>
        </div>
      </div>
    </div>
    <div class="gap-2 border border-t pl-2 flex !flex-nowrap overflow-x-auto no-scrollbar  divide-x divide-gray-10">
      <div className=" flex !flex-nowrap overflow-auto no-scrollbar pt-3 divide-x divide-gray-10  gap-2 sliding-image-container">

        <router-link
          class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800"
          :class="{ 'border-primary-400 bg-blue-25 text-primary-400 font-bold': $route.path === '/home', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/home' }"
          :style="{ borderColor: boderrankhome ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
          to="/home">
          <div className="flex px-4 w-auto   w-full">
            <div className="flex !flex-nowrap  w-full  ">
              <svg width="20" height="20" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M9.68808 1.54188C9.88787 1.75315 9.92769 2.06953 9.78648 2.3237C9.7769 2.34094 9.76736 2.35807 9.75787 2.3751C9.56392 2.72328 9.39227 3.03142 9.39808 3.41698C9.4035 3.77691 9.66268 4.4316 10.5225 5.15708C11.6863 6.13901 12.2616 7.34954 12.546 8.29804C12.6885 8.77336 12.7601 9.18928 12.7962 9.48937C12.8142 9.63972 12.8235 9.76193 12.8283 9.84911C12.8307 9.89272 12.8319 9.92766 12.8326 9.95306C12.8329 9.96576 12.8331 9.97609 12.8332 9.98394L12.8333 9.99384L12.8333 9.99737L12.8333 9.99878C12.8333 9.99878 12.8333 9.99994 12.1667 9.99994H12.8333C12.8333 12.5773 10.744 14.6666 8.16667 14.6666C5.58934 14.6666 3.5 12.5773 3.5 9.99994C3.5 8.71379 3.68055 7.59138 3.93688 6.75812C4.06428 6.34396 4.21691 5.97993 4.38953 5.69755C4.47572 5.55655 4.57713 5.41898 4.69662 5.30387C4.81138 5.19333 4.98098 5.06672 5.20551 5.0162C5.38874 4.97498 5.58086 5.01295 5.73463 5.12078C5.8884 5.22862 5.98955 5.39631 6.01321 5.58263C6.03458 5.75097 6.10452 5.99749 6.21584 6.25777C6.21028 5.81561 6.24296 5.34618 6.33334 4.87448C6.58514 3.56037 7.2994 2.18197 8.91297 1.40001C9.17463 1.27321 9.4883 1.33062 9.68808 1.54188ZM11.5 10.0017C11.499 11.8418 10.007 13.3333 8.16667 13.3333C6.32572 13.3333 4.83333 11.8409 4.83333 9.99994C4.83333 8.86093 4.99002 7.89329 5.19483 7.20454C5.24734 7.30077 5.30317 7.39513 5.36201 7.48633C5.69704 8.0057 6.29052 8.66661 7.12963 8.66661C7.33969 8.66661 7.53745 8.56761 7.66333 8.39945C7.78921 8.2313 7.82847 8.01365 7.76929 7.8121C7.59625 7.22284 7.44354 6.16557 7.64285 5.1254C7.72788 4.68163 7.87419 4.25005 8.10621 3.85865C8.24121 4.60111 8.71414 5.3758 9.66268 6.17614C10.573 6.9442 11.0347 7.90034 11.2688 8.68101C11.3856 9.07027 11.4436 9.40956 11.4724 9.64854C11.4867 9.76771 11.4936 9.861 11.4969 9.92193C11.4986 9.95237 11.4994 9.97465 11.4997 9.98794L11.5 10.001L11.5 10.0017Z"
                  fill="currentColor"></path>
              </svg>
              <span :style="{ width: width1, fontSize: 'medium', paddingLeft: '2px', paddingTop: '1px' }">{{ $t('single_storm.single_storm') }}
              </span>
            </div>
          </div>
        </router-link>
        <!-- <router-link  class="flex !flex-nowrap  h-8 items-center justify-center  rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800"
            :class="{ 'border-primary-400 bg-blue-25 text-primary-400': $route.path === '/rank', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/rank' }"
            :style="{ borderColor: boderrank ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
            @click="acctionrank"  to="/rank">
            <div className="flex px-4 w-auto">
              <div className="flex !flex-nowrap w-[100px]  ">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M9.68808 1.54188C9.88787 1.75315 9.92769 2.06953 9.78648 2.3237C9.7769 2.34094 9.76736 2.35807 9.75787 2.3751C9.56392 2.72328 9.39227 3.03142 9.39808 3.41698C9.4035 3.77691 9.66268 4.4316 10.5225 5.15708C11.6863 6.13901 12.2616 7.34954 12.546 8.29804C12.6885 8.77336 12.7601 9.18928 12.7962 9.48937C12.8142 9.63972 12.8235 9.76193 12.8283 9.84911C12.8307 9.89272 12.8319 9.92766 12.8326 9.95306C12.8329 9.96576 12.8331 9.97609 12.8332 9.98394L12.8333 9.99384L12.8333 9.99737L12.8333 9.99878C12.8333 9.99878 12.8333 9.99994 12.1667 9.99994H12.8333C12.8333 12.5773 10.744 14.6666 8.16667 14.6666C5.58934 14.6666 3.5 12.5773 3.5 9.99994C3.5 8.71379 3.68055 7.59138 3.93688 6.75812C4.06428 6.34396 4.21691 5.97993 4.38953 5.69755C4.47572 5.55655 4.57713 5.41898 4.69662 5.30387C4.81138 5.19333 4.98098 5.06672 5.20551 5.0162C5.38874 4.97498 5.58086 5.01295 5.73463 5.12078C5.8884 5.22862 5.98955 5.39631 6.01321 5.58263C6.03458 5.75097 6.10452 5.99749 6.21584 6.25777C6.21028 5.81561 6.24296 5.34618 6.33334 4.87448C6.58514 3.56037 7.2994 2.18197 8.91297 1.40001C9.17463 1.27321 9.4883 1.33062 9.68808 1.54188ZM11.5 10.0017C11.499 11.8418 10.007 13.3333 8.16667 13.3333C6.32572 13.3333 4.83333 11.8409 4.83333 9.99994C4.83333 8.86093 4.99002 7.89329 5.19483 7.20454C5.24734 7.30077 5.30317 7.39513 5.36201 7.48633C5.69704 8.0057 6.29052 8.66661 7.12963 8.66661C7.33969 8.66661 7.53745 8.56761 7.66333 8.39945C7.78921 8.2313 7.82847 8.01365 7.76929 7.8121C7.59625 7.22284 7.44354 6.16557 7.64285 5.1254C7.72788 4.68163 7.87419 4.25005 8.10621 3.85865C8.24121 4.60111 8.71414 5.3758 9.66268 6.17614C10.573 6.9442 11.0347 7.90034 11.2688 8.68101C11.3856 9.07027 11.4436 9.40956 11.4724 9.64854C11.4867 9.76771 11.4936 9.861 11.4969 9.92193C11.4986 9.95237 11.4994 9.97465 11.4997 9.98794L11.5 10.001L11.5 10.0017Z"
                    fill="currentColor"></path>
                </svg>
                <span class=""> <span>{{$t('see_rankings.see_rankings')}} </span></span>
              </div>
            </div>
          </router-link> -->
        <router-link
          class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800"
          :class="{ 'border-primary-400 bg-blue-25 text-primary-400 font-bold': $route.path === '/rank', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/rank' }"
          :style="{ borderColor: boderrank ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
          to="/rank">
          <div className="flex items-center justify-center w-auto w-[100px] px-2">
            <div className="flex !flex-nowrap items-center justify-center w-[100px]  ">
              <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M10.6668 1.33325C9.93045 1.33325 9.3335 1.93021 9.3335 2.66659V4.66658H6.66683C5.93045 4.66658 5.3335 5.26354 5.3335 5.99992V7.99992H2.66683C1.93045 7.99992 1.3335 8.59687 1.3335 9.33325V13.3333C1.3335 14.0696 1.93045 14.6666 2.66683 14.6666H6.00016H10.0002H13.3335C14.0699 14.6666 14.6668 14.0696 14.6668 13.3333V2.66659C14.6668 1.93021 14.0699 1.33325 13.3335 1.33325H10.6668ZM9.3335 13.3333V5.99992H6.66683V9.33325V13.3333H9.3335ZM10.6668 13.3333H13.3335V2.66659H10.6668V5.99992V13.3333ZM5.3335 9.33325V13.3333H2.66683V9.33325H5.3335Z"
                  fill="currentColor"></path>
              </svg>
              <span style="font-size:medium;padding-left: 2px;">{{ $t('see_rankings.see_rankings') }} </span>
            </div>
          </div>
        </router-link>
        <router-link
          class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800 px-2"
          :class="{ 'border-primary-400 bg-blue-25 text-primary-400 font-bold': $route.path === '/share', 'border-primary-400 !important bg-blue-25 text-primary-400 font-bold': $route.path === '/share' }"
          :style="{ borderColor: bodershare ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
          to="/share">
          <div className="flex items-center justify-center w-auto">
            <div className="flex !flex-nowrap items-center justify-center w-[100px]  ">
              <div
                  class="x9f619 x1n2onr6 x1ja2u2z x78zum5 xdt5ytf x2lah0s x193iq5w xeuugli x150jy0e x1e558r4 x10b6aqq x1yrsyyn"
                  :class="{ 'border-primary-400 bg-blue-25 text-primary-400 font-bold': $route.path === '/share' }"
                >
                  <!-- <i
                    data-visualcompletion="css-img"
                    class="x1b0d499 x1d69dk1"
                    style="background-image: url(&quot;https://static.xx.fbcdn.net/rsrc.php/v3/yc/r/U46SvbOsaBN.png?_nc_eui2=AeE_bLfE2eArrunz1_YDqUGAj0fFUM5csmePR8VQzlyyZ0EzwtIfl4SZs7IWDRC1zXf6raJ8MIFkulIPgZiNdy-z&quot;); background-position: 0px -907px; background-size: auto; width: 19px; height: 19px; background-repeat: no-repeat; display: inline-block;"
                  ></i> -->
                  <i class="fa fa-share-alt" style="font-size: 16px;"></i>
                </div>

              <span style="font-size:medium;padding-left: 3px;">{{ $t('aff_share.aff_share') }} </span>
            </div>
          </div>
        </router-link>
        <router-link
          class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800 px-2"
          :class="{ 'border-primary-400 bg-blue-25 text-primary-400 font-bold': $route.path === '/member', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/member' }"
          :style="{ borderColor: bodermember ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
          to="/member">
          <div className="flex items-center justify-center w-[120px]">
            <div className="flex !flex-nowrap items-center justify-center w-[120px]  ">
              <svg class="flex h-6 w-6 items-center justify-center" width="24" height="24" viewBox="0 0 24 24"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.0312 2.5C9.12688 2.5 6.77138 4.84177 6.77138 7.73164C6.77138 10.5852 9.22604 12.9685 12.0651 12.9633C14.9598 12.9525 17.2999 10.6102 17.29 7.72974C17.289 4.84089 14.934 2.5 12.0312 2.5ZM8.72452 7.73164C8.72452 5.91546 10.205 4.44304 12.0312 4.44304C13.8311 4.44304 15.3331 5.93462 15.3369 7.73526C15.3436 9.54195 13.8763 11.0126 12.0596 11.0202C10.2547 11.0202 8.72452 9.55386 8.72452 7.73164Z"
                  fill="currentColor"></path>
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M8.01704 14.7202C7.32397 14.9546 6.66829 15.2949 6.1774 15.8067C5.66399 16.3419 5.37294 17.0229 5.37294 17.8199C5.37294 18.6191 5.66699 19.3001 6.18393 19.8332C6.67758 20.3423 7.33583 20.6787 8.02931 20.9094C10.5731 21.7557 13.5038 21.7553 16.0444 20.898C16.7374 20.6642 17.3932 20.3244 17.8844 19.8131C18.3981 19.2783 18.6894 18.5975 18.6894 17.8004C18.6894 17.0013 18.3952 16.3203 17.8785 15.7871C17.385 15.2779 16.727 14.9409 16.0334 14.7095C13.4889 13.8609 10.5585 13.8608 8.01704 14.7202ZM7.32608 17.8199C7.32608 17.101 8.0562 16.7592 8.64562 16.5599C10.7881 15.8354 13.2676 15.8364 15.4125 16.5518C15.9987 16.7473 16.7363 17.0837 16.7363 17.8004C16.7363 18.5186 16.0056 18.8593 15.417 19.058C13.2747 19.7808 10.7937 19.7803 8.64873 19.0667C8.06257 18.8717 7.32608 18.5364 7.32608 17.8199Z"
                  fill="currentColor"></path>
                <path
                  d="M17.5462 4.39634C17.6335 3.86686 18.1357 3.50803 18.668 3.59487C20.6626 3.92031 22.1837 5.64093 22.1837 7.71685C22.1821 9.7761 20.6081 11.5634 18.5726 11.848C18.0385 11.9227 17.5447 11.5525 17.4696 11.0212C17.3945 10.4898 17.7667 9.99856 18.3007 9.92388C19.3893 9.77169 20.2281 8.84075 20.2306 7.71599C20.2302 6.60765 19.4175 5.68617 18.3518 5.51229C17.8196 5.42545 17.4589 4.92582 17.5462 4.39634Z"
                  fill="currentColor"></path>
                <path
                  d="M5.3934 3.59487C5.92563 3.50803 6.42785 3.86686 6.51514 4.39634C6.60243 4.92582 6.24174 5.42545 5.7095 5.51229C4.64382 5.68617 3.83116 6.60765 3.83074 7.71599C3.83322 8.84075 4.67201 9.77169 5.7606 9.92388C6.2947 9.99856 6.66682 10.4898 6.59176 11.0212C6.5167 11.5525 6.02288 11.9227 5.48878 11.848C3.45324 11.5634 1.87929 9.7761 1.8776 7.71685C1.8776 5.64093 3.39879 3.92031 5.3934 3.59487Z"
                  fill="currentColor"></path>
                <path
                  d="M18.4852 13.8285C18.5646 13.2978 19.0615 12.9317 19.595 13.0107C20.4052 13.1308 21.2127 13.3459 21.841 13.756C22.5183 14.1982 22.9999 14.8844 22.9999 15.8127C22.9999 16.4243 22.7883 16.962 22.4051 17.3928C22.0401 17.8032 21.5585 18.0742 21.0674 18.2598C20.5632 18.4503 19.9992 18.1981 19.8077 17.6966C19.6162 17.195 19.8697 16.6339 20.3739 16.4434C20.6577 16.3361 21.0468 16.1679 21.0468 15.8127C21.0468 15.6529 20.9996 15.5308 20.7695 15.3806C20.4903 15.1983 20.0116 15.0369 19.3072 14.9326C18.7738 14.8535 18.4057 14.3592 18.4852 13.8285Z"
                  fill="currentColor"></path>
                <path
                  d="M4.40499 13.0107C4.93845 12.9317 5.43531 13.2978 5.51476 13.8285C5.59421 14.3592 5.22616 14.8535 4.69271 14.9326C3.98832 15.0369 3.50963 15.1983 3.23047 15.3806C3.00032 15.5308 2.95313 15.6529 2.95313 15.8127C2.95313 16.1679 3.34225 16.3361 3.62609 16.4434C4.13028 16.6339 4.38375 17.195 4.19223 17.6966C4.00071 18.1981 3.43672 18.4503 2.93253 18.2598C2.44146 18.0742 1.95982 17.8032 1.59481 17.3928C1.21169 16.962 1 16.4243 1 15.8127C1 14.8844 1.48162 14.1982 2.15895 13.756C2.78727 13.3459 3.59472 13.1308 4.40499 13.0107Z"
                  fill="currentColor"></path>
              </svg>
              <span style="font-size:medium;padding-left: 2px;">{{ $t('member.member') }} </span>
            </div>
          </div>
        </router-link>
        <router-link
          class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800 px-2"
          :class="{ 'border-primary-400 bg-blue-25 text-primary-400 font-bold': $route.path === '/income', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/income' }"
          :style="{ borderColor: bodermember ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
           to="/income">
          <div className="flex items-center justify-center w-auto">
            <div className="flex !flex-nowrap items-center justify-center w-[100px]  ">
              <i class="fa-solid fa-money-bill  " style="font-size: 20px;"></i>
              <span style="font-size:medium;padding-left: 3px;"> {{ $t('income.income') }} </span>
            </div>
          </div>
        </router-link>
        <router-link
          class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800 px-2"
          :class="{ 'border-primary-400 bg-blue-25 text-primary-400 font-bold': $route.path === '/courses', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/courses' }"
          :style="{ borderColor: bodermember ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
           to="/courses">
          <div className="flex items-center justify-center w-auto">
            <div className="flex !flex-nowrap items-center justify-center w-[100px]  ">
              <i class="fas fa-chalkboard-teacher" style="font-size: 16px;"></i>
              <span style="font-size:medium;padding-left: 4px;"> {{ $t('courses.courses') }} </span>
            </div>
          </div>
        </router-link>
        <router-link
          class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800"
          :class="{ 'border-primary-400 bg-blue-25 text-primary-400': $route.path === '/support', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/support' }"
          :style="{ borderColor: bodermember ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
           to="/support">
          <div className="flex items-center justify-center w-auto">
            <div className="flex !flex-nowrap items-center justify-center w-[100px]  ">
              <i class="fas fa-hands-helping" style="font-size: 16px;"></i>
              <span style="font-size:medium;padding-left: 2px;"> {{ $t('support.support') }} </span>
            </div>
          </div>
        </router-link>
        <!-- <router-link
            class="flex !flex-nowrap  h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800"
            :class="{ 'border-primary-400 bg-blue-25 text-primary-400': $route.path === '/home', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/home' }"
            :style="{ borderColor: boderrankhome ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
            @click="acctionrankhome" to="/home">
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M9.68808 1.54188C9.88787 1.75315 9.92769 2.06953 9.78648 2.3237C9.7769 2.34094 9.76736 2.35807 9.75787 2.3751C9.56392 2.72328 9.39227 3.03142 9.39808 3.41698C9.4035 3.77691 9.66268 4.4316 10.5225 5.15708C11.6863 6.13901 12.2616 7.34954 12.546 8.29804C12.6885 8.77336 12.7601 9.18928 12.7962 9.48937C12.8142 9.63972 12.8235 9.76193 12.8283 9.84911C12.8307 9.89272 12.8319 9.92766 12.8326 9.95306C12.8329 9.96576 12.8331 9.97609 12.8332 9.98394L12.8333 9.99384L12.8333 9.99737L12.8333 9.99878C12.8333 9.99878 12.8333 9.99994 12.1667 9.99994H12.8333C12.8333 12.5773 10.744 14.6666 8.16667 14.6666C5.58934 14.6666 3.5 12.5773 3.5 9.99994C3.5 8.71379 3.68055 7.59138 3.93688 6.75812C4.06428 6.34396 4.21691 5.97993 4.38953 5.69755C4.47572 5.55655 4.57713 5.41898 4.69662 5.30387C4.81138 5.19333 4.98098 5.06672 5.20551 5.0162C5.38874 4.97498 5.58086 5.01295 5.73463 5.12078C5.8884 5.22862 5.98955 5.39631 6.01321 5.58263C6.03458 5.75097 6.10452 5.99749 6.21584 6.25777C6.21028 5.81561 6.24296 5.34618 6.33334 4.87448C6.58514 3.56037 7.2994 2.18197 8.91297 1.40001C9.17463 1.27321 9.4883 1.33062 9.68808 1.54188ZM11.5 10.0017C11.499 11.8418 10.007 13.3333 8.16667 13.3333C6.32572 13.3333 4.83333 11.8409 4.83333 9.99994C4.83333 8.86093 4.99002 7.89329 5.19483 7.20454C5.24734 7.30077 5.30317 7.39513 5.36201 7.48633C5.69704 8.0057 6.29052 8.66661 7.12963 8.66661C7.33969 8.66661 7.53745 8.56761 7.66333 8.39945C7.78921 8.2313 7.82847 8.01365 7.76929 7.8121C7.59625 7.22284 7.44354 6.16557 7.64285 5.1254C7.72788 4.68163 7.87419 4.25005 8.10621 3.85865C8.24121 4.60111 8.71414 5.3758 9.66268 6.17614C10.573 6.9442 11.0347 7.90034 11.2688 8.68101C11.3856 9.07027 11.4436 9.40956 11.4724 9.64854C11.4867 9.76771 11.4936 9.861 11.4969 9.92193C11.4986 9.95237 11.4994 9.97465 11.4997 9.98794L11.5 10.001L11.5 10.0017Z"
                fill="currentColor"></path>
            </svg>
       
            <span>{{$t('single_storm.single_storm')}} </span>
          </router-link> -->
        <!-- Same for other links -->
        <!-- <router-link
            :class="['flex','!flex-nowrap ', 'h-8', 'items-center', 'justify-center', 'space-x-1', 'rounded-full', 'border', 'bg-white', 'text-xs', 'transition', 'duration-200', 'bg-gray-10', 'text-gray-800', 'bg-primary-300', $route.path === '/rank' && 'border-primary-400 bg-blue-25 text-primary-400 ']"
            to="/rank" 
            :style="{ borderColor: boderrank ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
            @click="acctionrank">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M10.6668 1.33325C9.93045 1.33325 9.3335 1.93021 9.3335 2.66659V4.66658H6.66683C5.93045 4.66658 5.3335 5.26354 5.3335 5.99992V7.99992H2.66683C1.93045 7.99992 1.3335 8.59687 1.3335 9.33325V13.3333C1.3335 14.0696 1.93045 14.6666 2.66683 14.6666H6.00016H10.0002H13.3335C14.0699 14.6666 14.6668 14.0696 14.6668 13.3333V2.66659C14.6668 1.93021 14.0699 1.33325 13.3335 1.33325H10.6668ZM9.3335 13.3333V5.99992H6.66683V9.33325V13.3333H9.3335ZM10.6668 13.3333H13.3335V2.66659H10.6668V5.99992V13.3333ZM5.3335 9.33325V13.3333H2.66683V9.33325H5.3335Z"
                fill="currentColor"></path>
            </svg>
            <span>{{$t('see_rankings.see_rankings')}} </span>

          </router-link> -->
        <!-- <router-link :style="{ borderColor: boderranksale ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }"
            @click="acctionranksale"
            :class="['flex','!flex-nowrap ', 'h-8', 'items-center', 'justify-center', 'space-x-1', 'rounded-full', 'border', 'bg-white', 'text-xs', 'transition', 'duration-200', 'bg-gray-10', 'text-gray-800', $route.path === '/promotion' && 'border-primary-400 bg-blue-25 text-primary-400']"
            to="/promotion">

            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M12.9798 2.16729C13.4036 2.4023 13.6665 2.84874 13.6665 3.33333V5.78047C14.4433 6.05503 14.9998 6.79586 14.9998 7.66667C14.9998 8.53748 14.4433 9.27831 13.6665 9.55286V12C13.6665 12.4846 13.4036 12.931 12.9798 13.166C12.556 13.4011 12.0381 13.3876 11.627 13.131L10.3159 12.3123C9.29181 11.6729 8.16922 11.2196 6.99984 10.9673V13.3333C6.99984 13.7015 6.70136 14 6.33317 14H4.33317C3.96498 14 3.6665 13.7015 3.6665 13.3333V10.5427C2.49869 10.1095 1.6665 8.98525 1.6665 7.66667C1.6665 5.97299 3.0395 4.6 4.73317 4.6H4.80561C6.75399 4.6 8.66322 4.0529 10.3159 3.02099L11.627 2.20236C12.0381 1.9457 12.556 1.93228 12.9798 2.16729ZM4.99984 10.7351V12.6667H5.6665V10.769C5.44511 10.7506 5.22278 10.7393 4.99984 10.7351ZM12.3332 3.33333L11.0221 4.15197L10.9998 4.16581C9.14048 5.32101 6.99495 5.93333 4.80561 5.93333H4.73317C3.77588 5.93333 2.99984 6.70937 2.99984 7.66667C2.99984 8.62396 3.77588 9.4 4.73317 9.4H4.80561C6.99495 9.4 9.14048 10.0123 10.9998 11.1675L11.0221 11.1814L12.3332 12V10.4281V4.90523V3.33333Z"
                fill="currentColor"></path>
            </svg>
            <span> {{$t('promotion.promotion')}}</span>
          </router-link> -->

      </div>

    </div>
  </div>
</template>

<script>
// import { getShops } from '../../services/authentication/authService'
// import axios from 'axios';
export default {
  name: "HeaderCompoment",
  data() {
    return {
      bodermember: false,
      bodershare: false,
      boderrankhome: false,
      boderrank: false,
      boderranksale: false,
      user: [], shop: '',
      datashop: '',
      cartItemCount: 0,
      cartItems: '',
      lang:'',
      width1: '',

    };
  },
  created(){
    this.lang = window.$cookies.get('lang');
    this.width1 =  (this.lang === 'en') ? '100px' : '80px';
    // console.log(this.$route.path)
    // this.selectimg1(this.$route.path);
  },
  watch(){
    this.lang = window.$cookies.get('lang');
   return  this.width1 =  (this.lang === 'en') ? '100px' : '80px';
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('datauserInfo'));
    this.getShops();
    if(this.$route === "/home"){
      this.boderrankhome = true;
    }
    this.getCartItems();
  },
  methods: {
    acctionrank() {
      this.boderrank = true;
      this.boderrankhome = false;
      this.boderranksale = false;
    },
    acctionrankhome() {
      this.boderrank = false;
      this.boderrankhome = true;
      this.boderranksale = false;
    },
    acctionranksale() {
      this.boderrank = false;
      this.boderrankhome = false;
      this.boderranksale = true;
    },
    async getShops() {
      try {
        // const response = await axios.get('https://salekit.net/api/v1/affiliate/dropship/getShops', {
        //   headers: {
        //     'Shop-Id': this.user.shop_id,
        //     'Lang': 'vi',
        //     'Content-Type': 'application/json',
        //   },
        //   params: {
        //     username: this.user.username,
        //   }
        // });
        // this.datashop = response.data.shopList[0];
        this.datashop = JSON.parse(localStorage.getItem('getshop'));
        
        localStorage.setItem('datashop', JSON.stringify(this.datashop));
        this.shop = this.datashop.avatar;
        // console.log("this.shop", JSON.parse(localStorage.getItem('datashop')) );
        // console.log(this.shop.avatar);
      } catch (error) {
        console.error(error);
      }
    },
    getCartItems() {
      let cartData = localStorage.getItem('cart');
      let getIdHash = localStorage.getItem("datauserInfo");
      if(getIdHash){
         getIdHash=JSON.parse(getIdHash).id_hash;
        if (cartData) {
        const data = JSON.parse(cartData);
        this.cartItems = data.filter(item => item.username === getIdHash);
        this.cartItemCount = this.cartItems.length;
      }
      }
      
    },
    // selectimg1(path) {
    //   // console.log(path)
    //     let index = 0;
    //     if(path === "/income"){
    //       index = 5;
    //     }
    //     const slideContainer = document.getElementsByClassName('sliding-image-container');
    //     const imageWidth = slideContainer.offsetWidth;
    //     console.log(imageWidth)
    //     const scaleFactor = window.innerWidth <= 468 ? 0.12 : 0.27;
    //     const scrollPosition = (index * (imageWidth - imageWidth * scaleFactor));
    //     console.log(imageWidth)
    //     // Cuộn đến vị trí tương ứng với index
    //     slideContainer.scrollTo({
    //         left: scrollPosition,
    //         behavior: 'smooth'
    //     });
    //     setTimeout(() => {
    //             this.isScrolling = false;
    //         }, 100); 
    //     },
  }
};
</script>

<style scoped>
/* Component-specific styles */
</style>
