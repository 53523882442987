<template>
  <div class="pb-20">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
// Import các thành phần cần thiết từ Vue
import Header from '../shared/Header.vue';
import Footer from '../shared/Footer.vue'; 

export default {
  name: 'layouts_index',
  components: {
    Header,
    Footer
  }
}
</script>

<style scoped>
/* Thêm CSS tại đây nếu cần */
</style>
