<template>
    <div class="">

        <div class="sm:min-h-screen sm:container" id="main-container">
            <header class="sticky inset-x-0 top-0 z-20 w-full sm:w-[480px]">
                <div class="border-b border-gray-25 bg-white sm:container bg-white">
                    <div class="relative flex h-16 justify-between items-center  pr-14 h-[64px] pl-12">
                        <h5 class="!font-bold leading-snug font-semibold text-gray-900 text-lg truncate">{{ $t('cart.cart') }}</h5>

                        <router-link to="/product" class="!absolute left-2 center-y relative rounded-md text-2xl h-10 w-10
                                 transition duration-500 border border-transparent bg-transparent text-primary-400 
                                hover:text-primary-600 inline-flex items-center justify-center 
                                whitespace-nowrap text-center leading-none transition duration-200
                                 focus:outline-none cursor-pointer" aria-label="Trở về"><svg class="h-6 w-6"
                                height="1em" width="1em" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
                                    fill="currentColor"></path>
                            </svg></router-link>

                       
                    </div>
                </div>
            </header>
            <!-- <template v-for="(group, storeId) in groupedCartItems" :key="storeId">
            <div v-if="group.length > 0">
                <h2>{{ group[0].store.name }}</h2>
                <ul>
                    <li v-for="(item, index) in group" :key="index">
                        {{ item.product.name }} - {{ item.product.price }} - {{ item.quantity }}
                    </li>
                </ul>
            </div>
        </template> -->
            <main class="bg-gray-10 sm:min-h-screen sm:container space-y-3 ">
                <div class="space-y-3 pb-20">
                    <form id="cart-form" novalidate="" v-for="(group, storeId) in groupedCartItems.groupedItems"
                        :key="storeId">
                        <div class="bg-white">
                            <div class="sticky top-[56px] z-10 flex space-x-3 bg-white p-4">
                                <div class="relative inline-flex items-center">
                                    <!-- <input v-model="selectedAll[group[0].store.id]"
                                        @click="clickSelected(group[0].store.id, group.map(item => item.product.id))"
                                        class="rounded border-2 border-gray-400 !bg-[length:75%_75%] h-6 w-6 text-primary-400 
                                        form-checkbox border-gray-400 focus:ring-primary-400"
                                        type="checkbox" value=""> -->
                                    <input type="checkbox" :checked="selectedAll[group[0].store.id]" class="rounded border-2 border-gray-400 !bg-[length:75%_75%] h-6 w-6 text-primary-400 
                                        form-checkbox border-gray-400 focus:ring-primary-400"
                                        @change="toggleAllSelected(group[0].store.id)">

                                </div>
                                <p class="text-lg font-semibold text-gray-1100">{{ group[0].store.name }}</p>
                            </div>
                            <div class="px-4 pb-4" v-for="(item, index) in group" :key="index">
                                <div class="divide-y divide-gray-50">
                                    <div class="bg-white space-y-3 py-3 !pt-0">
                                        <div class="flex items-start space-x-3">
                                            <div class="flex flex-none items-center space-x-4">
                                                <div>
                                                    <div class="relative inline-flex items-center" aria-invalid="false">
                                                        <!-- <input aria-invalid="false"
                                                            v-model="selectedItems[group[0].store.id]"
                                                            class="rounded border-2 border-gray-400 !bg-[length:75%_75%] h-5 w-5 text-primary-400 
                                                            form-checkbox border-gray-400 focus:ring-primary-400"
                                                            type="checkbox"> -->

                                                        <input type="checkbox"
                                                            :checked="isSelected(group[0].store.id, item.product.id)"
                                                            class="rounded border-2 border-gray-400 !bg-[length:75%_75%] h-5 w-5 text-primary-400 
                                                            form-checkbox border-gray-400 focus:ring-primary-400"
                                                            @change="toggleItemSelection(group[0].store.id, item.product.id)">
                                                    </div>

                                                </div>
                                                <div class="h-[70px] w-[70px] flex-none space-y-2">
                                                    <div class="relative h-0" style="padding-bottom: 100%;">
                                                        <div
                                                            class="absolute inset-0 h-full w-full flex-none rounded-lg animate-pulse bg-gray-800 bg-opacity-10">
                                                        </div>
                                                        <img v-if="item.product.img" class="absolute inset-0 h-full w-full object-cover transition duration-500 flex-none rounded-lg"
                                                            :src="item.product.img">
                                                        <img v-else-if="item.product.photo" class="absolute inset-0 h-full w-full object-cover transition duration-500 flex-none rounded-lg"
                                                            :src="item.product.photo">
                                                        <img v-else-if="item.product.image" class="absolute inset-0 h-full w-full object-cover transition duration-500 flex-none rounded-lg"
                                                            :src="item.product.image">
                                                        <img v-else class="absolute inset-0 h-full w-full object-cover transition duration-500 flex-none rounded-lg"
                                                        src="https://salekit.net/assets/images/no-image.png">   

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="space-y-1.5">
                                                <h6 class="text-nm font-semibold text-gray-1100 line-clamp-2">{{
                        item.product.name
                    }}</h6>
                                                <div class="flex flex-wrap items-center text-nm">
                                                    <!-- <b class="font-semibold text-primary-400"
                                                        v-if="item.product.price_sale">₫ {{
                        item.product.price_sale
                    }}</b>
                                                    <b class="font-semibold text-primary-400" v-else>₫ {{
                            item.product.price
                        }}</b> -->
                                                    <b class="font-semibold text-primary-400">{{ formatNumber(item.total)}}₫</b>
                                                    
                                                    <span class="mx-1 text-gray-50">|</span>
                                                    <p class="text-gray-1100">SL: {{ item.quantity }}</p>
                                                </div>
                                                <div v-if="item.profit" class="flex flex-wrap items-center text-nm">
                                                    <!-- <span v-if="item.product.price_sale" class="text-gray-1100">LN:
                                                        ₫ {{ formatNumber(item.product.price_sale * (item.product.rate /
                        100)) }}
                                                    </span> -->
                                                    <span  class="text-gray-1100">LN: {{ formatNumber(item.profit)}}₫</span>
                                                    <span class="mx-1 text-gray-50">|</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pl-8"></div>
                                        <div class="ml-[116px] flex flex-wrap items-center gap-x-4">
                                            <div class="flex items-center space-x-6">
                                                <div class="inline-flex">
                                                    <button @click="decreaseQuantity(item.product.id, item.store.id)"
                                                        class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                                                        type="button" aria-label="Giảm">
                                                        <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
                                                                fill="currentColor"></path>
                                                        </svg>
                                                    </button>
                                                    <input v-model="item.quantity" disabled
                                                        class="h-10 w-10 border-none bg-transparent p-0 text-center font-bold text-gray-900 focus:outline-none h-10 w-10"
                                                        max="4243" min="0" name="items.0.quantity" value="1">
                                                    <button @click="increaseQuantity(item.product.id, item.store.id)"
                                                        class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                                                        type="button" aria-label="Thêm">
                                                        <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
                                                                fill="currentColor"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <button @click.prevent="deleteItem(item.product.id, item.store.id)"
                                                    class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-gray-900 hover:text-gray-600"
                                                    type="button">{{ $t('delete.delete') }} </button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <!-- <button
                                    class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none border h-10 w-full flex text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300"
                                    type="button"><span class="">Thêm sản phẩm</span><span class="text-lg ml-2"><svg
                                            width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
                                                fill="currentColor"></path>
                                        </svg></span></button> -->
                            </div>
                            <div class="w-full"></div>
                            <div class="bg-white p-4 border-t border-gray-50">
                                <div v-if="selectedItems[group[0].store.id] == null || selectedItems[group[0].store.id].length < 1"></div>
                                <div v-else  class="space-y-2 w-full pb-5">
                                    <div v-if="groupedCartItems.profitByStore[storeId]" class="flex justify-between space-x-2">
                                        <div class="flex-shrink-0">{{ $t('retail_profits.retail_profits') }} </div>
                                        <div v-if="totalprofit1(storeId)" class="text-right">{{formatNumber(totalprofit1(storeId)) }}₫</div>
                                        <div v-else class="text-right">{{formatNumber(groupedCartItems.profitByStore[storeId]) }}₫</div>
                                        <!-- <div class="text-right">{{formatNumber(totalproduct1(storeId)) }}₫</div> -->

                                    </div>
                                    <!-- <div class="flex justify-between space-x-2">
                                        <div class="flex-shrink-0">Points </div>
                                        <div class="text-right">246,913</div>
                                    </div> -->
                                    <div   class="flex justify-between space-x-2 font-bold">
                                        <div class="flex-shrink-0">{{ $t('total_payment.total_payment') }} </div>
                                     
                                        <div v-if="totalproduct1(storeId)" class="text-right">{{formatNumber(totalproduct1(storeId)) }}₫</div>
                                        <div v-else class="text-right">{{formatNumber(groupedCartItems.totalsByStore[storeId])}}₫</div>
                                    </div>
                                </div>
                                <!-- <button
                                    class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300"
                                    type="submit">Tạo đơn hàng</button> -->
                                <button  :class="[ 'px-3', 'rounded-lg', 'transition', 'duration-200', 'justify-center',
                                    'items-center', 'whitespace-nowrap', 'leading-none', 'focus:outline-none',
                                    'h-10', 'w-full', 'flex', 'bg-primary-400', 'text-white',
                                    'hover:bg-primary-300',
                                    (selectedItems[group[0].store.id] == null || selectedItems[group[0].store.id].length < 1 ) ? 'opacity-30 disabled' : '']" 
                                    @click.prevent="createOrderAndRedirect(group)">
                                     {{ $t('create_order.create_order') }}
                                </button>
<!--                                
                                <button v-if="selectedItems[group[0].store.id] == null || selectedItems[group[0].store.id] < 1" class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300 opacity-30 disabled"
                                       
                                        @click.prevent="createOrderAndRedirect(group)"
                                        disabled>
                                    {{ $t('create_order.create_order') }}
                                </button>
                                <button v-else class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300 "
                                        
                                        @click.prevent="createOrderAndRedirect(group)"
                                        >
                                    {{ $t('create_order.create_order') }}
                                </button> -->





                            </div>
                        </div>
                    </form>

                </div>
            </main>
            <div style="position: fixed; z-index: 9999; inset: 16px; pointer-events: none;"></div>
        </div>

    </div>

    <Footer />

</template>

<script>
import Footer from '@/components/shared/Footer.vue';

export default {
    name: 'ProductCart',
    components: {
        Footer, 
    },

    data() {
        return {
            isOrderVisible: false,
            cartItems: [],
            quantity: 1, // Số lượng mặc định
            minQuantity: 1, // Số lượng tối thiểu
            maxQuantity: Infinity, // Số lượng tối đa, có thể thay đổi
            selectedAll: {},
            selectedItems: {},
            totalproduct:[],
        };
    },
    mounted() {
        this.cartItemCount = this.cartItems.length;

    },created() {
        document.title =this.$t(`cart.cart`);
        this.getCartItems();
    },
    computed: {
        groupedCartItems() {
            // Nhóm sản phẩm theo cửa hàng
            if(this.cartItems){
                const groupedItems = {};
                this.cartItems.forEach(item => {
                const storeId = item.store.id;
                if (!groupedItems[storeId]) {
                    groupedItems[storeId] = [];
                }
                groupedItems[storeId].push(item);
            });
            const totalsByStore = {};
            for (const storeId in groupedItems) {
                if (Object.prototype.hasOwnProperty.call(groupedItems, storeId)) {
                    let total = 0;
                    groupedItems[storeId].forEach(item => {
                        total += item.total;
                    });
                    totalsByStore[storeId] = total;
                }
            }
            const profitByStore = {};
            for (const storeId in groupedItems) {
                if (Object.prototype.hasOwnProperty.call(groupedItems, storeId)) {
                    let profit = 0;
                    groupedItems[storeId].forEach(item => {
                        profit += item.profit;
                    });
                    profitByStore[storeId] = profit;
                }
            }
            return { groupedItems, totalsByStore, profitByStore };
            
        }else{
            return console.log("không có sản phẩm");
        }
            // Tính tổng tiền cho từng cửa hàng
            // Tính tổng lợi nhuận cho từng cửa hàng
            // console.log(totalsByStore);
        }
    },
    methods: {
        createOrderAndRedirect(group) {
        //    console.log(group[0].store);
            const storeId = group[0].store.id;
            if( this.groupedCartItems.groupedItems[storeId] &&  this.selectedItems[storeId] ){
                const selectedProducts = this.groupedCartItems.groupedItems[storeId].filter(item => this.selectedItems[storeId].includes(item.product.id));
             //console.log('Cart:', selectedProducts);
            if (selectedProducts && selectedProducts.length > 0) {
            localStorage.setItem('order', JSON.stringify(selectedProducts));
                // Chuyển hướng người dùng đến view đơn hàng và truyền danh sách sản phẩm đã chọn
                this.$router.push({
                    name: 'order-create',
                });
            } else {
                console.log("Không có sản phẩm nào được chọn để tạo đơn hàng.");
            }
            }
        },
        //Chọn tất cả
        toggleAllSelected(storeId) {
            this.selectedAll[storeId] = !this.selectedAll[storeId];
            if (this.selectedAll[storeId]) {
                // Chọn tất cả các mục
                const check = this.totalproduct.filter(item=> item.storeId == storeId)
                if(!check || check.length <1 || check == [false] ){
                    this.totalproduct.push({"storeId":storeId})
                }
                if( this.totalproduct && this.totalproduct.length > 1 ){
                this.totalproduct.forEach(item => {
                    if(!item.totalproduct){
                        item.totalproduct = 0;
                        item.profit = 0;
                    }
                });
                }else{
                    if(! this.totalproduct[0].totalproduct)  {
                        this.totalproduct[0].totalproduct = 0;
                        this.totalproduct[0].profit = 0;
                    }
                }
                
                this.selectedItems[storeId] = this.groupedCartItems.groupedItems[storeId].map(item => item.product.id);
                // Log ra các sản phẩm đã chọn
                let total = 0;
                let profit = 0;
                this.selectedItems[storeId].forEach(productId => {
                    const selectedProduct = this.groupedCartItems.groupedItems[storeId].find(item => item.product.id === productId);
                    if (selectedProduct) {
                        // let a=1;
                        //  console.log(selectedProduct.product); // Log ra sản phẩm đã chọn
                    }
                });

                this.cartItems.forEach(item => {
                    if(item.store.id ==  storeId ){
                        total += item.total
                        profit += item.profit
                    }
                });
                
                this.totalproduct.forEach(item1 => {
                           if(item1.storeId == storeId) {
                            item1.totalproduct = total
                            item1.profit = profit
                           }
                        });
            } else {
                // Bỏ chọn tất cả các mục
                this.selectedItems[storeId] = [];
                
                this.totalproduct.forEach((item1, index) => {
                    if (item1.storeId === storeId) {
                        item1.totalproduct = 0;
                        item1.profit = 0;
                        if (item1.totalproduct <= 0) {
                            this.totalproduct.splice(index, 1);
                            // console.log(index)
                        }
                    }
                });
                // this.created;
            }
        },
        async toggleItemSelection(storeId, productId) {
            this.isSelected(storeId,productId);
            if (!this.selectedItems[storeId]) {
                this.selectedItems[storeId] = [];
            }
            const index = this.selectedItems[storeId].indexOf(productId);
            // let totalproduct = '';
            const check = this.totalproduct.filter(item=> item.storeId == storeId)
            if(!check || check.length <1 ){
                this.totalproduct.push({"storeId":storeId})
            }
           
           
            //  console.log("this.totalproduct",this.totalproduct)
            if( this.totalproduct && this.totalproduct.length > 1 ){
                this.totalproduct.forEach(item => {
                    if(!item.totalproduct){
                        item.totalproduct = 0;
                        item.profit = 0;
                    }
                //    console.log("item",item)
                });
            }else{
                if(! this.totalproduct[0].totalproduct){
                    this.totalproduct[0].totalproduct = 0;
                    this.totalproduct[0].profit = 0;
                }
            }

            if (index === -1) {
                // Nếu sản phẩm không được chọn, thêm vào danh sách chọn
                this.selectedItems[storeId].push(productId);
                //  console.log(this.selectedItems[storeId])
               let total = 0;
               let profit = 0;
                this.cartItems.forEach(item => {
                    if(item.product.id ==  productId ){
                        // console.log(this.totalproduct)
                        // this.totalproduct[storeId].totalproduct += item.total;
                        total = item.total
                        profit = item.profit
                    }
                  
                });
                this.totalproduct.forEach(item1 => {
                           if(item1.storeId == storeId) {
                            // console.log(item1)
                            item1.totalproduct += total
                            item1.profit += profit
                            // console.log( item1.totalproduct, item1.profit)
                           }
                        });
                // console.log(total)
                // const totalsByproduct = {};
                    
            } else {
                // Nếu sản phẩm đã được chọn, loại bỏ khỏi danh sách chọn
                // console.log(this.selectedItems[storeId])
               
                let total1 = 0;
                let profit1 = 0;
                // let x = {}
                //  x = await this.gettotalprofit(productId,storeId);
                // console.log(x)
                this.cartItems.forEach(item => {
                    if(item.product.id ==  productId && item.store.id == storeId){
                        total1 = item.total;
                        profit1 = item.profit;
                    }
                });
              
                 this.totalproduct.forEach((item1, index) => {
                    if (item1.storeId === storeId) {
                        item1.totalproduct -= total1;
                        item1.profit -=  profit1;
                        // console.log(profit1,"_",item1.profit)
                        if (item1.totalproduct <= 0) {
                            this.totalproduct.splice(index, 1);
                           
                        }
                    }
                });
                this.selectedItems[storeId].splice(index, 1);
              
                // console.log(this.totalproduct)

                // console.log("====loai====");
            }
            // Cập nhật trạng thái chọn tất cả nếu tất cả các mục đã được chọn
            if(this.selectedAll[storeId]){
                this.selectedAll[storeId] = this.selectedItems[storeId].length === this.groupedCartItems.groupedItems[storeId].length;
            // Log ra danh sách các sản phẩm đã chọn
            // console.log("Danh sách các sản phẩm đã chọn:");
            this.selectedItems[storeId].forEach(productId => {
                const selectedProduct = this.groupedCartItems.groupedItems[storeId].find(item => item.product.id === productId);
                if (selectedProduct) {
                    // console.log(selectedProduct.product); // Log ra sản phẩm đã chọn
                }
            });
            }
           
        },
        async gettotalprofit(product_id,storeid){
            let total1 = 0;
            let profit1 = 0;
            await this.cartItems.forEach(item => {
                    if(item.product.id ==  product_id && item.store.id == storeid){
                        total1 = item.total;
                        profit1 = item.profit;
                    }
                });
            return  total1,profit1;
        },
       
        totalproduct1(storeId){
             const price = this.totalproduct.filter(item=> item.storeId == storeId);
             if(price && price[0] ){
                return price[0].totalproduct;
             }
             else{
                return 0 ; 
             }
        },
        totalprofit1(storeId){
             const price = this.totalproduct.filter(item=> item.storeId == storeId);
             if(price && price[0] ){
             return price[0].profit;
             }
             else{
                return 0 ; 
             }
             
        },
        isSelected(storeId, productId) {
            //  console.log( "======");
             //this.selectedItems[storeId] ='';
            return this.selectedItems[storeId] && this.selectedItems[storeId].includes(productId);
        },
        calculatestore(item,i){
            //i=1 la tang,i=0 la giam
            const storeId = item.store.id;
            const productId = item.product.id;
            let total = 0;
            let profit = 0;
            let quantity = 0;
            // const id_storeId_total = this.totalproduct.filter(item=> item.storeId)
                this.cartItems.forEach(item1 => {
                    if(item1.product.id ==  productId && item1.store.id == storeId ){
                        total = item.total;
                        profit = item.profit;
                        quantity = item.quantity;
                    }
                });
                // console.log(this.selectedItems[storeId]);
                if(this.selectedItems[storeId] && this.selectedItems[storeId].length == 1) {
                    this.totalproduct.forEach(item => {
                      if(item.storeId == storeId){
                        item.profit = profit
                        item.totalproduct = total
                      }
                    });
                }
                else{
                    // console.log(i)
                    if(i==1){
                        const profit1 = profit/quantity;
                        const total1= total/quantity;
                        this.totalproduct.forEach(item => {
                      if(item.storeId == storeId){
                        item.profit = item.profit +profit1;
                        item.totalproduct =  item.totalproduct+ total1;
                      }
                    });
                    }
                    if(i==0){
                        const profit1 = profit/quantity;
                        const total1= total/quantity;
                        this.totalproduct.forEach(item => {
                      if(item.storeId == storeId){
                        item.profit = item.profit -profit1;
                        item.totalproduct =  item.totalproduct- total1;
                      }
                    });
                    }
                    if(i==3){
                        
                        this.totalproduct.forEach(item => {
                      if(item.storeId == storeId){
                        item.profit = item.profit -profit;
                        item.totalproduct =  item.totalproduct- total;
                      }
                    });
                    }
                }
                // this.selectedItems[storeId].push(productId);
            // this.totalproduct.forEach(item => {
            // });
            // console.log(total,"===",profit);

        },
        //Cập nhật lợi nhuận, total
        calculatePriceAndProfit(item,i,j) {
            // console.log(item)
            
            if (item.product.price_sale) {
                item.total = item.product.price_sale * item.quantity;
                item.profit = item.total * (item.product.rate / 100);
            } else {
                item.total = item.product.price * item.quantity;
                item.profit = item.total * (item.product.rate / 100);
            }
            if (item.product.sale_price) {
                item.total = item.product.sale_price * item.quantity;
            } 
            //  console.log( j);
            // console.log("this.cartItems",this.cartItems);
            localStorage.setItem('cart', JSON.stringify(this.cartItems));
            if(j === -1) return;
            this.calculatestore(item,i)

        },
        //Tăng số lượng
        increaseQuantity(productId, storeId) {
            let index1 = -1;
            if( this.selectedItems && this.selectedItems[storeId])  index1 = this.selectedItems[storeId].indexOf(productId);
            
           
            const index = this.cartItems.findIndex(item => item.product.id === productId && item.store.id === storeId);
            if (index !== -1 && this.cartItems[index].quantity < this.maxQuantity) {
                this.cartItems[index].quantity++;
                this.calculatePriceAndProfit(this.cartItems[index],1,index1); // Tính lại giá tiền và lợi nhuận sau khi tăng số lượng
                localStorage.setItem('cart', JSON.stringify(this.cartItems));

            }

        },
        //Giảm số lượng
        decreaseQuantity(productId, storeId) {
            let index1 = -1;
            if( this.selectedItems && this.selectedItems[storeId])  index1 = this.selectedItems[storeId].indexOf(productId);
            
            const index = this.cartItems.findIndex(item => item.product.id === productId && item.store.id === storeId);
            if (index !== -1 && this.cartItems[index].quantity > this.minQuantity) {
                this.cartItems[index].quantity--;
                this.calculatePriceAndProfit(this.cartItems[index],0,index1); // Tính lại giá tiền và lợi nhuận sau khi giảm số lượng
                localStorage.setItem('cart', JSON.stringify(this.cartItems));

            }

        },

        getCartItems() {
            localStorage.removeItem('order');
            let cartData = localStorage.getItem('cart');
            const getIdHash = JSON.parse(localStorage.getItem('datauserInfo')).id_hash;
            if (cartData) {
                const data = JSON.parse(cartData);
                this.cartItems = data.filter(item => item.username === getIdHash);
                // this.cartItems = JSON.parse(cartData);
                
            } 
            // console.log(this.cartItems);
        },
        deleteItem(productId, storeId) {
            
            let total1 = 0;
            let profit1 = 0;
            this.cartItems.forEach(item => {
                if(item.product.id ==  productId ){
                    total1 = item.total;
                    profit1 = item.profit;
                    // console.log(item);
                    // this.calculatestore(item,3)
                }
            });

            const index = this.cartItems.findIndex(item => item.product.id === productId && item.store.id === storeId);
            if (index !== -1) {
                this.cartItems.splice(index, 1);
            }
            this.toggleItemSelection(storeId, productId)
            this.totalproduct.forEach((item1, index) => {
                if (item1.storeId === storeId) {
                    item1.totalproduct -= total1;
                    item1.profit -= profit1;
                    if (item1.totalproduct <= 0) {
                        this.totalproduct.splice(index, 1);
                    }
                }
            });
            localStorage.setItem('cart', JSON.stringify(this.cartItems));
        },
        formatNumber(value) {
      const result = parseFloat(value);

      if (isNaN(result)) {
        return "Invalid value";
      }
      return result.toLocaleString('en-US', {
        maximumFractionDigits: 0
      });
    }
        
    }
};
</script>

<style scoped></style>
