<template>
  <div>
    <!-- <Stories /> -->
    <TrainingCalendar />
    <OrderList />
  </div>
</template>

<script>
// import Stories from "../../section/home-section/Stories.vue";
import TrainingCalendar from "../../section/home-section/TrainingCalendar.vue";
import OrderList from "../../section/home-section/OrderList.vue";

export default {
  name: "HomeCompoment",
  components: {
    // Stories,
    TrainingCalendar,
    OrderList
  },
  created() {
    // window.location.reload();
    document.title =this.$t(`home.home`);
    if (localStorage.getItem('reload')) {
      localStorage.removeItem('reload')
      window.location.reload();
    }
  },
  mounted() {
    if (localStorage.getItem('reload')) {
      localStorage.removeItem('reload')
      window.location.reload();

    }
  }
};
</script>

<style scoped>
/* Thêm CSS tùy chỉnh tại đây nếu cần */
</style>
