import { createApp } from "vue";
import App from "./App.vue";
// import VueI18n from 'vue-i18n';
import enMessage from '../language/en.json'
import viMessage from '../language/vi.json'

import VueCookies from 'vue-cookies'
import { createI18n } from 'vue-i18n';
const app = createApp(App)
app.use(require('vue-cookies'))
app.use(VueCookies)

let messages;
let lang;
window.$cookies.get('lang');
if (window.$cookies.get('lang') && window.$cookies.get('lang') != 'vi'){
	lang = 'en';
	messages = {
		en: enMessage,
	}
} 
else{
	lang = 'vi';
	messages = {
		vi: viMessage,
	}
}

// export default createI18n({
//   locale: lang,
//   messages,
// });
const i18n = createI18n({
    locale: lang,
    messages: messages,
  });
 

export default i18n;