<template>
  <div>
    <div class="relative overflow-auto overflow-x-hidden ">
    <div class="relative w-auto bg-white px-4">
      <div class="flex h-max justify-between py-4 align-middle">
        <router-link
          to = "/register"
          class="flex h-max align-middle relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
          aria-label="Quay lại"
        >
          <svg
            class="h-7 w-7"
            height="1em"
            width="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
              fill="currentColor"
            />
          </svg>
        </router-link>
       
        
        <button @click="makePhoneCall"
          class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none border h-8 text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 text-md uppercase"
          type="button"
        >
          <span>{{$t('support.support')}}</span>
          <span class="text-md ml-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.3333 5.4165C13.9167 3.83317 12 2.9165 10 2.9165C8 2.9165 6.08333 3.83317 4.66667 5.4165C3.25 6.99984 2.5 9.08317 2.5 11.2498V14.5832C2.5 15.9998 3.58333 17.0832 5 17.0832C6.41667 17.0832 7.5 15.9998 7.5 14.5832V12.0832C7.5 10.6665 6.41667 9.58317 5 9.58317C4.75 9.58317 4.58333 9.58317 4.33333 9.6665C4.58333 8.49984 5.16667 7.33317 5.91667 6.49984C7 5.24984 8.5 4.58317 10 4.58317C11.5 4.58317 13 5.24984 14.0833 6.49984C14.8333 7.33317 15.4167 8.49984 15.6667 9.6665C15.4167 9.58317 15.25 9.58317 15 9.58317C13.5833 9.58317 12.5 10.6665 12.5 12.0832V14.5832C12.5 15.9998 13.5833 17.0832 15 17.0832C16.4167 17.0832 17.5 15.9998 17.5 14.5832V11.2498C17.5 9.08317 16.75 6.99984 15.3333 5.4165ZM5 11.2498C5.5 11.2498 5.83333 11.5832 5.83333 12.0832V14.5832C5.83333 15.0832 5.5 15.4165 5 15.4165C4.5 15.4165 4.16667 15.0832 4.16667 14.5832V12.0832C4.16667 11.5832 4.5 11.2498 5 11.2498ZM15.8333 14.5832C15.8333 15.0832 15.5 15.4165 15 15.4165C14.5 15.4165 14.1667 15.0832 14.1667 14.5832V12.0832C14.1667 11.5832 14.5 11.2498 15 11.2498C15.5 11.2498 15.8333 11.5832 15.8333 12.0832V14.5832Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </button>
      </div>
      <div class="relative flex items-center justify-center">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-50" />
        </div>
      </div>
    </div>  
   
    <hr class="border" />
   
   
  </div>
    <div class="space-y-2 bg-white shadow-100   space-y-4">
     
      <div class="bg-white shadow-100 p-4 pt-10 rounded-2xl  space-y-4" >
          <div className="space-y-4">
            <div class="flex justify-between space-x-2 font-semibold mt-2 flex flex-col items-center justify-center space-y-4">
                <div class="flex-shrink-0">{{$t('congratulations_only_one_last_tep.congratulations_only_one_last_tep')}}
                </div>
            </div>
            <div class="mt-2 flex flex-col items-center justify-center space-y-4">
                  <div class="relative mt-4  h-[126px] w-[126px]">
                    <div class="flex justify-center ring-4 ring-gray-25 aspect-square overflow-hidden rounded-full bg-white w-full">
                          <div>
                              <label for="file-upload" class="avatar_id">
                                  <img v-if="datauser.avatar" id="preview" class="show_image" :src="datauser.avatar" style="width: 100%; height:100%;">
                                  <img v-else id="preview" class="show_image" src="https://tse3.mm.bing.net/th?id=OIP.Ti0n8FNMFWVex0GGSewFWgHaFj&pid=Api&P=0&h=220" style="width: 100%; height:100%;">
                                  <img id="output" class="hidden" style="width: 100%; height:100%;">
                                  <!-- <img id="preview" class="show_image" src="https://tse3.mm.bing.net/th?id=OIP.Ti0n8FNMFWVex0GGSewFWgHaFj&pid=Api&P=0&h=220" style="width: 100%; height:100%;"> -->
   
                              </label>
                              <input type="file" class="hidden" id="file-upload" style="background: none;border: none;" name="user_avatar"  
                              @change="previewImage($event)"  >
                             
                          </div>
                          <div class="clear"></div>
                    </div>
                    </div>
                    <div class="bg-white p-4 w-full"><div class="space-y-2 !space-y-4 text-nm">
                                  
                                  <div class="flex justify-center space-x-2 font-semibold">
                                    <div class="flex-shrink-0 form-control" style="padding-top: 10px;display: inline;">{{$t('gender.gender')}}
                                        <div class="sex-group" style="padding-top: 10px;display: inline; padding-left: 10px;">
                                            <input type="radio" name="sex" id="male" class="css-checkbox" checked="checked" value="2" v-model="selectedSex" @change="changesex">
                                            <label for="male" class="css-label pl-2">{{$t('aff_male.aff_male')}}</label>
                                        </div>
                                        <div class="sex-group" style="padding-top: 10px;display: inline; padding-left: 10px;">
                                            <input type="radio" name="sex" id="female" class="css-checkbox" value="1" v-model="selectedSex" @change="changesex">
                                            <label for="female" class="css-label pl-2">{{$t('aff_female.aff_female')}}</label>
                                        </div>
                                    </div>
                                </div>

                                        <div>
                                            <label class="block text-normal font-medium mb-1 text-gray-800" for="date">{{$t('choose_dob.choose_dob')}}<span
                                              class="ml-0.5 text-red-500">*</span></label>
                                          <div class="relative inline-flex w-full">
                                            <input
                                              class="block w-full rounded-lg border border-gray-300 py-2 text-normal px-3 appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-100 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium focus:border-blue-600"
                                              name="date" placeholder="dd/mm/yyyy" id="date" type="date" v-model="birthday">
                                            
                                          </div>
                                          <p v-if="errors && errors.birthday " style="color: red;"> {{errors.birthday}}</p> 
                                        </div>
                                          <div>
                                            <div class="relative inline-flex w-full">
                                              <input
                                                class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-400 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-400 focus:border-primary-400"
                                                name="text"
                                                :placeholder="$t('number_cccd.number_cccd')"
                                                aria-invalid="false"
                                                id="full-name"
                                                v-model="nationalid"
                                              
                                              />
                                            </div>
                                            <p v-if="errors && errors.nationalid " style="color: red;"> {{errors.nationalid}}</p> 
                                          </div>
                                          <div>             
                                            <button class="relative w-full" type="button" fdprocessedid="5now7h">
                                                <div class="relative inline-flex w-full">
                                                    <select name="addressAreaLevel1" label="Chọn Tỉnh/Thành Phố"
                                                        class="form-select max-h-[200px] w-full cursor-default rounded-lg border bg-white py-0 pl-3 pr-10 text-left text-nm appearance-none border !ring-0 bg-gray-25 transition duration-200 focus:outline-none h-[48px] !text-gray-900 border-gray-200 border-transparent bg-[#F9FAFB] text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium placeholder-shown:border-transparent hover:border-gray-100 focus:border-blue-600"
                                                        id="addressAreaLevel1" 
                                                        :placeholder="$t('select_city.select_city')"
                                                        style="max-height: 200px; overflow-y: auto;"
                                                        v-model="selectProvinces"
                                                        @change="getDistricts1($event.target.value)">
                                                        <option v-if="selectProvinces" >{{selectProvinces}}</option>
                                                        <option v-else value="">{{$t('select_city.select_city')}}</option>
                                                        <option v-for="(area, index) in Provinces" :key="index" :value="area.name">{{ area.name }}</option>
                                                    </select>
                                                    <div class="absolute right-2 top-[50%] translate-y-[-50%]">
                                                      <svg class="text-3xl text-gray-300" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                          d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                                                          fill="currentColor" />
                                                      </svg>
                                                    </div>
                                                </div>
                                                <div class="absolute right-2 center-y">
                                                </div>
                                            </button>
                                            <p v-if="errors && errors.Provinces " style="color: red;"> {{errors.Provinces}}</p> 
                                          </div>
                                          <div >
                                            <button class="relative w-full" type="button" fdprocessedid="5now7h" >
                                              <div class="relative inline-flex w-full" >
                                                <select name="addressAreaLevel2" label="Chọn Quận/Huyện"
                                                    class="form-select max-h-[400px] w-full cursor-default rounded-lg border bg-white py-0 pl-3 pr-10 text-left text-nm appearance-none border !ring-0 transition duration-200 focus:outline-none h-[48px] !text-gray-900 bg-gray-25 border-gray-200 border-transparent bg-[#F9FAFB] text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium placeholder-shown:border-transparent hover:border-gray-100 focus:border-blue-600"
                                                    id="2area2" placeholder="Chọn khu vực"
                                                    style="max-height: 100px; overflow-y: auto;"
                                                    v-model="selectDistricts"
                                                    :disabled="!selectProvinces"
                                                    >
                                                    <!-- <option value="">Chọn Quận/Huyện</option> -->
                                                    <option v-if="selectDistricts" >{{selectDistricts}}</option>
                                                    <option v-else value="">{{$t('select_district.select_district')}}</option>
                                                    <option v-for="(District, index) in Districts" :key="index"  :value="District.name">{{ District.name }}</option>
                                                </select>
                                                <div class="absolute right-2 top-[50%] translate-y-[-50%]">
                                                  <svg class="text-3xl text-gray-300" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                                                      fill="currentColor" />
                                                  </svg>
                                                </div>
                                              </div>
                                            </button>
                                            <p v-if="errors && errors.Districts " style="color: red;"> {{errors.Districts}}</p>  
                                        </div>
                                        
                                  
                    </div>
                    <div class="pt-4">
                        <button  @click.prevent="submitlogin" class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none border h-10 w-full flex text-white bg-primary-400 border-primary-400  ">
                          <div v-if="isloading===true" data-v-3fcda164="" class="icon-inside-textarea">
                            <span data-v-3fcda164="" style="display: none;">Loading...</span>
                            <i data-v-3fcda164="" class="fa fa-spinner fa-spin"></i>
                          </div>
                          <p v-if="isloading===false"> {{$t('completed.completed')}}</p>  
                         
                        </button>
                    </div>
                </div>
            </div>
            <div class="space-y-2   text-center">
                <p class="text-gray-800 text-xs inline"> {{datashop.name}}</p>
                <p class="text-gray-800 text-xs inline">-  Hotline:   {{datashop.phone}}</p>
            </div>
          </div>
      </div>
    </div>
    
    
  </div>
</template>

<script>
// import { useNavigate } from "vue-router";
import {editprofilestep5}  from "@/services/customer/customerService";
import {getPositions} from "@/services/authentication/authService";
import { getLogin } from "../../../services/authentication/authService";
import {getProvinces,getDistricts,uploaduploadAvatar} from "@/services/affiliate/affiliate";
import { useToast } from "vue-toastification";
export default {
  name: "Registry_step5",
  data() {
    return{
      datashop:'',
      dataArea: [],
      selectedSex:2,
      birthday:'',
      nationalid:'',
      selectProvinces:'',
      selectDistricts:'',
      selectWards:'',
      Provinces:'',
      Districts:'',
      Positions:'',
      member_data:'',
      datauser:'',
      photo:'',
      isloading:false,
      Username1:'',
      errors:{
              nationalid:'',
              Provinces:'',
              Districts:'',
              birthday:'',
        },
    }
  },
  methods: {
    previewImage(event) {
      const _this= this;
        var input = event.target;
        var preview = document.getElementById('preview');

        if (input.files && input.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                preview.src = e.target.result;
                // console.log( preview.src);
                 _this.onUploadImage(preview.src);
            }
             reader.readAsDataURL(input.files[0]);
            // var formData = new FormData();
            // var imageFile =input.files[0]; 
            // formData.append('image', imageFile); 
            // formData.append('type', 'salekitio_5af6c8fb3803892dab2e3276');
            // this.onUploadImage(formData);
        }
    },
    async getosition(){
      if(!this.Username1 || (this.Username1 && this.Username1.length == 0)) return
      const response = await getPositions(this.Username1);
      
      console.log(response);
      this.Positions = response.data.data;
      this.member_data = JSON.parse(this.Positions.member_data);
      // console.log(this.member_data.price);
    },
    changesex(){
      // console.log( this.selectedSex);
      // if(this.selectedSex == 2){
      //   console.log( "nam");
      // }else{
      //   console.log( "nu");
      // }
    },
    async submitlogin() {
      this.isloading =true;
     if(!this.birthday){
        this.errors.birthday = 'Nhập lại ngày sinh';
        this.isloading =false;
        return;
     }
     else{
      this.errors.birthday = '';
     }
     if(!this.nationalid){
        this.errors.nationalid = 'Nhập số căn cước';
        this.isloading =false;
        return;
     }
     else{
      this.errors.nationalid = '';
     }
     if(!this.selectProvinces){
        this.errors.Provinces = 'Chọn tỉnh/thành phố';
        this.isloading =false;
        return;
     }
     else{
      this.errors.Provinces = '';
     }
     if(!this.selectDistricts){
        this.errors.Districts = 'Chọn quận/huyện';
        this.isloading =false;
        return;
     }
     else{
      this.errors.Districts = '';
     }
      const address = this.selectProvinces +','+ this.selectDistricts;
      console.log( this.selectedSex);
      const body = {
                        "address": address, 
                        "birthday": this.birthday,
                        "gender": this.selectedSex,
                        "card_id":this.nationalid
                       
                    };
        
        const response = await editprofilestep5(body,this.datauser.id_hash);
        console.log(response);
        if(response.status == 1 ){
          this.isloading =false;
          if(this.member_data.price === "0"){
            localStorage.removeItem("singup");
            localStorage.setItem('islogin',true);
            this.$router.push('/home');
          }else{
            this.isloading =false;
            this.$router.push('/platfromfee');
          }
        }else{
          this.isloading =false;
          console.log(response.data.msg);
        }
     
      // truoc dang nhap gui thong tin cap nhat thong tin tai khoan
      // if(this.member_data.price === "0"){
       
      // }
    },
     async onUploadImage(data) {
      const toast = useToast();
        try {
            const id_hash = this.datauser.id_hash;
            const body ={
              "photo":data
            }
            const result = await uploaduploadAvatar(body,id_hash)
            const { error, msg, name, url } = result.data
            console.log(result.data);
            if (!error) {
              this.photo = result.data.url + result.data.name;
                // console.log('url: ', url, name)
                return { url: `${url}${name}` }
            } else {
              console.log(msg);
              toast.error(this.$t('error_pls_try_again.error_pls_try_again'))
            }
        } catch (error) {
            console.log(error);
            toast.error(this.$t('error_pls_try_again.error_pls_try_again'))
        }
    },

    async getdata(){
      this.datashop = JSON.parse( localStorage.getItem("getshop")) ;
      const data = JSON.parse(localStorage.getItem('singup'));
      const Username = localStorage.getItem('Username');
      this.Username1 = Username;
      const body ={
                  email:data.email,
                  password: data.password,
                  Username:Username
                };
        //  console.log(body);
          const response = await getLogin(body);
          //  console.log(response)
          if(response.status == 1){
            this.datauser = response.data;
            this.datauser.avatar = "https://salekit.net/avatar/"+response.data.id+".png"
            // console.log( this.datauser.avatar)
            localStorage.setItem('datauserInfo', JSON.stringify( this.datauser));
            // console.log(response);
          }else{
            console.log(response.data.msg);
          }
    },
    async getProvinces1() {
      const response = await getProvinces();
      if(response && response.data.data){
            this.Provinces = response.data.data;
      }
    },
    async getDistricts1(id) {
        // console.log("co goi");
        const test = this.Provinces.find(item => item.name === id).id;
        const response = await getDistricts(test);
        if(response && response.data.data){
                this.Districts = response.data.data;
        }
    },
  },
  async  created() {
    this.getProvinces1();
    await this.getdata();
     this.getosition();
   // console.log('Data from Step3:', this.formData);
  }
};
</script>

<!-- Add scoped styles here -->
