// import axios from "axios";
import axiosInstance from "@/axios";
const axios = axiosInstance;
import Config from "@/components/config";

const BASE_URL = Config.BASE_URL;
const BASE_URL_Node = Config.BASE_URL_Node;
// const token1 = `eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDIzLTEyLTE4VDE2OjMyOjM3KzA3MDAiLCJzaG9wX2lkIjoiODZlNzg0OTllZWIzM2ZiOWNhYzE2Yjc1NTViNTA3NjcifQ.bUI5cTy8t5mtiRQ6cXcLZWDfp-uHEpAz90mg8UuOJQE`;
let datashop =  JSON.parse(localStorage.getItem('getshop'));
// const token = datashop.token;
// const config = {
//   headers: {
//     "Content-Type": "application/json",
//     Token: token,
//   },
// };
let datauserInfo =  localStorage.getItem('datauserInfo');
if(datauserInfo){
  datauserInfo = JSON.parse(datauserInfo)
}

let token;
let config;
if(datashop){
  token = datashop.token;
  config = {
  headers: {
    "Content-Type": "application/json",
    Token: token
  }
};
}else{
  setTimeout(() => {
    datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
    config = {
      headers: {
        "Content-Type": "application/json",
        Token: token
      }
    };
  }, 2000);
}
const getdetailproduct = async(id) =>{
  try{
   
      config = {
        headers: {
          "Content-Type": "application/json",
          "Token": datashop.token,
        }
      };
      // console.log(config);
    const response = await axios.get(`${Config.BASE_URL_Node}/product/detail?id=${id}`, config);
    return response;
    
  }catch(error){
    console.log("error",error);
  }
};
const searchproduct = async(type,key)=>{
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token
      }
    };
    const params = {
      "type": type ?? "name",
      "key": key
    };
    return await axios.get(`${Config.BASE_URL_Node}/product/find`, { 
      params: params,
      headers: config.headers
    });
   

};
const calculatorDiscount = async(body) =>{
  try{
    const config1 = {
      headers: {
        'Content-Type': 'application/json',
        'Token': datashop.token
      }
    };
    const response = await axios.post(`${Config.BASE_URL_Node}/coupon/calculate`,body, config1);
    return response;
  }catch(error){
    console.log("error",error);
  }
};

const getcombo = async() => {
  try{
    if(datashop){
      // console.log("44",datashop);
    const config1 = {
      headers: {
        'Content-Type': 'application/json',
        'Token': token,
      }
    };
    const response = axios.get(`${BASE_URL_Node}/combo/list`, config1);
    return response;
  }
  else{
    const response = {
        error: true,
        message: "not token"
    };
  return response;
  }
  }catch(error){
    console.log(error);
  }
};
const getStores = async(id) =>{
  try{
    const config1 = {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token
      },
    };
    const response = axios.get(`${Config.BASE_URL_Node}/store/combo?id=`+id, config1);
    return response;
  }catch(error){
    console.log(error);
  }
};
const getStoreList = async() =>{
  try{
    const config1 = {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token,
      },
    };
    const response = axios.get(`${BASE_URL_Node}/store/list`, config1);
    return response;
  }catch(error){
    console.log(error);
  }
};
const getStoresproduct = async(id) =>{
  try{
    if(datashop){
      const config1 = {
        headers: {
          "Content-Type": "application/json",
          "Token": token
        },
      };
      const response = axios.get(`${Config.BASE_URL_Node}/store/product?id_product=`+id, config1);
      return response;
    }else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      const config1 = {
        headers: {
          "Content-Type": "application/json",
          "Token": token
        },
      };
      const response = axios.get(`${Config.BASE_URL_Node}/store/product?id_product=`+id, config1);
      return response;
    }
    
   
  }catch(error){
    console.log(error);
  }
};

const getAllProduct = (page,limit) => {
  try {
    if(datashop){
      let page1 = page;
      let limit1 = limit;
      if(!limit1){
        limit1 = 10;
      }
      if(!page1){
        page1 = 1;
      }
      let config1
         config1={
          headers: {
          "Content-Type":"application/json",
          "Token": datashop.token,
          "page":page1,
          "limit":limit1
          },
      };
      
      
    const response = axios.get(`${Config.BASE_URL_Node}/product/list?limit=${limit1}&page=${page1}`, config1);
    return response;
    }else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      let page1 = page;
      let limit1 = limit;
      if(!limit1){
        limit1 = 10;
      }
      if(!page1){
        page1 = 1;
      }
      let config1
         config1={
          headers: {
          "Content-Type":"application/json",
          "Token": datashop.token,
          "page":page1,
          "limit":limit1
          },
      };
      
      
    const response = axios.get(`${Config.BASE_URL_Node}/product/list?limit=${limit1}&page=${page1}`, config1);
    return response;
   
    }
  } catch (error) {
    console.error("Error fetching products:", error.message);
  }
};
const getAllCourses = () => {
  try {
    if(datashop){
      const config1={
        headers: {
        "Content-Type":"application/json",
        "Token": datashop.token,
        },
    };
    const response = axios.get(`${Config.BASE_URL_Node}/product/courses`, config1);
    return response;
    }else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      const config1={
        headers: {
        "Content-Type":"application/json",
        "Token": datashop.token,
        },
    };
    const response = axios.get(`${Config.BASE_URL_Node}/product/courses`, config1);
   
    return response;
    }
  } catch (error) {
    console.error("Error fetching products:", error.message);
  }
};
const getCoursesDetail = (id) => {
  try {
    if(datashop){
      const config1={
        headers: {
        "Content-Type":"application/json",
        "Token": datashop.token,
        },
    };
    const response = axios.get(`${Config.BASE_URL_Node}/product/coursesdetail?id=${id}`, config1);
    return response;
    }else{
      const response = {
          error: true,
          message: "not token"
      };
    return response;
    }
  } catch (error) {
    console.error("Error fetching products:", error.message);
  }
};

const getProductById = (id) => {
  try {
    if(datashop){
    const response = axios.get(`${BASE_URL}/dropship/productDetail`, {
      config,
      params: { id },
    });
    return response;
  }
  else{
    const response = {
        error: true,
        message: "not token"
    };
  return response;
  }
  } catch (error) {
    console.error("Error fetching products:", error.message);
  }
};

const getCategory = () => {
  try {
    let response = '';
    if(datashop){
      response = axios.get(
        `${BASE_URL_Node}/product/category`,
        {
          headers: {
            "Content-Type": "application/json",
            "Token":
              datashop.token,
          },
        }
      );
    }else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      response = axios.get(
        `${BASE_URL_Node}/product/category`,
        {
          headers: {
            "Content-Type": "application/json",
            "Token":
              datashop.token,
          },
        }
      );
    }
    
    return response;
  } catch (error) {
    console.error("Lỗi khi gọi API:", error);
  }
};

export { getAllProduct,getAllCourses,getCoursesDetail, getProductById,getStores,getStoreList ,
  getcombo ,getdetailproduct,calculatorDiscount,getStoresproduct, getCategory,searchproduct};
