<template>
  <div class="space-y-2 px-0 py-0">
    <div class="mx-auto max-w-[250px] pt-20">
      <div class="flex flex-col items-center text-center">
        <picture>
          <source srcset="img/package-done.png" type="image/webp" />
          <img alt="" src="img/package-done.png" height="100" width="100" />
        </picture>
        <p class="mb-7 whitespace-pre-line text-gray-600">
          {{$t('create_customers_new.create_customers_new')}}
        </p>
        <button @click="this.$router.push('/customer/new')"
          class="px-3 transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex rounded-full bg-primary-400 text-white hover:bg-primary-300"
          type="button">
          {{$t('add_customers.add_customers')}}
        </button>
      </div>
    </div>
    <div class="space-y-2"></div>
    <div class="relative">
      <div class="absolute" :style="{ top: '0px' }"></div>
      <div></div>
    </div>
    <div class="relative z-50">
      <div class="fixed inset-x-0 bottom-0 translate-x-0 transform sm:container"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoCustomer',
};
</script>

<style scoped>
/* Thêm CSS tùy chỉnh tại đây nếu cần */
</style>
