<template>
  <div>
    <div class="fixed inset-0 z-50 w-[480px] mx-auto " id="headlessui-dialog-:r1h:" role="dialog" aria-modal="true"
      data-headlessui-state="open" aria-labelledby="headlessui-dialog-title-:r1j:">
      <div class="sm:relative sm:h-full sm:translate-x-0 sm:transform sm:container">
        <div class="fixed inset-0 bg-black bg-opacity-50 opacity-100" id="headlessui-dialog-overlay-:r1i:"
          aria-hidden="true" data-headlessui-state="open"></div>
        <div
          class="modal-province fixed flex flex-col overflow-hidden rounded-2xl bg-white inset-x-4 bottom-8 translate-y-0"
          style="max-height: 670px;">
          <header class="relative shrink grow-0 basis-[1%] bg-white p-4 shadow-[inset_0px_-1px_0px_#E5EBED]">
            <h3 class="text-center w-full font-bold leading-snug text-gray-900" id="headlessui-dialog-title-:r1j:"
              data-headlessui-state="open">Chọn Tỉnh/Thành Phố</h3>
            <button
              class="right-4 !text-3xl top-[50%] translate-y-[-50%] absolute rounded-md text-md h-10 w-10 focus:outline-none cursor-pointer"
              type="button" aria-label="Thoát" tabindex="0" @click="onCloseModalProvince">
              <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.63615 18.3641C6.02668 18.7546 6.65984 18.7546 7.05037 18.3641L12.0001 13.4143L16.9499 18.3641C17.3404 18.7546 17.9736 18.7546 18.3641 18.3641C18.7546 17.9736 18.7546 17.3404 18.3641 16.9499L13.4143 12.0001L18.3641 7.05037C18.7546 6.65984 18.7546 6.02668 18.3641 5.63615C17.9736 5.24563 17.3404 5.24563 16.9499 5.63616L12.0001 10.5859L7.0504 5.63617C6.65987 5.24565 6.02671 5.24565 5.63618 5.63617C5.24566 6.0267 5.24566 6.65986 5.63618 7.05039L10.5859 12.0001L5.63615 16.9499C5.24563 17.3404 5.24563 17.9736 5.63615 18.3641Z"
                  fill="currentColor" />
              </svg>
            </button>
          </header>
          <div class="shrink grow-0 basis-[1%] overflow-auto px-4">
            <div>
              <div class="relative inline-flex h-10 w-full rounded-md bg-gray-25 flex-none my-3">
                <div class="absolute flex h-10 w-10 flex-none items-center justify-center text-xl text-gray-300"
                  aria-hidden="true">
                  <svg class="h-[18px] w-[18px] text-gray-600" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M17.3117 17.3433C17.7806 16.8777 18.5311 16.8743 19.0039 17.3333L21.5513 19.3567C22.1496 19.9614 22.1496 20.9407 21.5513 21.5454C20.9516 22.1515 19.9782 22.1515 19.3785 21.5454L17.3047 19.1693C17.0672 18.927 16.9343 18.6001 16.9343 18.2599C16.9343 17.9187 17.0713 17.5854 17.3117 17.3433Z"
                      fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10.6123 2C5.85342 2 2 5.89865 2 10.7024C2 15.5061 5.85342 19.4047 10.6123 19.4047C15.3713 19.4047 19.2247 15.5061 19.2247 10.7024C19.2247 8.3952 18.3179 6.182 16.7031 4.54979C15.0882 2.9175 12.8973 2 10.6123 2ZM3.95122 10.7024C3.95122 6.9567 6.95053 3.95122 10.6123 3.95122C12.373 3.95122 14.0654 4.65802 15.316 5.92211C16.5673 7.18683 17.2735 8.906 17.2735 10.7024C17.2735 14.448 14.2741 17.4535 10.6123 17.4535C6.95053 17.4535 3.95122 14.448 3.95122 10.7024Z"
                      fill="currentColor" />
                  </svg>
                </div>
                <input autocomplete="off"
                  class="h-10 w-full rounded-md pl-10 pr-8 appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-300 bg-white text-gray-900 placeholder:text-gray-400 placeholder:font-medium focus:border-primary-400"
                  placeholder="Tìm tỉnh hoặc thành phố" @input="searchProvince" />
                <div class="absolute right-0 flex h-10 w-10 flex-none items-center justify-center text-gray-800"></div>
              </div>
              <div class="relative flex items-center justify-center mt-2">
                <div class="absolute inset-0 flex items-center">
                  <div class="w-full border-t border-gray-300"></div>
                </div>
              </div>
            </div>
            <ul v-if="listProvinces.length && !listSearchProvinces.length">
              <li v-for="(province, index) in listProvinces" :key="index">
                <button class="flex w-full items-center justify-between border-t border-gray-100 font-medium py-4"
                  @click="() => onChangeProvince({ id: province.id, name: province.name })">
                  {{ province.name }}
                  <!-- <svg
                    class="text-3xl text-primary-400"
                    width="1em"
                    height="1em"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M19.6987 6.78456C20.0938 7.17043 20.1013 7.80355 19.7155 8.19867L9.94984 18.1987C9.75306 18.4002 9.48044 18.5093 9.19897 18.4994C8.91751 18.4894 8.6533 18.3612 8.47128 18.1463L4.23691 13.1463C3.87998 12.7248 3.9323 12.0938 4.35376 11.7369C4.77521 11.38 5.40621 11.4323 5.76314 11.8537L9.28704 16.0148L18.2846 6.80133C18.6704 6.4062 19.3036 6.39869 19.6987 6.78456Z"
                      fill="currentColor"
                    />
                  </svg>-->
                </button>
              </li>
            </ul>
            <ul v-else-if="listSearchProvinces.length">
              <li v-for="(province, index) in listSearchProvinces" :key="index">
                <button class="flex w-full items-center justify-between border-t border-gray-100 font-medium py-4"
                  @click="() => onChangeProvince({ id: province.id, name: province.name })">{{
                    province.name }}</button>
              </li>
            </ul>
            <div v-else class="flex flex-col items-center text-center py-10">
              <picture>
                <source srcset="../../../public/search-product.png" type="image/webp" />
                <img alt src="../../../public/search-product.png" height="100" width="100" />
              </picture>
              <p class="mb-7 whitespace-pre-line text-gray-600">Không tìm thấy kết quả nào</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getProvinces } from "@/services/affiliate/affiliate";
export default {
  name: "ModalProvince",
  props: {
    onCloseModalProvince: Function,
    onChangeProvince: Function,
    provinceName: String
  },
  data() {
    return {
      listProvinces: [],
      listSearchProvinces: [],
      pronvinceChecked: ""
    };
  },
  methods: {
    normalizeString(str) {
      return str
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
    },
    searchProvince(ev) {
      const provinceKeyword = ev.target.value;
      if (provinceKeyword) {
        this.listSearchProvinces = this.listProvinces.filter(province =>
          this.normalizeString(province.name).includes(
            this.normalizeString(provinceKeyword)
          )
        );
      } else {
        this.listSearchProvinces = this.listProvinces;
      }
    }
  },
  async created() {
    try {
      await getProvinces()
        .then(res => {
          this.listProvinces = res.data.data;
        })
        .catch(err => console.log(err));
    } catch (error) {
      throw new Error(error);
    }
  },
};
</script>
<style scoped>
@keyframes slide-up {
  to {
    bottom: 2rem;
  }
}

.modal-province {
  bottom: -100%;
  animation: slide-up 0.4s ease forwards;
}
</style>