<template>
  <div class="sm:min-h-screen sm:container relative">
    <div class="flex justify-center">
      <div class=" inset-0 overflow-y-auto overflow-x-hidden bg-white sm:container">
        <header class="flex justify-center pb-10 pt-6 px-4 max-w-md mx-auto w-full relative">
          <div class="absolute left-4 h-auto w-auto rounded-full bg-gray-25 p-1">
            <button @click="this.$router.push('/registry')"
              class="relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
              type="button">
              <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M15.7071 5.29289C15.3166 4.90237 14.6834 4.90237 14.2929 5.29289L8.29289 11.2929C7.90237 11.6834 7.90237 12.3166 8.29289 12.7071L14.2929 18.7071C14.6834 19.0976 15.3166 19.0976 15.7071 18.7071C16.0976 18.3166 16.0976 17.6834 15.7071 17.2929L10.4142 12L15.7071 6.70711C16.0976 6.31658 16.0976 5.68342 15.7071 5.29289Z"
                  fill="currentColor"></path>
              </svg>
            </button>
          </div>
          <div style="width: 50%;height: 10%;">
            <img src="https://salekit.com/assets/landing/images/logo/salekit_logo.png" alt="Salekit.io"
              class="img-fluid fixed-only">
          </div>
        </header>
        <div class="px-4 max-w-md mx-auto w-full">
          <div class="max-h-[calc(100vh-240px)] overflow-auto">
            <p class="text-[26px] font-bold text-gray-1100">Trở thành đối tác kinh doanh với Salekit</p>
            <p class="text-gray-600 font-semibold">Vui lòng để lại thông tin đăng ký để Salekit có thể liên hệ và hướng
              dẫn với bạn.
            </p>
            <form class="mt-6 flex flex-col gap-4">
              <div>
                <label class="block text-normal font-medium mb-1 text-gray-800" for="name">
                  Họ và tên
                  <span class="ml-0.5 text-red-500">*</span>
                </label>
                <div class="relative inline-flex w-full">
                  <input
                    class="block w-full rounded-lg border border-gray-300 py-2 text-nm pl-3 pr-3 pr-3  h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-100 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium focus:border-blue-600"
                    name="name" placeholder="Nhập họ và tên" id="name">
                </div>
              </div>
              <div><label class="block text-normal font-medium mb-1 text-gray-800" for="phone">Số điện thoại<span
                    class="ml-0.5 text-red-500">*</span></label>
                <div class="relative inline-flex w-full"><input
                    class="block w-full rounded-lg border border-gray-300 py-2 text-nm pl-3 pr-3 pr-3  h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-100 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium focus:border-blue-600"
                    name="tel" placeholder="Nhập số điện thoại" id="phone"></div>
              </div>
              <div><label class="block text-normal font-medium mb-1 text-gray-800" for="email">Email<span
                    class="ml-0.5 text-red-500">*</span></label>
                <div class="relative inline-flex w-full"><input
                    class="block w-full rounded-lg border border-gray-300 py-2 text-nm pl-3 pr-3 pr-3  h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-100 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium focus:border-blue-600"
                    name="email" placeholder="Nhập email" id="email"></div>
              </div>
              <div><label class="block text-normal font-medium mb-1 text-gray-800" for="message">Lời nhắn của bạn<span
                    class="ml-0.5 text-red-500">*</span></label>
                <div class="relative inline-flex w-full"><input
                    class="block w-full rounded-lg border border-gray-300 py-2 text-nm pl-3 pr-3 pr-3  h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-100 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium focus:border-blue-600"
                    name="note" placeholder="Khung giờ bạn muốn nhận cuộc gọi tư vấn" id="message"
                    label="Lời nhắn của bạn"></div>
              </div>
              <div><label class="block text-normal font-medium mb-1 text-gray-800" for="date">Ngày sinh<span
                    class="ml-0.5 text-red-500">*</span></label>
                <div class="relative inline-flex w-full">
                  <input
                    class="block w-full rounded-lg border border-gray-300 py-2 text-normal px-3 appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-100 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium focus:border-blue-600"
                    name="date" placeholder="dd/mm/yyyy" id="date" type="date">

                </div>
              </div>
              <div>
                <label class="block text-normal font-medium text-gray-800 mb-1" for="job">Nghề nghiệp<span
                    class="ml-0.5 text-red-500">*</span></label>
                <div>
                  <select name="job" label="Nghề nghiệp"
                    class="form-select w-full cursor-default rounded-lg border bg-white py-0 pl-3 pr-10 text-left text-nm appearance-none border !ring-0 transition duration-200 focus:outline-none h-[48px] !text-gray-900 border-transparent bg-[#F9FAFB] text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium placeholder-shown:border-transparent hover:border-gray-100 focus:border-blue-600"
                    dir="auto" id="job">
                    <option value="">Chọn nghề nghiệp</option>
                    <option v-for="(job, index) in dataJob" :key="index">{{ job }}</option>
                  </select>
                </div>
              </div>

              <div>
                <label class="block text-normal font-medium text-gray-800 mb-1" for="area">Khu vực
                  <span class="ml-0.5 text-red-500">*</span>
                </label>
                <select name="addressAreaLevel1" label="Khu vực"
                  class="form-select w-full cursor-default rounded-lg border bg-white py-0 pl-3 pr-10 text-left text-nm appearance-none border !ring-0 transition duration-200 focus:outline-none h-[48px] !text-gray-900 border-gray-200 border-transparent bg-[#F9FAFB] text-gray-1100 text-gray-900 placeholder:text-gray-300 placeholder:font-medium placeholder-shown:border-transparent hover:border-gray-100 focus:border-blue-600"
                  id="area" placeholder="Chọn khu vực">
                  <option value="">Chọn khu vực</option>
                  <option v-for="(area, index) in dataArea" :value="area" :key="index">{{ area }}</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <footer class="w-full absolute bottom-0 left-0 right-0">
          <footer class="inset-x-0 bottom-0">
            <div
              class="flex h-[72px] items-center border-t border-gray-200 bg-white px-4 sm:container flex w-full justify-center">
              <button
                class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300 max-w-md"
                type="submit">
                Gửi yêu cầu
              </button>
            </div>
          </footer>
        </footer>
      </div>
    </div>

  </div>
</template>
<script>
// import { ref } from 'vue';
// import { useAuth } from "../../../context/AuthContext";
// import { getLogin } from "../../../services/authentication/authService";
// import { useRouter } from 'vue-router';

// const router = useRouter();
import axios from 'axios';
export default {
  name: "Register_partner",
  data() {
    return {
      Hidencalendar: false,
      dataArea: [],
      dataJob: [
        'Bác sĩ/Dược sĩ/Y tế',
        'Giáo viên/Giáo dục/Ngành đào tạo',
        'Nội trợ/Mẹ bỉm',
        'Nhân viên tài chính/Tư vấn bảo hiểm/Bất động sản',
        'Nhân viên văn phòng, Nhân viên hành chính',
        'Tài xế/Shipper',
        'Kinh doanh/Kinh doanh online/Buôn bán',
        'Công nhân hoặc xây dựng',
        'Khác',
      ]
    };
  },
  methods: {
    birthday() {
      event.preventDefault();
      this.Hidencalendar = true;
    },
    exitbirthday() {
      event.preventDefault();
      this.Hidencalendar = false;
    },

    updatePassword() {

    },
    toggleShowPassword() {
      // Đặt logic để hiển thị hoặc ẩn mật khẩu ở đây nếu cần
    },
    //Lấy dữ liệu khu vực việt nam để render ra select tag
    getDataArea() {
      const apiDataAreaUrl = "https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json"
      axios.get(apiDataAreaUrl)
        .then(res => {
          const removeKeyWord = res.data.map(data => data.Name.replace("Tỉnh", ""))
          console.log(removeKeyWord)
          this.dataArea = removeKeyWord.sort();
        })
    },
  },
  mounted() {
    this.getDataArea();
    console.log("Component mouted")
  },
};
</script>