<template>
    <div class="">

        <div class="sm:min-h-screen sm:container pb-16" id="main-container">

            <header class="sticky inset-x-0 top-0 z-20 w-full sm:w-[480px]">
                <div class="border-b border-gray-25 bg-white sm:container bg-white">
                    <div class="relative flex h-16 flex-col justify-center pr-14 h-[64px] pl-12">
                        <h5 class="!font-bold leading-snug font-semibold text-gray-900 text-lg truncate">{{ $t('create_order.create_order') }}
                        </h5>
                        <router-link to="/cart"
                            class="!absolute left-2 center-y relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                            type="button" aria-label="Trở về"><svg class="h-6 w-6" height="1em" width="1em"
                                viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
                                    fill="currentColor"></path>
                            </svg></router-link>
                    </div>
                </div>
            </header>
            <main class="bg-gray-10 sm:min-h-screen sm:container">
                <form class="space-y-2 bg-gray-25" novalidate="">
                    <div class="bg-white p-4 space-y-3">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl">
                                <svg class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19.7858 7.08301L13.8102 2.59727C12.7148 1.77495 11.2099 1.80445 10.1467 2.6691L4.12227 7.5684C3.41279 8.14537 3 9.01739 3 9.93921V18.9601C3 20.639 4.34315 22 6 22L8 22H10H14H16L18 22C19.6569 22 21 20.639 21 18.9601V9.52566C21 8.56251 20.5496 7.65634 19.7858 7.08301ZM16 19.9734H18C18.5523 19.9734 19 19.5197 19 18.9601V9.52566C19 9.20461 18.8499 8.90255 18.5953 8.71144L12.6197 4.2257C12.2546 3.95159 11.7529 3.96143 11.3985 4.24965L5.37409 9.14894C5.1376 9.34126 5 9.63194 5 9.93921V18.9601C5 19.5197 5.44772 19.9734 6 19.9734H8V16.9335C8 14.6949 9.79086 12.8803 12 12.8803C14.2091 12.8803 16 14.6949 16 16.9335V19.9734ZM10 19.9734H14V16.9335C14 15.8142 13.1046 14.9069 12 14.9069C10.8954 14.9069 10 15.8142 10 16.9335V19.9734Z"
                                        fill="currentColor"></path>
                                </svg> {{ $t('salekit_warehouse.salekit_warehouse') }}
                            </h5>
                            <!-- <button
                                class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-primary-400 hover:text-primary-300 -mr-4"
                                type="button">
                                <span class="">Thay đổi</span>
                                <span class="text-md ml-2"><svg width="1em"
                                        height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                                            fill="currentColor"></path>
                                    </svg></span></button> -->
                        </div>
                        <div v-for="(item, index) in orderItems" :key="index">
                            <!-- Kiểm tra xem index là 0 hoặc thông tin kho của mục hiện tại khác với thông tin kho của mục trước đó -->
                            <div v-if="index === 0 || orderItems[index - 1].store.id !== item.store.id">
                                <div class="flex items-center gap-3 rounded-xl bg-gray-10 px-4 py-3">
                                    <div
                                        class="inline-flex items-center justify-center rounded-full border-2 border-gray-400 h-6 w-6 radio-check border-primary-400 bg-primary-400">
                                    </div>
                                    <b class="font-semibold text-gray-1100">{{ item.store.name }}</b>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="bg-white p-4 space-y-3" id="order-form-product-section">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl"><svg
                                    class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M12.3162 2.05402C12.111 1.98199 11.889 1.98199 11.6838 2.05402L2.68377 5.21191C2.27543 5.35519 2 5.75744 2 6.21053V16.7368C2 17.1528 2.23273 17.5298 2.59386 17.6987L11.5939 21.9093C11.8524 22.0302 12.1476 22.0302 12.4061 21.9093L21.4061 17.6987C21.7673 17.5298 22 17.1528 22 16.7368V6.23509C22.0019 6.14762 21.9935 6.05879 21.9737 5.97044C21.961 5.91344 21.9439 5.85802 21.9227 5.80466C21.8483 5.61671 21.7268 5.46215 21.5778 5.35128C21.5182 5.30687 21.4534 5.26896 21.3843 5.23871C21.3584 5.22733 21.332 5.21709 21.3051 5.20802L12.3162 2.05402ZM17.8377 6.21053L12 4.1622L10.9123 4.54386L16.6325 6.55094C16.6704 6.56424 16.7077 6.57865 16.7444 6.59414L17.8377 6.21053ZM6.16228 6.21053L7.75 5.65343L13.5877 7.70176L12 8.25885L6.16228 6.21053ZM13 10.1271L16 9.07448L16 17.7895C16 17.8339 16.0026 17.8776 16.0077 17.9205L13 19.3276V10.1271ZM11 10.1271L4 7.67097V16.0528L11 19.3276V10.1271ZM20 16.0528L18 16.9884V8.54817C18 8.49002 17.9977 8.43229 17.9933 8.37509L20 7.67097V16.0528Z"
                                        fill="currentColor"></path>
                                </svg> {{ $t('products.products') }}</h5>
                        </div>
                        <div class="divide-y divide-gray-50" v-for="(item, index) in orderItems" :key="index">

                            <div class="bg-white space-y-3 py-3" discount="20.5">
                                <div class="flex items-start space-x-3">
                                    <div class="w-[94px] flex-none">
                                        <div class="relative h-0" style="padding-bottom: 100%;">
                                            <div
                                                class="absolute inset-0 h-full w-full rounded-lg animate-pulse bg-gray-800 bg-opacity-10">
                                            </div>
                                            <img v-if="item.product.img" :alt="item.product.name"
                                                class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg"
                                                :src="item.product.img">
                                            <img v-else-if="item.product.photo" :alt="item.product.name"
                                                class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg"
                                                :src="item.product.photo">
                                                <img v-else-if="item.product.image" :alt="item.product.name"
                                                class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg"
                                                :src="item.product.image">
                                            <img v-else :alt="item.product.name"  class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-lg" src="https://salekit.net/assets/images/no-image.png"  />
                                        </div>
                                    </div>
                                    <div class="space-y-1.5">
                                        <h6 class="text-nm font-semibold text-gray-1100 line-clamp-2">{{
                                            item.product.name
                                            }}</h6>
                                        <div class="flex flex-wrap items-center text-nm">
                                            <b class="font-semibold text-primary-400">{{
                                                formatNumber(item.total)
                                                }}₫</b>
                                            <!-- <b
                                                class="font-semibold text-primary-400">₫{{
                formatNumber(item.product.price)
                                                }}</b> -->
                                            <span class="mx-1 text-gray-50">|</span>
                                            <p class="text-gray-1100">SL: {{ item.quantity }}</p>
                                        </div>
                                        <div v-if="item.profit" class="flex flex-wrap items-center text-nm"><span
                                                class="text-gray-1100">LN:
                                                {{ formatNumber(item.profit)
                                                }}₫</span><span class="mx-1 text-gray-50">|</span>
                                            <!-- <p class="text-gray-1100">Points: 158,730</p> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="pl-8"></div>
                                <div class="relative ml-[108px] flex flex-col space-y-1">
                                    <div class="flex items-center space-x-6">
                                        <div class="inline-flex">
                                            <button @click="decreaseQuantity(item.product.id, item.store.id)"
                                                class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                                                type="button" aria-label="Giảm">
                                                <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </button>
                                            <input v-model="item.quantity" disabled
                                                class="h-10 w-10 border-none bg-transparent p-0 text-center font-bold text-gray-900 focus:outline-none h-10 w-10"
                                                max="4243" min="0" value="1">
                                            <button @click="increaseQuantity(item.product.id, item.store.id)"
                                                class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                                                type="button" aria-label="Thêm">
                                                <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                                        d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
                                                        fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                        <button @click.prevent="deleteItem(item.product.id, item.store.id)"
                                            class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-red-600 hover:text-gray-600"
                                            type="button">{{ $t('delete.delete') }} </button>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button
                            class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none border h-10 w-full flex text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300"
                            type="button"><span class="" @click.prevent="openModalProduct">{{ $t('add_product.add_product') }}</span><span
                                class="text-lg ml-2"><svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
                                        fill="currentColor"></path>
                                </svg></span></button>
                    </div>
                    <div class="w-full"></div>

                    <div class="bg-white p-4 space-y-3">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl"><svg
                                    class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M16.5487 12.1637L12.0455 18.7203L7.50811 12.1855C6.76428 10.7087 6.68438 8.94769 7.29099 7.40024C9.04849 2.91683 14.9513 2.90133 16.7347 7.38581C17.3475 8.92687 17.2791 10.6851 16.5487 12.1637ZM18.1983 13.1099L13.1142 20.8423L13.1135 20.8438L13.0642 20.9436L12.5873 21.6568C12.3503 22.1366 11.713 22.1391 11.4723 21.6613L11.0438 20.9518L10.9937 20.8523L10.993 20.8509L5.86579 13.1451C4.85099 11.1302 4.74198 8.7277 5.56957 6.61652C7.96992 0.493191 16.0219 0.482173 18.4522 6.59367C19.2878 8.69494 19.1949 11.0925 18.1983 13.1099ZM12.9778 9.38272C12.9791 9.9386 12.5619 10.3903 12.0459 10.3915C11.5299 10.3928 11.1105 9.94318 11.1092 9.3873C11.1078 8.83142 11.525 8.37976 12.041 8.3785C12.557 8.37723 12.9764 8.82684 12.9778 9.38272ZM14.8463 9.37814C14.8504 11.0458 13.5988 12.4008 12.0509 12.4045C10.5029 12.4083 9.24469 11.0595 9.2406 9.39187C9.23652 7.72423 10.4881 6.36926 12.0361 6.36547C13.5841 6.36168 14.8423 7.7105 14.8463 9.37814Z"
                                        fill="currentColor"></path>
                                </svg> {{ $t('customer_info.customer_info') }}</h5>

                            <button v-if="customerAddress"
                                class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none bg-transparent text-sm w-fit h-fit text-primary-400 hover:text-primary-300 !p-0"
                                type="button"><span class="" @click.prevent="openModalAddress">{{ $t('change.change') }}</span><span
                                    class="text-md ml-2"><svg width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                                            fill="currentColor"></path>
                                    </svg></span></button>
                        </div>
                        <button @click.prevent="openModalAddress" v-if="!customerAddress"
                            class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none border h-10 w-full flex text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300"
                            type="button"><span class="">{{ $t('add_delivery_address.add_delivery_address') }}</span><span class="text-lg ml-2"><svg
                                    width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z"
                                        fill="currentColor"></path>
                                </svg></span></button>
                        <!-- <div class="space-y-1.5 rounded-xl border border-gray-25 bg-gray-10 px-3 py-2"
                            v-for="(address, index) in customerAddress" :key="index">

                            <p class="text-nm font-semibold text-gray-1100">{{ address.name }} <span
                                    class="mx-1 text-gray-50">|</span>
                                {{
                                    address.phone }}</p>
                            <p class="text-nm text-gray-1100">{{ address.full_address }}</p>
                        </div> -->
                        <div v-else class="space-y-1.5 rounded-xl border border-gray-25 bg-gray-10 px-3 py-2">

                            <p class="text-nm font-semibold text-gray-1100">{{ customerAddress.name }} <span
                                    class="mx-1 text-gray-50">|</span>
                                {{
                                    customerAddress.phone }}</p>
                            <p class="text-nm text-gray-1100">{{ customerAddress.fulladdress }}</p>
                        </div>
                    </div>
                    <div v-if="shippingList && shippingList.length > 0" class="bg-white p-4 space-y-3" id="form-shippingMethod">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl"><svg
                                    class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2 5.99805C2 4.89348 2.89543 3.99805 4 3.99805H15C18.866 3.99805 22 7.13205 22 10.998V17.8693C22 18.4927 21.4947 18.998 20.8713 18.998H19.9646C19.7219 20.6942 18.2632 21.998 16.5 21.998C14.7368 21.998 13.2781 20.6942 13.0354 18.998H9.96456C9.72194 20.6942 8.26324 21.998 6.5 21.998C5.05127 21.998 3.80812 21.1178 3.27617 19.863C2.52938 19.573 2 18.8473 2 17.998V13.998V11.998V5.99805ZM9.66318 16.998H13.3368C13.6826 16.2702 14.2722 15.6806 15 15.3349V13.998H4V16.0486C4.63526 15.4003 5.52066 14.998 6.5 14.998C7.8962 14.998 9.10145 15.8156 9.66318 16.998ZM15 18.498C15 19.3265 15.6716 19.998 16.5 19.998C17.3284 19.998 18 19.3265 18 18.498C18 17.6696 17.3284 16.998 16.5 16.998C15.6716 16.998 15 17.6696 15 18.498ZM4 11.998H15L15 5.99805L4 5.99805V11.998ZM17 15.0335C18.1798 15.2022 19.1698 15.9593 19.6632 16.998H20V11.998H17V15.0335ZM17 9.99805H19.9C19.5729 8.38672 18.4709 7.05677 17 6.41409V9.99805ZM6.5 16.998C5.67157 16.998 5 17.6696 5 18.498C5 19.3265 5.67157 19.998 6.5 19.998C7.32843 19.998 8 19.3265 8 18.498C8 17.6696 7.32843 16.998 6.5 16.998Z"
                                        fill="currentColor"></path>
                                </svg> {{ $t('form_of_transportation.form_of_transportation') }}</h5>
                        </div>
                        <div v-if="customerAddress && Object.keys(customerAddress).length === 0" class="space-y-1.5 rounded-xl border border-gray-25 bg-gray-10 p-3">
                            <p class="text-gray-1100">{{ $t('please_add_delivery_information_in_advance.please_add_delivery_information_in_advance') }}</p>
                        </div>
                        <div v-else>
                            <div>
                                <div class="divide-y divide-gray-25" aria-invalid="false" id=":r67:" role="group">

                                    <label v-for="(item, index) in shippingList" :key="index" class="block text-nm text-gray-600 flex min-h-[64px] 
                             items-center justify-between gap-4 py-4 cursor-pointer">
                                        <input class="peer hidden" id="variant-radio-:r69:" name="shippingMethod-0"
                                            type="radio" value="c4f55b8a-85cb-459d-be6c-6732f004f492">
                                        <div class="flex flex-grow items-center gap-4">
                                            <img v-if=" item.partner_name ==='GHTK'" alt="" class="rounded-sm" height="40"
                                                src="https://cdn.droppii.vn/droppii-production-public/delivery-provider/GHTK.png"
                                                width="40">
                                            <img v-if=" item.partner_name ==='Ahamove'" alt="" class="rounded-sm" height="40"
                                                src="https://i.pinimg.com/originals/52/b6/78/52b67897873b59f7403d597eb9ac9d04.png"
                                                width="40">    
                                            <img v-if=" item.partner_name ==='GHN'" alt="" class="rounded-sm" height="40"
                                                src="https://play-lh.googleusercontent.com/Ela0dN0rMHF3bkZ5_TxnfNQXjD9kFE_TIlxH6SBRkE1ZoVJse90a8P8-yzhhUYxx7amD"
                                                width="40">
                                            <img v-if=" item.partner_name ==='ViettelPost'" alt="" class="rounded-sm" height="40"
                                                src="https://tse3.mm.bing.net/th?id=OIP.khqR21ivPJG1qbu9CC2adQHaHd&pid=Api&P=0&h=220"
                                                width="40">   
                                            <div class="flex flex-col gap-1">
                                                <p class="font-medium text-gray-1100 text-md">{{ item.partner_name }}</p>
                                            </div>
                                        </div>
                                        <input type="radio" name="htvc" @click="handleShipping(item.partner_name)"
                                            class="inline-flex items-center justify-center rounded-full border-2 border-gray-400 h-6 w-6 flex-shrink-0" />

                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="bg-white p-4 space-y-3" id="form-shippingMethod">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl"><svg
                                    class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2 5.99805C2 4.89348 2.89543 3.99805 4 3.99805H15C18.866 3.99805 22 7.13205 22 10.998V17.8693C22 18.4927 21.4947 18.998 20.8713 18.998H19.9646C19.7219 20.6942 18.2632 21.998 16.5 21.998C14.7368 21.998 13.2781 20.6942 13.0354 18.998H9.96456C9.72194 20.6942 8.26324 21.998 6.5 21.998C5.05127 21.998 3.80812 21.1178 3.27617 19.863C2.52938 19.573 2 18.8473 2 17.998V13.998V11.998V5.99805ZM9.66318 16.998H13.3368C13.6826 16.2702 14.2722 15.6806 15 15.3349V13.998H4V16.0486C4.63526 15.4003 5.52066 14.998 6.5 14.998C7.8962 14.998 9.10145 15.8156 9.66318 16.998ZM15 18.498C15 19.3265 15.6716 19.998 16.5 19.998C17.3284 19.998 18 19.3265 18 18.498C18 17.6696 17.3284 16.998 16.5 16.998C15.6716 16.998 15 17.6696 15 18.498ZM4 11.998H15L15 5.99805L4 5.99805V11.998ZM17 15.0335C18.1798 15.2022 19.1698 15.9593 19.6632 16.998H20V11.998H17V15.0335ZM17 9.99805H19.9C19.5729 8.38672 18.4709 7.05677 17 6.41409V9.99805ZM6.5 16.998C5.67157 16.998 5 17.6696 5 18.498C5 19.3265 5.67157 19.998 6.5 19.998C7.32843 19.998 8 19.3265 8 18.498C8 17.6696 7.32843 16.998 6.5 16.998Z"
                                        fill="currentColor"></path>
                                </svg> {{ $t('form_of_transportation.form_of_transportation') }}</h5>
                        </div>
                        <div class="space-y-1.5 rounded-xl border border-gray-25 bg-gray-10 p-3">
                            <p class="text-gray-1100">{{ $t('shop_has_not_shipping_method.shop_has_not_shipping_method') }}</p>
                        </div>
                    </div>
                    <div  v-if="isshiping" tabindex="-1" >
                                    <div class="fixed top-0 left-0 z-50 justify-center items-center h-screen w-screen bg-gray-100 bg-opacity-50">
                                        <div  class="min-h-screen min-w-[480px] md:w-[480px] mx-auto my-auto justify-center items-center"  >
                                    <div style="top: 100px;" class="relative  w-full max-w-2xl h-full">
                                        <!-- Modal content -->
                                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 h-full">
                                            <!-- Modal header -->
                                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 h-full">
                                                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                                    {{$t('choose_service.choose_service')}}
                                                </h3>
                                                <button  @click.prevent="isshiping = false" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                                    </svg>
                                                    <span class="sr-only">Close modal</span>
                                                </button>
                                            </div>
                                            <!-- Modal body -->
                                            <div class="p-1  ">
                                                <!-- Content for the first column -->
                                                <div  v-for=" data in dataVTP" :key="data.id" class="flex items-center justify-between pl-2 pb-2 pt-2 border-b rounded-t dark:border-gray-600">
                                                    <!-- Content goes here items-center justify-center -->
                                                    <div class="flex   h-full " style="width: 270px;">
                                                        <p class="btn"> {{ data.TEN_DICHVU }} </p>
                                                     </div>
                                                     <div class="  h-full pl-4" style="width:200px">
                                                        <p class="btn" >{{ $t('price_g.price_g') }}: {{ formatNumber(data.GIA_CUOC)}} </p>
                                                    </div>
                                                    <div class="  h-full w-full pl-2">
                                                        <p class="btn">{{ $t('time.time') }}: {{ data.THOI_GIAN}} </p>
                                                      </div>
                                                     <div class="flex items-center justify-center h-full w-auto">
                                                        <input type="radio" name="htvc1" @click="getPriceVTP(data.MA_DV_CHINH)"
                                                            class="inline-flex items-center justify-center rounded-full border-2 border-gray-400 h-6 w-6 flex-shrink-0" />
                                                    </div>


                                                 </div>
                                                
                                            </div>
                                            <!-- Modal footer -->
                                            <!-- <div class="flex items-center justify-center h-full w-full">
                                                <div class="flex items-center justify-center p-4">
                                                    <button  data-modal-hide="default-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-[70px] text-center">{{$t('copy_aff.copy_aff')}}</button>                                            
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div  v-if="isshipingGHN" tabindex="-2" >
                                    <div class="fixed top-0 left-0 z-50 justify-center items-center h-screen w-screen bg-gray-100 bg-opacity-50">
                                        <div  class="min-h-screen min-w-[480px] md:w-[480px] mx-auto my-auto justify-center items-center"  >
                                    <div style="top: 100px;" class="relative  w-full max-w-2xl h-full">
                                        <!-- Modal content -->
                                        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700 h-full">
                                            <!-- Modal header -->
                                            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600 h-full">
                                                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                                                    {{$t('choose_service.choose_service')}}
                                                </h3>
                                                <button  @click.prevent="isshipingGHN = false" type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
                                                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                                                    </svg>
                                                    <span class="sr-only">Close modal</span>
                                                </button>
                                            </div>
                                            <!-- Modal body -->
                                            <div class="p-1  ">
                                                <!-- Content for the first column -->
                                                <div  v-for=" data in dataGHN" :key="data.id" class="flex items-center justify-between pl-2 pb-2 pt-2 border-b rounded-t dark:border-gray-600">
                                                    <!-- Content goes here items-center justify-center -->
                                                    <div class="flex   h-full " style="width: 270px;">
                                                        <p class="btn"> {{ data.short_name }} </p>
                                                     </div>
                                                     <div class="flex items-center justify-center h-full w-auto">
                                                        <input type="radio" name="htvc1" @click="getfreeshipGHN(data.service_id)"
                                                            class="inline-flex items-center justify-center rounded-full border-2 border-gray-400 h-6 w-6 flex-shrink-0" />
                                                    </div>


                                                 </div>
                                                
                                            </div>
                                            <!-- Modal footer -->
                                            <!-- <div class="flex items-center justify-center h-full w-full">
                                                <div class="flex items-center justify-center p-4">
                                                    <button  data-modal-hide="default-modal" type="button" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 w-[70px] text-center">{{$t('copy_aff.copy_aff')}}</button>                                            
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <!-- <div class="bg-white p-4 space-y-3" id="form-shippingFee">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl"><svg
                                    class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M2 12.002C2 17.5248 6.47715 22.002 12 22.002C17.5228 22.002 22 17.5248 22 12.002C22 6.47911 17.5228 2.00195 12 2.00195C6.47715 2.00195 2 6.47911 2 12.002ZM4 12.002C4 7.58368 7.58172 4.00195 12 4.00195C16.4183 4.00195 20 7.58368 20 12.002C20 16.4202 16.4183 20.002 12 20.002C7.58172 20.002 4 16.4202 4 12.002ZM13 6.00195C13 5.44967 12.5523 5.00195 12 5.00195C11.4477 5.00195 11 5.44967 11 6.00195V7.00195C9.34315 7.00195 8 8.3451 8 10.002C8 11.6588 9.34315 13.002 11 13.002H13C13.5523 13.002 14 13.4497 14 14.002C14 14.5542 13.5523 15.002 13 15.002H9.5C8.94772 15.002 8.5 15.4497 8.5 16.002C8.5 16.5542 8.94772 17.002 9.5 17.002H11V18.002C11 18.5542 11.4477 19.002 12 19.002C12.5523 19.002 13 18.5542 13 18.002V17.002C14.6569 17.002 16 15.6588 16 14.002C16 12.3451 14.6569 11.002 13 11.002H11C10.4477 11.002 10 10.5542 10 10.002C10 9.44967 10.4477 9.00195 11 9.00195H14.5C15.0523 9.00195 15.5 8.55424 15.5 8.00195C15.5 7.44967 15.0523 7.00195 14.5 7.00195H13V6.00195Z"
                                        fill="currentColor"></path>
                                </svg> Người chịu phí vận chuyển</h5>
                        </div>
                        <div>
                            <div class="divide-y divide-gray-25" aria-invalid="false" id=":r2c:" role="group"><label
                                    class="block text-nm text-gray-600 flex min-h-[64px] items-center justify-between gap-4 py-4 cursor-pointer"
                                    for="variant-radio-:r2e:"><input class="peer hidden" id="variant-radio-:r2e:"
                                        name="shippingFee-0" type="radio" value="seller">
                                    <div class="flex flex-grow items-center gap-4"><img alt="" class="rounded-sm"
                                            height="40" src="/img/icon/seller-pay.png" width="40">
                                        <div class="flex flex-col gap-1">
                                            <p class="font-medium text-gray-1100 text-md">Người bán chịu phí</p>
                                        </div>
                                    </div>
                                    <input type="radio" name="nbcp"
                                        class="inline-flex items-center justify-center rounded-full border-2 border-gray-400 h-6 w-6 flex-shrink-0" />
                                </label><label
                                    class="block text-nm text-gray-600 flex min-h-[64px] items-center justify-between gap-4 py-4 cursor-pointer"
                                    for="variant-radio-:r2f:"><input class="peer hidden" id="variant-radio-:r2f:"
                                        name="shippingFee-1" type="radio" value="customer">
                                    <div class="flex flex-grow items-center gap-4"><img alt="" class="rounded-sm"
                                            height="40" src="/img/icon/customer-pay.png" width="40">
                                        <div class="flex flex-col gap-1">
                                            <p class="font-medium text-gray-1100 text-md">Khách hàng chịu phí</p>
                                        </div>
                                    </div>
                                    <input type="radio" name="nbcp"
                                        class="inline-flex items-center justify-center rounded-full border-2 border-gray-400 h-6 w-6 flex-shrink-0" />

                                </label></div>
                        </div>
                    </div> -->
                    <div v-if="hidden" class="bg-white p-4 space-y-3" id="form-paymentMethod">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl"><svg
                                    class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M19 5.00195H5C3.34315 5.00195 2 6.3451 2 8.00195V16.002C2 17.6588 3.34315 19.002 5 19.002H19C20.6569 19.002 22 17.6588 22 16.002V8.00195C22 6.3451 20.6569 5.00195 19 5.00195ZM4 8.00195C4 7.44967 4.44772 7.00195 5 7.00195H19C19.5523 7.00195 20 7.44967 20 8.00195V8.50195H4V8.00195ZM4 10.502V16.002C4 16.5542 4.44772 17.002 5 17.002H19C19.5523 17.002 20 16.5542 20 16.002V10.502H4ZM6 14.002C6 13.4497 6.44772 13.002 7 13.002H10C10.5523 13.002 11 13.4497 11 14.002C11 14.5542 10.5523 15.002 10 15.002H7C6.44772 15.002 6 14.5542 6 14.002ZM13 13.002C12.4477 13.002 12 13.4497 12 14.002C12 14.5542 12.4477 15.002 13 15.002H14C14.5523 15.002 15 14.5542 15 14.002C15 13.4497 14.5523 13.002 14 13.002H13Z"
                                        fill="currentColor"></path>
                                </svg>{{ $t('payment_methods.payment_methods') }}</h5>
                        </div>
                        <div>
                            <div class="divide-y divide-gray-25" aria-invalid="false" id=":r2h:" role="group">

                                <label v-for="(item, index) in paymentList" :key="index"
                                    class="block text-nm text-gray-600 flex min-h-[64px] items-center justify-between gap-4 py-4 cursor-pointer">
                                    <input class="peer hidden" id="variant-radio-:r2j:" name="paymentMethod-0"
                                        type="radio" value="2">
                                    <div class="flex flex-grow items-center gap-4"><img alt="" class="rounded-sm"
                                            height="40" :src="item.logo" width="40">
                                        <div class="flex flex-col gap-1">
                                            <p class="font-medium text-gray-1100 text-md">{{ item.name }}</p>
                                            <div class="mt-1 text-gray-900 text-nm">
                                                <div><span class="text-gray-900 text-nm">{{ item.content_vi }}
                                                    </span>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="radio" name="pttt"
                                        class="inline-flex items-center justify-center rounded-full border-2  h-6 w-6 flex-shrink-0" />

                                </label>

                            </div>
                        </div>
                    </div>


                    <div class="bg-white p-4 space-y-3">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl">
                            <img src="/img/voucher-svgrepo-com.svg" class="mr-2.5 text-3xl" style="width: 1em; height: 1em; fill: currentColor;">
                            {{ $t('discount_code.discount_code') }}</h5>
                        </div>
                        <div>
                            <div class="relative inline-flex w-full ">
                                <input
                                    class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 mr-8 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-300 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400"
                                    name="note" :placeholder="$t('enter_discount.enter_discount')" v-model="codeDiscount">
                                <div style="width: 20px;"></div>
                                <button class="px-3 rounded-lg mr-5 ml-5 h-[48px] transition duration-400 justify-center items-center whitespace-nowrap leading-none focus:outline-none
                                    h-10 w-[80px] flex bg-primary-400 text-white hover:bg-primary-400" type="submit"
                                    @click.prevent="CalculatorDiscount">
                                    {{ $t('apply.apply') }}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="bg-white p-4 space-y-3">
                        <div class="flex flex-wrap items-center justify-between gap-2">
                            <h5 class="flex items-center leading-snug font-semibold text-gray-1100 text-xl"><svg
                                    class="mr-2.5 text-3xl" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M15.6099 2.58877C16.3949 1.80374 17.6677 1.80374 18.4527 2.58877L21.4112 5.54729C22.1963 6.33232 22.1963 7.6051 21.4112 8.39012L9.14172 20.6596C8.86229 20.9391 8.5067 21.1299 8.1194 21.2084L4.40932 21.96C3.74887 22.0937 3.0653 21.8877 2.5888 21.4112C2.1123 20.9347 1.90626 20.2511 2.04005 19.5907L2.79161 15.8806C2.87007 15.4933 3.06094 15.1377 3.34036 14.8583L15.6099 2.58877ZM4.44348 17.851L4.57274 17.2129L6.78709 19.4273L6.14901 19.5565L4.01022 19.9898L4.44348 17.851ZM8.58059 18.3779L5.62208 15.4194L14.2516 6.78988L17.2101 9.7484L8.58059 18.3779ZM18.6315 8.32699L19.9898 6.96871L18.5684 5.54729L18.4527 5.43161L17.0313 4.01019L15.673 5.36847L18.6315 8.32699Z"
                                        fill="currentColor"></path>
                                </svg> {{ $t('note.note') }}</h5>
                        </div>
                        <div>
                            <div class="relative inline-flex w-full"><input
                                    class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-3 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-300 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400"
                                    name="note" :placeholder="$t('enter_your_notes.enter_your_notes')" v-model="note"></div>
                        </div>
                    </div>
                    <!-- <button class=" p-4 flex items-center bg-white w-full" type="button">
                        <div class="flex-1 space-y-1">
                            <h6 class="text-left leading-snug font-semibold text-gray-1100 text-lg">Yêu cầu xuất hóa đơn
                                điện tử</h6>
                        </div>
                        <div class="flex w-10 flex-none justify-end"><svg class="text-3xl text-gray-300" width="1em"
                                height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                                    fill="currentColor"></path>
                            </svg></div>
                    </button> -->
                    <div class="bg-white p-4 space-y-5">
                        <div class="space-y-2">
                            <!-- <div class="flex justify-between space-x-2">
                                <div class="flex-shrink-0">Tạm tính <span class="text-gray-300">(chưa VAT)</span></div>
                                <div class="text-right">₫0</div>
                            </div>
                            <div class="flex justify-between space-x-2">
                                <div class="flex-shrink-0">VAT </div>
                                <div class="text-right">₫0</div>
                            </div> -->
                            
                            <div class="flex justify-between space-x-2">
                                <div class="flex-shrink-0">{{ $t('discount.discount') }} </div>
                                <div v-if="coupon_discount" class="text-right"> {{ formatNumber(coupon_discount) }}₫
                                </div>
                                <div v-else class="text-right">0₫</div>

                            </div>
                            <div class="flex justify-between space-x-2">
                                <div class="flex-shrink-0">{{ $t('transport_fee.transport_fee') }}</div>
                                <div v-if="ship_fee" class="text-right">{{ formatNumber(ship_fee) }} ₫</div>
                                <div v-else class="text-right">0₫</div>

                            </div>
                            <div class="flex justify-between space-x-2">
                                <div class="flex-shrink-0">{{ $t('pack_fee.pack_fee') }} </div>
                                <div class="text-right">0₫</div>
                            </div>
                            <div class="flex justify-between space-x-2 font-bold">
                                <div class="flex-shrink-0">{{ $t('total_payment.total_payment') }}</div>
                                <div class="text-right">{{ formatNumber(totalPrice) }}₫</div>
                            </div>
                            <div class="flex justify-between space-x-2">
                                <div class="my-2 h-px w-full border-t border-gray-25"></div>
                            </div>
                            <div class="flex justify-between space-x-2 font-semibold">
                                <div class="flex-shrink-0">{{ $t('retail_profits.retail_profits') }} </div>
                                <div class="text-right"> {{ formatNumber(totalProfit) }} ₫</div>
                            </div>
                            <!-- <div class="flex justify-between space-x-2 font-semibold">
                                <div class="flex-shrink-0">Points </div>
                                <div class="text-right">0</div>
                            </div> -->
                        </div>
                    </div>
                    <div class="bg-white p-4">
                        <div class="rounded-lg bg-gray-10 px-4 py-3">
                            <p class="text-nm text-gray-1100">{{ $t('click_contents_and_terms.click_contents_and_terms') }}
                                <a href="https://salekit.net/terms" target="_blank"
                                    class="text-left font-medium text-primary-400">{{ $t('contents_and_terms.contents_and_terms') }}
                                </a> Salekit
                            </p>
                        </div>
                        <div class="relative flex items-center justify-center -mx-4 mb-3 mt-1">
                            <div class="absolute inset-0 flex items-center">
                                <div class="w-full border-t border-gray-50"></div>
                            </div>
                        </div>

                        <button v-if="isLoading===false" class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none
                             h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300" type="submit"
                            @click.prevent="orderCreate">{{ $t('create_order.create_order') }} </button>

                        <button v-if="isLoading===true" class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none
                             h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300" 
                           >
                            <i data-v-3fcda164="" class="fa fa-spinner fa-spin"></i>
                            </button>


                    </div>
                    <div></div>
                </form>
            </main>
            <div style="position: fixed; z-index: 9999; inset: 16px; pointer-events: none;"></div>
        </div>
        <Footer />
        <ModalAddress v-if="isShowModalAddress" :closeModalAddress="closeModalAddress"
            :onChangeAddress="getcustomerAddress" />
        <ModalAddProduct v-if="isShowModalProduct" :closeModalProduct="closeModalProduct" :storeInfo="Stores" :summoney="summoney"
            :getOrder="getOrder" />
    </div>
</template>

<script>
import Footer from '@/components/shared/Footer.vue';
import ModalAddress from '@/components/layouts/ModalAddress.vue';
import ModalAddProduct from '@/components/layouts/ModalAddProduct.vue';
import { getPayments } from '@/services/affiliate/affiliate';
import { createOrder } from '@/services/order/order';
import { calculatorDiscount,getStoreList } from '@/services/product/productService';
import {getService_GHN,
    getShippingUnits, getshipGHTK, shipGHN, getPriceAllViettelPost, getPriceViettelPost,getProvinceGHN,getDistrictGHN,getWardGHN,getStoreGHN
} from '@/services/shipping/shipping';
import { useToast } from "vue-toastification";

// import axios from 'axios';
export default {
    name: 'ProductOrer',
    props: {

    },
    components: {
        Footer,
        ModalAddress,
        ModalAddProduct
    },

    data() {
        const toast = useToast()

        return {
            orderItems: [],
            cartItems: [],
            selectedProducts: [],
            codeDiscount: '',//ma giam gia
            coupon_discount: '',//so tien giam khi ap dung ma
            quantity: 1, // Số lượng mặc định
            minQuantity: 1, // Số lượng tối thiểu
            maxQuantity: Infinity, // Số lượng tối đa, có thể thay đổi
            isShowModalAddress: false,
            isShowModalProduct: false,
            customerAddress: {},
            shippingList: [],
            paymentList: [],
            ship_fee: '',
            total: 0,
            profit: 0,
            totalPrice: 0,
            totalProfit: 0,
            toast,
            note: '',
            weight:'',
            isLoading: false,
            Stores:'',
            isShipping: false,
            isPayment: false,
            isshiping: false,
            dataVTP:'',
            dataGHN:'',
            codeAddress:[],
            tokenGHN:'',
            isshipingGHN:false,
        };
    },
    created() {
        document.title =this.$t(`create_order_dropship.create_order_dropship`);
        const selectedProductsString = localStorage.getItem("order");
        this.storess();
        if (selectedProductsString) {
            this.selectedProducts = JSON.parse(selectedProductsString);
            localStorage.setItem('order', JSON.stringify(this.selectedProducts));
        }
    },
    methods: {
        async CalculatorDiscount() {
            if(this.totalPrice == 0){
                this.toast.error(this.$t('select_product_before_the_discount.select_product_before_the_discount')); 
                setTimeout(() => {
                    this.toast.clear();
                }, 1500);
                return;
            }
            else if(this.codeDiscount ===""){
                this.coupon_discount = 0;
                this.calculateTotalPrice();
                this.summoney();
                this.toast.error(this.$t('enter_discount.enter_discount')); 
                setTimeout(() => {
                    this.toast.clear();
                }, 1500);
                return;
            }
            
            else{
                const products = [];
            this.selectedProducts.forEach(item => {
                // Tạo đối tượng sản phẩm từ thông tin product
                const product = {
                    "product_id": item.product.id,
                    "product_name": item.product.name,
                    "quantity": item.quantity,
                    "price": parseFloat(item.product.price_sale)
                };

                // Thêm sản phẩm vào mảng products
                products.push(product);
            });
            const body = {
                "code": this.codeDiscount,
                "products": products
            }
            // console.log(body);
            const response = await calculatorDiscount(body);
            if (response.data.status == 1) {
                // console.log(response.data.msg);
                // console.log(response.data);
                this.coupon_discount = response.data.coupon_discount;
                this.toast.success(this.$t('success.success'), {
                    timeout: 2000
                });
                this.calculateTotalPrice();
                this.summoney();
            } else {
                // console.log(response.data.msg);
                this.toast.error(response.data.msg, {
                    timeout: 2000
                });
            }

            }
            

        },
        async storess(){
            const res = await getStoreList();
            if(res.data.error !== true){
                //  console.log(res.data.data);
                this.Stores = res.data.data
            }
        },
        summoney(){
            let totalAmount = 0;
            let profit1 = 0;
            this.orderItems.forEach((item) => {
                totalAmount += item.total;
                profit1 += item.profit;
            });
           
            if(this.coupon_discount){
                console.log(profit1, parseInt( this.coupon_discount));
                this.totalProfit = profit1 +  parseInt( this.coupon_discount);
                this.totalPrice = totalAmount - this.coupon_discount + this.ship_fee;
            }
            else{
                this.totalProfit = profit1;
                this.totalPrice = totalAmount + this.ship_fee;
            }
           
            //console.log('Tổng tiền của giỏ hàng là: ', totalAmount);
        },
        openModalAddress() {
            this.isShowModalAddress = true;
        },
        closeModalAddress() {
            this.isShowModalAddress = false;
        },
        openModalProduct() {
            this.isShowModalProduct = true;
        },
        closeModalProduct() {
            this.isShowModalProduct = false;
        },
        async getcustomerAddress(address) {
            if(!address){
                return;
            }
            this.customerAddress = address;
            // console.log(address);
            const token = '74667173-7886-11ee-a59f-a260851ba65c'
            const province = await getProvinceGHN(token);
            //lay dia chi khach hang
            const province_id = province.data.data.find(item => item.ProvinceName === this.customerAddress.province_name)
            //  console.log(province_id.ProvinceID)
            this.codeAddress.ProvinceID = province_id.ProvinceID
            let district = await getDistrictGHN(token,province_id.ProvinceID);
            // district.data.data.splice(3, 1);
            const district_id = await district.data.data.filter(item =>item.NameExtension && item.NameExtension.includes(this.customerAddress.district_name));
            // console.log(district_id)
            if (district_id) {
                if (district_id.length >= 2) {
                    let flag = false;  // Tạo biến flag để kiểm tra
                    for (let i = 0; i < district_id.length; i++) {
                        let ward = await getWardGHN(token, district_id[i].DistrictID);
                        // console.log(this.customerAddress.ward_name)
                        const ward_id = await ward.data.data.find(item => item.NameExtension && item.NameExtension.includes(this.customerAddress.ward_name));
                        if (ward_id) {
                            this.codeAddress.WardCode = ward_id.WardCode;
                            this.codeAddress.DistrictID = district_id[i].DistrictID;
                            flag = true;  // Đánh dấu đã tìm thấy ward_id
                            break;  // Thoát khỏi vòng lặp
                        }
                    }
                    if (!flag) {
                        // Thực hiện hành động khi không tìm thấy ward_id
                    }
                }
                else{
                    this.codeAddress.DistrictID = district_id[0].DistrictID;
                   
                    let ward = await getWardGHN(token, district_id[0].DistrictID);
                        // console.log(this.customerAddress.ward_name)
                        const ward_id = await ward.data.data.find(item => item.NameExtension && item.NameExtension.includes(this.customerAddress.ward_name));
                        if (ward_id) {
                            this.codeAddress.WardCode = ward_id.WardCode;
                        }
                        // console.log(this.customerAddress.WardCode)
                }
   
            } else {
                console.log("Không xác định được điểm giao hàng DistrictID ", district_id.DistrictID);
            }
            
            // this.codeAddress.DistrictID = district_id.DistrictID
            // console.log( "DistrictID ", this.codeAddress.DistrictID)
            // let ward = await getWardGHN(token,district_id.DistrictID);
            // console.log(this.customerAddress.ward_name)
            // const ward_id = await ward.data.data.find(item => item.NameExtension && item.NameExtension.includes(this.customerAddress.ward_name));
            // if(ward_id && ward_id.length >= 1 ){
            //     this.codeAddress.WardCode = ward_id.WardCode
            // }
            // else{
            //     console.log("Không xác định được điểm giao hàng",ward_id)
            // }
           
            //lay dia chi cua kho
            let orderData = localStorage.getItem('order');
            if (orderData) {
                orderData = JSON.parse(orderData);
                const province_id1 = province.data.data.find(item => item.ProvinceName === orderData[0].store.province_name)
                // console.log('store id district',province_id1.ProvinceID);
                district = await getDistrictGHN(token,province_id1.ProvinceID);
                
                let district_id1 = district.data.data.find(item =>item.NameExtension && item.NameExtension.includes(orderData[0].store.district_name));
                if(!district_id1 || district_id1.length <1 ){
                     district_id1 = district.data.data.find(item => item.NameExtension &&item.NameExtension.some(name => name.includes(orderData[0].store.district_name)));                
                }
                // console.log("where",district_id1.DistrictID)
                // ward = await getWardGHN(token,district_id1.DistrictID);
                // // console.log("district_id1",orderData[0].store.ward_name)
                // let ward_id1 = ward.data.data.find(item => item.NameExtension && item.NameExtension.includes(orderData[0].store.ward_name));
                // if(!ward_id1 || ward_id1.length <1 ){
                //     console.log("co vao log 2")
                //     ward_id1 = ward.data.data.find(item => item.NameExtension &&item.NameExtension.some(name => name.includes(orderData[0].store.ward_name)));                
                // }
                // console.log(ward_id1)
                this.codeAddress.WardCode_store = district_id1.DistrictID
            }
            // const data_service = await getService_GHN(this.tokenGHN, this.store_id_GHN,this.codeAddress.WardCode_store,this.codeAddress.DistrictID)
            // console.log(data_service)
        },
        getOrder() {
            // localStorage.removeItem('customerAddress');
            let orderData = localStorage.getItem('order');
            if (orderData) {
                this.orderItems = JSON.parse(orderData);
                // this.calculateTotalPrice();
                // /console.log('store id district', this.orderItems.store);
            }

        },
        calculateTotalPrice() {
            // Tính tổng giá tiền của các sản phẩm trong giỏ hàng
            const productTotalPrice = this.orderItems.reduce((total, item) => {
                return total + item.total;
            }, 0);
            //Tổng lợi nhuận
            this.totalProfit = this.orderItems.reduce((total, item) => {
                return total + (item.profit ? item.profit : 0);
            }, 0);
            // console.log( this.totalProfit,this.coupon_discount);
            this.totalProfit =this.totalProfit+ parseInt( this.coupon_discount);
            // Tổng giá tiền bằng tổng giá sản phẩm và giá ship
            this.totalPrice = productTotalPrice + this.ship_fee - this.coupon_discount;

        },
        // beforeRouteLeave(to, from, next) {
        //     // Xóa dữ liệu khi rời khỏi trang order
        //     localStorage.removeItem('order');
        //     next();
        // }
        // ,
        //Cập nhật lợi nhuận, total
        calculatePriceAndProfit(item) {
            if (item.product.price_sale) {
                item.total = item.product.price_sale * item.quantity;
                item.profit = item.total * (item.product.rate / 100);

            } else if (item.product.sale_price) {
                item.total = item.product.sale_price * item.quantity;
            }
            else {
                item.total = item.product.price * item.quantity;
                item.profit = item.total * (item.product.rate / 100);
            }
            localStorage.setItem('cart', JSON.stringify(this.orderItems));
        },
        //Tăng số lượng
        increaseQuantity(productId, storeId) {

            const index = this.orderItems.findIndex(item => item.product.id === productId && item.store.id === storeId);
            if (index !== -1 && this.orderItems[index].quantity < this.maxQuantity) {
                // this.quantity = this.orderItems[index].quantity++
                this.orderItems[index].quantity++;
                this.calculatePriceAndProfit(this.orderItems[index]); // Tính lại giá tiền và lợi nhuận sau khi tăng số lượng
                localStorage.setItem('cart', JSON.stringify(this.orderItems));
                localStorage.setItem('order', JSON.stringify(this.orderItems));
                // console.log('Tăng', this.orderItems[index]);

            }
            // this.getOrder()
            this.summoney();
        },
        //Giảm số lượng
        decreaseQuantity(productId, storeId) {
            const index = this.orderItems.findIndex(item => item.product.id === productId && item.store.id === storeId);

            if (index !== -1 && this.orderItems[index].quantity > this.minQuantity) {
                this.orderItems[index].quantity--;
                this.calculatePriceAndProfit(this.orderItems[index]); // Tính lại giá tiền và lợi nhuận sau khi giảm số lượng
                localStorage.setItem('cart', JSON.stringify(this.orderItems));
                localStorage.setItem('order', JSON.stringify(this.orderItems));
                // console.log('Giảm', this.orderItems[index]);

            }
            this.summoney();

        },
        deleteItem(productId, storeId) {
            const index = this.orderItems.findIndex(item => item.product.id === productId && item.store.id === storeId);
            if (index !== -1) {
                this.orderItems.splice(index, 1);
            }
            localStorage.setItem('order', JSON.stringify(this.orderItems));
            this.summoney();
        },
        formatNumber(value) {
            const result = parseFloat(value);

            if (isNaN(result)) {
                return "Invalid value";
            }
            return result.toLocaleString('en-US', {
                maximumFractionDigits: 0
            });
        },
        getCartItems() {
            let cartData = localStorage.getItem('cart');
            if (cartData) {
                this.cartItems = JSON.parse(cartData);
            }
            // console.log(this.cartItems);
        },
        getStoreId() {
            const storeInfo = [];
            this.orderItems.forEach(item => {
                const id = item.store.id;
                const name = item.store.name;
                storeInfo.push({ id, name });
            });
            return storeInfo;
        },
        async getShippingUnits() {
            try {
                // this.isLoading = true;
                const response = await getShippingUnits();
                this.shippingList = response.data;
                // console.log(response.data)
                this.shippingList = this.shippingList.filter(element => element.partner_name !== "Ahamove");
                this.tokenGHN = response.data.filter(item => item.partner_name === "GHN")[0].token
                // console.log( this.tokenGHN);
            } catch (error) {
                console.error('Error fetching getShippingUnits:', error.message);
            }
        },
        handleShipping(ship_name) {
            if(Object.keys(this.customerAddress).length === 0)
            return;
            try {

                if (ship_name === 'GHTK') {
                    this.isShipping=true;
                    this.getShippingPriceGHTK();
                } else if (ship_name === 'GHN') {
                    this.isShipping=true;
                    this.getShippingPriceGHN();
                } else if (ship_name === 'ViettelPost') {
                    this.isShipping=true;
                    this.getPriceAllVTP()
                } else {
                    this.isShipping=false;
                    // console.log('Trống');
                }

            } catch (error) {
                console.log(error.message);
            }

        },
        async getShippingPriceGHTK() {
            // console.log(this.orderItems)
            try {

                  const data = 
                  { 
                    "headers":{
                        "Content-Type": "application/json",
                        "Token": "4dc0b0fe7ee95de3c200c9e1131e713db771f789"
                                            },
                    "params":{
                        "pick_province": this.orderItems[0].store.province_name,
                        "pick_district": this.orderItems[0].store.district_name,
                        "province":  this.customerAddress.province_name,
                        "district":this.customerAddress.district_name,
                        "address":this.customerAddress.full_address,
                        // "weight": this.weight,
                        "weight": 1000,
                        "value": 300,
                        "transport": "road"
                            }
                    }
                // const data = {
                //     address: this.customerAddress.full_address,
                //     province: this.customerAddress.province_name,
                //     district: this.customerAddress.district_name,
                //     pick_province: "Hà Nội",
                //     pick_district: "Quận Hai Bà Trưng",
                //     weight: 1000,
                //     value: 3000000,
                //     deliver_option: "xteam",
                //     tags: [1],
                // }
                const response = await getshipGHTK(data);
                // console.log( response.data.data)
                this.ship_fee = response.data.data.fee.ship_fee_only
                // console.log(this.ship_fee);
                this.calculateTotalPrice();
                this.summoney();
                // console.log('GHTK', this.ship_fee);
                // console.log(this.customerAddress);

            } catch (error) {
                console.error('Error fetching getShippingPriceGHTK:', error.message);
            }

        },
        async getShippingPriceGHN() {
            this.isshipingGHN = true
            const data_store = await getStoreGHN(this.tokenGHN);
            // console.log("data_store",data_store.data.data.shops[0]._id)
            this.store_id_GHN = data_store.data.data.shops[0]._id;
            const data_service = await getService_GHN(this.tokenGHN, this.store_id_GHN,this.codeAddress.WardCode_store,this.codeAddress.DistrictID)
            // console.log(data_service.data.data)
            this.dataGHN = data_service.data.data
            // console.log(this.orderItems);
            
        }
        ,
        async getfreeshipGHN(id){
            let items = [];
            this.orderItems.forEach(item => {
                items.push({
                    name: item.product.name,
                    quantity: item.quantity,
                    weight: (item.product.weight && item.product.weight > 0) ? item.product.weight : 200,
                    height: 200,
                    length: 200,
                    width: 200
                });
            });
            try {
                const data = {
                    service_id: id,
                    insurance_value: 500000,
                    coupon: null,
                    from_district_id:  this.codeAddress.WardCode_store,
                    to_district_id: this.codeAddress.DistrictID,
                    to_ward_code: this.codeAddress.WardCode.toString(),
                    height: 25,
                    length: 25,
                    weight: 3000,
                    width: 25,
                    items: items
                                
                };
                const response = await shipGHN(data); // Adding await here
                // console.log(response.data)
                if(response.data.error == true){
                   this.toast.error(this.$t('service_currently_unavailable.service_currently_unavailable'));
                    return 
                }
                else{
                    this.toast.success(this.$t('success.success'));
                }

                this.ship_fee = response.data.data.total
                this.calculateTotalPrice();
                this.summoney();
                // console.log('shipGHN', response.data.data.total);
                // console.log(this.customerAddress);

            } catch (error) {
                console.error('Error fetching getShippingPriceGHN:', error.message);
            }
        },
        async getPriceAllVTP() {
            this.isshiping = true
            try {
                const productTotalPrice = this.orderItems.reduce((total, item) => {
                    return total + item.total;
                }, 0);

                const data = {
                    SENDER_PROVINCE: 1,
                    SENDER_DISTRICT: 53,
                    RECEIVER_PROVINCE: parseInt(this.customerAddress.province_id),
                    RECEIVER_DISTRICT: parseInt(this.customerAddress.district_id),
                    PRODUCT_TYPE: 'HH',
                    PRODUCT_WEIGHT: 500,
                    PRODUCT_PRICE: parseInt(productTotalPrice),
                    MONEY_COLLECTION: parseInt(productTotalPrice),
                    TYPE: 1,
                };
                const response = await getPriceAllViettelPost(data); // Adding await here
                this.dataVTP = response.data.data;
                // console.log(this.dataVTP);
                this.ship_fee = response.data.data[0].GIA_CUOC
                // console.log('VTP ALL', response);

                // this.getPriceVTP()
                this.calculateTotalPrice();
                this.summoney();
               
                // console.log(this.ship_fee);
            } catch (error) {
                console.error('Error fetching getShippingPriceGHN:', error.message);
            }
        },
        async getPriceVTP(MA_DV_CHINH) {
            this.isshiping = false;
            try {
                const productTotalPrice = this.orderItems.reduce((total, item) => {
                    return total + item.total;
                }, 0);
                // console.log('Product totel', productTotalPrice);
                const data = {
                    PRODUCT_WEIGHT: 500, //Trọng lượng
                    PRODUCT_PRICE: parseInt(productTotalPrice),
                    MONEY_COLLECTION: parseInt(productTotalPrice),
                    ORDER_SERVICE_ADD: '',
                    ORDER_SERVICE: MA_DV_CHINH,
                    SENDER_PROVINCE: 1,
                    SENDER_DISTRICT: 53,
                    RECEIVER_PROVINCE: parseInt(this.customerAddress.province_id),
                    RECEIVER_DISTRICT: parseInt(this.customerAddress.district_id),
                    PRODUCT_TYPE: "HH",
                    NATIONAL_TYPE: 1,
                };
                const res = await getPriceViettelPost(data); // Adding await here
                this.ship_fee = res.data.data.MONEY_TOTAL
                // this.calculateTotalPrice();
                this.calculateTotalPrice();
                this.summoney();
                // console.log('VTP', res);
            } catch (error) {
                console.error('Error fetching getShippingPriceGHN:', error.message);
            }
        },
        async getPayments() {
            try {

                // this.isLoading = true;
                const response = await getPayments();
                this.paymentList = response.data.data;
                // console.log(this.paymentList);
            } catch (error) {
                console.error('Error fetching getPayments:', error.message);
            }
        },
        async orderCreate() {
            this.isLoading=true;
            try {

                const dataUser = JSON.parse(localStorage.getItem('datauserInfo'))
                const dataProduct = JSON.parse(localStorage.getItem('order'));
                // console.log('Sp trong order',dataProduct);

                const aProduct = [];
                // console.log(this.ship_fee)
                dataProduct.forEach(product => {
                    // console.log(product.store.id);
                    if (product.product.price_sale) {
                        this.price = product.product.price_sale
                    } else {
                        this.price = product.product.price
                    }
                     this.storeId=product.store.id
                     this.weight=product.product.weight
                    aProduct.push({
                        product_id: product.product.id,
                        product_name: product.product.name,
                        quantity: product.quantity,
                        price: this.price
                    });
                });

                //  console.log('Đây là sp mua:', aProduct);
                if (this.customerAddress === undefined) {
                    this.isLoading=false;
                    return this.toast.error(`${this.$t('pls_select_address.pls_select_address')}`, {
                     timeout: 2000,
                    });
                }
                if (this.isShipping == false) {
                    this.isLoading=false;
                    return this.toast.error(`${this.$t('please_select_your_shipping_method.please_select_your_shipping_method')}`, {
                     timeout: 2000,
                    });
                }
                const data = {
                    "full_name": this.customerAddress.name,
                    "email": dataUser.email,
                    "pay_fee": "",
                    "phone": this.customerAddress.phone,
                    "store_id": this.storeId,
                    "ship_partner_id": "6",
                    "ship_fee": this.ship_fee,
                    "note": this.note,
                    "address": this.customerAddress.full_address,
                    "province_id": this.customerAddress.province_id,
                    "district_id": this.customerAddress.district_id,
                    "ward": this.customerAddress.ward_id,
                    "discount": this.coupon_discount,
                    "src": "",
                    "products": aProduct
                }
                let response = await createOrder(data);
                // console.log(response);

                window.location.href = response.data.checkout_url;
            } catch (error) {
                console.log(error)
            }
        }

    },

    mounted() {
        this.getCartItems();
        this.getOrder();
        this.getcustomerAddress();
        this.getShippingUnits()
        this.getPayments();
        this.summoney();
        // this.getStoreId();
        // this.customerAddress = localStorage.getItem('customerAddress')
        if (this.$route.query?.address) {
            const address = JSON.parse(this.$route.query.address);
            this.getcustomerAddress(address);
            // console.log('data', address);
        }
    },

};
</script>


