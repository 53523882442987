<template>
<div class="bg-blue-10">

    <div class="sm:min-h-screen sm:container " id="main-container">
        <div class="sticky inset-x-0 top-0 z-20 w-full sm:w-[480px]">
            <div class="grid grid-cols-3 gap-2 border border-t border-gray-25 px-4 py-2">

                <router-link class="flex h-8 items-center justify-center space-x-1 rounded-lg border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800" :class="{ 'border-primary-400 bg-blue-25 text-primary-400': $route.path === '/my_order', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/home' }" :style="{ borderColor: boderrankhome ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }" to="/my_order">

                    <!-- SVG Path -->

                    <span>{{ $t('my_order.my_order') }} </span>
                </router-link>
                <!-- Same for other links -->
                <router-link :class="['flex', 'h-8', 'items-center', 'justify-center', 'space-x-1', 'rounded-lg', 'border', 'bg-white', 'text-xs', 'transition', 'duration-200', 'bg-gray-10', 'text-gray-800', 'bg-primary-300', $route.path === '/my_reffer' && 'border-primary-400 bg-blue-25 text-primary-400 ']" :style="{ borderColor: boderrank ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }" to="/my_reffer">

                    <span>{{ $t('order_my_ref.order_my_ref') }} </span>

                </router-link>

                <router-link :class="['flex', 'h-8', 'items-center', 'justify-center', 'space-x-1', 'rounded-lg', 'border', 'bg-white', 'text-xs', 'transition', 'duration-200', 'bg-gray-10', 'text-gray-800', 'bg-primary-300', $route.path === '/downline' && 'border-primary-400 bg-blue-25 text-primary-400 ']" :style="{ borderColor: boderrank ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }" to="/downline">

                    <span>{{ $t('network_aff.network_aff') }} </span>

                </router-link>

            </div>
            <div class="flex  space-x-2 px-4 pb-3 pt-3">
                <!-- Form tìm kiếm -->
                <router-link :class="['flex', 'h-11','w-11', 'items-center', 'justify-center', 'space-x-1', 'rounded-full', 'border', 'bg-white', 'text-xs', 'transition', 'duration-200', 'bg-gray-10', 'text-gray-800', 'bg-primary-300', $route.path === '/rank' && 'border-primary-400 bg-blue-25 text-primary-400 ']" to="" :style="{ borderColor: boderrank ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }" @click.prevent="openModal">
                    <i class="fa-solid fa-filter fa-1x "  style= "font-size: 15px"></i>
                    <span></span>
                    <!-- {{$t('advanced_search.advanced_search')}} -->

                </router-link>
                <div class="flex h-full w-full h-10 pl-2 items-center rounded-full bg-gray-25" role="search">
                    <div class="relative inline-flex h-10 w-full rounded-full bg-gray-25">
                        <!-- Icon tìm kiếm -->
                        <div class="absolute flex h-10 w-12 flex-none items-center justify-center text-xl text-gray-300" aria-hidden="true">
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3117 17.3433C17.7806 16.8777 18.5311 16.8743 19.0039 17.3333L21.5513 19.3567C22.1496 19.9614 22.1496 20.9407 21.5513 21.5454C20.9516 22.1515 19.9782 22.1515 19.3785 21.5454L17.3047 19.1693C17.0672 18.927 16.9343 18.6001 16.9343 18.2599C16.9343 17.9187 17.0713 17.5854 17.3117 17.3433Z" fill="currentColor" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6123 2C5.85342 2 2 5.89865 2 10.7024C2 15.5061 5.85342 19.4047 10.6123 19.4047C15.3713 19.4047 19.2247 15.5061 19.2247 10.7024C19.2247 8.3952 18.3179 6.182 16.7031 4.54979C15.0882 2.9175 12.8973 2 10.6123 2ZM3.95122 10.7024C3.95122 6.9567 6.95053 3.95122 10.6123 3.95122C12.373 3.95122 14.0654 4.65802 15.316 5.92211C16.5673 7.18683 17.2735 8.906 17.2735 10.7024C17.2735 14.448 14.2741 17.4535 10.6123 17.4535C6.95053 17.4535 3.95122 14.448 3.95122 10.7024Z" fill="currentColor" />
                            </svg>
                        </div>

                        <input v-model="search" class="max-w-[98%] w-full rounded-full pl-11 pr-8 rounded-full border-none text-black truncate bg-gray-25 px-4 rounded-lg focus:outline-none focus:border-gray-400" type="text" :placeholder="$t('search_name_order_id.search_name_order_id')" />
                    </div>
                </div>

            </div>
            <div class="grid grid-cols-2 gap-2 px-4 ">
                <div class="flex w-full"> {{ $t('find_here.find_here') }} {{total_count }} {{ $t('order_low.order_low') }}</div>
                <div class="flex justify-end">
                        <input type="checkbox" v-model="checkedselectall"  width:20px class="mr-2"> {{$t('multi_choice.multi_choice')}}
                    </div>
                <!-- 
                <router-link class="flex h-8 items-center justify-center space-x-1 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-800" :class="{ 'border-primary-400 bg-blue-25 text-primary-400': $route.path === '/', 'border-primary-400 !important bg-blue-25 text-primary-400': $route.path === '/home' }" :style="{ borderColor: boderrankhome ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }" to="/my_order">

                   
                    <i class="fa-solid fa-chart-simple"></i>
                    <span>{{$t('month_statistics.month_statistics')}}</span>
                </router-link> -->
                <!-- Same for other links -->
                <!-- <router-link :class="['flex', 'h-8', 'items-center', 'justify-center', 'space-x-1', 'rounded-full', 'border', 'bg-white', 'text-xs', 'transition', 'duration-200', 'bg-gray-10', 'text-gray-800', 'bg-primary-300', $route.path === '/rank' && 'border-primary-400 bg-blue-25 text-primary-400 ']" to="" :style="{ borderColor: boderrank ? 'rgb(27 63 228/var(--tw-border-opacity))' : '#e5e7eb' }" @click.prevent="openModal">
                    <i class="fa-solid fa-filter"></i>
                    <span>{{$t('advanced_search.advanced_search')}}</span>

                </router-link> -->

            </div>
            <div class="space-y-3 bg-blue-10 p-3 pb-10">
                <div class="space-y-1">
                    <div v-if="filteredAndPaginatedOrders.length < 0" class="flex flex-col items-center text-center mx-auto mt-10 max-w-[255px]">
               
                            <i class="fa-solid fa-money-bill text-3xl"></i>
                        <p class="mb-7 whitespace-pre-line text-gray-600">
                        {{$t('you_dont_currently_have_any_orders.you_dont_currently_have_any_orders')}}
                            {{$t('create_a_new_order_now.create_a_new_order_now')}}</p>

                            <router-link to="/order/create" class="px-3 transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex rounded-full bg-primary-400 text-white hover:bg-primary-300">
                                {{$t('create_order.create_order')}}

                            </router-link>
                    </div>
                    <div  class=" p-4 rounded-2xl pb-1  mt-3" v-for="order in filteredAndPaginatedOrders" :key="order.id">
                        <div v-if="checkedselectall == true" :class="[ 'bg-white shadow-100 p-4 rounded-2xl pt-2 mt-3',
                                isselectedorder(order.id) ? 'border-primary-400 bg-primary-50 text-primary-600' : 'bg-white' ]"
                                @click.prevent = push_order_id(order.id) >
                            <div class="flex justify-end pb-2 ">
                                <div class=" w-full flex space-y-1.5">
                                    <div class="flex items-center "> 
                                        <span v-if=" order.data &&  order.data.full_name" class=" font-semibold text-gray-1100 " style="font-size: 20px;"> {{ order.data.full_name }}</span>
                                        <span v-else class="text-nm font-semibold text-gray-1100 pl-2"> {{ order.contact_name }}</span>
                                    </div>
                                </div>
                                <div class="flex justify-end space-x-3 items-center">
                                            <span class="text-green-500 font-semibold">{{formatNumber(order.total_pay)}}đ</span>
                                    </div>
                            
                            
                            </div>
                            <div class=" flex w-full pb-2">
                                    <div class="flex w-full">
                                        <span v-if="order.created_at" class="text-nm  text-gray-1100">{{ formatdate(order.created_at) }}</span>
                                    </div>
                                    <div class="flex justify-end w-full space-y-1.5">
                                    <div class="flex items-center space-x-3">
                                        <p v-if="order.pay_status == 0" class="text-xs text-white bg-red-500 px-2 py-1 rounded-lg">
                                            <span>
                                                {{$t('unpaid.unpaid')}}
                                            </span>
                                        </p>
                                        <p v-if="order.pay_status == 1" class="text-xs text-white bg-green-500 px-2 py-1 rounded-lg">
                                            <span>
                                                {{$t('paid.paid')}}
                                            </span>
                                        </p>
                                        <p v-if="order.status == 4" class="text-xs text-white bg-gray-500 px-2 py-1 rounded-lg">
                                            <span>
                                                {{$t('cancelled.cancelled')}}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex w-full">
                                <div class="flex w-full" >
                                    ID: <span class="text-nm  text-gray-1100 "> {{ order.id }}</span>
                                </div>
                            </div>
                        </div>
                        <div v-else class="bg-white shadow-100 p-4 rounded-2xl  pt-2 mt-3" >
                            <div class="flex justify-end pb-2 ">
                                <div class=" w-full flex space-y-1.5">
                                    <div class="flex items-center "> 
                                        <span v-if=" order.data &&  order.data.full_name" class=" font-semibold text-gray-1100 " style="font-size: 20px;"> {{ order.data.full_name }}</span>
                                        <span v-else class="text-nm font-semibold text-gray-1100 pl-2"> {{ order.contact_name }}</span>
                                    </div>
                                </div>
                                <div class="flex justify-end space-x-3 items-center">
                                            <span class="text-green-500 font-semibold">{{formatNumber(order.total_pay)}}đ</span>
                                    </div>
                            
                            
                            </div>
                            <div class=" flex w-full pb-2">
                                    <div class="flex w-full">
                                        <span v-if="order.created_at" class="text-nm  text-gray-1100">{{ formatdate(order.created_at) }}</span>
                                    </div>
                                    <div class="flex justify-end w-full space-y-1.5">
                                    <div class="flex items-center space-x-3">
                                        <p v-if="order.pay_status == 0" class="text-xs text-white bg-red-500 px-2 py-1 rounded-lg">
                                            <span>
                                                {{$t('unpaid.unpaid')}}
                                            </span>
                                        </p>
                                        <p v-if="order.pay_status == 1" class="text-xs text-white bg-green-500 px-2 py-1 rounded-lg">
                                            <span>
                                                {{$t('paid.paid')}}
                                            </span>
                                        </p>
                                        <p v-if="order.status == 4" class="text-xs text-white bg-gray-500 px-2 py-1 rounded-lg">
                                            <span>
                                                {{$t('cancelled.cancelled')}}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="flex w-full">
                                <div class="flex w-full" >
                                    ID: <span class="text-nm  text-gray-1100 "> {{ order.id }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                   
                </div>
            </div>
            <button v-if="allRecordsLoaded == true" @click.prevent="loadMore" class="flex m-auto h-8 items-center justify-center p-4 rounded-full border bg-white text-xs transition duration-200 bg-gray-10 text-gray-900">
                <span>{{$t('see_more.see_more')}}</span>
            </button>
           <div class="pb-20"></div>
        </div>
    </div>
        <main class="sticky inset-x-0 top-0 z-20 w-full sm:w-[480px]">
            <div>
                Chọn thao tác
            </div>
        </main>
    </div>
    <Footer />
    <div v-if="isShowModal" style="z-index: 9999;" class="relative z-10 " aria-labelledby="modal-title" role="dialog" aria-modal="true">

<div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
<div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div class=" flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        <div class="modal-filter-order absolute  transform overflow-hidden rounded-xl bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md ">
            <header class="relative shrink grow-0 basis-[1%] bg-white p-4 shadow-[inset_0px_-1px_0px_#E5EBED] flex flex-col items-center">
                <h3 class="text-center w-full font-bold leading-snug text-gray-900" id="headlessui-dialog-title-:r3r5:" data-headlessui-state="open">
                    {{ $t('find_order.find_order') }}

                </h3><button class="!absolute right-4 !text-3xl text-gray-400 center-y relative rounded-md text-md h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" @click.prevent="isShowModal = false" type="button" aria-label="Thoát" tabindex="0"><svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.63615 18.3641C6.02668 18.7546 6.65984 18.7546 7.05037 18.3641L12.0001 13.4143L16.9499 18.3641C17.3404 18.7546 17.9736 18.7546 18.3641 18.3641C18.7546 17.9736 18.7546 17.3404 18.3641 16.9499L13.4143 12.0001L18.3641 7.05037C18.7546 6.65984 18.7546 6.02668 18.3641 5.63615C17.9736 5.24563 17.3404 5.24563 16.9499 5.63616L12.0001 10.5859L7.0504 5.63617C6.65987 5.24565 6.02671 5.24565 5.63618 5.63617C5.24566 6.0267 5.24566 6.65986 5.63618 7.05039L10.5859 12.0001L5.63615 16.9499C5.24563 17.3404 5.24563 17.9736 5.63615 18.3641Z" fill="currentColor"></path>
                    </svg>

                </button>
            </header>
            <div data-testid="AddCartView-sheetContent" class="shrink grow-0 basis-[1%] overflow-auto px-4 p-0 max-h-[340px]">
                <div>
                    <div class="relative inline-flex h-10 w-full pl-2 rounded-full bg-gray-25 mt-4">
                        <!-- Icon tìm kiếm -->
                        <div class="absolute flex h-10 w-12 flex-none rounded-full items-center justify-center text-xl text-gray-300" aria-hidden="true">
                            <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3117 17.3433C17.7806 16.8777 18.5311 16.8743 19.0039 17.3333L21.5513 19.3567C22.1496 19.9614 22.1496 20.9407 21.5513 21.5454C20.9516 22.1515 19.9782 22.1515 19.3785 21.5454L17.3047 19.1693C17.0672 18.927 16.9343 18.6001 16.9343 18.2599C16.9343 17.9187 17.0713 17.5854 17.3117 17.3433Z" fill="currentColor" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6123 2C5.85342 2 2 5.89865 2 10.7024C2 15.5061 5.85342 19.4047 10.6123 19.4047C15.3713 19.4047 19.2247 15.5061 19.2247 10.7024C19.2247 8.3952 18.3179 6.182 16.7031 4.54979C15.0882 2.9175 12.8973 2 10.6123 2ZM3.95122 10.7024C3.95122 6.9567 6.95053 3.95122 10.6123 3.95122C12.373 3.95122 14.0654 4.65802 15.316 5.92211C16.5673 7.18683 17.2735 8.906 17.2735 10.7024C17.2735 14.448 14.2741 17.4535 10.6123 17.4535C6.95053 17.4535 3.95122 14.448 3.95122 10.7024Z" fill="currentColor" />
                            </svg>
                        </div>
                        <input v-model="search" class="max-w-[80%] w-full rounded-full pl-11 pr-8  border-none text-black truncate bg-gray-25 px-4  focus:outline-none focus:border-gray-400" type="text" :placeholder="$t('search_name_order_id.search_name_order_id')" style="font-size: 15px; font-weight: normal;" />
                    </div>

                    <div class="grid grid-cols-2 gap-3 h-10 mt-4">
                        <!-- Từ ngày ... đến ngày ... -->
                        <input v-model="start_date" class=" rounded-full border-none text-black truncate bg-gray-25 px-4  focus:outline-none focus:border-gray-400" type="date" style="font-size: 15px; font-weight: normal;" />
                        <input v-model="end_date" class=" rounded-full border-none text-black truncate bg-gray-25 px-4  focus:outline-none focus:border-gray-400" type="date" style="font-size: 15px; font-weight: normal;" />
                    </div>

                    <!-- Trạng thái -->
                    <div class="relative inline-flex h-10 w-full pl-2 rounded-full bg-gray-25 mt-4">
                        <select name="" id="" v-model="status" class="max-w-[80%] w-full rounded-full pr-8  border-none text-black truncate bg-gray-25 px-4  focus:outline-none focus:border-gray-400" type="text" :placeholder="$t('find_product.find_product')" style="font-size: 15px; font-weight: normal;">
                            <option value="">{{ $t('status.status') }}</option>
                            <option value="0">{{ $t('unpaid.unpaid') }}</option>
                            <option value="1">{{ $t('paid.paid') }}</option>
                            <option value="4">{{ $t('cancelled.cancelled') }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 border-t rounded-full border-gray-25 mx-4 py-4 border-none">

                <button :class="[
                'px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300',
            ]" type="button" @click.prevent="closeModal">
                    {{$t('search.search')}}
                </button>
                <button :class="[
                'px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-white border border-black  text-black hover:bg-primary-300 hover:text-white',

            ]" type="reset" @click.prevent="resetFilter">
                    Reset
                </button>

            </div>
        </div>
    </div>
</div>
</div>
<div v-if="isShowModal1" style="z-index: 9999;" class="relative z-10 " aria-labelledby="modal-title" role="dialog" aria-modal="true">

<div class="fixed w-full">
    <div class="fixed  w-full md:w-[480px] mx-auto  inset-x-0 bottom-0 z-10 items-center justify-center">
        <div class="w-full modal-filter-order absolute  transform overflow-hidden rounded-xl bg-gray text-left shadow-xl transition-all  sm:w-full sm:max-w-md ">
            <header class=" relative shrink grow-0 basis-[1%] bg-gray-300 p-4 shadow-[inset_0px_-1px_0px_#E5EBED] flex flex-col items-center">
                <h3 class="text-center w-full font-bold leading-snug text-gray-900" id="headlessui-dialog-title-:r3r5:" data-headlessui-state="open">
                    {{ $t('find_order.find_order') }}
                </h3><button class="!absolute right-4 !text-3xl text-gray-400 center-y relative rounded-md text-md h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" @click.prevent="isShowModal = false" type="button" aria-label="Thoát" tabindex="0"><svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.63615 18.3641C6.02668 18.7546 6.65984 18.7546 7.05037 18.3641L12.0001 13.4143L16.9499 18.3641C17.3404 18.7546 17.9736 18.7546 18.3641 18.3641C18.7546 17.9736 18.7546 17.3404 18.3641 16.9499L13.4143 12.0001L18.3641 7.05037C18.7546 6.65984 18.7546 6.02668 18.3641 5.63615C17.9736 5.24563 17.3404 5.24563 16.9499 5.63616L12.0001 10.5859L7.0504 5.63617C6.65987 5.24565 6.02671 5.24565 5.63618 5.63617C5.24566 6.0267 5.24566 6.65986 5.63618 7.05039L10.5859 12.0001L5.63615 16.9499C5.24563 17.3404 5.24563 17.9736 5.63615 18.3641Z" fill="currentColor"></path>
                    </svg>
                </button>
            </header>
        </div>
    </div>
</div>
</div>
</template>

<script>
import Footer from '@/components/shared/Footer.vue';
import {
    listMyOrder
} from '@/services/order/order'
import {
    useToast
} from "vue-toastification";
// import axios from 'axios';
export default {
    name: 'OrderList',
    props: {

    },
    components: {
        Footer

    },

    data() {
        const toast = useToast()
        return {
            toast,
            isLoading: false,
            listMyOrders: [],
            checkedselectall: false,
            search: '',
            currentPage: 1,
            pageSize: 10,
            allRecordsLoaded: false,
            isShowModal: false,
            start_date: '',
            end_date: '',
            status: '',
            total_count:0,
            array_order_id:[]

        };
    },
    created() {
        document.title =this.$t(`my_order.my_order`);
    },
    methods: {
        deleteselect(){
            this.checkedselectall != this.checkedselectall;
            if(this.checkedselectall == false) this.array_order_id =[];
        },
        isselectedorder(id){
            const index = this.array_order_id.indexOf(id);
           if (index === -1){
                return false
            }
            else{
                return true
            }
        },
        push_order_id(id){
            const index = this.array_order_id.indexOf(id);
        
            if (index === -1){
                this.array_order_id.push(id)
            }
           else {
                // ID đã tồn tại trong mảng, loại bỏ
                this.array_order_id.splice(index, 1);
            }
            console.log(this.array_order_id)
        },
        formatdate(date){
            // console.log(date)
            //2024-05-13T05:27:09.000Z
            const date1 = date.slice(0,10)
            const date2 = date.slice(11,16)
            return `${date1} ${date2}`
        },
        async listMyOrder(type = 'my_order') {
            try {
                const res = await listMyOrder({type:type});
                if(res.data.status ==1){
                    this.listMyOrders = res.data.data
                    this.total_count = res.data.TotalCount
                    if((this.total_count - this.pageSize * this.currentPage ) > 0   )
                    {
                        this.allRecordsLoaded = true
                    }
                    else{
                        this.allRecordsLoaded = false
                    }
                }
                
            } catch (error) {
                console.log(error);
            }
        },
        formatNumber(value) {
            const result = parseFloat(value);

            if (isNaN(result)) {
                return "Invalid value";
            }
            return result.toLocaleString('vi-VN', {
                maximumFractionDigits: 0
            });
        },
        changeType(type) {
            this.listMyOrder(type);
        },
        async loadMore() {
            this.isLoading = true;
            this.currentPage++;
            let data = {type:'my_order',page: this.currentPage,pay_status:this.status,search:this.search}
            const res = await listMyOrder(data);
                if(res.data.status ==1){
                    // this.listMyOrders = [...res.data.data]
                    res.data.data.forEach(item => {
                        this.listMyOrders.push(item);
                    });
                    this.total_count = res.data.TotalCount
                    if((this.total_count - this.pageSize * this.currentPage ) > 0   )
                    {
                        this.allRecordsLoaded = true
                    }
                    else{
                        this.allRecordsLoaded = false
                    }
                    let filteredOrders = this.listMyOrders;
                    if (!Array.isArray(filteredOrders)) {
                        // Trả về một mảng rỗng nếu filteredOrders không phải là mảng
                        this.isLoading = false;
                    }
                }
        },
        openModal() {
            this.isShowModal = true;
        },
        async closeModal() {
            let data = {}
            this.isLoading = true;
            this.isShowModal = false;
            // console.log(this.status, this.start_date,this.end_date,this.search)
            data = {type:'my_order',page: this.currentPage,pay_status:this.status,search:this.search}
            const res = await listMyOrder(data);
            if(res.data.status == 1){
                this.listMyOrders = res.data.data;
                this.total_count = res.data.TotalCount
                if((this.total_count - this.pageSize * this.currentPage ) > 0   )
                {
                    this.allRecordsLoaded = true
                }
                else{
                    this.allRecordsLoaded = false
                }
            }
        },
        resetFilter() {
            this.search=''
            this.status=''
            this.start_date=''
            this.end_date=''
        }

    },
    watch:{
        checkedselectall() {
            if(this.checkedselectall == false) this.array_order_id =[];
    }
    },

    mounted() {
        this.listMyOrder()
    },
    computed: {
        filteredAndPaginatedOrders() {
            // console.log("oke")
          
            let filteredOrders = this.listMyOrders;
            // Kiểm tra nếu filteredOrders không phải mảng
            if (!Array.isArray(filteredOrders)) {
                // Trả về một mảng rỗng nếu filteredOrders không phải là mảng
                filteredOrders = [];
                // console.log("rong")
            }else{
                // if (this.search !== '') {
                //     filteredOrders = this.listMyOrders.filter(order => {
                //         // Chuyển đổi id thành chuỗi để có thể sử dụng includes
                //         return order.id.toString().includes(this.search);
                //     });
                // }

            //  Lọc theo khoảng thời gian
            // if (this.start_date && this.end_date) {
            //     const startDate = new Date(this.start_date);
            //     const endDate = new Date(this.end_date);

            //     if (!isNaN(startDate.getTime()) && !isNaN(endDate.getTime())) {

            //         filteredOrders = filteredOrders.filter(order => {
            //             const orderDate = new Date(order.created_at);
            //             return orderDate >= startDate && orderDate <= endDate;
            //         });
            //     } else {
            //             console.error("Ngày bắt đầu hoặc kết thúc không hợp lệ.");
            //     }
            // }

            // Lọc theo trạng thái
            // if (this.status !== '') {
            //     filteredOrders = filteredOrders.filter(order => {
            //         return order.pay_status === this.status;
            //     });
            // }

            const startIndex = 0;
            const endIndex = this.currentPage * this.pageSize;

            return filteredOrders.slice(startIndex, endIndex)
        }
        return filteredOrders;
        }

    }

};
</script>

<style scoped>
@keyframes slide-up {
  to {
    bottom:5rem;
  }
}

.modal-filter-order {
  bottom: -100%;
  animation: slide-up 0.4s ease forwards;
}
</style>
