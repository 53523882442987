import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/pages/home/home.vue";
import DefaultLayout from "../components/layouts/layoutsindex.vue";
import RankPage from "../components/pages/rank/rank.vue";
// import PromotionPage from "../components/pages/promotion/promotion.vue";
import OnlyFooterLayout from "../components/layouts/OnlyFooter.vue";
import CustomerPage from "../components/pages/customer/customer.vue";
// import ProductPage from "../components/pages/product/product.vue";
import ProductCart from "../components/pages/cart/cart.vue";
import OrderCreate from "../components/pages/order/createOrder.vue";
import OrderList from "../components/pages/order/listOrder.vue";
import OrderListDownline from "../components/pages/order/listOrderDownline.vue";
import OrderListMyRef from "../components/pages/order/listOrderMyRef.vue";
import ProfilePage from "../components/pages/profile/profile.vue";
import LoginPage from "../components/pages/login/login.vue";
import PlatformFee from "../components/pages/platform-fee/PlatformFeeBanner.vue";
import RegistryPage from "../components/pages/registry/registry.vue";
// import LinkedTiktokPage from "../components/pages/profile/tiktok/tiktok.vue";
import NotificationPage from "../components/pages/notification/notification.vue";
import Detailpromotion from "../components/pages/promotion/detailpromotion.vue";

import ModalProductAdd from "../components/layouts/ModalAddProduct.vue";
import ModalAddress from "../components/layouts/ModalAddress.vue";

import LoginPhonePage from "../components/pages/login/loginphone.vue";
import Registerpartner from "../components/pages/registry/registerpartner.vue";
import NewCustomerPage from "../components/pages/customer/newcustomer.vue";

import ListTrainingCalendar from "../components/section/home-section/TrainingYoutube.vue";

const routes = [
  {
    path: "/:catchAll(.*)",
    component: LoginPage, //  xử lý trường hợp không khớp
  },
  {
    path: "/",
    component: LoginPage,
    meta: { datashop1: [] }
  },
  {
    path: "/platformfee",
    component: PlatformFee,
  },
  {
    path: "/register",
    component: RegistryPage,
  },
  {
    path: "/forgotpassword",
    component: () =>
      import("../components/pages/forgotpassword/forgotpassword.vue"),
  },
  {
    path: "/phone-login",
    component: LoginPhonePage,
  },
  {
    path: "/notification",
    component: NotificationPage,
  },
  {
    path: "/register-partner",
    component: Registerpartner,
  },
  {
    path: "/notification",
    component: NotificationPage,
  },
  {
    path: "/product",
    // component: ProductPage,
    component: () => import("../components/pages/product/product.vue"),
  },
  {
    path: "/product/:id",
    component: () => import("../components/pages/product/productdetail.vue"),
  },
  {
    path: "/cart",
    component: ProductCart,
  },
  {
    path: "/customer/new",
    component: NewCustomerPage,
  },
  {
    path: "/order/create",
    name: "order-create",
    component: OrderCreate,
  },
  {
    path: "/my_order",
    name: "order",
    component: OrderList,
  },
  {
    path: "/my_reffer",
    name: "my_reffer",
    component: OrderListMyRef,
  },
  {
    path: "/downline",
    name: "downline",
    component: OrderListDownline,
  },
  {
    path: "/print",
    name: "listOrderPrint",
    component: () =>
      import("../components/pages/order/listOrderPrint.vue"),
  },
  {
    path: "/add-product",
    name: "add-product",
    component: ModalProductAdd,
  },
  {
    path: "/home/listtraning",
    name: "TrainingYoutube",
    component: ListTrainingCalendar,
  },
  {
    path: "/home/listtraningtest",
    name: "ListTrainingCalendar",
    component: () => import("../components/section/home-section/detailtraning.vue"),
  },
  {
    path: "/address",
    component: ModalAddress,
  },
  {
    path: "/profile/commission",
    name: "CommissionUser",
    component: () => import("../components/pages/profile/commission.vue"),
  },
  {
    path: "/profile/banking",
    name: "BangkingUser",
    component: () => import("../components/pages/profile/banking.vue"),
  },

  {
    path: "/profile",
    component: ProfilePage,
  },
  {
    path: "/profile/tiktok",
    component: () => import("../components/pages/profile/tiktok/tiktok.vue"),
  },
  {
    path: "/profile/vouchers",
    name: "VouhersCoupons",
    component: () => import("../components/pages/profile/Vourcher.vue"),
  },
  {
    path: "/profile/me/edit",
    name: "ProfileEdit",
    component: () => import("../components/pages/profile/editprofile.vue"),
  },
  {
    path: "/profile/me",
    name: "ProfileMe",
    component: () => import("../components/pages/profile/profileme.vue"),
  },
  {
    path: "/promotion/:id",
    name: "detailpromotion",
    component: Detailpromotion,
    props: true, // Truyền data component thông qua props
  },
  {
    path: "/promotion",
    name: "promotion",
    //  component: PromotionPage
    component: () => import("../components/pages/promotion/promotion.vue"),
  },
  {
    path: "/register/step5",
    name: "Registry_step5",
    component: () => import("../components/pages/registry/Step5.vue"),
    props: true, // Truyền data vào component thông qua props
  },
  {
    path: "/product/:id",
    name: "ProductDetail",
    component: () => import("../components/pages/product/productdetail.vue"),
    props: true, // Truyền data vào component thông qua props
  },
  {
    path: "/member",
    //  component: PromotionPage
    component: () => import("../components/pages/member/member.vue"),
  },
  {
    path: "/",
    component: DefaultLayout,
    OnlyFooterLayout,
    children: [
      { path: "home", component: Home },
      { path: "rank", component: RankPage },
      {
        path: "promotion",
        //  component: PromotionPage
        component: () => import("../components/pages/promotion/promotion.vue"),
      },
      
      {
        path: "share",
        //  component: PromotionPage
        component: () => import("../components/pages/share/share.vue"),
      },
      {
        path: "support",
        //  component: PromotionPage
        component: () => import("../components/pages/support/support.vue"),
      },
      {
        path: "courses",
        //  component: PromotionPage
        component: () => import("../components/pages/courses/courses.vue"),
      },
      {
        path: "income",
        //  component: PromotionPage
        component: () => import("../components/pages/income/income.vue"),
      },

      { path: "customer", component: CustomerPage },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  // Kiểm tra xem người dùng đã đăng nhập chưa
  const isLoggedIn = JSON.parse(localStorage.getItem("islogin"));
  if (isLoggedIn && to.path === "/") {
    next("/home");
  }
  // Nếu người dùng đã đăng nhập hoặc đang truy cập trang đăng nhập, cho phép truy cập
  if (
    isLoggedIn ||
    to.path === "/" ||
    to.path === "/forgotpassword" ||
    to.path === "/register" ||
    to.path === "/register" ||
    to.path === "/platformfee" ||
    to.path === "/register/step5"
  ) {
    next();
  } else {
    // Nếu chưa đăng nhập và đang truy cập trang khác, chuyển hướng đến trang đăng nhập
    console.log("Bạn cần đăng nhập");
    next("/"); // Chuyển hướng đến trang đăng nhập
  }
});
export default router;
