<template>
  <div class="pb-20">
    <router-view />
    <Footer />
  </div>
</template>

<script>
// Import các thành phần cần thiết từ Vue
import Footer from "../shared/Footer.vue";

export default {
  components: {
    Footer
  }
}
</script>

<style scoped>
/* Thêm CSS tại đây nếu cần */
</style>
