<template>
  <div id="app" class="min-h-screen min-w-[480px] md:w-[480px] mx-auto ">
    <router-view></router-view>
  </div>
</template>

<script>
//import { defineComponent } from 'vue';
import { RouterView } from 'vue-router';
import { getShopCurren } from "@/services/affiliate/affiliate";
export default {
  name: 'App',
  components: {
    RouterView
  },
  async created() {
    if (process.env.NODE_ENV === 'production') {
     console.warn = function () { };
     console.error = function () { };
    }
    //  await getShopCurren("dropship");
    if (!localStorage.getItem('getshop')) {
      
      const currentUrl = window.location.href;
      const match = currentUrl.match(/https:\/\/([^.]+)\.salekit/);
      let Username;
      if (match && match[1]) {
        Username = match[1];
        if (localStorage.getItem('Username') && localStorage.getItem('Username') !== Username) {
          localStorage.setItem('Username', Username);
          const response1 = await getShopCurren(Username);
          if (response1.data.status == 1) {
            this.datashop = response1.data.data;
            localStorage.setItem('getshop', JSON.stringify(response1.data.data));
            // Kiểm tra số lần reload đã lưu trong localStorage
            let reloadCount = localStorage.getItem('reloadCount') ? parseInt(localStorage.getItem('reloadCount')) : 0;
            if (reloadCount < 1) {
              // Tăng số lần reload lên và cập nhật lại trong localStorage
              localStorage.setItem('reloadCount', JSON.stringify(reloadCount + 1));
              // Chuyển hướng người dùng và reload trang
              this.$router.push("/").then(() => {
                location.reload();
              });
            }
          }
          else {
            // Nếu điều kiện không được thoả mãn, đặt lại reloadCount về 0 để quá trình có thể lặp lại sau này
            localStorage.setItem('reloadCount', JSON.stringify(0));
          }

          document.title = this.datashop.seo_title;
          var metaDescription = document.createElement('meta');
          metaDescription.name = "description";
          metaDescription.content = this.datashop.seo_description;
          document.head.appendChild(metaDescription);
        }
        else {
          localStorage.setItem('Username', Username);
          const response1 = await getShopCurren(Username);
         
          this.datashop = response1.data.data;
          localStorage.setItem('getshop', JSON.stringify(response1.data.data));

          document.title = this.datashop.seo_title;
          metaDescription = document.createElement('meta');
          metaDescription.name = "description";
          metaDescription.content = this.datashop.seo_description;
          document.head.appendChild(metaDescription);
        }
      }
    }
    else {
      // console.log("ko vao")
      if(localStorage.getItem('getshop') === (undefined || null) ){
        localStorage.removeItem('getshop');
        return;
      }
      this.datashop = JSON.parse(localStorage.getItem('getshop'));
      document.title = this.datashop.seo_title;
      metaDescription = document.createElement('meta');
      metaDescription.name = "description";
      metaDescription.content = this.datashop.seo_description;
      document.head.appendChild(metaDescription);

    }
  }
};


</script>

<style></style>
