<template>
<div className="flex flex-col bg-white  rounded-lg ">
        <div className="mx-4 flex justify-between border-b  py-2 rounded-lg">
          <router-link className="flex items-center gap-4 uppercase" to="/home">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7 13C7 12.4477 7.44772 12 8 12C8.55228 12 9 12.4477 9 13C9 13.5523 8.55228 14 8 14C7.44772 14 7 13.5523 7 13Z" fill="currentColor"></path><path d="M8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18C8.55228 18 9 17.5523 9 17C9 16.4477 8.55228 16 8 16Z" fill="currentColor"></path><path d="M11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13Z" fill="currentColor"></path><path d="M12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16Z" fill="currentColor"></path><path d="M15 13C15 12.4477 15.4477 12 16 12C16.5523 12 17 12.4477 17 13C17 13.5523 16.5523 14 16 14C15.4477 14 15 13.5523 15 13Z" fill="currentColor"></path><path d="M16 16C15.4477 16 15 16.4477 15 17C15 17.5523 15.4477 18 16 18C16.5523 18 17 17.5523 17 17C17 16.4477 16.5523 16 16 16Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7 3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3L15 3C15 2.44772 15.4477 2 16 2C16.5523 2 17 2.44772 17 3C19.7614 3 22 5.23858 22 8V17C22 19.7614 19.7614 22 17 22H7C4.23858 22 2 19.7614 2 17V8C2 5.23858 4.23858 3 7 3ZM15 5V6C15 6.55228 15.4477 7 16 7C16.5523 7 17 6.55228 17 6V5C18.6569 5 20 6.34315 20 8H4C4 6.34315 5.34315 5 7 5V6C7 6.55228 7.44772 7 8 7C8.55228 7 9 6.55228 9 6V5H15ZM4 10V17C4 18.6569 5.34315 20 7 20H17C18.6569 20 20 18.6569 20 17V10H4Z" fill="currentColor"></path></svg>
            <b className="text-gray-900 text-nm">{{$t('training_schedule.training_schedule')}}</b>
          </router-link>
          <a
            className="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap leading-none focus:outline-none bg-transparent h-10 text-primary-400 hover:text-primary-300 -mr-4"
            href="/home"
          >
            <span>
              <p className="text-primary-400 text-sm">{{$t('aff_see_more.aff_see_more')}}</p>
            </span>
            <span className="text-lg ml-2">
              <svg
                width="1em"
                height="1em"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8.29289 5.29289C8.68342 4.90237 9.31658 4.90237 9.70711 5.29289L15.7071 11.2929C16.0976 11.6834 16.0976 12.3166 15.7071 12.7071L9.70711 18.7071C9.31658 19.0976 8.68342 19.0976 8.29289 18.7071C7.90237 18.3166 7.90237 17.6834 8.29289 17.2929L13.5858 12L8.29289 6.70711C7.90237 6.31658 7.90237 5.68342 8.29289 5.29289Z"
                  fill="currentColor"
                />
              </svg>
            </span>
          </a>
        </div>
        <div v-if="isloading" className="flex !flex-nowrap overflow-auto no-scrollbar py-4 divide-x divide-gray-10 " style="height: 137px;">
          <div>
            <div className="relative">
            <div className="absolute" style="top:0px" >
            <div class=" grid grid-cols-2">
              <div className="bg-white shadow-100 p-4 rounded-2xl space-y-3">
                  <div className="h-4 rounded w-52 animate-pulse bg-gray-800 bg-opacity-10"></div>
                  <div className="h-4 rounded w-52 animate-pulse bg-gray-800 bg-opacity-10" ></div>
                <div className="h-8 rounded w-28 animate-pulse bg-gray-800 bg-opacity-10" ></div>
              </div>
            </div>
          </div>
          </div>
          </div>
      
         
       
        </div>
        <div v-else className="flex !flex-nowrap overflow-auto no-scrollbar py-4 divide-x divide-gray-10 ">
          <router-link to="/home" >
            <!-- v-for="(training, index) in this.listtraning" :key="index"  -->
            <div class="flex px-4 w-[320px]">
              <div class="flex w-[200px] flex-col gap-2">
                <p class="h-[auto] font-semibold text-gray-900 text-nm ">
                 {{ listtraning.name}}
                </p>
                <p class="text-gray-900 text-xs">247 Cầu giấy, Hà Nội</p>
                <b class="text-gray-900 text-sm">{{listtraning.created_at_show}}</b>
              
              </div>
              <div>
                <div class="relative w-[120px]">
                  <div class="relative h-0" style="padding-bottom: 100%">
                    <div class="absolute inset-0 h-full w-full h-full w-full rounded-lg object-cover animate-pulse bg-gray-800 bg-opacity-10"></div>
                    <img 
                      alt="PDP logo"
                      class="absolute inset-0 h-full w-full object-cover transition duration-500 h-full w-full rounded-lg object-cover"
                      :height="120"
                      loading="eager"
                      :width="120"
                      :src="listtraning.logo_image_show"
                    />
                   

                  </div>
                  <!-- <button class="!absolute  right-0 top-0 flex h-10 w-10 items-center justify-center rounded-br-none rounded-tl-none bg-white relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent  text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer " 
                 
                  type="button" aria-label="unfollow" fdprocessedid="9ixreb"><svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.39157 3.06086C7.4741 2.28229 8.89567 2 10.3527 2H14.9004C16.4064 2 17.8405 2.30411 18.918 3.13295C20.0414 3.99718 20.625 5.3128 20.625 6.98416V20.0118C20.625 21.5233 18.9982 22.4862 17.6741 21.7373L12.6501 18.3206L7.57127 21.7444C6.24715 22.4902 4.625 21.5254 4.625 20.017V6.79449C4.625 5.1471 5.25497 3.87831 6.39157 3.06086ZM7.55933 4.68454C7.00767 5.0813 6.625 5.70976 6.625 6.79449V19.982L12.1611 16.3006C12.4667 16.1285 12.8401 16.1291 13.1452 16.3021L18.625 19.9773V6.98416C18.625 5.81147 18.2404 5.13501 17.6985 4.71817C17.1106 4.26595 16.1824 4 14.9004 4H10.3527C9.10737 4 8.16506 4.2489 7.55933 4.68454Z" fill="currentColor"></path></svg></button> -->
                </div>
              </div>
            </div>
          </router-link>
        
          <router-link to="/home">
            <div className="flex px-4  w-[295px]">
              <div className="flex w-[175px] flex-col gap-2">
                <p className="h-[44px] font-semibold text-gray-900 text-nm line-clamp-2">
                  BUỔI ĐÀO TẠO KIẾN THỨC CHUYÊN SÂU VỀ CÁC SẢN PHẨM TRANG ĐIỂM
                  NỀN DA ‼
                </p>
                <b className="text-gray-900 text-sm">19:30 - 22/01/2024</b>
                <p className="text-gray-900 text-xs">PUNAR (C`CHOI)</p>
              </div>
              <div>
                <div className="relative w-[120px]">
                  <div
                    className="relative h-0"
                    style=" padding-bottom: 100%"
                  >
                    <div className="absolute inset-0 h-full w-full h-full w-full rounded-lg object-cover animate-pulse bg-gray-800 bg-opacity-10" />
                    <img
                      alt="PDP logo"
                      className="absolute inset-0 h-full w-full object-cover transition duration-500 h-full w-full rounded-lg object-cover"
                      height={120}
                      loading="eager"
                      width={120}
                      src="https://cdn.droppii.com/droppii-production-public/product/pdp/6c742a3e-6a31-4e70-b0e2-707a609bce55/training-schedules/medias/8c55f75b-37c0-454c-abfe-8c39df447ff5_240x240.jpeg"
                    />
                  </div>
                 
                </div>
              </div>
            </div>
          </router-link>
         
          <div className="relative">
            <div className="absolute" style="top:0px" >
            <div>
              <div className="bg-white shadow-100 p-4 rounded-2xl space-y-3">
                <div className="h-8 rounded w-28 animate-pulse bg-gray-800 bg-opacity-10" ></div>
                <div className="h-4 rounded w-52 animate-pulse bg-gray-800 bg-opacity-10" ></div>
                <div className="h-4 rounded w-52 animate-pulse bg-gray-800 bg-opacity-10"></div>
              </div>
            </div>
          </div>
        </div>
          
        
        </div>
      </div>
</template>

<script>
import {
  training
} from '@/services/affiliate/affiliate';
export default {
  name: 'TrainingCalendar',
  props: ['combo'],
  data() {
    return {
        clickbuy: false,
       datadetail:'',
       isloading:false,
       valuecombo: 1,
       valuecombo1: '',
       listStores:'',
       addcombo1:'',
       total:'',
       selectedStoreIndex:'',
       color: "gary",
       store:'',
       datadetailbuy:'',
       detailbuy: false,
       deletecombo:false,
       listtraning:'',
      
      // Khai báo các biến dữ liệu cần thiết
    };
  },
  methods: {
    async getstraing(){
      this.isloading = true;
      const response = await training();
      if(response && response.data){
        // console.log(response.data.source);
        this.listtraning = response.data.source;
        this.isloading = false;
      }
    }
  },
  created() {
    // this.getstraing();
  },
  mounted() {
   this.getstraing();
  },
  computed: {
  }
  
}
</script>

<style scoped>
/* Your component-specific styles here */
</style>