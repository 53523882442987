<template>
  <div class="absolute top-0 sm:container z-100 min-h-screen w-full md:w-[480px] mx-auto">
    <header class="fixed top-0 z-20">
      <div class="border-b border-gray-25 bg-white sm:container bg-white">
        <div class="w-[480px] relative flex flex-col justify-center pr-14 h-[64px] pl-12">
          <h5 class=" !font-bold leading-snug font-semibold text-gray-900 text-lg truncate">
            Sửa thông tin khách
            hàng
          </h5>
          <button @click="onCloseModalEdit"
            class="!absolute left-2 center-y relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
            type="button">
            <svg class="h-6 w-6" height="1em" width="1em" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
                fill="currentColor" />
            </svg>
          </button>
        </div>
      </div>
    </header>
    <main class="bg-gray-10 sm:min-h-screen sm:container pb-[72px] pt-[65px]">
      <form @submit.prevent="handleUpdateCustomer">
        <div class="bg-white px-4 py-6 space-y-3 ">
          <div>
            <label class="block text-base font-medium text-gray-600 mb-1" for="name">
              Họ và tên
              <span class="ml-0.5 text-red-600">*</span>
            </label>
            <div class="relative inline-flex w-full">
              <input
                class="block w-full rounded-lg py-2 text-normal font-normal px-3 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none focus:border-blue-700 border border-gray-400 placeholder:text-gray-400 plachoder:font-medium"
                name="name" placeholder="Nhập họ và tên khách hàng" id="name" label="Họ và tên" :value="name"
                @change="ev => name = ev.target.value" />
            </div>
            <span v-if="!!errors.name" class="ml-0.5 text-red-600">{{ errors.name }}</span>
          </div>
          <div class="w-full">
            <label class="block text-base font-medium text-gray-600 mb-1" for="phone">
              Số điện thoại
              <span class="ml-0.5 text-red-600">*</span>
            </label>
            <div class="relative inline-flex w-full">
              <input
                class="block w-full rounded-lg py-2 text-normal font-normal px-3 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none focus:border-blue-700 border border-gray-400 placeholder:text-gray-400 plachoder:font-medium"
                type="tel" placeholder="Nhập số điện thoại khách hàng" id="phone" label="Số điện thoại" :value="phone"
                @change="ev => phone = ev.target.value" />
            </div>
            <span v-if="!!errors.phone" class="ml-0.5 text-red-600">{{ errors.phone }}</span>
          </div>
          <div class="w-full">
            <label class="block text-normal font-medium text-gray-500 mb-1" for="province">
              Chọn
              Tỉnh/Thành Phố
              <span class="ml-0.5 text-red-600">*</span>
            </label>
            <button class="relative w-full" type="button" @click="handleOpenModalProvince">
              <div class="relative inline-flex w-full">
                <input
                  class="block w-full rounded-lg py-2 text-normal font-normal px-3 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none focus:border-blue-700 border border-gray-400 placeholder:text-gray-400 plachoder:font-medium cursor-pointer"
                  type="text" name="province" placeholder="Tìm tỉnh hoặc thành phố" id="province" readonly
                  :value="province_name" />
              </div>
              <div class="absolute right-2 top-[50%] translate-y-[-50%]">
                <svg class="text-3xl text-gray-300" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                    fill="currentColor" />
                </svg>
              </div>
            </button>
            <span v-if="!!errors.province_id" class="ml-0.5 text-red-600">{{ errors.province_id }}</span>
          </div>
          <div class="w-full">
            <label class="block text-normal font-medium text-gray-500 mb-1" for="district">
              Chọn
              Quận/Huyện
              <span class="ml-0.5 text-red-600">*</span>
            </label>
            <button class="relative w-full" type="button" @click="handleOpenModalDistrict">
              <div class="relative inline-flex w-full">
                <input
                  class="block w-full rounded-lg py-2 text-normal font-normal px-3 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none focus:border-blue-700 border border-gray-400 placeholder:text-gray-400 plachoder:font-medium cursor-pointer"
                  type="text" name="district" placeholder="Tìm Quận hoặc Huyện" id="district" readonly
                  :value="district_name" />
              </div>
              <div class="absolute right-2 top-[50%] translate-y-[-50%]">
                <svg class="text-3xl text-gray-300" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                    fill="currentColor" />
                </svg>
              </div>
            </button>
            <span v-if="!!errors.district_id" class="ml-0.5 text-red-600">{{ errors.district_id }}</span>
          </div>
          <div class="w-full">
            <label class="block text-normal font-medium text-gray-500 mb-1" for="ward">
              Chọn
              Phường/Xã
              <span class="ml-0.5 text-red-600">*</span>
            </label>
            <button class="relative w-full" type="button" @click="handleOpenModalWard">
              <div class="relative inline-flex w-full">
                <input
                  class="block w-full rounded-lg py-2 text-normal font-normal px-3 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none focus:border-blue-700 border border-gray-400 placeholder:text-gray-400 plachoder:font-medium cursor-pointer"
                  type="text" name="ward" placeholder="Tìm Phường hoặc Xã" id="ward" readonly :value="ward_name" />
              </div>
              <div class="absolute right-2 top-[50%] translate-y-[-50%]">
                <svg class="text-3xl text-gray-300" width="1em" height="1em" viewBox="0 0 24 24" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                    fill="currentColor" />
                </svg>
              </div>
            </button>
            <span v-if="!!errors.ward_id" class="ml-0.5 text-red-600">{{ errors.ward_id }}</span>
          </div>
          <div class="w-full">
            <label class="block text-base font-medium text-gray-600 mb-1" for="address">
              Chọn Số nhà,tên đường
              <span class="ml-0.5 text-red-600">*</span>
            </label>
            <div class="relative inline-flex w-full">
              <input
                class="block w-full rounded-lg py-2 text-normal font-normal px-3 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none focus:border-blue-700 border border-gray-400 placeholder:text-gray-400 plachoder:font-medium"
                name="address" placeholder="Nhập Số nhà, tên đường" id="address" :value="address"
                @change="ev => address = ev.target.value" />
            </div>
            <span v-if="!!errors.address" class="ml-0.5 text-red-600">{{ errors.address }}</span>
          </div>
        </div>
        <footer class="fixed md:w-[480px] w-full bottom-0 z-50">
          <div class="flex h-[72px] items-center border border-t border-gray-25 bg-white px-4 sm:container">
            <button
              class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300"
              type="submit">Lưu thay đổi</button>
          </div>
        </footer>
      </form>
    </main>
    <ModalProvince v-if="isOpenModalProvince" :onCloseModalProvince="handleClosenModalProvince"
      :onChangeProvince="handleChangeProvince" />
    <ModalDistrict v-if="isOpenModalDistrict" :provinceName="province_name" :provinceId="province_id"
      :onCloseModalDistrict="handleClosenModalDistrict" :onBackModalDistrict="handleBackModalDistrict"
      :onChangeDistrict="handleChangeDistrict" />
    <ModalWard v-if="isOpenModalWard" :districtName="district_name" :districtId="district_id"
      :onCloseModalWard="handleClosenModalWard" :onBackModalWard="handleBackModalWard"
      :onChangeWard="handleChangeWard" />
  </div>
</template>
<script>
import ModalProvince from "@/components/layouts/ModalProvince.vue";
import ModalDistrict from "@/components/layouts/ModalDistrict.vue";
import ModalWard from "@/components/layouts/ModalWard.vue";
import { addUpdateCustomer } from "../../services/customer/customerService";
import { POSITION, useToast } from "vue-toastification";
export default {
  name: "ModalEditCustomer",
  props: {
    customerInfor: Object,
    onCloseModalEdit: Function,
    onCloseAllModal: Function
  },
  components: {
    ModalProvince,
    ModalDistrict,
    ModalWard
  },
  data() {
    return {
      name: "",
      phone: "",
      province_id: "",
      province_name: "",
      district_id: "",
      district_name: "",
      ward_id: "",
      ward_name: "",
      address: "",
      errors: {
        name: "",
        phone: "",
        province_id: "",
        district_id: "",
        ward_id: "",
        address: ""
      },
      isOpenModalProvince: false,
      isOpenModalDistrict: false,
      isOpenModalWard: false
    };
  },
  methods: {
    validate(data) {
      let isCheckData = true;
      Object.keys(data).forEach(key => {
        switch (key) {
          case "name":
            if (data["name"] === "") {
              this.errors.name = "Vui lòng nhập thông tin của bạn";
              isCheckData = false;
            }
            else {
              this.errors.name = "";
            }
            break;
          case "phone":
            if (data["phone"] === "") {
              this.errors.phone = "Vui lòng nhập số điện thoại";
              isCheckData = false;
            } else if (
              !/^(0[3|5|7|8|9]|\+84)([0-9]{8})$/.test(data["phone"])
            ) {
              this.errors.phone = "Số điện thoại không đúng định dạng";
              isCheckData = false;
            }
            else {
              this.errors.phone = "";
            }
            break;
          case "province_id":
            if (data["province_id"] === "") {
              this.errors.province_id = "Vui lòng nhập thông tin của bạn";
              isCheckData = false;
            }

            else {
              this.errors.province_id = "";
            }
            break;
          case "district_id":
            if (data["district_id"] === "") {
              this.errors.district_id = "Vui lòng nhập thông tin của bạn";
              isCheckData = false;
            }

            else {
              this.errors.district_id = "";
            }
            break;
          case "ward_id":
            if (data["ward_id"] === "") {
              this.errors.ward_id = "Vui lòng nhập thông tin của bạn";
              isCheckData = false;
            }

            else {
              this.errors.ward_id = "";
            }
            break;
          case "address":
            if (data["address"] === "") {
              this.errors.address = "Vui lòng nhập thông tin của bạn";
              isCheckData = false;
            }

            else {
              this.errors.address = "";
            }
            break;
        }
      });
      return isCheckData;
    },
    async handleUpdateCustomer() {
      const toast = useToast();
      const formData = {
        id: this.customerInfor.id,
        name: this.name,
        phone: this.phone,
        province_id: this.province_id,
        district_id: this.district_id,
        ward_id: this.ward_id,
        address: this.address
      };
      if (this.validate(formData)) {
        const getIdHash = JSON.parse(localStorage.getItem("datauserInfo")).id_hash;
        await addUpdateCustomer(getIdHash, formData)
          .then(res => {
            if (!res.error) {
              toast.success(res.msg, { position: POSITION.BOTTOM_CENTER });
              this.onCloseAllModal();
            } else {
              toast.error(res.msg);
            }
          })
          .catch(error => {
            console.log(error);
            throw new Error(error);
          });
      }
    },
    //Mở modal tỉnh/thành phố
    handleOpenModalProvince() {
      this.isOpenModalProvince = true;
    },
    //Đóng modal tỉnh/thành phố
    handleClosenModalProvince() {
      this.isOpenModalProvince = false;
    },
    //Chọn tỉnh/thành phố
    handleChangeProvince(province) {
      this.province_id = province.id;
      this.province_name = province.name;
      this.isOpenModalProvince = false;
      this.isOpenModalDistrict = true;
    },
    //Mở modal quận/huyện
    handleOpenModalDistrict() {
      this.isOpenModalDistrict = true;
    },
    //Đóng modal quận/huyện
    handleClosenModalDistrict() {
      this.isOpenModalDistrict = false;
    },
    //Quay trở lại modal tỉnh/thành phố
    handleBackModalDistrict() {
      this.isOpenModalProvince = true;
      this.isOpenModalDistrict = false;
    },
    //Chọn quận/huyện
    handleChangeDistrict(district) {
      this.district_id = district.id;
      this.district_name = district.name;
      this.isOpenModalDistrict = false;
      this.isOpenModalWard = true;
    },
    //Mở modal phường/xã
    handleOpenModalWard() {
      this.isOpenModalWard = true;
    },
    //Đóng modal phường/xã
    handleClosenModalWard() {
      this.isOpenModalWard = false;
    },
    //Quay trở lại modal quận/huyện
    handleBackModalWard() {
      this.isOpenModalDistrict = true;
      this.isOpenModalWard = false;
    },
    //Chọn quận/huyện
    handleChangeWard(ward) {
      this.ward_id = ward.id;
      this.ward_name = ward.name;
      this.isOpenModalWard = false;
    }
  },
  mounted() {
    this.name = this.customerInfor.name;
    this.phone = this.customerInfor.phone;
    this.province_name = this.customerInfor.province_name;
    this.province_id = this.customerInfor.province_id;
    this.district_name = this.customerInfor.district_name;
    this.district_id = this.customerInfor.district_id;
    this.ward_name = this.customerInfor.ward_name;
    this.ward_id = this.customerInfor.ward_id;
    this.address = this.customerInfor.address;
  }
};
</script>