// import axios from "axios";
import axiosInstance from "@/axios";
const axios = axiosInstance;
import Config from "@/components/config";
// import axios1 from '@/axios';


const BASE_URL = Config.BASE_URL + "/dropship";
// const username = localStorage.getItem('Username');
let datashop = localStorage.getItem('getshop') || '';
if(datashop && datashop.length > 1){
  datashop = JSON.parse(datashop)
}
else{
  if(datashop && datashop.length < 1){
    setTimeout(() => {
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')):'' ;
    
    }, 2000);
  }
}
let token;
if (datashop) {
  token = datashop.token;
}
  
const SIGNUP_API = BASE_URL + "/signup";
const RESEND_CODE_API = BASE_URL + "/resend-code";

//const token = `eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDIzLTEyLTE4VDE2OjMyOjM3KzA3MDAiLCJzaG9wX2lkIjoiODZlNzg0OTllZWIzM2ZiOWNhYzE2Yjc1NTViNTA3NjcifQ.bUI5cTy8t5mtiRQ6cXcLZWDfp-uHEpAz90mg8UuOJQE`;
// const datashop =  JSON.parse(localStorage.getItem('datashop'));
// const token = datashop.token;

const config = {
  headers: {
    "Content-Type": "application/json",
  },
};
const signupsuccess = async (body) => {
  const config1 = {
    headers: {
      "Content-Type": "application/json",
      // Username: username,
      Token: token
    },
  };
  return await axios.post(Config.BASE_URL_Node + '/member/signup-success', body, config1);
 
};
const getPositions = async (username) => {
  if(!username ||(username && username.length == 0)){
    return;
  }
  const config1 = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return await axios.get(`${Config.BASE_URL_Node}/position?Username=`+username, config1);
    
};
const forgotpassword = async (body) => {
  if(datashop && datashop.length > 0){
   
    const config1 = {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token
        // Cookie: "ci_session=92c7mu67klpriile7pt4sfpolj4kvl74; ci_session=iq82he3a738nae4c6952p894fr2ed257; ci_session=iq82he3a738nae4c6952p894fr2ed257"
     
      },
      withCredentials: true 
    };
  return await axios.post(Config.BASE_URL_Node + '/member/forgot-password', body, config1);
  }else{
   
    datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
    const config1 = {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token
        // Cookie: "ci_session=92c7mu67klpriile7pt4sfpolj4kvl74; ci_session=iq82he3a738nae4c6952p894fr2ed257; ci_session=iq82he3a738nae4c6952p894fr2ed257"
     
      },
      withCredentials: true 
    };
    return await axios.post(Config.BASE_URL_Node + '/member/forgot-password', body, config1);
  }
  
};
const verifycode = async (body) => {
  const config1 = {
    headers: {
      "Content-Type": "application/json",
      "Token": token,
      "Cookie": "ci_session=92c7mu67klpriile7pt4sfpolj4kvl74; ci_session=iq82he3a738nae4c6952p894fr2ed257; ci_session=iq82he3a738nae4c6952p894fr2ed257"
    },
    withCredentials: true 
  };
  
  // return await axios.post('http://localhost:3038/api/v1/member/verify-code', body, config1);
  return await axios.post(Config.BASE_URL_Node + '/member/verify-code', body, config1);
};
const verifycode1 = async (body) => {
  const config1 = {
    headers: {
      "Content-Type": "application/json",
      "Token": token,
      "Cookie": "ci_session=92c7mu67klpriile7pt4sfpolj4kvl74; ci_session=iq82he3a738nae4c6952p894fr2ed257; ci_session=iq82he3a738nae4c6952p894fr2ed257"
    },
     withCredentials: true 
  };
  const response = await axios.post(Config.BASE_URL_Node + '/member/verify-code', body, config1);
  return response;

};

const resetpassword = async (body) => {
  const config1 = {
    headers: {
      "Content-Type": "application/json",
      "Token": token,
    },
    withCredentials: true 
  };
  return await axios.post(Config.BASE_URL_Node + '/member/reset-password', body, config1);
};

const getLogin = async (body, attempt = 0) => {
  // let token = localStorage.getItem('token'); // Ví dụ lấy token từ localStorage
  const config = {
    headers: {
      "Content-Type": "application/json",
      Token: token,
    },
  };
  try {
    const response = await axios.post(`${Config.BASE_URL_Node}/member/login`, body, config);
    if (response.data.status === 4 && attempt < 3) {
      console.log("reload")
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      token = datashop.token || '';
      // localStorage.setItem('token', token); // Cập nhật token mới
     
      return getLogin(body, attempt + 1); // Thử lại với token mới
    }

    localStorage.setItem("islogin", true);
    return response.data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error; // Hoặc xử lý lỗi theo cách khác
  }
};
// const getLogin = async (body) => {
// 
//   const config1 = {
//     headers: {
//       "Content-Type": "application/json",
//       Token: token,
//     },
//   };
//   localStorage.setItem("islogin", true);
//   const response = await axios.post(Config.BASE_URL_Node + '/member/login', body, config1);
//  
//   if(response.data.status == 4){
//     datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')):'' ;
//     token = datashop.token;
//    
//     await getLogin(body)
//   }
//   return response.data;
// };
const getResendCode = async (body) => {
  const response = await axios.post(RESEND_CODE_API, body, config);
  if (response.data.error) {
    throw new Error(response.data?.msg);
  }
  return response.data;
};

const getRegister = async (body) => {
  try {
    const response = await axios.post(SIGNUP_API, body);

    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};



export { getLogin, getResendCode, getRegister, getPositions, signupsuccess, forgotpassword, verifycode, verifycode1, resetpassword };
