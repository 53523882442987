import axios from "axios";
import Config from "@/components/config";
const BASE_URL = Config.BASE_URL+"/dropship";
// const BASE_URL = "https://salekit.net/api/v1/affiliate/dropship";
//const token = `eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOiIyMDIzLTEyLTE4VDE2OjMyOjM3KzA3MDAiLCJzaG9wX2lkIjoiODZlNzg0OTllZWIzM2ZiOWNhYzE2Yjc1NTViNTA3NjcifQ.bUI5cTy8t5mtiRQ6cXcLZWDfp-uHEpAz90mg8UuOJQE`;
let datashop =  JSON.parse(localStorage.getItem('getshop'));
// const datauser =  JSON.parse(localStorage.getItem('datauserInfo'));
// const token = datashop.token;
let token;
if(datashop){
  token = datashop.token;
}else{
  setTimeout(() => {
    datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')):'' ;
    token = datashop.token;
  }, 2000);
}

const editprofile = async (body,id_hash) => {
  try {
    if(datashop){
    const response = await axios.post(`${BASE_URL}/customerInformation`,body, {
      headers: {
        "Content-Type": "application/json",
        "Token": token,
        "Affiliate-Id-Hash": id_hash,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
  }
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};
const editprofileNode = async (body,id_hash) => {
  try {
    if(datashop){
    const response = await axios.post(`${Config.BASE_URL_Node}/customer/editcustomer`,body, {
      headers: {
        "Content-Type": "application/json",
        "Token": token,
        "Affiliate-Id-Hash": id_hash,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
  }
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};
const editprofilestep4 = async (body,id_hash) => {
  try {
    if(datashop){
    const response = await axios.post(`${BASE_URL}/customerInformation`,body, {
      headers: {
        "Content-Type": "application/json",
        "Token": token,
        "Affiliate-Id-Hash":id_hash,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
  }
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};
const editprofilestep5 = async (body,id_hash) => {
  try {
    if(datashop && datashop.length > 0 ){
    const response = await axios.post(`${Config.BASE_URL_Node}/customer/editcustomer`,body, {
      headers: {
        "Content-Type": "application/json",
        "Token": token,
        "Affiliate-Id-Hash":id_hash,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
  }
  else{
    datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
    const response = await axios.post(`${Config.BASE_URL_Node}/customer/editcustomer`,body, {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token,
        "Affiliate-Id-Hash":id_hash,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
  }
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};
const getCustomers = async (affiliateId) => {
  try {
    if(datashop){
    const response = await axios.get(`${Config.BASE_URL_Node}/customer/list?id_hash=`+affiliateId, {
      headers: {
        "Content-Type": "application/json",
        "Token": token,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
    }
  else{
    datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
    const response1 = await axios.get(`${Config.BASE_URL_Node}/customer/list?id_hash=`+affiliateId, {
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token,
      },
    });

    if (response1.data.status != 1) {
      throw new Error(response1.data?.msg);
    }
    
    return response1.data;
  }
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};
const searchCustomer = async (id_hash,type,key) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      "Token": token,
    }
  };
  const params = {
    "id_hash": id_hash,
    "type": type ?? "name",
    "key": key
  };
  return await axios.get(`${Config.BASE_URL_Node}/customer/find`, { 
    params: params,
    headers: config.headers
  });
 

};
const getMembers = async (affiliateId) => {
  try {
    if(datashop){
    const response = await axios.post(`${Config.BASE_URL_Node}/member/affiliate`,
      {
        "cid": affiliateId
      } ,{
      headers: {
        "Content-Type": "application/json",
        "Token": token,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
    }else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      const response1 = await axios.post(`${Config.BASE_URL_Node}/member/affiliate`,
      {
        "cid": affiliateId
      } ,{
      headers: {
        "Content-Type": "application/json",
        "Token": token,
      },
    });

    if (response1.data?.error) {
      throw new Error(response1.data?.msg);
    }
    
    return response1.data;
    }
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};
const getMemberscid = async (affiliateId) => {
  try {
    if(datashop){
    const response = await axios.post(`${Config.BASE_URL_Node}/member/getmember`,
      {
        "cid": affiliateId
      } ,{
      headers: {
        "Content-Type": "application/json",
        "Token": token,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
    }
    else{
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      const response = await axios.post(`${Config.BASE_URL_Node}/member/getmember`,
      {
        "cid": affiliateId
      } ,{
      headers: {
        "Content-Type": "application/json",
        "Token": datashop.token,
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }
    
    return response.data;
    }
  } catch (error) {
    console.error("Error fetching customers:", error);
    throw error;
  }
};

const addUpdateCustomer = async (affiliateId, body) => {
  try {
    if(datashop){
    const response = await axios.post(`${Config.BASE_URL_Node}/customer/addcustomer`, body, {
      headers: {
        "Content-Type": "application/json",
        "Token": token,
        "Affiliate-Id-Hash": affiliateId.toString(),
      },
    });

    if (response.data?.error) {
      throw new Error(response.data?.msg);
    }

    return response.data;
  }
  } catch (error) {
    console.error("Error adding/updating customer:", error);
    throw error;
  }
};

export { getCustomers,getMembers,getMemberscid, addUpdateCustomer,editprofile,editprofilestep5,editprofilestep4,editprofileNode, searchCustomer};
