<template>
    <div class="bg-gray-10 sm:min-h-screen sm:container relative bg-gray-25 ">
        <div  class="fixed inset-x-0 top-0 z-20 z-40 bg-gray-10  relative ">
            <div v-if="this.detailbuy == false" class="border-b border-gray-25 bg-white sm:container bg-white">
                <div class="relative flex h-16 flex-col justify-center pr-14  pl-12">
                <h5 class="!font-bold  relative left-50 pl-[20px] leading-snug font-semibold text-gray-900 text-lg truncate">Chương trình ưu đãi</h5>
                <router-link to="/promotion" class="absolute  left-0 inline-block left">
                    <button class=" absolute left-5 top-2 transform -translate-y-1/2 rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex  justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="Trở về" fdprocessedid="z10rj">
                    <svg class="h-6 w-6" height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z" fill="currentColor"></path>
                    </svg>
                    </button>
                </router-link>
                </div>
            </div>
            
                <div v-if="this.detailbuy == true" class="border-b border-gray-25 bg-white sm:container bg-white"><div class="relative flex h-16 flex-col justify-center pr-14 h-[65px] pl-4 !pl-12"><h5 class="!font-bold leading-snug font-semibold text-gray-900 text-lg truncate">Combo đã chọn</h5><div class="mt-0.5 text-gray-900 text-sm truncate">Từ kho: {{this.store.name}}</div>
                <button class="!absolute left-2 center-y relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-primary-400 hover:text-primary-600 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="Trở về" tabindex="0" @click="exitdetailbuy">
                    <svg class="h-6 w-6" height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.63615 18.3641C6.02668 18.7546 6.65984 18.7546 7.05037 18.3641L12.0001 13.4143L16.9499 18.3641C17.3404 18.7546 17.9736 18.7546 18.3641 18.3641C18.7546 17.9736 18.7546 17.3404 18.3641 16.9499L13.4143 12.0001L18.3641 7.05037C18.7546 6.65984 18.7546 6.02668 18.3641 5.63615C17.9736 5.24563 17.3404 5.24563 16.9499 5.63616L12.0001 10.5859L7.0504 5.63617C6.65987 5.24565 6.02671 5.24565 5.63618 5.63617C5.24566 6.0267 5.24566 6.65986 5.63618 7.05039L10.5859 12.0001L5.63615 16.9499C5.24563 17.3404 5.24563 17.9736 5.63615 18.3641Z" fill="currentColor"></path></svg></button></div></div>
            
        </div>
        <div v-if="this.datadetail && this.detailbuy == false" class="bg-gray-10 sm:min-h-screen sm:container relative bg-gray-25  ">
        <div class="space-y-2">
            <div class="flex flex-col">
                <button class="relative" type="button" aria-hidden="true">
                    <div class="relative h-0" style="padding-bottom: 100%;">
                        <div class="absolute inset-0 h-full w-full animate-pulse bg-gray-800 bg-opacity-10"></div>
                        <img alt="https://cdn.droppii.com/droppii-production-public/promotions/gifts/7cbd3622-f873-4076-b12c-340f40fd654c/a92cbf90-a1b8-49dc-8b78-1751d787ae1c.jpg" class="absolute inset-0 h-full w-full object-cover transition duration-500" :src="this.datadetail.image">
                    </div>
                   
                    <div class="!mt-0 bg-white p-4 pb-0 pr-[10px]">
                        <div class="flex flex-col pl-0 gap-1 border-b-2 border-gray-25 pb-4">
                            <div class="flex justify-between items-center">
                                <!-- Phần tử chữ bên trái -->
                                <h2 class="mt-[3px] leading-snug font-semibold text-gray-1100 text-xl">{{ this.datadetail.name }}</h2>
                                <!-- Nút bên phải -->
                                <div class="top-2 right-2" style="position: relative;">
                                    <button class="h-10 w-10 rounded-full bg-primary-400 relative rounded-md text-lg transition duration-500 border border-transparent bg-transparent text-white inline-flex items-center justify-center whitespace-nowrap text-center leading-none focus:outline-none cursor-pointer" type="button" aria-label="Thêm mới" fdprocessedid="gsxwyg"
                                    @click="Buy">
                                        <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z" fill="currentColor">
                                            </path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <p class="text-md flex flex-row items-start pl-0">{{ this.datadetail.description }}</p>
                            
                            <span class="font-semibold flex flex-row items-start pl-0 text-gray-1100 text-xl text-black">
                                 {{formatPrice(this.datadetail.sale_price)}}
                            </span>
                            <div class="flex items-center justify-between gap-2"><p class="  font-bold text-gray-900 text-nm">Chọn Kho Hàng</p>
                                                <div>
                                                    <button class=" !text-xl !rounded-lg relative rounded-md text-lg h-10 w-auto transition duration-500 border border-transparent bg-gray-50 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer pl-[5px] pr-[5px]" 
                                                    v-for="(store, index) in listStores" :key="index"  :id="index" 
                                                    :style="{ marginLeft: '10px', backgroundColor: index === selectedStoreIndex ? '#0559c1' : '' }"
                                                    @click="handleButtonClick(index)"
                                                    >
                                                        {{ store.name }} <!-- Giả sử store có một thuộc tính name -->
                                                    </button>


                                                </div>
                                            </div>
                            <!-- <span class="inline-flex items-center gap-1 relative bg-warning-400 text-white p-2 text-xs rounded-lg h-7 !w-fit">
                                <b>Có trên Droppii Mall</b>
                            </span> -->
                            
                        </div>
                        <div class="border-b border-gray-75 pt-4">
                            <div class="relative ">
                                <ul class="mb-4 list-disc pl-5 text-nm text-gray-900">
                                            <li v-if="this.datadetail.content" class="marker:text-[10px]">
                                                <p class="mb-[1px]">
                                                    {{this.datadetail.content}}
                                                </p>
                                            </li>
                                            <!-- <li class="marker:text-[10px]">
                                                <p class="mb-[1px]">
                                                <span class=" font-bold ">Đối tượng: 
                                                </span>Tất cả tài khoản</p>
                                            </li>
                                            <li class="marker:text-[10px]">
                                                <p class="mb-[1px]">
                                                    <span class=" font-bold ">Kho áp dụng: </span>
                                                    Droppii - HCM, Droppii - Ha Noi</p>
                                            </li>
                                            <li class="marker:text-[10px]">
                                                    <p class="mb-[1px]">
                                                        <span class=" font-bold ">Cách thức áp dụng: </span>
                                                        Áp dụng đồng thời với các chương trình khác. </p>
                                            </li>
                                            <li class="marker:text-[10px]">
                                                        <p class="mb-[1px]">
                                                            <span class=" font-bold ">Số lượng quà tặng có giới hạn</span>
                                                        </p>
                                            </li> -->
                                            </ul>
                                </div>
                            </div>
                        </div>
                </button>
            </div>
           
         </div>
        </div> 
        <div v-if="  this.detailbuy == true">
            <div v-if="this.deletecombo == true">
                                <div class="fixed inset-0 flex items-center justify-center px-4 "><div class="fixed inset-0   bg-opacity-50 opacity-100" id="headlessui-dialog-overlay-:r2t:" aria-hidden="true" data-headlessui-state="open"></div>
                                <div class="relative z-10 w-full rounded-xl !bg-gray shadow p-5 max-w-xs !rounded-2xl !pb-0 text-center opacity-100 translate-y-0" style="border-color: rgba(156, 163, 175, 0.25);border-width: 2px;">
                                    <div class="-mx-5 border-b border-gray-25 pb-4">
                                        <h3 class="text-lg font-bold leading-snug text-gray-1100" id="headlessui-dialog-title-:r2u:" data-headlessui-state="open">Xóa Combo</h3></div><p class="mb-5 mt-4 text-gray-1100">Bạn chắn chắn muốn bỏ Combo này?</p><div class="-mx-5 flex overflow-hidden rounded-b-2xl border-t border-gray-25"><button class="inline-flex h-14 items-center justify-center text-lg font-medium ring-0 focus:bg-gray-10 focus:outline-none w-1/2 flex-1 text-primary-400" fdprocessedid="wdzb3h" tabindex="0" @click="deletecomboflase">Đóng</button><div class="h-14 w-px flex-none bg-gray-50"></div><button class="inline-flex h-14 items-center justify-center text-lg font-medium ring-0 focus:bg-gray-10 focus:outline-none w-1/2 flex-1 text-primary-400" fdprocessedid="u2com" @click="combodelete">Xoá</button></div></div></div>
                    </div>
            <main class=" sm:min-h-screen sm:container h-screen overflow-y-auto bg-white pb-20  no-scrollbar">
                <div class="mb-20">
                    <div class="px-4">
                        <div class="bg-white border-b border-gray-25 py-4">
                            
                <div class="w-full space-x-3">
                    <div class="flex flex-none items-start space-x-4">
                        <div class="flex flex-col items-center gap-4">
                            <div class="h-[70px] w-[70px] flex-none space-y-2 text-center">
                                <div class="relative h-0" style="padding-bottom: 100%;"><div class="absolute inset-0 h-full w-full flex-none rounded-lg animate-pulse bg-gray-800 bg-opacity-10"></div><img class="absolute inset-0 h-full w-full object-cover transition duration-500 flex-none rounded-lg" 
                :src="this.datadetail.image"></div>
            </div>
            <button class="h-[18px] w-[18px] !text-gray-400 relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="delete" fdprocessedid="c9y3uw" @click="deletecombotrue">
                    <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.2597 5L16 5C16 3.34315 14.6569 2 13 2H11C9.34314 2 8 3.34315 8 5L7.34328 5L7 5L6.99882 5L4 5C3.44772 5 3 5.44772 3 6C3 6.55229 3.44772 7 4 7L5 7L5 19C5 20.6569 6.34315 22 8 22H16C17.6569 22 19 20.6569 19 19L19 7L20 7C20.5523 7 21 6.55228 21 6C21 5.44771 20.5523 5 20 5L17.0018 5L17 5L16.2597 5ZM17 7H15L14 7L10 7L9 7L7 7L7 9L7 19C7 19.5523 7.44772 20 8 20H16C16.5523 20 17 19.5523 17 19L17 9V7ZM10 5H11H12L13 5H14C14 4.44772 13.5523 4 13 4L11 4C10.4477 4 10 4.44772 10 5ZM10 9C10.5523 9 11 9.44772 11 10V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V10C9 9.44772 9.44772 9 10 9ZM15 10C15 9.44772 14.5523 9 14 9C13.4477 9 13 9.44772 13 10V17C13 17.5523 13.4477 18 14 18C14.5523 18 15 17.5523 15 17V10Z" fill="currentColor"></path></svg></button></div><div class="w-full space-y-1.5"><h6 class="text-nm font-semibold text-gray-1100 line-clamp-2">
                    {{this.datadetailbuy.product.name}}</h6>
                    <div class="flex w-full justify-between"><div>
                        <p class="font-bold text-gray-600 text-sm"> {{this.datadetailbuy.product.description}}</p>
                    </div></div><div class="flex flex-wrap items-center text-nm">
                        <p class="font-bold text-warning-400 text-nm"> {{formatPrice(this.datadetailbuy.product.sale_price)}}</p>
                    </div><div class="flex flex-wrap items-center text-nm">
                        </div><div class="inline-flex">
                         <p> Số lượng: {{ this.valuecombo }}</p>
                           
                                       
                        </div>
                    </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                <div class="relative z-50">
                    <div class="fixed inset-x-0 bottom-0 translate-x-0 transform sm:container">
                    </div>
                </div>
                </main>
        </div>
        <footer class="fixed inset-x-0 bottom-0 z-40  bg-gray-10 md:w-[480px] mx-auto bg-white " >
            <div v-if="this.detailbuy == false" class="border-b border-gray-25 bg-white sm:container bg-white">
            <!-- <div class="space-y-2"> -->
            <div class="flex h-[72px] items-center border border-t border-gray-25 bg-white px-4 sm:container flex !h-[110px] flex-col !px-0 !h-[105px]">
                <div class="bg-warning-25 flex w-full items-center justify-between px-4 py-2">
                    <span class="text-warning-900 text-[13px]">Giỏ hàng của bạn
                    </span>
                </div>
                <div class="flex w-full items-center justify-between gap-2 pl-4  pr-0">
                    <div class="flex-start flex-no-wrap flex items-center gap-2 overflow-auto pb-4 pt-3">
                        <div class="flex h-[40px] w-[40px] shrink-0 items-center justify-center rounded-lg border-[0.75px] border-dashed border-blue-500 ">
                            <!-- <svg class="h-[15px] w-[15px] text-gray-100" width="25" height="24" viewBox="0 0 25 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M11.5 15.0007V18.0007C11.5 18.5537 11.052 19.0007 10.5 19.0007C9.948 19.0007 9.5 18.5537 9.5 18.0007V15.0007C9.5 14.4477 9.948 14.0007 10.5 14.0007C11.052 14.0007 11.5 14.4477 11.5 15.0007ZM14.5 14.0007C13.948 14.0007 13.5 14.4477 13.5 15.0007V18.0007C13.5 18.5537 13.948 19.0007 14.5 19.0007C15.052 19.0007 15.5 18.5537 15.5 18.0007V15.0007C15.5 14.4477 15.052 14.0007 14.5 14.0007ZM20.929 12.6217L20.178 18.5067C19.924 20.4977 18.218 21.9997 16.21 21.9997H8.81201C6.81101 21.9997 5.10601 20.5038 4.84601 18.5198L4.073 12.6227C3.141 12.1127 2.5 11.1357 2.5 10.0007C2.5 8.34671 3.846 7.00072 5.5 7.00072H6.93399L9.64301 2.4857C9.92701 2.0117 10.54 1.85868 11.015 2.14268C11.489 2.42668 11.642 3.04075 11.358 3.51475L9.267 6.99974H15.735L13.644 3.51475C13.36 3.04075 13.513 2.42668 13.987 2.14268C14.461 1.85868 15.075 2.0117 15.359 2.4857L18.068 7.00072H19.5C21.154 7.00072 22.5 8.34671 22.5 10.0007C22.5 11.1347 21.86 12.1117 20.929 12.6217ZM4.5 10.0007C4.5 10.5527 4.949 11.0007 5.5 11.0007H19.5C20.051 11.0007 20.5 10.5527 20.5 10.0007C20.5 9.44872 20.051 9.00072 19.5 9.00072H5.5C4.949 9.00072 4.5 9.44872 4.5 10.0007ZM18.864 13.0007H6.14001L6.82999 18.2607C6.95899 19.2527 7.81201 20.0007 8.81201 20.0007H16.21C17.214 20.0007 18.067 19.2497 18.193 18.2547L18.864 13.0007Z" fill="currentColor">
                                </path>
                            </svg> -->
                            <img v-if=" this.datadetailbuy " :src="this.datadetail.image">
                            </div>
                    </div>
                    <div class="flex h-full items-center">
                        <button v-if="this.valuecombo1 && this.datadetailbuy" @click="detailbuy1">
                            <div class="flex h-full items-center gap-1 border-l border-r border-[#EFEFEF]  px-1">
                                <div class="flex flex-col text-xs text-gray-600">
                                    <span v-if="valuecombo1" class="font-bold lowercase">{{ valuecombo1 }} Combo</span>
                                    <span v-if="valuecombo1" class="text-nm font-bold text-warning-400">{{ formatPrice(this.total) }}</span>
                                </div>
                                <svg class="shrink-0 text-xs text-gray-600" width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 15.7071C4.90237 15.3166 4.90237 14.6834 5.29289 14.2929L11.2929 8.29289C11.6834 7.90237 12.3166 7.90237 12.7071 8.29289L18.7071 14.2929C19.0976 14.6834 19.0976 15.3166 18.7071 15.7071C18.3166 16.0976 17.6834 16.0976 17.2929 15.7071L12 10.4142L6.70711 15.7071C6.31658 16.0976 5.68342 16.0976 5.29289 15.7071Z" fill="currentColor">

                                    </path>
                                </svg>
                            </div>
                        </button>
                        <div class="flex h-full items-center">
                            <a class=" h-full" href="/cart?from=%2Fgift%2Ff76af5f5-51ec-482d-9876-9119836779dc%3Ffrom%3D%252Fpromotion%26giftDetailEvent%3DGiftDetail_AddToCart_Tap__%26previewProduct%3DGiftDetail_ChosenProducts_Tap_&amp;promotionPreviousEvent=GiftDetail_Cart_Tap_">
                                <button class="h-full w-[48px] !rounded-none bg-primary-400 relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-white inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="cart">
                                    <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 8C12.4477 8 12 8.44772 12 9C12 9.55228 12.4477 10 13 10H17C17.5523 10 18 9.55228 18 9C18 8.44772 17.5523 8 17 8H13Z" fill="currentColor">
                                        </path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 2C2.44772 2 2 2.44772 2 3C2 3.55228 2.44772 4 3 4H4.60222C5.03304 4 5.33 4.35693 5.30033 4.7127L5.3002 4.71426L4.53628 13.1832L4.53608 13.1855C4.35337 15.2646 6.0324 17 8.10131 17H17.908C19.7417 17 21.3491 15.5507 21.4924 13.7295L22.9896 7.4712L22.99 7.46576C23.1454 5.36495 21.4969 3.68299 19.4052 3.68299H7.11421C6.71693 2.69769 5.73627 2 4.60222 2H3ZM6.5284 13.3605L7.22093 5.68299H19.4052C20.3883 5.68299 21.0599 6.42978 20.9956 7.31598L20.9954 7.31824L19.4986 13.5726C19.4393 14.3254 18.7262 15 17.908 15H8.10131C7.1503 15 6.45388 14.215 6.5284 13.3605Z" fill="currentColor">
                                        </path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M16 18C14.8954 18 14 18.8954 14 20C14 21.1046 14.8954 22 16 22C17.1046 22 18 21.1046 18 20C18 18.8954 17.1046 18 16 18ZM15.3333 20C15.3333 19.6318 15.6318 19.3333 16 19.3333C16.3682 19.3333 16.6667 19.6318 16.6667 20C16.6667 20.3682 16.3682 20.6667 16 20.6667C15.6318 20.6667 15.3333 20.3682 15.3333 20Z" fill="currentColor">
                                        </path>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 20C8 18.8954 8.89543 18 10 18C11.1046 18 12 18.8954 12 20C12 21.1046 11.1046 22 10 22C8.89543 22 8 21.1046 8 20ZM10 19.3333C9.63181 19.3333 9.33333 19.6318 9.33333 20C9.33333 20.3682 9.63181 20.6667 10 20.6667C10.3682 20.6667 10.6667 20.3682 10.6667 20C10.6667 19.6318 10.3682 19.3333 10 19.3333Z" fill="currentColor">
                                        </path>
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div v-if="this.detailbuy == true" class="flex h-[72px] items-center border border-t border-gray-25 bg-white px-4 sm:container flex !h-[135px] flex-col !px-0">
                <div class="flex w-full items-center justify-between bg-warning-25 p-4 text-md font-bold">
                    <span class=" text-gray-900">Tổng cộng:</span>
                    <span class=" text-warning-400">{{ formatPrice(this.datadetailbuy.product.sale_price * this.valuecombo1) }}</span>
                </div>
                <div class="flex w-full items-center justify-between gap-2 p-4">
                   
                    <a class="block h-fit w-full" href="/cart?from=%2Fgift%2Fb52800c0-0ecc-4727-8113-fe5e1adbe6d6%3Ffrom%3D%252Fpromotion%26giftDetailEvent%3DGiftDetail_AddToCart_Tap__%26previewProduct%3DGiftDetail_ChosenProducts_Tap_">
                        <button class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap focus:outline-none h-12 text-md w-full flex bg-primary-400 text-white hover:bg-primary-300" type="button" fdprocessedid="moux1">
                            Đến giỏ hàng
                        </button>
                        </a>
                        </div>
                    </div>
        </footer>
        <div v-if="this.clickbuy == true && this.store" class="fixed flex md:w-[480px] mx-auto bg-white flex-col  overflow-hidden rounded-2xl bg-white inset-x-4 bottom-8 translate-y-0 pb-[80px]" style="max-height: 851px;">
            <header class="relative shrink grow-0 basis-[1%] bg-white p-4 shadow-[inset_0px_-1px_0px_#E5EBED] flex flex-col items-center">
                <h3 class="text-center w-full font-bold leading-snug text-gray-900" id="headlessui-dialog-title-:r2h:" data-headlessui-state="open">Tùy chọn Combo</h3>
                <button class="!absolute right-4 !text-3xl text-gray-400 center-y relative rounded-md text-md h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="Thoát" fdprocessedid="u9fht" tabindex="0" 
                @click="exitbuy">
                    <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.63615 18.3641C6.02668 18.7546 6.65984 18.7546 7.05037 18.3641L12.0001 13.4143L16.9499 18.3641C17.3404 18.7546 17.9736 18.7546 18.3641 18.3641C18.7546 17.9736 18.7546 17.3404 18.3641 16.9499L13.4143 12.0001L18.3641 7.05037C18.7546 6.65984 18.7546 6.02668 18.3641 5.63615C17.9736 5.24563 17.3404 5.24563 16.9499 5.63616L12.0001 10.5859L7.0504 5.63617C6.65987 5.24565 6.02671 5.24565 5.63618 5.63617C5.24566 6.0267 5.24566 6.65986 5.63618 7.05039L10.5859 12.0001L5.63615 16.9499C5.24563 17.3404 5.24563 17.9736 5.63615 18.3641Z" fill="currentColor"></path></svg></button></header><div data-testid="AddCartView-sheetContent" class="shrink grow-0 basis-[1%] overflow-auto px-4 p-0"><div><div><div class="flex gap-3 px-4 py-3 -mx-4 bg-gray-75" discount="20.5">
                        <div class="flex w-full flex-col gap-2">
                            <p class="font-medium text-gray-900 text-nm line-clamp-2">{{ this.datadetail.name }}</p>
                        <div class="flex gap-4">
                            <div class="w-[72px] flex-none">
                            <div class="relative h-0" style="padding-bottom: 100%;">
                                <div class="absolute inset-0 h-full w-full rounded-md animate-pulse bg-gray-800 bg-opacity-10">
                            </div>
                            <img class="absolute inset-0 h-full w-full object-cover transition duration-500 rounded-md" :src="this.datadetail.image"></div></div>
                            <div class="flex-1 space-y-1">
                                <p class="font-bold text-warning-400">{{formatPrice(this.datadetail.sale_price)}}</p>
                                <p class="font-bold text-primary-400 text-sm">Lợi nhuận:
                                    <span class="ml-1 font-bold text-primary-400 text-sm">
                                        ₫92,995</span></p><p class="font-medium text-gray-900 text-sm">Points: 216.017</p></div></div></div></div></div><div class="border-b border-gray-400/20 py-3">
                                            <div class="flex items-center justify-between gap-2"><p class="  font-bold text-gray-900 text-nm">Từ Kho Hàng: {{ this.store.name }}</p>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <footer class="border-t border-gray-25 mx-4 py-4 border-none">
                                    <div class="mb-4 flex items-center justify-between"><div><p class="font-bold text-gray-900 text-nm">Số lượng</p>
                                    </div>
                                    <div class="inline-flex">
                                        <button class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="Thêm" 
                                        @click="reducecombo">
                                        <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M5 12C5 11.4477 5.44772 11 6 11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H6C5.44772 13 5 12.5523 5 12Z" fill="currentColor"></path></svg>
                                        </button>

                                        <input class="h-10 w-10 border-none bg-transparent p-0 text-center font-bold text-gray-900 focus:outline-none h-10 w-10" max="134" min="1"  v-model="valuecombo" fdprocessedid="onvi6g">
                                        <button class="!bg-gray-50 !text-xl !rounded-lg relative rounded-md text-lg h-10 w-10 transition duration-500 border border-transparent bg-gray-25 text-gray-900 hover:bg-gray-50 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="Thêm" 
                                        @click="increasecombo">
                                                    <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1943 4.11509C13.1943 3.49924 12.6951 3 12.0792 3C11.4634 3 10.9642 3.49924 10.9642 4.11509L10.9642 10.8057L4.1151 10.8057C3.49925 10.8057 3 11.3049 3 11.9208C3 12.5366 3.49924 13.0358 4.11509 13.0358H10.9642L10.9642 19.8849C10.9642 20.5008 11.4634 21 12.0792 21C12.6951 21 13.1943 20.5008 13.1943 19.8849L13.1943 13.0358H19.8849C20.5008 13.0358 21 12.5366 21 11.9208C21 11.3049 20.5008 10.8057 19.8849 10.8057L13.1943 10.8057L13.1943 4.11509Z" fill="currentColor">
                                                </path></svg>
                                        </button>
                                            </div>
                                        </div>
                                        <button class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300" type="button" @click="addcombo">Thêm vào giỏ hàng
                                        </button>
                                    </footer>
                                </div>
    </div>

</template>
<script>
import {
  
  getStores
} from '@/services/product/productService';

export default {
  name: "RankPage",
  components: {
    // Header
  },
   props: ['combo'],
  data() {
    return {
        clickbuy: false,
       datadetail:'',
       valuecombo: 1,
       valuecombo1: '',
       listStores:'',
       addcombo1:'',
       total:'',
       selectedStoreIndex:'',
       color: "gary",
       store:'',
       datadetailbuy:'',
       detailbuy: false,
       deletecombo:false,
       listStoreshow:'',
      
      // Khai báo các biến dữ liệu cần thiết
    };
  },
  methods: {
    formatPrice(price) {
        // Chuyển số thành chuỗi
        let priceString = price.toString();
        // Tạo mảng chứa các phần tử
        let parts = [];
        // Thêm từng ba chữ số vào mảng
        while (priceString.length > 3) {
            parts.unshift(priceString.slice(-3));
            priceString = priceString.slice(0, -3);
        }
        // Thêm phần còn lại (ít hơn ba chữ số) vào mảng
        if (priceString) {
            parts.unshift(priceString);
        }
        // Kết hợp các phần và thêm dấu chấm vào giữa chúng
        return parts.join('.') + ' ₫';
        },
    combodelete(){
        this.deletecombo = false;
        this.datadetailbuy ='';
        this.detailbuy = false;
        this.total = 0;
        this.valuecombo1 = "";
        let cartItems = JSON.parse(localStorage.getItem('cart')) || [];
        let existingcomboIndex = cartItems.findIndex(item => item.product.id === this.datadetail.id && item.store.id === this.store.id);
        cartItems.splice(existingcomboIndex, 1);
        localStorage.setItem('cart', JSON.stringify(cartItems));
    },
    deletecomboflase(){
        this.deletecombo = false;
    },
    deletecombotrue(){
        this.deletecombo = true;
    },
    exitdetailbuy(){
        this.detailbuy= false;
    },
    detailbuy1(){
        this.detailbuy= true;
        this.clickbuy = false;
        

    },
    handleButtonClick(index) {
      const selectedStoreValue = this.listStores[index];
    //   console.log("selectedStoreValue",selectedStoreValue);
      this.store = selectedStoreValue;
      this.selectedStoreIndex = index;
      this.getinfocombo( this.store.id );
      console.log(this.store)
     

    },
    reducecombo(){
        if(this.valuecombo >1 ){
            this.valuecombo =  this.valuecombo -1;
        }
    },
    increasecombo(){
        this.valuecombo =  this.valuecombo + 1;
        // this.total = this.valuecombo* this.datadetail.sale_price;
    },
    exitbuy(){
        this.clickbuy = false;
    },
    Buy(){
        this.clickbuy = true;
    },
    addcombo(){
        if(this.store){
            console.log(this.store)
            this.addcombo1 = true;
            this.datadetailbuy = this.datadetail;
            // console.log( this.datadetailbuy.id);//gia tri luu trong gio hang
            this.valuecombo1 = this.valuecombo;
            this.total= this.valuecombo1* this.datadetail.sale_price;
            //lay thong tin cua gio hang
            let cartItems = JSON.parse(localStorage.getItem('cart')) || [];
            //kiemtra combo co trogn gio hang khong
            let existingcomboIndex = cartItems.findIndex(item => item.product.id === this.datadetail.id && item.store.id === this.store.id);
            // console.log(existingcomboIndex);
            if (existingcomboIndex !== -1) {
                    // console.log('update');
                    // Nếu sản phẩm đã tồn tại, tăng số lượng
                    cartItems[existingcomboIndex].quantity = this.valuecombo1;
                    cartItems[existingcomboIndex].store.id = this.store.id;
                    cartItems[existingcomboIndex].store.name = this.store.name;
                    this.valuecombo = cartItems[existingcomboIndex].quantity;
                    cartItems[existingcomboIndex].total = this.total;
                    cartItems[existingcomboIndex].store.address =this.store.address;
                    cartItems[existingcomboIndex].store.province_name = this.store.province_name;
                    cartItems[existingcomboIndex].store.district_name = this.store.district_name;
                    cartItems[existingcomboIndex].store.ward_name = this.store.ward_name;
                    // cartItems[existingcomboIndex].profit = this.profit;
                    // console.log(cartItems);
                } else {
                    // console.log('add');
                    const username =  JSON.parse(localStorage.getItem('datauserInfo')).id_hash;
                    cartItems.push({ product: this.datadetail, quantity: this.valuecombo, total: this.total, store: { id: this.store.id, name: this.store.name,address:this.store.address, district_name:this.store.district_name, province_name:this.store.province_name,ward_name:this.store.ward_name },username:username });
                    // console.log(cartItems);
                }
                localStorage.setItem('cart', JSON.stringify(cartItems));
                
                // this.toast.success("Thêm giỏ hàng thành công", {
                //     timeout: 2000,
                //     position: 'bottom-center'
                // });
               
        }else{
            this.error = 'Vui lòng chọn kho hàng!';
            console.log( this.error);
        }
        this.getinfocombo(this.store.id);
        //localStorage.setItem("addcombo",);
    },
    getinfocombo(id_store){
        let cartItems = JSON.parse(localStorage.getItem('cart')) || [];
            //kiemtra combo co trogn gio hang khong
            let existingcomboIndex = cartItems.findIndex(item => item.product.id === this.datadetail.id && item.store.id === id_store);
            //console.log("getinfocombo",existingcomboIndex);
            if (existingcomboIndex !== -1) {
                  this.total =  cartItems[existingcomboIndex].total;
                  this.valuecombo1 = cartItems[existingcomboIndex].quantity;
                  this.valuecombo = cartItems[existingcomboIndex].quantity;
                  this.datadetailbuy =  cartItems[existingcomboIndex];
                  //console.log("datadetailbuy",this.datadetailbuy);
                //   console.log(cartItems[existingcomboIndex]);
            }else{
                this.valuecombo1 = '';
                this.datadetailbuy = '';
            }

    },
    async getStore(){
      try {
        this.isLoading = true;
        if(this.datadetail.id){
            const response = await getStores(this.datadetail.id);
            if(response.data.status == 1){
                this.listStoreshow =  response.data.data;
                this.listStores = response.data.data;
            }
         
        }
        //console.log("this list stores", this.listStores);
      } catch (error) {
        console.error('Error fetching combo:', error.message);
      }
    }
  },
  created() {
    document.title =this.$t(`Combo.Combo`);
  
  },
  mounted() {
    // In ra giá trị của tham số id
    this.datadetail  = JSON.parse(this.$route.query.combo);
    //console.log(this.datadetail);
    this.getStore();
    // In ra giá trị của dữ liệu được truyền qua URL
    // Thực hiện các tác vụ sau khi component đã được render
  },
  computed: {
  }
};
</script>

<style scoped>
/* CSS cho trang RankPage */
</style>