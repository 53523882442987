<template>
  <div class="relative min-h-[450px] overflow-auto overflow-x-hidden ">
    <div class="relative w-full bg-white px-2">
      <div class="flex h-max justify-between py-4 align-middle ">
        <a v-if="check_invite_code ===true "
          @click="backstep"
          class="flex h-max align-middle relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
          aria-label="Quay lại"
          style="padding-top: 2px;"

        >
          <svg
            class="h-7 w-7"
            height="1em"
            width="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <a v-if="check_invite_code ===false "
          @click="backstep1"
          class="flex h-max align-middle relative rounded-md text-2xl h-10 w-10  transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
          aria-label="Quay lại"
          style="padding-top: 2px;"
        >
          <svg
            class="h-7 w-7"
            height="1em"
            width="1em"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.7071 6.7071C11.0976 6.31657 11.0976 5.6834 10.7071 5.29288C10.3166 4.90236 9.6834 4.90237 9.29288 5.29291L3.29288 11.2931C3.10535 11.4806 3 11.735 3 12.0002C3 12.2654 3.10537 12.5198 3.2929 12.7073L9.29291 18.7071C9.68344 19.0976 10.3166 19.0976 10.7071 18.7071C11.0976 18.3166 11.0976 17.6834 10.7071 17.2929L6.41407 13L20 13C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11L6.41435 11L10.7071 6.7071Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <h5  v-if="step ===3 " class="pl-2 pt-1 rounded-lg transition duration-200 inline-flex  whitespace-nowrap focus:outline-none  h-8 text-primary-400  hover:text-primary-300  text-md uppercase font-semibold text-gray-1100 w-full"
        style="font-size: 18px;"
        >
        {{$t('account_registration.account_registration')}}
        </h5>
        <button @click="makePhoneCall"
          class="px-3 rounded-lg transition duration-200 inline-flex justify-center items-center whitespace-nowrap focus:outline-none border h-8 text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 text-md uppercase"
          type="button"
        >
          <span>{{$t('support.support')}}</span>
          <span class="text-md ml-2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.3333 5.4165C13.9167 3.83317 12 2.9165 10 2.9165C8 2.9165 6.08333 3.83317 4.66667 5.4165C3.25 6.99984 2.5 9.08317 2.5 11.2498V14.5832C2.5 15.9998 3.58333 17.0832 5 17.0832C6.41667 17.0832 7.5 15.9998 7.5 14.5832V12.0832C7.5 10.6665 6.41667 9.58317 5 9.58317C4.75 9.58317 4.58333 9.58317 4.33333 9.6665C4.58333 8.49984 5.16667 7.33317 5.91667 6.49984C7 5.24984 8.5 4.58317 10 4.58317C11.5 4.58317 13 5.24984 14.0833 6.49984C14.8333 7.33317 15.4167 8.49984 15.6667 9.6665C15.4167 9.58317 15.25 9.58317 15 9.58317C13.5833 9.58317 12.5 10.6665 12.5 12.0832V14.5832C12.5 15.9998 13.5833 17.0832 15 17.0832C16.4167 17.0832 17.5 15.9998 17.5 14.5832V11.2498C17.5 9.08317 16.75 6.99984 15.3333 5.4165ZM5 11.2498C5.5 11.2498 5.83333 11.5832 5.83333 12.0832V14.5832C5.83333 15.0832 5.5 15.4165 5 15.4165C4.5 15.4165 4.16667 15.0832 4.16667 14.5832V12.0832C4.16667 11.5832 4.5 11.2498 5 11.2498ZM15.8333 14.5832C15.8333 15.0832 15.5 15.4165 15 15.4165C14.5 15.4165 14.1667 15.0832 14.1667 14.5832V12.0832C14.1667 11.5832 14.5 11.2498 15 11.2498C15.5 11.2498 15.8333 11.5832 15.8333 12.0832V14.5832Z"
                fill="currentColor"
              />
            </svg>
          </span>
        </button>
      </div>
      <div class="relative flex items-center justify-center">
        <div class="absolute inset-0 flex items-center">
          <div class="w-full border-t border-gray-50" />
        </div>
      </div>
    </div>  
   
    <hr class="border" />
    <!-- Content -->
    <div class="py-6 px-2 bg-white">
      <Step1 v-if="step === 1" @next-step="handleNextStepFromStep1" />
      <Step2 v-if="step === 2" :customerdata="customerData" @next-step="setStep(3)" />
      <Step3 v-if="step === 3" @next-step="setStep(4)" />
      <Step4 v-if="step === 4" @next-step="setStep(5)"/>
      <Step5 v-if="step === 5" />
    </div>
  </div>
</template>

<script>
import Step1 from './Step1.vue';
import Step2 from './Step2.vue';
import Step3 from './Step3.vue';
import Step4 from './Step4.vue';
import Step5 from './Step5.vue';

export default {
  name: "RegistryCompoment",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  data() {
    return {
      step: 1,
      getshop:'',
      check_invite_code:true,
      customerData:'',
    };
  },
  created(){
    this.getdata();
  },
  methods: {
    getdata(){
      if(localStorage.getItem('getshop')){
        this.getshop = JSON.parse(localStorage.getItem('getshop'));
        // console.log(this.getshop.check_invite_code);
        if( this.getshop &&  this.getshop.check_invite_code === null || this.getshop.check_invite_code == 0 ){
          this.step = 3;
          this.check_invite_code = false;
        }
      }
      
    },
    makePhoneCall() {
      window.location.href = "tel:0985535186";
    },
    handleNextStepFromStep1(customerDataFromStep1) {
      console.log("truyen data ",customerDataFromStep1);
      this.customerData = customerDataFromStep1; // Lưu dữ liệu từ Step1
      this.setStep(2); // Chuyển sang Step2
    },
    setStep(step) {
      //console.log();
      this.step = step; // Cập nhật giá trị step để chuyển sang step tiếp theo
      //console.log(this.step);
    },
    handleNextStep(customerData) {
      // Xử lý dữ liệu được truyền từ component con
      console.log('Dữ liệu nhận được từ component con:', customerData);
      // Tiến hành các xử lý khác nếu cần
    },
    handleNextStepFromStep3(formDataFromStep3) {
      // Nhận dữ liệu từ Step3
      console.log(formDataFromStep3);
      this.formDataFromStep3 = formDataFromStep3;
      // Chuyển sang Step4
      this.step++;
    },
    backstep1(){
        this.$router.push("/");
    },
    backstep(){
      if(this.step > 1){
        this.step = this.step -1 ;
      }
      if(this.step == 1){
        this.$router.replace("/");
      }
    }
  }
  
};
</script>

<!-- You may add scoped styles here -->
