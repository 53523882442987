import axios from "axios";
import Config from "@/components/config";
import { format } from 'date-fns';
let datashop = JSON.parse(localStorage.getItem("getshop"));
let datauserInfo =  JSON.parse(localStorage.getItem('datauserInfo'));
if(datashop && datashop.length > 1){
  datashop = JSON.parse(datashop)
}
else{
  if(datashop && datashop.length < 1){
    setTimeout(() => {
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')):'' ;
    
    }, 2000);
  }
}

const createOrder = (data) => {
  try {
    let response = '';
    if(datashop){
      response = axios.post(
        "https://salekit.net/api/v1/affiliate/dropship/createOrder",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Token":
              datashop.token,
            "Affiliate-Id-Hash": datauserInfo.id_hash,
          },
        }
      );
    }else{
      datauserInfo =  JSON.parse(localStorage.getItem('datauserInfo'));
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
      response = axios.post(
        "https://salekit.net/api/v1/affiliate/dropship/createOrder",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Token":
              datashop.token,
            "Affiliate-Id-Hash": datauserInfo.id_hash,
          },
        }
      );
    }
    return response;
  } catch (error) {
    console.error("Lỗi khi gọi API:", error);
  }
};
const listMyOrder=(body)=>{
  const currentDate = new Date();
  const dateString = format(currentDate, 'yyyy-MM-dd').slice(0, 10);
  let date = body.date ?? `06%2F03%2F2023-${dateString}`;
  let page = body.page ?? 1
  let pay_status = body.pay_status ;
  let search = body.search;
  try {
    let response ='';
    if(datashop){
       response = axios.get(
        // 06%2F03%2F2023-08%2F03%2F2024
        `${Config.BASE_URL_Node}/order/order-type?date_search=${date}&type=${body.type}&page=${page}&pay_status=${pay_status}&search=${search}`,
        {
          headers: {
            "Token": datashop.token,
            "Affiliate-Id-Hash": datauserInfo.id_hash,
          },
        }
      );
    }else{
      datauserInfo =  JSON.parse(localStorage.getItem('datauserInfo'));
      datashop = localStorage.getItem('getshop') ? JSON.parse(localStorage.getItem('getshop')) : '';
       response = axios.get(
        `${Config.BASE_URL_Node}/order/order-type?date_search=${date}&type=${body.type}&page=${page}`,
        {
          headers: {
            "Token": datashop.token,
            "Affiliate-Id-Hash": datauserInfo.id_hash,
          },
        }
      );
    }
    return response;
  } catch (error) {
    console.error("Lỗi khi gọi API:", error);
  }
}
export { createOrder,listMyOrder };
