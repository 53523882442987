import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import './styles.css'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
// import { createI18n } from 'vue-i18n';
// import enMessage from '../language/en.json';
// import viMessage from '../language/vi.json';

// const i18n = createI18n({
//     locale: 'vi', // Ngôn ngữ mặc định
//     messages: {
//       en: enMessage,
//       vi: viMessage
//     }
//   });
// const app = createApp(App)
// app.use(Toast, {
//     position: POSITION.TOP_RIGHT
// })
// app.use(router)
// app.use(i18n)
// app.mount("#app");


import enMessage from '../language/en.json'
import viMessage from '../language/vi.json'

import VueCookies from 'vue-cookies'
import { createI18n } from 'vue-i18n';
const app = createApp(App)
app.use(require('vue-cookies'))
app.use(VueCookies)

let messages;
let lang;
if (process.env.NODE_ENV === 'production') {
    console.warn = function() {};
    console.error = function() {};
}

window.$cookies.get('lang');
if (window.$cookies.get('lang') && window.$cookies.get('lang') != 'vi'){
	lang = 'en';
	messages = {
		en: enMessage,
	}
} 
else{
	window.$cookies.set('lang', 'vi');
	lang = 'vi';
	messages = {
		vi: viMessage,
	}
}

const i18n = createI18n({
    locale: lang,
    messages: messages,
  });

// const app = createApp(App)
app.use(Toast, {
    position: POSITION.TOP_RIGHT
})
app.use(router)
app.use(i18n)
app.mount("#app");