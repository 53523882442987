<template>
  <!-- <div class="inset-0 flex flex-col justify-between overflow-y-auto overflow-x-hidden bg-white sm:container">
    <div class="relative min-h-[450px] overflow-auto overflow-x-hidden px-8">
      <div class="pointer-events-none absolute left-0 h-[204px] w-full bg-cover bg-left-bottom bg-no-repeat" :style="{ backgroundImage: 'url(/bg-login.png)' }"></div>
      <header class="relative mx-auto flex max-w-md justify-center pb-2 pt-6">
        <h1 class="leading-snug font-bold text-white truncate text-3xl">Logo</h1>
      </header>
      <div class="absolute left-0 flex w-full justify-center space-y-2">
        <p class="max-w-xs whitespace-pre-wrap text-center text-white">Business Nền tảng hỗ trợ Kinh Doanh Online</p>
      </div>
      <div class="relative mt-36">
        <p class="mb-4 text-center font-bold text-gray-900 text-2xl">Đăng nhập tài khoản</p>
        <form class="m-auto max-w-lg bg-white" @submit.prevent="handleLogin">
          <div class="mb-4 space-y-4">
            <div>
              <div class="relative inline-flex w-full">
                <input class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-1000 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400" type="text" name="username" autocomplete="username" placeholder="Tên đăng nhập" v-model="username" />
              </div>
            </div>
            <div>
              <div class="relative inline-flex w-full">
                <input class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 pr-23 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-1000 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400" type="password" name="password" autocomplete="current-password" placeholder="Mật khẩu" v-model="password" />
                <button class="!absolute right-1 top-1 relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer" type="button" aria-label="Hiện" @click="toggleShowPassword">
                  <svg height="1em" width="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9995 8C9.79023 8 8 9.79064 8 12.0005C8 14.2096 9.79043 16 11.9995 16C14.2082 16 16 14.21 16 12.0005C16 9.79023 14.2084 8 11.9995 8ZM10 12.0005C10 10.8948 10.8952 10 11.9995 10C13.1043 10 14 10.8952 14 12.0005C14 13.1046 13.1045 14 11.9995 14C10.895 14 10 13.105 10 12.0005Z" fill="currentColor" />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0019 4C7.77868 4 4.09588 6.98537 2.08337 11.6003C1.97221 11.8552 1.97221 12.1448 2.08337 12.3997C4.10222 17.0292 8.0226 20 12.0019 20C15.9526 20 19.5766 17.7019 21.9149 12.4038C22.0278 12.148 22.0284 11.8566 21.9166 11.6003C20.2236 7.71791 16.8868 4 12.0019 4ZM12.0019 18C9.13107 18 5.93087 15.8681 4.09804 11.9992C5.92305 8.1182 8.91948 6 12.0019 6C15.5885 6 18.3193 8.63312 19.9018 11.9989C17.8228 16.425 14.9122 18 12.0019 18Z" fill="currentColor" />
                  </svg>
                </button>
              </div>
            </div>
            <div class="flex justify-end">
              <a class="w-fit text-gray-900" href="/forgot-password">Quên mật khẩu?</a>
            </div>
            <button class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300" type="submit">Đăng nhập</button>
          </div>
          <div class="flex flex-col items-center justify-center gap-2 pb-10">
            <p class="lowercase text-gray-600 text-sm">Hoặc</p>
            <a class="w-fit text-nm font-medium text-primary-400" href="/phone-login">Đăng nhập bằng số điện thoại</a>
          </div>
        </form>
      </div>
    </div>
    <div class="bg-gray-100 px-8 pb-8 pt-4 text-center">
      <p class="mb-4 text-gray-600 text-sm">Trở thành đối tác của chúng tôi?</p>
      <a href="/registry" class="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none border h-10 w-full flex text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 max-w-lg !text-nm capitalize" type="button">Đăng ký ngay</a>
    </div>
  </div> -->
  <div
    className="inset-0 flex flex-col justify-between overflow-y-auto overflow-x-hidden bg-white sm:container h-screen">
    <div className="relative overflow-auto overflow-x-hidden px-8">
      <div className="pointer-events-none absolute left-0 h-[204px] w-full bg-cover bg-left-bottom bg-no-repeat"
        :style="{ backgroundImage: 'url(img/bg-login.png)' }" />
      <header className="relative mx-auto flex max-w-md justify-center pb-2 pt-6">
        <h1 className="leading-snug font-bold text-white truncate text-3xl">
          <!-- Logo  -->
          <img :src="datashop.avatar" alt="" style="width:200px; padding:5px">
        </h1>
      </header>
      <div className="absolute left-0 flex w-full justify-center space-y-2">
        <p className="max-w-xs whitespace-pre-wrap text-center text-white">
          {{ $t('title_from_login.title_from_login') }}
          <br>
        </p>
      </div>
      <div className="relative mt-36">
        <p className="mb-4 text-center font-bold text-gray-900 text-2xl">
          {{ $t('title_login.title_login') }}
        </p>
        <form className="m-auto max-w-lg bg-white" @submit.prevent="handleLogin" @keyup.enter="handleLogin">
          <div className="mb-4 space-y-4">
            <div>
              <div class="grid grid-cols-2 ">
                <div class="">
                  <button
                    className=" transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex  text-gray-900 "
                    @click.prevent="loginemail"
                    :style="isloginemail ? { borderBottom: '3px solid blue', fontWeight: 'bold' } : { borderBottom: '1px solid black' }">

                    <label> Email</label>
                  </button>
                </div>
                <div class=" ">
                  <button
                    className=" transition  bg-none duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex  text-gray-900 "
                    @click.prevent="loginphone"
                    :style="isloginemail ? { borderBottom: '1px solid black ' } : { borderBottom: '3px solid blue', fontWeight: 'bold' }">
                    <label style=""> {{ $t('phone_number.phone_number') }}</label>

                  </button>
                </div>
              </div>

            </div>

            <div v-if="isloginemail === true">
              <div className="relative inline-flex w-full">
                <input
                  className="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-1000 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400"
                  name="username" autoComplete="username" :placeholder="$t('enter_email.enter_email')" aria-invalid="false" id=":r9:"
                  value={email} @change="updateUsername" v-model="email" />
              </div>
            </div>
            <div v-if="isloginemail === false">
              <div className="relative inline-flex w-full">
                <input
                  className="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-1000 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400"
                  name="phonenumber" autoComplete="phonenumber" :placeholder="$t('d4u_value_53.d4u_value_53')"
                  type="tel"
                  aria-invalid="false" id=":r9:" v-model="phonenumber" />
              </div>
            </div>
            <div v-if="error"> <p style="font-size: 12px; color: red;">{{ error }} </p></div>
            <div>
              <div className="relative inline-flex w-full">
                <input
                  class="block w-full rounded-lg py-2 text-nm pl-3 pr-3 pr-11 pr-23 h-[48px] appearance-none !ring-0 transition duration-200 focus:outline-none border border-gray-1000 bg-white text-gray-1100 text-gray-900 placeholder:text-gray-400 hover:border-gray-100 focus:border-primary-400"
                  :type="showPassword ? 'text' : 'password'" v-model="password" name="password"
                  autocomplete="current-password" :placeholder="$t('password.password')" aria-invalid="false"
                  :value="password" @input="updatePassword($event)" />
                <button
                  class="!absolute right-1 top-1 relative rounded-md text-2xl h-10 w-10 transition duration-500 border border-transparent bg-transparent text-gray-900 hover:text-gray-900 inline-flex items-center justify-center whitespace-nowrap text-center leading-none transition duration-200 focus:outline-none cursor-pointer"
                  type="button" aria-label="Hiện" @click="toggleShowPassword">
                  <svg v-if="showPassword === false" height="1em" width="1em" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.9995 8C9.79023 8 8 9.79064 8 12.0005C8 14.2096 9.79043 16 11.9995 16C14.2082 16 16 14.21 16 12.0005C16 9.79023 14.2084 8 11.9995 8ZM10 12.0005C10 10.8948 10.8952 10 11.9995 10C13.1043 10 14 10.8952 14 12.0005C14 13.1046 13.1045 14 11.9995 14C10.895 14 10 13.105 10 12.0005Z"
                      fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M12.0019 4C7.77868 4 4.09588 6.98537 2.08337 11.6003C1.97221 11.8552 1.97221 12.1448 2.08337 12.3997C4.10222 17.0292 8.0226 20 12.0019 20C15.9526 20 19.5766 17.7019 21.9149 12.4038C22.0278 12.148 22.0284 11.8566 21.9166 11.6003C20.2236 7.71791 16.8868 4 12.0019 4ZM12.0019 18C9.13107 18 5.93087 15.8681 4.09804 11.9992C5.92305 8.1182 8.91948 6 12.0019 6C15.5885 6 18.3193 8.63312 19.9018 11.9989C17.8228 16.425 14.9122 18 12.0019 18Z"
                      fill="currentColor" />
                  </svg>
                  <svg v-else height="1em" width="1em" viewBox="0 0 24 24" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M19.3298 6.75071L21.326 4.72927C21.7166 4.33366 21.7166 3.69231 21.326 3.2967C20.9354 2.9011 20.3031 2.9011 19.9124 3.2967L4.13843 19.2715C3.74787 19.667 3.7478 20.3072 4.13822 20.7028C4.33334 20.9016 4.59002 21 4.84521 21C5.10037 21 5.35747 20.9011 5.55259 20.7024L7.88266 18.3428C9.19401 19.037 10.5943 19.4135 12.0019 19.4135C15.9526 19.4135 19.5766 17.0861 21.9149 11.7205C22.0278 11.4615 22.0284 11.1664 21.9166 10.9068C21.283 9.43521 20.419 7.98696 19.3298 6.75071ZM17.9152 8.1832L15.864 10.2605C15.9527 10.5958 16 10.9483 16 11.3121C16 13.5497 14.2082 15.3625 11.9995 15.3625C11.6405 15.3625 11.2926 15.3147 10.9616 15.2249L9.38592 16.8205C10.2572 17.1953 11.1442 17.388 12.0019 17.388C14.9122 17.388 17.8228 15.7929 19.9018 11.3105C19.367 10.1586 18.701 9.09137 17.9152 8.1832Z"
                      fill="currentColor"></path>
                    <path
                      d="M12.0019 3.20979C13.5474 3.20979 14.9379 3.58668 16.1705 4.2265L14.6586 5.75758C13.8363 5.42117 12.949 5.23525 12.0019 5.23525C8.91948 5.23525 5.92305 7.38041 4.09804 11.3108C4.65967 12.5115 5.34968 13.5469 6.11926 14.4057L4.70553 15.8374C3.66219 14.689 2.76454 13.2983 2.08337 11.7164C1.97221 11.4583 1.97221 11.1649 2.08337 10.9068C4.09588 6.23316 7.77868 3.20979 12.0019 3.20979Z"
                      fill="currentColor"></path>
                    <path
                      d="M11.9995 7.26071C12.3588 7.26071 12.707 7.30866 13.0382 7.3986C11.4038 9.05379 9.77017 10.7083 8.13597 12.3633C8.04729 12.0281 8 11.6757 8 11.3121C8 9.07414 9.79023 7.26071 11.9995 7.26071Z"
                      fill="currentColor"></path>
                  </svg>
                </button>
              </div>
            </div>

            <!-- <div className="flex justify-end">
              <a className="w-fit text-gray-900" href="/forgot-password">
                Quên mật khẩu?
              </a>
            </div> -->
           
            <button
              className="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none h-10 w-full flex bg-primary-400 text-white hover:bg-primary-300"
              style="--tw-bg-opacity: 1; background-color: rgb(27 63 228 / var(--tw-bg-opacity));">

              <div v-if="isloading === true" data-v-3fcda164="" class="icon-inside-textarea">
                <span data-v-3fcda164="" style="display: none;">Loading...</span>
                <i data-v-3fcda164="" class="fa fa-spinner fa-spin"></i>
              </div>

              <p v-if="isloading === false">{{ $t('login.login') }}</p>
            </button>
          </div>
        </form>
        <div className="flex flex-col items-center justify-center gap-2 pb-10">
          <!-- <p className="lowercase text-gray-600 text-sm">{{ $t('or.or') }}</p> -->

          <router-link className="w-fit text-nm font-medium text-primary-400" to="/forgotpassword">
            {{ $t('forget_password.forget_password') }}
          </router-link>
        </div>
        <div class="flex justify-center align-items-center" style="position: relative ; ">
          <div>
            <button class="btn btn-default dropdown-toggle" type="button" data-toggle="dropdown"
              @click.prevent="openchange">
              <img v-if="lang === 'vi'" width="25px inline" style="display: inline;"
                src="https://salekit.io/assets/images/flag/vi.svg">
              <p class="inline pl-1 " v-if="lang === 'vi'" style="display: inline; font-size: 12px;">Tiếng Việt</p>
              <img v-if="lang === 'en'" width="25px inline" style="display: inline;"
                src="https://salekit.io/assets/images/flag/en.svg">
              <p class="inline pl-1" v-if="lang === 'en'" style="display: inline;font-size: 12px;"> English</p>
              <svg class="text-3xl text-gray-300" style="display: inline;" width="1em" height="1em" viewBox="0 0 24 24"
                fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                  fill="currentColor"></path>
              </svg>
              <span class="caret d-none"></span>
            </button>
          </div>

        </div>
        <div class="flex justify-center items-center z-100"
          style="position: relative ;padding-top: 15px; margin-left: 7%;">
          <ul v-if="isopenchange" style="width: 40%;">
            <div class="flex items-center">
              <a href="/affiliateLogin/changeLanguage?lang=vietnam" rel="vi" @click.prevent="changeLanguage('vi')">
                <img src="https://thanh.salekit.net/assets/images/flag/vi.svg" style="display: inline;">
                <p style="display: inline;font-size: 12px; "> Tiếng Việt</p>
              </a>
            </div>
            <div class="flex items-center" style="padding-top: 10px;">
              <a href="/affiliateLogin/changeLanguage?lang=english" rel="en" @click.prevent="changeLanguage('en')">
                <img src="https://thanh.salekit.net/assets/images/flag/en.svg" style="display: inline;">
                <p style="display: inline;font-size: 12px;"> English</p>
              </a>
            </div>

          </ul>
        </div>



      </div>
    </div>
    <div>
      <div className="bg-gray-100 px-8 pb-4 pt-4 text-center">
        <p className="mb-4 text-gray-600 text-sm">
          {{ $t('salekit_agent.salekit_agent')}} {{datashop.name }}
        </p>
        <button @click="this.$router.push('/register')"
          className="px-3 rounded-lg transition duration-200 justify-center items-center whitespace-nowrap leading-none focus:outline-none border h-10 w-full flex text-primary-400 border-primary-400 hover:text-primary-300 hover:border-primary-300 max-w-lg !text-nm capitalize"
          type="button" style="    --tw-border-opacity: 1;border-color: rgb(27 63 228 / var(--tw-border-opacity));">
          {{ $t('register_now_upcase.register_now_upcase') }}
        </button>
        <div
          style="padding-bottom: 10px ;padding-top:10px ;margin-left: 0px;display: flex; justify-content: space-between;">
          <p class="relative  " style="display: inline; "> <i data-v-3fcda164="" class="fa fa-envelope "></i>
            {{ datashop.email }} </p>
          <p class="relative " style="display: inline;">{{ datashop.phone }} <i data-v-3fcda164=""
              class="fa fa-headphones"></i></p>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.dropdown {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blur-effect {
  filter: blur(5px);
}
</style>
<script>
// import { useStore } from 'vuex';
import i18n from "@/i18n";

// import { getshiptest } from "@/services/shipping/shipping";
import { useAuth } from "../../../context/AuthContext";
import { getShopCurren } from "@/services/affiliate/affiliate";
import { getLogin } from "../../../services/authentication/authService";
import { getPositions } from "@/services/authentication/authService";


export default {
  name: "LoginPage",
  i18n: i18n,
  data() {
    return {
      email: '',
      password: '',
      phonenumber: '',
      error: '',
      member_data: '',
      showPassword: false,
      loginfalse: '',
      isloginemail: true,
      isloading: false,
      locale: 'en',
      flagImage: 'https://salekit.io/assets/images/flag/vi.svg',
      datashop:'',
      lang: '',
      isopenchange: false,
      Username: '',
     
    };
  },
  computed: {
    
  },
  mounted() {
   
    // console.log("datashop",localStorage.getItem('getshop'))
    this.getlang();
    // this.getosition("dropship");
    this.createdFirst()
    // if(localStorage.getItem('Username') && localStorage.getItem('getshop') ){
    //   this.datashop = JSON.parse( localStorage.getItem('getshop'));
    //   document.title =  this.datashop.seo_title;
    //   var metaDescription = document.createElement('meta');
    //   metaDescription.name = "description";
    //   metaDescription.content =  this.datashop.seo_description;
    //   document.head.appendChild(metaDescription);
    // }

  },
  async created() {
   
  },
  methods: {
     
    async createdFirst(){
      const currentUrl = window.location.href;
      const match = currentUrl.match(/https:\/\/([^.]+)\.salekit/);
      let Username1;
      
      if (match && match[1]) {
        this.Username = Username1 = match[1];
        // console.log(this.Username);
        if (localStorage.getItem('Username')) {
          this.getosition(Username1);
          const response1 = await getShopCurren(Username1);
          this.datashop = response1.data.data;
          localStorage.setItem('getshop', JSON.stringify(response1.data.data));

          document.title = this.datashop.seo_title;
          var metaDescription = document.createElement('meta');
          metaDescription.name = "description";
          metaDescription.content = this.datashop.seo_description;
          document.head.appendChild(metaDescription);
        } else {
          // console.log("ko");
          if(localStorage.getItem('getshop') === (undefined || null) ){
          localStorage.removeItem('getshop');
          return this.createdFirst();
          }
          localStorage.setItem('Username', Username1);
          const response1 = await getShopCurren(Username1);
          this.getosition(Username1);
          this.datashop = response1.data.data;
          localStorage.setItem('getshop', JSON.stringify(response1.data.data));

          document.title = this.datashop.seo_title;
          metaDescription = document.createElement('meta');
          metaDescription.name = "description";
          metaDescription.content = this.datashop.seo_description;
          document.head.appendChild(metaDescription);


        }
      }
    }
    ,
    openchange() {
      this.isopenchange = !this.isopenchange;
    },
    changeLanguage(language) {
      // if (language === 'vi') {
      //   window.$cookies.set('lang', 'vi');
      // } else {
      //   window.$cookies.set('lang', 'en');
      // }
      if (window.$cookies.get('lang') === language) return;
      window.$cookies.set('lang', language);
      return window.location.reload();

    },
    getlang() {
      this.lang = window.$cookies.get('lang');
      if (this.lang === 'vi') {
        return this.flagImage = 'https://salekit.io/assets/images/flag/vi.svg';
      } else {
        return this.flagImage = 'https://salekit.io/assets/images/flag/en.svg';
      }
    },

    loginphone() {
      this.isloginemail = false;
      this.username = '';
    },
    loginemail() {
      this.isloginemail = true;
      this.phonenumber = '';
    },
    changeemail(email){
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async handleLogin() {
      this.isloading = true;

      try {
        if (this.isloginemail === true) {
          if(!this.email){
            this.error = this.$t('re_enter_your_email.re_enter_your_email');
            this.isloading = false;
            return  ;
          }else if(!this.changeemail(this.email)){
            this.error = this.$t('re_enter_your_email.re_enter_your_email');
            this.isloading = false;
            return  ;
          }
          else{
            this.error = "";
          }
          if (this.email && this.password) {
            const Username = localStorage.getItem("Username");
            const user = await getLogin({ email: this.email, password: this.password, Username: Username });
            // console.log(user.status)
            if (user.status != 1) {
              // if(user.status == 4){
              //     console.log("1",user.status)
              //     location.reload();
              // }
              this.isloading = false;
              this.error = this.$t(`${user.msg_lang_code}.${user.msg_lang_code}`)
            
            }
            // console.log(user)
            localStorage.setItem("customer_id", user.data.id);
            if (this.member_data.price === "0") {
              localStorage.setItem('datauserInfo', JSON.stringify(user.data));
              this.$router.push("/home").then(() => {
                // location.reload();
              });
            } else {
              if (user.data.package_type) {
                localStorage.setItem('datauserInfo', JSON.stringify(user.data));
                this.$router.push("/home").then(() => {
                  // location.reload();
                });
              } else {
                this.$router.push("/platformfee");
                localStorage.setItem("islogin", false);
              }
            }

            if (useAuth.value) {
              this.$router.push("/");
              localStorage.setItem("islogin", false);
            }
          } else {
            this.isloading = false;
            
            //this.loginfalse = 'Vui lòng nhập đầy đủ thông tin';
            localStorage.setItem("islogin", false);
          }
        } else {
          if (this.phonenumber && this.password) {
            if (this.phonenumber === "") {
              this.error= "Vui lòng nhập số điện thoại";
              this.isloading = false;
              return;
            } else if (
              !/^(0[3|5|7|8|9]|\+84)([0-9]{8})$/.test(this.phonenumber)
            ) {
              this.error = "Số điện thoại không đúng định dạng";
              this.isloading = false;
              return;
            } else {
              this.error = "";
            }
            const Username = localStorage.getItem("Username");
            const user = await getLogin({ phone: this.phonenumber, password: this.password, Username: Username });
            // console.log(user)
            if (user.status != 1) {
              this.isloading = false;
              // const toast = useToast();
              this.error = this.$t(`${user.msg_lang_code}.${user.msg_lang_code}`);
              // toast.error(this.$t(`${user.msg_lang_code}.${user.msg_lang_code}`));
              // setTimeout(() => {
              //   toast.clear();
              // }, 3000);
            }
            localStorage.setItem("customer_id", user.data.id);
            if (this.member_data.price === "0") {
              localStorage.setItem('datauserInfo', JSON.stringify(user.data));
              this.isloading = true;
              this.$router.push("/home").then(() => {
                // location.reload();
              });
            } else {
              if (user.data.package_type) {
                this.isloading = false;
                localStorage.setItem('datauserInfo', JSON.stringify(user.data));
                this.$router.push("/home").then(() => {
                  // location.reload();
                });
              } else {
                this.isloading = false;
                this.$router.push("/platformfee");
                localStorage.setItem("islogin", false);
              }
            }

            if (useAuth.value) {
              this.isloading = false;
              this.$router.push("/");
              localStorage.setItem("islogin", false);
            }
          } else {
            this.isloading = false;
           
            //this.loginfalse = 'Vui lòng nhập đầy đủ thông tin';
            localStorage.setItem("islogin", false);
          }
        }

      } catch (error) {
        this.isloading = false;
        localStorage.setItem("islogin", false);
        console.error("loi", error);
      }
    },
    updatePassword() {

    },
    toggleShowPassword() {
      // Đặt logic để hiển thị hoặc ẩn mật khẩu ở đây nếu cần
      this.showPassword = !this.showPassword;
    },
    async getosition(username) {
      const response = await getPositions(username);
      if(response.data.status ==1){
        const Positions = response.data.data;
         this.member_data = JSON.parse(Positions.member_data);
        //  console.log( this.member_data);
      }else{
        console.log(response.data.msg);
      }
      
      // this.member_data = JSON.parse(Positions.member_data);
      // console.log(this.member_data);

    },
  },

};
</script>
